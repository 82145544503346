import React, { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconTemplate } from 'assets/inline-svg/icons/icon__template.svg';
import { ReactComponent as IconDownload } from 'assets/inline-svg/icons/icon__download.svg';
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import styles from "./UploadForm.module.scss";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { FileProcessingStatus, UploadPersonnelResult } from "features/mobiscan/draft/domain/models";
import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { AppConfig } from "config/AppConfig";

interface UploadFormProps {
  draftId: string,
  fileProcessingStatus: FileProcessingStatus,
  onFileUploadStart: () => void,
  onFileUploadFinished: () => void,
  onFileUploadError: () => void,
}

export function UploadForm(props: UploadFormProps) {
  return <>
    <TemplateDownload />
    <Info />
    <UploadControls
      draftId={ props.draftId }
      fileProcessingStatus={ props.fileProcessingStatus }
      onFileUploadStart={ props.onFileUploadStart }
      onFileUploadFinished={ props.onFileUploadFinished }
      onFileUploadError={ props.onFileUploadError }
    />
  </>;
}


function TemplateDownload() {
  const { t } = useTranslation();

  return <div className={ styles.template }>
    <a href={ AppConfig.API_URL + '/share/personeelsgegevens.xlsx' } className={ styles.templateLink }>
      <span className={ styles.templateDownload }>
        <span className={ styles.templateDownloadIcon }><IconTemplate /></span>
        <span className={ styles.templateDownloadLabel }>{ t('edit_personnel_tab_template_download_cta') }</span>
      </span>
      <span className={ styles.templateIcon }><IconDownload /></span>
    </a>
  </div>;
}


function Info() {
  const { t } = useTranslation();

  return (
    <div className={ styles.tips }>
      <div className={ styles.tipsTitle }>{ t('edit_personnel_tab_template_info_title') }</div>
      <ul className={ styles.tipsList }>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_1') }</span>
        </li>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_2') }</span>
        </li>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_3') }</span>
        </li>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_4') }</span>
        </li>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_5') }</span>
        </li>
        <li>
          <IconArrow />
          <span>{ t('edit_personnel_tab_template_info_6') }</span>
        </li>
      </ul>
      <div className={ styles.tipsTitle }>{ t('edit_personnel_tab_template_tip_title') }</div>
      <p className={ styles.tipContent }>{ t('edit_personnel_tab_template_tip') }</p>
    </div>
  );
}



interface UploadControlsProps {
  draftId: string,
  onFileUploadStart: () => void,
  onFileUploadFinished: () => void,
  onFileUploadError: () => void,
  fileProcessingStatus: FileProcessingStatus,
}

function UploadControls(props: UploadControlsProps) {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const uploadFile = useCallback(async () => {
    try {
      if (selectedFile) {
        props.onFileUploadStart();
        const result = await mobiscanDraftServices.uploadPersonnelFile(props.draftId, selectedFile);
        if (result.validationResult.isValid) {
          props.onFileUploadFinished();
          return { result: result };
        } else {
          return { validationResult: result.validationResult };
        }
      }
      throw Error('No file selected');
    } catch (e) {
      props.onFileUploadError();
      throw e;
    }
  }, [props, selectedFile]);

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<UploadPersonnelResult>(uploadFile, false);


  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (selectedFile) {
      executeFetch();
    }
  };

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return <div className={ styles.uploadFormContainer }>

    {
      fetchStatus !== FetchStatus.pending &&
      props.fileProcessingStatus === FileProcessingStatus.finished &&
      <MessageBox icon={ MessageBoxIcon.info } message={ t('edit_personnel_tab_template_success') } />
    }

    {
      fetchStatus === FetchStatus.unknownError &&
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    }

    <form className={ styles.uploadForm } onSubmit={ onFormSubmit }>

      {
        fetchStatus !== FetchStatus.pending && props.fileProcessingStatus !== FileProcessingStatus.pending &&
        <div className={ styles.uploadFormItem }>
          <label htmlFor="file" className="visually-hidden">
            { t('edit_personnel_tab_template_file_label') }
          </label>
          {/* <input type="file" id="file" style={ { display: 'none' } }  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={ onFileChanged } /> */ }
          {/* <input type="button" value="Browse..." onClick={ () => document.getElementById('file')!.click() } /> */ }
          <input type="file" id="file" name="filename" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={ onFileChanged } />
        </div>
      }

      <div className={ styles.uploadFormAction }>
        {
          fetchStatus === FetchStatus.pending &&
          <LoadingIndicator message={ t('edit_personnel_tab_template_uploading') } />
        }
        {
          props.fileProcessingStatus === FileProcessingStatus.pending &&
          <LoadingIndicator message={ t('edit_personnel_tab_template_processing') } />
        }
        {
          fetchStatus !== FetchStatus.pending &&
          props.fileProcessingStatus !== FileProcessingStatus.pending &&
          <button className="btn-primary" type="submit" disabled={ selectedFile == null }>
            { t('edit_personnel_tab_template_file_submit') }
          </button>
        }
      </div>

      {
        validationResult && <ValidationErrorBox messages={ validationResult.messages } />
      }
    </form>
  </div>;
}
