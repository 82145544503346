import { Layout } from "features/common/ui/layout/Layout";
import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { RootState, useAppSelector } from "redux/store";
import { useTranslation } from "react-i18next";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import * as routes from "routes";

export function RegisterFinishedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const registerResult = useAppSelector((state: RootState) => state.registration.registerResult);
  const joinEnterpriseResult = useAppSelector((state: RootState) => state.registration.joinEnterpriseResult);

  useEffect(() => {
    if ((registerResult == null || registerResult.success !== true)
      && (joinEnterpriseResult == null || joinEnterpriseResult.success !== true)) {
      navigate(routes.REGISTER);
    }
  }, [navigate, registerResult, joinEnterpriseResult]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('register_success_back') } targetUrl={ routes.LOGIN } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('register_success_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <PageContent />
  </Layout>;
}


function PageContent() {
  const { t } = useTranslation();
  return <>
    <div className="content-wrapper">
      <div className="inner-wrapper">
        <MessageBox
          icon={ MessageBoxIcon.info }
          message={ t('register_success_content_text') }
          actions={
            <a href={ t('register_success_content_button_link') }>
              { t('register_success_content_button') }
            </a>
          } />
      </div>
    </div >
  </>;
}
