import { useTranslation } from "react-i18next";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { useCallback, useEffect, useState } from "react";
import { RegistrationOverview } from "features/province/ui/registration/RegistrationOverview";
import { EnterpriseOverview } from "features/province/ui/enterprise/EnterpriseOverview";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import styles from "./ProvinceHomePage.module.scss";
import tabHeaderStyles from "features/common/ui/tabs/Tab.headerStyles.module.scss";
import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import { ProvinceUserMenu } from "features/province/ui/ProvinceUserMenu";
import { ProvinceMobiscanOverview } from "features/province/ui/mobiscan/ProvinceMobiscanOverview";
import * as provinceServices from "features/province/domain/provinceServices";
import { useAppDispatch } from "redux/store";
import { getUser } from "features/auth/domain/authOperations";

export default function ProvinceHomePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [tabPanelToRender, setTabPanelToRender] = useState<JSX.Element>(<></>);

  const fetchUser = useCallback(async () => {
    provinceServices.logoutFromEnterprise();
    await dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <div className={ styles.introBlock }>
          <h1 className={ styles.introTitle }>{ t('province_home_title') }</h1>
        </div>
      </PageIntroItem>
      <PageIntroItem position={ IntroPosition.right }>
        <ProvinceUserMenu />
      </PageIntroItem>
      <PageIntroItem position={ IntroPosition.bottom }>
        <TabContainer onRenderTabPanel={ setTabPanelToRender } titleWrapperClassName={ tabHeaderStyles.headerTabsWrapper }>
          <Tab>
            <TabTitle><BasicTabTitle text={ t('province_home_tab_registrations') } /></TabTitle>
            <TabPanel>
              <RegistrationOverview />
            </TabPanel>
          </Tab>
          <Tab>
            <TabTitle><BasicTabTitle text={ t('province_home_tab_organisations') } /></TabTitle>
            <TabPanel>
              <EnterpriseOverview />
            </TabPanel>
          </Tab>
          <Tab>
            <TabTitle><BasicTabTitle text={ t('province_home_tab_mobiscans') } /></TabTitle>
            <TabPanel>
              <ProvinceMobiscanOverview />
            </TabPanel>
          </Tab>
        </TabContainer>
      </PageIntroItem>
    </PageIntro>

    { tabPanelToRender }

  </Layout>
}
