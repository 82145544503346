import * as dateUtil from "features/common/util/date";
import { CompletedMobiscanOverviewItem, DraftMobiscanOverviewItem, InProgressMobiscanOverviewItem, MobiscanOverviewItem, MobiscanStatus } from "features/mobiscan/overview/domain/models";
import { EstablishmentScope } from "features/mobiscan/permissions/domain/definitions";
import EstablishmentGate from "features/mobiscan/permissions/ui/EstablishmentGate";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./MobiscanList.module.scss";
import * as routes from "routes";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import * as mobiscanServices from "features/mobiscan/overview/domain/mobiscanServices";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import { roundNumber } from "features/common/util/math";
import { LoadingIndicator, LoadingIndicatorDisplayType } from "features/common/ui/LoadingIndicator";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ConfirmDeleteModal } from "features/common/ui/ConfirmDeleteModal";

function getStatusOrder(status: MobiscanStatus): number {
  switch (status) {
    case MobiscanStatus.IN_PROGRESS:
      return 0;
    case MobiscanStatus.DRAFT:
      return 1;
    case MobiscanStatus.COMPLETE:
      return 2;
  }
}

interface MobiscanListProps {
  mobiscans: MobiscanOverviewItem[],
  refreshMobiscans: () => Promise<void>;
}
export function MobiscanList(props: MobiscanListProps) {
  const { t } = useTranslation();

  const sortedMobiscans = props.mobiscans.sort((a, b) => {
    // Sort by status in IN_PROGRESS -> DRAFT -> COMPLETE
    if (a.status !== b.status) {
      return getStatusOrder(a.status) - getStatusOrder(b.status);
    }

    // If status the same: sort by date
    const dateA = a.startedDate ?? a.modifiedDate;
    const dateB = b.startedDate ?? b.modifiedDate;
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <>
      {
        sortedMobiscans.length > 0
          ? <ol className={ styles.scanList }>{
            sortedMobiscans.map((mobiscan) => {
              if (mobiscan.status === MobiscanStatus.DRAFT) {
                return <li key={ mobiscan.id }><MobiscanDraftListItem draft={ mobiscan as DraftMobiscanOverviewItem } refreshMobiscans={ props.refreshMobiscans } /></li>
              } else if (mobiscan.status === MobiscanStatus.IN_PROGRESS) {
                return <li key={ mobiscan.id }><MobiscanInProgressListItem mobiscan={ mobiscan as InProgressMobiscanOverviewItem } refreshMobiscans={ props.refreshMobiscans } /></li>
              } else if (mobiscan.status === MobiscanStatus.COMPLETE) {
                return <li key={ mobiscan.id }><MobiscanCompletedListItem mobiscan={ mobiscan as CompletedMobiscanOverviewItem } /></li>
              } else {
                return <></>;
              }
            })
          }
          </ol>
          :
          <div className={ styles.emptyNotice }>
            <div className={ styles.emptyNoticeInner }>
              { t('mobiscan_overview_empty') }
            </div>
          </div>
      }
    </>
  );
}


interface MobiscanDraftListItemProps {
  draft: DraftMobiscanOverviewItem;
  refreshMobiscans: () => Promise<void>;
}
function MobiscanDraftListItem(props: MobiscanDraftListItemProps) {
  const draft = props.draft;
  const { t } = useTranslation();
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  const { mutateAsync: removeDraft, isPending: isDeleteLoading } = useMutation({
    mutationFn: async () => {
      setConfirmDeleteModalVisible(false);
      await mobiscanDraftServices.deleteDraft(draft.id);
    },
    onSuccess: props.refreshMobiscans,
  });

  return (
    <article className={ styles.item } data-status="draft">
      <div className={ styles.itemInner }>
        <div className={ styles.itemInfoBlock }>
          <h3 className={ styles.itemTitle }>
            { draft.establishment.name ?? t('establishment_name_unknown') }
          </h3>
          <div className={ styles.itemAddress }>
            { formatAddressLine1(draft.establishment.address) }, { formatAddressLine2(draft.establishment.address) }
          </div>
          <div className={ styles.itemDate }>
            { t('mobiscan_item_draft_date_prefix') + ' ' + dateUtil.formatDateToExpandedString(draft.startedDate) }
          </div>
          <div className={ styles.itemRequester }>
            { t('mobiscan_item_draft_started_by') + ' ' + draft.startedBy.firstName + ' ' + draft.startedBy.lastName }
          </div>
        </div>
        <div className={ styles.itemStatusBlock }>
          <div className={ styles.itemStatusDetails }>
            <div className={ styles.itemStatusDisplay }>
              <div className={ styles.itemStatusDisplayIcon }>
                <img src="/images/icons/icon__status-draft.svg" alt="" />
              </div>
              <div className={ styles.itemStatusDisplayText }>
                <p><strong>{ t('mobiscan_item_draft_title') }</strong></p>
                <p className={ styles.itemStatusDisplaySubtitle }>{ t('mobiscan_item_draft_subtitle') }</p>
              </div>
            </div>
          </div>
          <div className={ styles.itemStatusActions }>
            <EstablishmentGate establishment={ draft.establishment } requiredScope={ EstablishmentScope.EDIT_MOBISCAN_DRAFT }>
              <Link to={ routes.generateEditDraftRoute(draft.id) } className={ `${styles.itemAction}` }>
                <span className="visually-hidden">
                  { t('mobiscan_item_draft_edit_cta') }: { draft.establishment.name ?? t('establishment_name_unknown') }, { t('mobiscan_item_draft_date_prefix') + ' ' + dateUtil.formatDateToExpandedString(draft.startedDate) }
                </span>
                <span className={ styles.itemActionLabel }>{ t('mobiscan_item_draft_edit_label') }</span>
              </Link>
            </EstablishmentGate>

            <EstablishmentGate establishment={ draft.establishment } requiredScope={ EstablishmentScope.DELETE_MOBISCAN_DRAFT }>
              { isDeleteLoading
                ? <LoadingIndicator displayType={ LoadingIndicatorDisplayType.minimal } />
                : <button type="button" onClick={ () => setConfirmDeleteModalVisible(true) } className={ `${styles.itemDelete}` }>
                  <span className="visually-hidden">
                    { t('mobiscan_item_draft_delete_cta') }: { draft.establishment.name ?? t('establishment_name_unknown') }, { t('mobiscan_item_draft_date_prefix') + ' ' + dateUtil.formatDateToExpandedString(draft.startedDate) }
                  </span>
                  <span className={ styles.itemActionLabel }>{ t('mobiscan_item_draft_delete_label') }</span>
                </button> }

              <ConfirmDeleteModal
                isOpen={ confirmDeleteModalVisible }
                message={ t('mobiscan_item_draft_delete_modal_title') }
                onAccept={ removeDraft }
                onCancel={ () => setConfirmDeleteModalVisible(false) }
              />
            </EstablishmentGate>
          </div>
        </div>
      </div>
    </article>
  );
}


interface MobiscanInProgressListItemProps {
  mobiscan: InProgressMobiscanOverviewItem;
  refreshMobiscans: () => Promise<void>;
}
function MobiscanInProgressListItem({ mobiscan, refreshMobiscans }: MobiscanInProgressListItemProps) {
  const { t } = useTranslation();

  const { data: mobiscans } = useQuery({
    queryKey: ['inProgressMobiscan', mobiscan.id],
    queryFn: mobiscanServices.getMobiscans,
    refetchInterval: 5000, // Refetch the data every 5 seconds
    gcTime: 0,
    enabled: true,
  });

  useEffect(() => {
    if (!mobiscans) return;

    const inProgressMobiscan = mobiscans.find((mob) => mob.id === mobiscan.id);
    if (inProgressMobiscan?.status === MobiscanStatus.COMPLETE) {
      refreshMobiscans();
    }
  }, [mobiscans, refreshMobiscans, mobiscan.id]);

  return (
    <article className={ styles.item } data-status="in-progress">
      <div className={ styles.itemInner }>
        <div className={ styles.itemInfoBlock }>
          <h3 className={ styles.itemTitle }>
            { mobiscan.establishment.name ?? t('establishment_name_unknown') }
          </h3>
          <div className={ styles.itemAddress }>
            { formatAddressLine1(mobiscan.establishment.address) }, { formatAddressLine2(mobiscan.establishment.address) }
          </div>
          <div className={ styles.itemDate }>
            { dateUtil.formatDateToExpandedString(mobiscan.startedDate) }
          </div>
          <div className={ styles.itemRequester }>
            { t('mobiscan_item_inprogress_requested_by') + ' ' + mobiscan.startedBy.firstName + ' ' + mobiscan.startedBy.lastName }
          </div>
        </div>
        <div className={ styles.itemStatusBlock }>
          <div className={ styles.itemStatusDetails }>
            <div className={ styles.itemStatusDisplay }>
              <div className={ styles.itemStatusDisplayIcon }>
                <img className={ styles.statusSpinnerMovement } src="/images/loaders/spinner-sticks.svg" alt="" />
                <img className={ styles.statusSpinnerStatic } src="/images/loaders/spinner-sticks--silent.svg" alt="" />
              </div>
              <div className={ styles.itemStatusDisplayText }>
                <p><strong>{ t('mobiscan_item_inprogress_title') }</strong></p>
                <p>{ t('mobiscan_item_inprogress_subtitle') }</p>
              </div>
            </div>
          </div>
          <div className={ styles.itemStatusActions }>
            <Link to={ '#' } aria-hidden={ true } className={ styles.itemAction }>
              <span className="visually-hidden">{ t('mobiscan_item_inprogress_cta') }</span>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}


interface CompletedMobiscanListItemProps {
  mobiscan: CompletedMobiscanOverviewItem;
}
function MobiscanCompletedListItem(props: CompletedMobiscanListItemProps) {
  const mobiscan = props.mobiscan;
  const { t } = useTranslation();

  const currentPercentage = roundNumber(mobiscan.durableActualPercentage, 0);
  const potentialPercentage = roundNumber(mobiscan.durablePotentialPercentage, 0);

  return (
    <article className={ styles.item } data-status="completed">
      <div className={ styles.itemInner }>
        <div className={ styles.itemInfoBlock }>
          <h3 className={ styles.itemTitle }>
            { mobiscan.establishment.name ?? t('establishment_name_unknown') }
          </h3>
          <div className={ styles.itemDate }>
            { dateUtil.formatDateToExpandedString(mobiscan.requestedDate) }
          </div>
          <div className={ styles.itemRequester }>
            { t('mobiscan_item_inprogress_requested_by') + ' ' + mobiscan.requestedBy.firstName + ' ' + mobiscan.requestedBy.lastName }
          </div>
        </div>
        <div className={ styles.itemStatusBlock }>
          <div className={ styles.itemStatusDetails }>
            <div className={ styles.itemSummary }>
              <div className={ styles.itemSummaryBlock }>
                <div className={ styles.itemSummaryCircle } data-type="current">
                  <div className={ styles.itemSummaryCircleBackground }></div>
                  <div className={ styles.itemSummaryCircleContent }>
                    <p className={ styles.itemSummaryCircleValue }>{ currentPercentage }<span>%</span></p>
                    <p className={ styles.itemSummaryCircleDescription }>{ t('mobiscan_item_completed_current_percentage') }</p>
                  </div>
                </div>
              </div>
              <div className={ styles.itemSummaryBlock }>
                <div className={ styles.itemSummaryCircle } data-type="potential">
                  <div className={ styles.itemSummaryCircleBackground }></div>
                  <div className={ styles.itemSummaryCircleContent }>
                    <p className={ styles.itemSummaryCircleValue }>{ potentialPercentage }<span>%</span></p>
                    <p className={ styles.itemSummaryCircleDescription }>{ t('mobiscan_item_completed_potential_percentage') }</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.itemStatusActions }>
            <Link to={ routes.generateReportRoute(mobiscan.id) } className={ `${styles.itemAction} ${styles.itemActionClickable}` }>
              <span className="visually-hidden">
                { t('mobiscan_item_completed_cta') }: { mobiscan.establishment.name ?? t('establishment_name_unknown') }, { dateUtil.formatDateToExpandedString(mobiscan.requestedDate) }
              </span>
              <span className={ styles.itemActionLabel }>{ t('mobiscan_item_completed_label') }</span>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}
