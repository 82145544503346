import { createSlice } from "@reduxjs/toolkit";
import { SetLoggedInAction, SetUserAction } from "features/auth/domain/actions";
import { AuthSliceState } from "features/auth/domain/models";

const initialState: AuthSliceState = {
  loggedIn: false,
  user: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action: SetLoggedInAction) => {
      state.loggedIn = action.payload;
    },
    setUser: (state, action: SetUserAction) => {
      state.user = action.payload;
    }
  }
});

export const {
  setLoggedIn,
  setUser,
} = authSlice.actions;

export default authSlice.reducer;