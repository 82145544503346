export interface ProvinceStorage {
  getProvinceEnterpriseCbeNumber(): Promise<string | undefined>;
  persistProvinceEnterpriseCbeNumber(provinceEnterpriseCbeNumber: string): Promise<void>;
  deleteProvinceEnterpriseCbeNumber(): Promise<void>;
}

export class ProvinceStorageImpl implements ProvinceStorage {

  private static readonly PROVINCE_ENTERPRISE_CBE_NUMBER = 'MOBIS_PROVINCE_ENTERPRISE_CBE_NUMBER';

  async getProvinceEnterpriseCbeNumber(): Promise<string | undefined> {
    return localStorage.getItem(ProvinceStorageImpl.PROVINCE_ENTERPRISE_CBE_NUMBER) ?? undefined;
  }

  async persistProvinceEnterpriseCbeNumber(provinceEnterpriseCbeNumber: string): Promise<void> {
    localStorage.setItem(ProvinceStorageImpl.PROVINCE_ENTERPRISE_CBE_NUMBER, provinceEnterpriseCbeNumber);
  }

  async deleteProvinceEnterpriseCbeNumber(): Promise<void> {
    localStorage.removeItem(ProvinceStorageImpl.PROVINCE_ENTERPRISE_CBE_NUMBER);
  }

}