import ReactModal from 'react-modal';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconClose } from 'assets/inline-svg/icons/icon__close.svg';
import styles from "./IframeModal.module.scss";

interface IframeModalProps {
  isOpen: boolean,
  onRequestClose: () => void,
  targetUrl: string,
  iframeTitle?: string, // Accessibility of iframe
  contentLabel?: string,  // Accessibility of modal
}

export function IframeModal(props: IframeModalProps) {
  const { t } = useTranslation();

  return <ReactModal
    isOpen={ props.isOpen }
    contentLabel={ props.contentLabel }
    onRequestClose={ props.onRequestClose }
    shouldCloseOnOverlayClick={ true }
    ariaHideApp={ true }
    className={ styles.modal }
    overlayClassName={ {
      base: styles.modalOverlay,
      afterOpen: styles.modalOverlayAfterOpen,
      beforeClose: styles.modalOverlayBeforeClose,
    } }
  >

    <div className={ styles.modalContent }>
      <div className={ styles.modalHeader }>
        <button type="button" className={ styles.modalCloseButton } onClick={ props.onRequestClose } >
          <span className="visually-hidden">{ t('label_close_modal') }</span>
          <IconClose />
        </button>
      </div>

      <div className={ styles.modalMain }>
        <iframe className={ styles.modalIframe } title={ props.iframeTitle } src={ props.targetUrl }></iframe>
      </div>
    </div>

  </ReactModal >;
}

