import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapMobiscanOverviewItemDTOsFromJsonList } from "features/mobiscan/overview/data/mappers";
import { MobiscanOverviewItemDTO } from "features/mobiscan/overview/data/models";

export interface MobiscanApi {
  fetchMobiscans(): Promise<MobiscanOverviewItemDTO[]>;
}

export class MobiscanApiImpl implements MobiscanApi {

  async fetchMobiscans(): Promise<MobiscanOverviewItemDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan/';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapMobiscanOverviewItemDTOsFromJsonList(json.mobiscans);
    }

    throw new ApiException(response.status);
  }

}