
import { logout } from "features/auth/domain/authOperations";
import { MobiscanOverview } from "features/mobiscan/overview/ui/MobiscanOverview";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { User } from "features/auth/domain/models";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import styles from "./HomePage.module.scss";
import { ReactComponent as IconLogout } from 'assets/inline-svg/icons/icon__logout.svg';
import { ReactComponent as IconEdit } from 'assets/inline-svg/icons/icon__edit-pencil.svg';
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";
import { ModalButton } from "features/common/ui/ModalButton";
import { TreffikUrls } from "features/i18n/TreffikUrls";

export default function HomePage() {
  const user = useAppSelector((state) => state.auth.user!);
  const { t } = useTranslation();

  //TODO: handle unconfirmed enterprise/enterprise
  return <Layout>
    {
      user.enterprise
        ?
        user.enterprise.confirmed
          ? <div>
            <PageIntro>
              <PageIntroItem position={ IntroPosition.left }>
                <div className={ styles.introBlock }>
                  <h1 className={ styles.introTitle }>{ user.enterprise.name }</h1>
                  <p className={ styles.introDescription }><b>{ t('home_steps_title') }</b></p>
                  <ol className={ styles.introList }>
                    <li>
                      <p>{ t('home_steps_1.1') }</p>
                      <p>{ t('home_steps_1.2') }</p>
                    </li>
                    <li>{ t('home_steps_2') }</li>
                    <li>{ t('home_steps_3') }</li>
                  </ol>
                  <p>{ t('home_steps_final') }</p>
                  <br />
                  <Trans
                    i18nKey={ 'home_more_info' }
                    components={ {
                      anchor: <ModalButton
                        targetUrl={ TreffikUrls.contactUrl }
                        iframeTitle={ t('home_more_info_a11y_iframe_title') }
                        modalContentLabel={ t('home_more_info_a11y_modal_content_label') }
                        className={ styles.introLink } />
                    } }
                  />
                </div>
              </PageIntroItem>

              <PageIntroItem position={ IntroPosition.right }>
                <UserMenu user={ user } />
              </PageIntroItem>
            </PageIntro>

            <MobiscanOverview />
          </div>
          : <UnconfirmedEnterpriseInfo />
        : <ErrorBox errorMessage={ "No enterprise set yet" } />
    }
  </Layout>
}


interface UserMenuProps {
  user: User,
}

function UserMenu(props: UserMenuProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return <article className={ styles.userBlock }>
    <header className={ styles.userBlockHeader }>
      <div className={ styles.userBlockHeaderTop }>
        <h2 className={ styles.userBlockHeaderTopTitle }>
          { t('home_menu_user_prefix') }
        </h2>
        <div className={ styles.userBlockHeaderTopActions }>
          <button type="button" onClick={ (e) => dispatch(logout()) } className={ styles.userBlockHeaderLogout }>
            <span>{ t('home_menu_user_logout') }</span>
            <IconLogout />
          </button>
        </div>
      </div>
      <div className={ styles.userBlockHeaderContent }>
        <div className={ styles.userBlockUserDetails }>
          <div className={ styles.userBlockUserDetailsName }>
            { props.user.firstName + ' ' + props.user.lastName }
          </div>
          <div className={ styles.userBlockUserDetailsAdditional }>
            { props.user.email }
          </div>
        </div>
        <div className={ styles.userBlockUserActions }>
          <Link to={ routes.USER } className={ styles.userBlockUserAction }>
            { t('home_menu_user_edit') }
            <span className="visually-hidden">{ props.user.firstName + ' ' + props.user.lastName }</span>
          </Link>
        </div>
      </div>
    </header>
    <div className={ styles.userBlockActions }>
      <ScopeGate requiredScope={ Scope.MANAGE_ESTABLISHMENTS }>
        <div className={ styles.userBlockAction }>
          <Link to={ routes.ESTABLISHMENTS } className={ styles.userBlockActionLink }>
            <img src="/images/icons/icon__building.svg" alt="" />
            <span className={ styles.userBlockActionLinkText }>{ t('home_menu_establishments_title') }</span>
            <span className={ styles.userBlockActionIcon }><IconEdit /></span>
          </Link>
        </div>
      </ScopeGate>
      <ScopeGate requiredScope={ Scope.MANAGE_USERS }>
        <div className={ styles.userBlockAction }>
          <Link to={ routes.ENTERPRISE_USERS } className={ styles.userBlockActionLink }>
            <img src="/images/icons/icon__users.svg" alt="" />
            <span className={ styles.userBlockActionLinkText }>{ t('home_menu_users_title') }</span>
            <span className={ styles.userBlockActionIcon }><IconEdit /></span>
          </Link>
        </div>
      </ScopeGate>
    </div>
  </article>;
}

function UnconfirmedEnterpriseInfo() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return <div className={ styles.unconfirmedPage }>
    <h1 className={ styles.unconfirmedTitle }>{ t('home_unconfirmed_intro') }</h1>
    <div className={ styles.unconfirmedContent }>
      <p>{ t('home_unconfirmed_description') }</p>
    </div>
    <button type="button" onClick={ (e) => dispatch(logout()) } className={ styles.userBlockHeaderLogout }>
      <span>{ t('home_menu_user_logout') }</span>
      <IconLogout />
    </button>
  </div>
}

