import { Address } from "features/enterprise/domain/models";


export function formatAddressLine1(address: Address): string {
  return `${address.street} ${address.houseNumber}`;
}

export function formatAddressLine2(address: Address): string {
  return `${address.postalCode} ${address.city}`;
}


/**
 * Cleans up and formats CBE number - if an invalid argument is passed, the original string is returned
 * @param cbeNumber Numerical string, 10 characters
 * @returns 
 */
export function formatCbeNumber(cbeNumber: string): string {
  const cleanedUpCbeNumber = cbeNumber.replace(/\D/g, "");
  //Clean up and validate
  if (cleanedUpCbeNumber.length === 10) {
    let formattedCbeNumber = 'BE ';
    formattedCbeNumber += cleanedUpCbeNumber.slice(0, 4) + ' ';
    formattedCbeNumber += cleanedUpCbeNumber.slice(3, 6) + ' ';
    formattedCbeNumber += cleanedUpCbeNumber.slice(6, 9);

    return formattedCbeNumber;
  } else {
    return cbeNumber;

  }
}