import { EstablishmentPermission, Permission, UserRole } from "features/auth/domain/models";
import { RadioButton } from "features/common/ui/forms/RadioButton";
import { EnterpriseUser, Establishment } from "features/enterprise/domain/models";
import { PermissionsEditor } from "features/mobiscan/permissions/ui/PermissionsEditor";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RoleEditor.module.scss";

interface RoleEditorProps {
  radioKey: number,
  enterpriseUser: EnterpriseUser,
  role: UserRole,
  establishments: Establishment[],
  establishmentPermissions: EstablishmentPermission[],
  setEnterpriseUserRole: (enterpriseUser: EnterpriseUser, role: UserRole) => void,
  setEnterpriseUserEstablishmentPermissions: (enterpriseUser: EnterpriseUser, establishmentPermissions: EstablishmentPermission[]) => void
}

export function RoleEditor(props: RoleEditorProps) {
  const { t } = useTranslation();
  const [adminPermissionsSelected, setAdminPermissionsSelected] = useState(props.role === UserRole.ORG_ADMIN);

  const onRoleChanged = (value: boolean) => {
    const role = value ? UserRole.ORG_ADMIN : UserRole.ORG_USER;

    setAdminPermissionsSelected(value);
    props.setEnterpriseUserRole(props.enterpriseUser, role);
  }

  return <div className={ styles.role }>
    <div className={ styles.roleChoice }>
      <RadioButton identifier={ `custom-permissions-${props.radioKey}` } checked={ !adminPermissionsSelected } onChange={ (value) => onRoleChanged(!value) } >
        { t('roles_editor_custom_permissions') }
      </RadioButton>
      <RadioButton identifier={ `admin-role-${props.radioKey}` } checked={ adminPermissionsSelected } onChange={ (value) => onRoleChanged(value) } >
        { t('roles_editor_admin_role') }
      </RadioButton>
    </div>
    {
      adminPermissionsSelected
        ? <AdminPermissionInfo />
        : <PermissionsEditorContainer enterpriseUser={ props.enterpriseUser } establishments={ props.establishments } initialPermissions={ props.establishmentPermissions } setEnterpriseUserEstablishmentPermissions={ props.setEnterpriseUserEstablishmentPermissions } />
    }
  </div>;
}

function AdminPermissionInfo() {

  const { t } = useTranslation();
  return <div className={ styles.roleInfo }>{ t('roles_editor_admin_role_info') }</div>;
}

interface PermissionsEditorContainerProps {
  enterpriseUser: EnterpriseUser,
  establishments: Establishment[],
  initialPermissions: EstablishmentPermission[],
  setEnterpriseUserEstablishmentPermissions: (enterpriseUser: EnterpriseUser, establishmentPermissions: EstablishmentPermission[]) => void
}

function PermissionsEditorContainer(props: PermissionsEditorContainerProps) {
  const { t } = useTranslation();

  const [establishmentPermissions, setEstablishmentPermissions] = useState<EstablishmentPermission[]>(props.initialPermissions);

  const areAllEstablishmentsSelected = establishmentPermissions && establishmentPermissions.length === props.establishments.length;
  const selectedEstablishments = establishmentPermissions.map(permissions => permissions.establishment);

  const addEstablishmentRow = () => {
    const notSelected = props.establishments.filter((establishment) => !selectedEstablishments.includes(establishment));

    if (notSelected.length > 0) {
      selectedEstablishments.push(notSelected[0]);

      const copy = [...establishmentPermissions, {
        establishment: notSelected[0],
        permission: Permission.READ,
      }];

      setEstablishmentPermissions(copy);
      props.setEnterpriseUserEstablishmentPermissions(props.enterpriseUser, copy);
    }
  };

  const onSelectedEstablishmentChanged = (permission: EstablishmentPermission, newEstablishment: Establishment) => {
    const copy = [...establishmentPermissions];
    const index = copy.indexOf(permission);

    copy[index].establishment = newEstablishment;
    setEstablishmentPermissions(copy);
    props.setEnterpriseUserEstablishmentPermissions(props.enterpriseUser, copy);
  };

  const onSelectedEstablishmentDeleted = (permission: EstablishmentPermission) => {
    const index = establishmentPermissions.indexOf(permission);
    const filtered = [...establishmentPermissions].filter((_, i) => i !== index);
    setEstablishmentPermissions(filtered);
    props.setEnterpriseUserEstablishmentPermissions(props.enterpriseUser, filtered);
  };

  const onPermissionChanged = (permission: EstablishmentPermission) => {
    const copy = [...establishmentPermissions];
    const establishmentPermissionThatChanged = copy.find(establishmentPermission => establishmentPermission.establishment.id === permission.establishment.id);

    const index = copy.indexOf(establishmentPermissionThatChanged!);
    copy[index] = permission;
    setEstablishmentPermissions(copy);
    props.setEnterpriseUserEstablishmentPermissions(props.enterpriseUser, copy);
  };

  return <>
    {
      establishmentPermissions.length === 0 &&
      <ErrorBox errorMessage={ t('roles_editor_custom_permissions_no_establishments') } />
    }

    {
      establishmentPermissions.map((establishmentPermission, index) =>
        <PermissionsEditor
          key={ index }
          establishments={ props.establishments }
          selectedEstablishments={ selectedEstablishments }
          establishmentPermission={ establishmentPermission }
          selectedEstablishmentChanged={ (newEstablishment) => onSelectedEstablishmentChanged(establishmentPermission, newEstablishment) }
          permissionChanged={ onPermissionChanged }
          onDeletePressed={ () => onSelectedEstablishmentDeleted(establishmentPermission) }
        />
      )
    }
    { !areAllEstablishmentsSelected &&
      <button type="button" className="btn-secondary btn-secondary--small btn-secondary--add" onClick={ addEstablishmentRow }>
        <span>{ t('roles_editor_custom_permissions_add_establishment') }</span>
      </button> }
  </>;
}
