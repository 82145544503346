import { Footer } from "features/common/ui/layout/Footer";
import { Header } from "features/common/ui/layout/Header";

interface LayoutProps {
  children?: React.ReactNode;
}

export function Layout(props: LayoutProps) {
  return <>
    <Header />
    <main className="main-content">
      { props.children }
    </main>
    <Footer />
  </>;
}
