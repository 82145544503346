import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import * as helpers from "features/validation/domain/helpers";
import { TFunction } from "react-i18next";

export function validateProvinceUserAddForm(firstName: string, lastName: string, jobTitle: string, phone: string, email: string, t: TFunction): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(firstName)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_first_name_empty')));
  }

  if (helpers.isNullOrWhitespace(lastName)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_last_name_empty')));
  }

  if (helpers.isNullOrWhitespace(jobTitle)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_job_title_empty')));
  }

  if (helpers.isNullOrWhitespace(phone)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_phone_empty')));
  }

  if (helpers.isNullOrWhitespace(email)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_email_empty')));
  } else if (!helpers.isEmail(email)) {
    validationMessages.push(helpers.validationMessageFromString(t('province_user_validation_email_incorrect')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}