import { Coordinates } from "features/map/domain/models";
import { Circle, } from "react-leaflet";
import React from "react";
import { TextMarker } from "features/map/ui/TextMarker";


interface RadiusLayerProps {
  center: Coordinates,
}

export function RadiusLayer(props: RadiusLayerProps) {
  const radii = [5000, 15000, 30000]; //in meters

  return <>
    {
      radii.map((radius) => {
        const markerLocation = destVincenty(props.center.latitude, props.center.longitude, 180, radius);
        const markerText = (radius / 1000) + 'km';
        const circleKey = 'basic_radius_' + radius;
        const markerKey = circleKey + '_marker';

        return <React.Fragment key={ 'basic_radius_' + radius }>
          <Circle key={ circleKey } center={ [props.center.latitude, props.center.longitude] } radius={ radius } fill={ false } color={ '#14315C' } />
          <TextMarker key={ markerKey } coordinates={ markerLocation } text={ markerText } />
        </React.Fragment>;
      })
    }
  </>;
}



// Function to calculate a new lat/long based on a point, a distance and an angle (brng) in degrees. 
// Copy pasted this from https://gist.github.com/mathiasbynens/354587/4137c2350d7cee9b757444ef3a6f13a1f69c2abc
// And modified it to avoid TypeScript errors
// So yeah, you could say I basically wrote it myself :)
function destVincenty(lat1: any, lon1: any, brng: any, dist: any): Coordinates {
  var a = 6378137,
    b = 6356752.3142,
    f = 1 / 298.257223563, // WGS-84 ellipsiod
    s = dist,
    alpha1 = toRad(brng),
    sinAlpha1 = Math.sin(alpha1),
    cosAlpha1 = Math.cos(alpha1),
    tanU1 = (1 - f) * Math.tan(toRad(lat1)),
    cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1)), sinU1 = tanU1 * cosU1,
    sigma1 = Math.atan2(tanU1, cosAlpha1),
    sinAlpha = cosU1 * sinAlpha1,
    cosSqAlpha = 1 - sinAlpha * sinAlpha,
    uSq = cosSqAlpha * (a * a - b * b) / (b * b),
    A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq))),
    B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq))),
    sigma = s / (b * A),
    sigmaP = 2 * Math.PI,
    cos2SigmaM = null, sinSigma = null, cosSigma = null, deltaSigma = null;

  while (Math.abs(sigma - sigmaP) > 1e-12) {
    cos2SigmaM = Math.cos(2 * sigma1 + sigma);
    sinSigma = Math.sin(sigma);
    cosSigma = Math.cos(sigma);
    deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) - B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
    sigmaP = sigma;
    sigma = s / (b * A) + deltaSigma;
  };
  var tmp = sinU1 * sinSigma! - cosU1 * cosSigma! * cosAlpha1,
    lat2 = Math.atan2(sinU1 * cosSigma! + cosU1 * sinSigma! * cosAlpha1, (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp)),
    lambda = Math.atan2(sinSigma! * sinAlpha1, cosU1 * cosSigma! - sinU1 * sinSigma! * cosAlpha1),
    C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha)),
    L = lambda - (1 - C) * f * sinAlpha * (sigma + C * sinSigma! * (cos2SigmaM! + C * cosSigma! * (-1 + 2 * cos2SigmaM! * cos2SigmaM!)));
  //revAz = Math.atan2(sinAlpha, -tmp); // final bearing

  return { latitude: toDeg(lat2), longitude: lon1 + toDeg(L) };
};

function toRad(number: any) {
  return number * Math.PI / 180;
}

function toDeg(number: any) {
  return number * 180 / Math.PI;
}
