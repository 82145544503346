import { Layout } from "features/common/ui/layout/Layout";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { DraftInputTabTitle } from "features/mobiscan/draft/ui/DraftInputTabTitle";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import styles from "./QuestionnairePage.module.scss";
import tabVerticalStyles from "features/common/ui/tabs/Tab.verticalStyles.module.scss";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";
import * as draftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { Draft, QuestionnaireField, QuestionnairePageData } from "features/mobiscan/draft/domain/models";
import { mapQuestionnaireLongTabNameToShort } from "features/mobiscan/draft/domain/mappers";
import QuestionnaireForm from "features/mobiscan/draft/ui/questionnaire/QuestionnaireForm";
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import FullPageLoadingIndicator from "features/common/ui/FullPageLoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import { capitalizeFirstLetter } from "features/common/util/string";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";

export default function QuestionnairePage() {
  const { t } = useTranslation();
  const { id, pageName } = useParams();
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [questionnaireFields, setQuestionnaireFields] = useState<QuestionnaireField[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  if (!id) {
    navigate(routes.HOME);
  }

  // Fetch page data
  const fetchPageData = useCallback(async () => {
    const pageData = await draftServices.getQuestionnaireData(id!, pageName!);
    const tab = pageData.tabs[currentTabIndex];
    setQuestionnaireFields(tab.fields);

    if (isInitialLoad) {
      setIsInitialLoad(false);
      const firstTabNotConfirmed = pageData.tabs.findIndex((tab) => !tab.confirmed);
      if (firstTabNotConfirmed !== -1) {
        setCurrentTabIndex(firstTabNotConfirmed);
      }
    }

    return pageData;
  }, [currentTabIndex, id, isInitialLoad, pageName]);

  const pageDataFetch = useFetch<QuestionnairePageData>(fetchPageData, true);

  // Fetch draft
  const fetchDraft = useCallback(async () => {
    const draft = await draftServices.getDraft(id!);
    return draft;
  }, [id]);

  const draftFetch = useFetch<Draft>(fetchDraft, true);

  // Update questionnaire fetch
  const saveQuestionnaireAnswers = useCallback(async () => {
    const draftId = draftFetch.fetchResult!.id;
    const tab = pageDataFetch.fetchResult!.tabs[currentTabIndex];
    const shortTabName = mapQuestionnaireLongTabNameToShort(tab.name);
    await mobiscanDraftServices.updateQuestionnaireAnswers(draftId, pageName!, shortTabName, questionnaireFields);
  }, [currentTabIndex, draftFetch.fetchResult, pageDataFetch.fetchResult, pageName, questionnaireFields]);

  const saveFetch = useFetch<void>(saveQuestionnaireAnswers, false);

  const onNextButtonPressed = async () => {
    await saveFetch.executeFetch();
    await pageDataFetch.executeFetch(); // Update questionnaire to latest data from API
    if (pageDataFetch.fetchStatus === FetchStatus.success && currentTabIndex === pageDataFetch.fetchResult!.tabs.length - 1) {
      navigate(routes.generateEditDraftRoute(id!));
    } else {
      setCurrentTabIndex(currentTabIndex + 1);
    }
  }

  const onTabChanged = async (tabIndex: number) => {
    await saveFetch.executeFetch();
    setCurrentTabIndex(tabIndex);
  };

  const getTreffikInfoUrl = (pageData: QuestionnairePageData): string => {
    const pageName = capitalizeFirstLetter(pageData.name);
    const keyToSearch = `draft${pageName}InfoUrl`;
    const key = Object.keys(TreffikUrls).find((urlKey) => urlKey === keyToSearch);

    return key
      ? TreffikUrls[key as keyof typeof TreffikUrls]
      : '';
  };

  // Render
  if (draftFetch.fetchStatus === FetchStatus.unknownError || pageDataFetch.fetchError === FetchStatus.unknownError) {
    return <div role="alert"> {/* "alert" content will be read out */ }
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    </div>;
  }

  if (draftFetch.fetchStatus === FetchStatus.success && pageDataFetch.fetchStatus === FetchStatus.success) {
    const draft = draftFetch.fetchResult!;
    const pageData = pageDataFetch.fetchResult!;

    return <Layout>
      <PageIntro>
        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('edit_questionnaire_header_back') } targetUrl={ routes.generateEditDraftRoute(id!) } displayType={ BackButtonType.box } />
          <h1>{ t('edit_questionnaire_header_title', { establishmentName: draft.establishment.name }) }</h1>
        </PageIntroItem>
      </PageIntro>

      <div className="content-wrapper">
        <div className={ styles.questionnaireHeader }>
          <h2 className={ styles.policyHeaderTitle }>{ t('edit_questionnaire_' + pageName + '_title') }</h2>
          <ModalButton targetUrl={ getTreffikInfoUrl(pageData) } className="btn-info btn-info--small">
            <IconInfo />
            <span>{ t('edit_questionnaire_' + pageName + '_more_info') }</span>
          </ModalButton>
        </div>
        <TabContainer
          className={ tabVerticalStyles.verticalTabContainer }
          titleWrapperClassName={ tabVerticalStyles.verticalTabSidebar }
          panelWrapperClassName={ tabVerticalStyles.verticalTabContent }
          selectedTabIndex={ currentTabIndex }
          onUpdateTabIndex={ onTabChanged }
        >
          {
            pageData.tabs.map((tab) => {
              const shortTabName = mapQuestionnaireLongTabNameToShort(tab.name);

              return <Tab key={ tab.name }>
                <TabTitle disabled={ saveFetch.fetchStatus === FetchStatus.pending }>
                  <DraftInputTabTitle label={ t('edit_questionnaire_' + pageName + '_tab_' + shortTabName + '_title') } isComplete={ tab.confirmed } />
                </TabTitle>
                <TabPanel>
                  <QuestionnaireForm
                    pageName={ pageName! }
                    tab={ tab }
                    draftId={ id! }
                    backButtonVisible={ currentTabIndex > 0 }
                    onBackPressed={ () => setCurrentTabIndex(currentTabIndex - 1) }
                    onNextPressed={ onNextButtonPressed }
                    fetchStatus={ saveFetch.fetchStatus }
                    fields={ questionnaireFields }
                    onFieldsChanged={ setQuestionnaireFields }
                  />
                </TabPanel>
              </Tab>
            })
          }
        </TabContainer>
      </div>
    </Layout >;
  }

  // Get draft is pending
  return <FullPageLoadingIndicator />;
}
