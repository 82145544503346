import { registerApi } from "features/common/data/repositories";
import { JoinEnterpriseRequest, RegisterRequest } from "features/register/data/models";
import { mapJoinEnterpriseResponse, mapRegisterResponse } from "features/register/domain/mappers";
import { JoinEnterpriseResult, RegisterResult } from "features/register/domain/models";

export async function register(registerRequest: RegisterRequest): Promise<RegisterResult> {
  const response = await registerApi.register(registerRequest);
  return mapRegisterResponse(response);
}

export async function requestJoinEnterprise(joinEnterpriseRequest: JoinEnterpriseRequest): Promise<JoinEnterpriseResult> {
  const response = await registerApi.requestJoinEnterprise(joinEnterpriseRequest);
  return mapJoinEnterpriseResponse(response);
}