import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import styles from "./RegisterForm.module.scss";
import { TextField } from "features/common/ui/forms/TextField";
import { EmailTextField } from "features/common/ui/forms/EmailTextField";
import { PasswordTextField } from "features/common/ui/forms/PasswordTextField";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ModalButton } from "features/common/ui/ModalButton";
import { setRegistrationUserInput } from "features/register/domain/redux";
import { RegistrationUserInput } from "features/register/domain/models";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch, useAppSelector } from "redux/store";
import { ValidationResult } from "features/validation/domain/models";
import { ReactComponent as IconExternal } from 'assets/inline-svg/icons/icon__external.svg';
import { TreffikUrls } from "features/i18n/TreffikUrls";

interface RegisterFormProps {
  onSubmit: () => void,
  submitLabel: string,
  isLoading: boolean,
  validationResult?: ValidationResult,
  hasUnknownError?: boolean,
  hidePasswordField?: boolean,
}

export function RegisterForm(props: RegisterFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userInput = useAppSelector((state: RootState) => state.registration.registrationUserInput);

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit();
  };

  const updateUserInput = (userInput: RegistrationUserInput) => {
    dispatch(setRegistrationUserInput(userInput));
  };

  return <form onSubmit={ onFormSubmit } className="form form--register" noValidate={ true } >
    <div className={ styles.data }>
      <h2 className={ styles.dataTitle }>{ t('register_form_title') }</h2>

      <div className="form-row">
        <TextField identifier="firstName" required={ true } value={ userInput.firstName } label={ t('register_form_first_name') } disabled={ props.isLoading } onChange={ (firstName) => updateUserInput({ ...userInput, firstName, }) } />
      </div>
      <div className="form-row">
        <TextField identifier="lastName" required={ true } value={ userInput.lastName } label={ t('register_form_last_name') } disabled={ props.isLoading } onChange={ (lastName) => updateUserInput({ ...userInput, lastName, }) } />
      </div>
      <div className="form-row">
        <TextField identifier="jobTitle" required={ true } value={ userInput.jobTitle } label={ t('register_form_function') } disabled={ props.isLoading } onChange={ (jobTitle) => updateUserInput({ ...userInput, jobTitle: jobTitle, }) } />
      </div>
      <div className="form-row">
        <TextField identifier="phone" required={ true } value={ userInput.phone } label={ t('register_form_phone') } disabled={ props.isLoading } onChange={ (phone) => updateUserInput({ ...userInput, phone, }) } />
      </div>
      <div className="form-row">
        <EmailTextField identifier="email" required={ true } value={ userInput.email } label={ t('register_form_email') } disabled={ props.isLoading } onChange={ (email) => updateUserInput({ ...userInput, email, }) } />
      </div>
      {
        !props.hidePasswordField &&
        <>
          <div className="form-row">
            <PasswordTextField identifier="password" required={ true } value={ userInput.password } label={ t('register_form_password') } disabled={ props.isLoading } onChange={ (password) => updateUserInput({ ...userInput, password, }) } />
          </div>
          <div className="form-row">
            <p>{ t('password_validation_requirements') }</p>
          </div>
        </>
      }
      <LegaNote />
    </div>
    {
      props.validationResult
      && !props.validationResult.isValid
      && <ValidationErrorBox mainMessage={ t('register_validation_title') } messages={ props.validationResult.messages } />
    }
    {
      props.hasUnknownError
      && <ErrorBox errorMessage={ t('register_magda_unknown_error') } />
    }
    {
      props.isLoading
        ? <LoadingIndicator />
        : <div className="form-actions">
          <div className="form-action form-action--large">
            <button type="submit" className="form-button">{ props.submitLabel }</button>
          </div>
        </div>
    }
  </form>;
}

function LegaNote() {
  const { t } = useTranslation();

  return <>
    <div className={ styles.legal }>
      { t('register_form_legal') }
      <div className={ styles.legalItems }>
        <div className={ styles.legalItem }>
          <ModalButton
            targetUrl={ TreffikUrls.privacyUrl }
            iframeTitle={ t('register_form_legal_button_privacy') }
            modalContentLabel={ t('register_form_legal_button_privacy') }
            className="link-text">
            <span>{ t('register_form_legal_button_privacy') }</span>
            <IconExternal />
          </ModalButton>
        </div>
      </div>
    </div>
  </>;
}
