import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";
import styles from "./ProvinceUserMenu.module.scss";
import { ReactComponent as IconLogout } from 'assets/inline-svg/icons/icon__logout.svg';
import { ReactComponent as IconEdit } from 'assets/inline-svg/icons/icon__edit-pencil.svg';
import * as routes from "routes";
import { logout } from "features/auth/domain/authOperations";
import { Link } from "react-router-dom";

export function ProvinceUserMenu() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user!);

  return <article className={ styles.userBlock }>
    <header className={ styles.userBlockHeader }>
      <div className={ styles.userBlockHeaderTop }>
        <h2 className={ styles.userBlockHeaderTopTitle }>
          { t('home_menu_user_prefix') }
        </h2>
        <div className={ styles.userBlockHeaderTopActions }>
          <button type="button" onClick={ (e) => dispatch(logout()) } className={ styles.userBlockHeaderLogout }>
            <span>{ t('home_menu_user_logout') }</span>
            <IconLogout />
          </button>
        </div>
      </div>
      <div className={ styles.userBlockHeaderContent }>
        <div className={ styles.userBlockUserDetails }>
          <div className={ styles.userBlockUserDetailsName }>
            { user.firstName + ' ' + user.lastName }
          </div>
          <div className={ styles.userBlockUserDetailsAdditional }>
            { user.email }
          </div>
        </div>
        <div className={ styles.userBlockUserActions }>
          <Link to={ routes.PROVINCE_USER } className={ styles.userBlockUserAction }>
            { t('home_menu_user_edit') }
            <span className="visually-hidden">{ user.firstName + ' ' + user.lastName }</span>
          </Link>
        </div>
      </div>
    </header>
    <div className={ styles.userBlockActions }>
      <div className={ styles.userBlockAction }>
        <Link to={ routes.PROVINCE_USERS } className={ styles.userBlockActionLink }>
          <img src="/images/icons/icon__users.svg" alt="" />
          <span className={ styles.userBlockActionLinkText }>{ t('home_menu_province_users_title') }</span>
          <span className={ styles.userBlockActionIcon }><IconEdit /></span>
        </Link>
      </div>

    </div>
  </article>;
}
