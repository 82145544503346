import { Enterprise } from "features/auth/domain/models";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { MessageBox, MessageBoxType, MessageBoxDisplayType } from "features/common/ui/boxes/MessageBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./EnterpriseTile.module.scss";
import { ConfirmDeleteModal } from "features/common/ui/ConfirmDeleteModal";

interface EnterpriseTileProps {
  enterprise: Enterprise,
}

export function EnterpriseTile(props: EnterpriseTileProps) {
  const { t } = useTranslation();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const requestDeleteEnterprise = useCallback(async () => {
    await enterpriseServices.requestDeleteEnterprise(props.enterprise.cbeNumber);
  }, [props.enterprise.cbeNumber]);

  const { executeFetch, fetchStatus } = useFetch<void>(requestDeleteEnterprise, false);

  const onDeleteClicked = () => {
    setConfirmModalVisible(true);
  };

  const onAccept = async () => {
    setConfirmModalVisible(false)
    await executeFetch();
  };

  return <>
    <ConfirmDeleteModal
      isOpen={ confirmModalVisible }
      onAccept={ onAccept }
      onCancel={ () => setConfirmModalVisible(false) }
    />
    <article className={ styles.tile }>
      <header className={ styles.header }>
        <h2 className={ styles.title }>
          { props.enterprise.name }
        </h2>
        <div className={ styles.info }>
          { t('enterprise_tile_cbe_number', { cbeNumber: props.enterprise.cbeNumber }) }
        </div>
      </header>
      <div className={ styles.actions }>
        <div className={ styles.actionItem }>
          {
            (() => {
              if (fetchStatus === FetchStatus.unknownError) {
                return <div className={ styles.actionItemErrorContainer }><ErrorBox errorMessage={ t('general_unknown_error') } /></div>;
              }
              if (fetchStatus === FetchStatus.success) {
                return <MessageBox type={ MessageBoxType.success } displayType={ MessageBoxDisplayType.noBorder } message={ t('enterprise_tile_delete_requested') } />
              }
              if (fetchStatus === FetchStatus.pending) {
                return <LoadingIndicator />
              }
              return <button className={ styles.actionButton } onClick={ onDeleteClicked }>{ t('enterprise_tile_delete') }</button>;
            })()
          }
        </div>
      </div>
    </article>
  </>;
}
