import { useTranslation } from "react-i18next";
import styles from "./EnterpriseTile.module.scss";

interface EnterpriseTileProps {
  enterpriseName: string,
  enterpriseCbeNumber: string,
}

export function EnterpriseTile(props: EnterpriseTileProps) {
  const { t } = useTranslation();

  return <div className={ styles.enterprise }>
    <h3 className={ styles.title }>{ props.enterpriseName }</h3>
    <div className={ styles.text }>
      <p>{ t('register_enterprise_tile_cbe_number', { cbeNumber: props.enterpriseCbeNumber, }) }</p>
    </div>
  </div>;
}