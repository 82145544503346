import { Layout } from "features/common/ui/layout/Layout";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { TemplateTab } from "features/mobiscan/draft/ui/personnel/TemplateTab";
import { TimetableTab } from "features/mobiscan/draft/ui/personnel/TimetableTab";
import { MainTransportTab } from "features/mobiscan/draft/ui/personnel/MainTransportTab";
import { DraftInputTabTitle } from "features/mobiscan/draft/ui/DraftInputTabTitle";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./InputPage.module.scss";
import tabVerticalStyles from "features/common/ui/tabs/Tab.verticalStyles.module.scss";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import * as routes from "routes";
import { Draft, FileProcessingStatus, Personnel } from "features/mobiscan/draft/domain/models";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { useInterval } from "features/common/hooks/UseInterval";
import FullPageLoadingIndicator from "features/common/ui/FullPageLoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import { TreffikUrls } from "features/i18n/TreffikUrls";

const POLLING_INTERVAL = 3000;

export function EditPersonnelPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const getDraft = useCallback(async () => {
    return await mobiscanDraftServices.getDraft(id!);
  }, [id]);

  const { fetchStatus, fetchResult } = useFetch<Draft>(getDraft, true);

  if (id == null) {
    navigate(routes.HOME);
  }

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    return <Layout>
      <PageIntro >
        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('edit_personnel_header_back') } targetUrl={ routes.generateEditDraftRoute(id!) } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('edit_personnel_header_title', { establishmentName: fetchResult!.establishment.name }) }</h1>
        </PageIntroItem>
      </PageIntro>

      <PageContent draftId={ fetchResult!.id } />
    </Layout>;
  }

  // Pending
  return <FullPageLoadingIndicator />;
}


interface PageContentProps {
  draftId: string,
}

function PageContent(props: PageContentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [tabDisabled, setTabDisabled] = useState(true);

  const getPersonnel = useCallback(async () => {
    const result = await mobiscanDraftServices.getPersonnel(props.draftId);
    setPollingEnabled(result.fileProcessingStatus === FileProcessingStatus.pending);
    setTabDisabled(result.fileProcessingStatus !== FileProcessingStatus.finished);
    return result;
  }, [props.draftId]);


  const getDraft = useCallback(async () => {
    return await mobiscanDraftServices.getDraft(props.draftId);
  }, [props.draftId]);


  const personnelFetch = useFetch<Personnel>(getPersonnel, true);
  const draftFetch = useFetch<Draft>(getDraft, true);

  useInterval(() => {
    if (pollingEnabled) {
      personnelFetch.executeFetch();
    }
  }, POLLING_INTERVAL);

  const onFileUploadStarted = () => {
    setTabDisabled(true);
  };

  const onFileUploadError = async () => {
    await personnelFetch.executeFetch();
  };

  const onFileUploadFinished = () => {
    setPollingEnabled(true);
    setTabDisabled(true);
    personnelFetch.executeFetch();
  };

  const onSituationSaved = async () => {
    setCurrentTabIndex(currentTabIndex + 1);
    // Update states so it contains the new values
    await draftFetch.executeFetch();
  };

  const onTimetableSaved = async () => {
    setCurrentTabIndex(currentTabIndex + 1);
    // Update states so it contains the new values
    await personnelFetch.executeFetch();
  };

  const uploadTabComplete = (draftFetch.fetchResult && draftFetch.fetchResult?.mobiscanSituation != null) ?? false;
  const timetableTabComplete = (personnelFetch.fetchResult && personnelFetch.fetchResult.timeTableErrors?.length === 0) ?? false;
  const transportTabComplete = (personnelFetch.fetchResult && personnelFetch.fetchResult.transportErrors?.length === 0) ?? false;
  const fileProcessingStatus = personnelFetch?.fetchResult?.fileProcessingStatus ?? (pollingEnabled ? FileProcessingStatus.pending : undefined);

  return <div className="content-wrapper">

    <div className={ styles.header }>
      <h1 className="page-title">{ t('edit_personnel_title') }</h1>
      <ModalButton targetUrl={ TreffikUrls.draftPersonnelInfoUrl } className="btn-info btn-info--small">
        <IconInfo />
        <span>{ t('edit_personnel_more_info') }</span>
      </ModalButton>
    </div>

    <TabContainer
      className={ tabVerticalStyles.verticalTabContainer }
      titleWrapperClassName={ tabVerticalStyles.verticalTabSidebar }
      panelWrapperClassName={ tabVerticalStyles.verticalTabContent }
      selectedTabIndex={ currentTabIndex }
      onUpdateTabIndex={ setCurrentTabIndex }>

      <Tab>
        <TabTitle>
          <DraftInputTabTitle label={ t('edit_personnel_tab_template') } isComplete={ uploadTabComplete } />
        </TabTitle>
        <TabPanel>
          {
            fileProcessingStatus == null || draftFetch.fetchResult == null
              ? <LoadingIndicator />
              : <TemplateTab
                draft={ draftFetch.fetchResult }
                fileProcessingStatus={ fileProcessingStatus }
                onSaveFinished={ onSituationSaved }
                onFileUploadFinished={ onFileUploadFinished }
                onFileUploadStart={ onFileUploadStarted }
                onFileUploadError={ onFileUploadError }
              />
          }
        </TabPanel>
      </Tab>

      <Tab>
        <TabTitle disabled={ tabDisabled }>
          <DraftInputTabTitle label={ t('edit_personnel_tab_timetable') } isComplete={ timetableTabComplete } />
        </TabTitle>
        <TabPanel>
          {
            personnelFetch.fetchResult == null
              ? <LoadingIndicator />
              : <TimetableTab
                draftId={ props.draftId }
                parseErrors={ personnelFetch.fetchResult.timeTableErrors! }
                onSaveFinished={ onTimetableSaved }
                onPreviousPressed={ () => setCurrentTabIndex(currentTabIndex - 1) }
              />
          }
        </TabPanel>
      </Tab>

      <Tab>
        <TabTitle disabled={ tabDisabled }>
          <DraftInputTabTitle label={ t('edit_personnel_tab_transport') } isComplete={ transportTabComplete } />
        </TabTitle>
        <TabPanel>
          {
            personnelFetch.fetchResult == null
              ? <LoadingIndicator />
              : <MainTransportTab
                draftId={ props.draftId }
                parseErrors={ personnelFetch.fetchResult.transportErrors! }
                onSaveFinished={ () => navigate(routes.generateEditDraftRoute(props.draftId)) }
                onPreviousPressed={ () => setCurrentTabIndex(currentTabIndex - 1) }
              />
          }
        </TabPanel>
      </Tab>

    </TabContainer>
  </div>;
}
