import { mapQuestionnaireLongTabNameToShort } from "features/mobiscan/draft/domain/mappers";
import { QuestionnaireField, QuestionnaireFieldType, QuestionnaireTab } from "features/mobiscan/draft/domain/models";
import { useTranslation } from "react-i18next";
import styles from "./QuestionnaireForm.module.scss";
import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";
import { NumericField } from "features/common/ui/forms/NumericField";
import { SwitchField, SwitchOption } from "features/common/ui/forms/SwitchField";
import { TextField } from "features/common/ui/forms/TextField";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";

interface QuestionnaireFormProps {
  pageName: string,
  tab: QuestionnaireTab,
  draftId: string,
  backButtonVisible: boolean,
  onBackPressed: () => any,
  onNextPressed: () => any,
  fetchStatus: FetchStatus,
  fields: QuestionnaireField[],
  onFieldsChanged: (fields: QuestionnaireField[]) => any,
}

export default function QuestionnaireForm(props: QuestionnaireFormProps) {
  const { t } = useTranslation();
  const { pageName, tab } = props;
  const shortTabName = mapQuestionnaireLongTabNameToShort(tab.name);

  const onFieldUpdated = (field: QuestionnaireField, newValue?: string) => {
    const fieldsCopy = [...props.fields];
    const editedField = fieldsCopy.find((f) => f.name === field.name);
    editedField!.value = newValue;
    props.onFieldsChanged(fieldsCopy);
  };

  return <>
    <h3 className={ styles.questionnaireTitle }>{ t('edit_questionnaire_' + pageName + '_tab_' + shortTabName + '_title') }</h3>
    <form onSubmit={ (e) => e.preventDefault() }>
      <div className={ styles.questionnaireOptions }>
        {
          props.fields.map((field) => ((
            <div className={ styles.policyFormItem } key={ field.name }>
              <FormFieldFactory
                disabled={ props.fetchStatus === FetchStatus.pending }
                field={ field }
                onFieldUpdated={ (value) => onFieldUpdated(field, value) }
              />
            </div>
          )))
        }
      </div>
      <div className={ styles.policyActions }>
        {
          props.fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
        }
        {
          props.fetchStatus === FetchStatus.pending
            ? <LoadingIndicator />
            : <>
              {
                props.backButtonVisible && <BackButton label={ t('edit_questionnaire_back') } targetUrl="#" displayType={ BackButtonType.text } onClick={ props.onBackPressed } />
              }
              <button className="btn-primary" onClick={ props.onNextPressed }>{ t('edit_questionnaire_next') }</button>
            </>
        }
      </div>
    </form>
  </>;
}


interface FormFieldFactoryProps {
  disabled: boolean,
  field: QuestionnaireField,
  onFieldUpdated: (newValue?: string) => void
}

function FormFieldFactory(props: FormFieldFactoryProps) {
  const { field, onFieldUpdated, disabled } = props;
  const { t } = useTranslation();

  switch (field.type) {
    case QuestionnaireFieldType.BIG_DECIMAL:
      return <>
        <NumericField
          identifier={ field.name }
          value={ parseFloat(field.value ?? '0') }
          onChange={ (value) => onFieldUpdated(value.toString()) }
          label={ field.label }
          allowDecimalPoint={ true }
          disabled={ disabled }
          min={ 0 }
          decimalPlaces={ 2 }
        />
      </>;

    case QuestionnaireFieldType.INTEGER:
      return <>
        <NumericField
          identifier={ field.name }
          value={ parseInt(field.value ?? '0') }
          onChange={ (value) => onFieldUpdated(value.toString()) }
          label={ field.label }
          disabled={ disabled }
          min={ 0 }
          decimalPlaces={ 2 }
        />
      </>;

    case QuestionnaireFieldType.POTENTIAL:
      const options = possibleValuesToSwitchOptions(field.possibleValues!);
      const currentOption = options.find((option) => option.value === field.value);

      return <>
        <SwitchField
          identifier={ field.name }
          options={ options }
          onOptionSelected={ (e) => onFieldUpdated(e.value) }
          currentOption={ currentOption }
          label={ field.label }
          disabled={ disabled }
        />
      </>;

    case QuestionnaireFieldType.COMPANY_BICYCLE_CONTRACT_TYPE:
      const dropdownOptions = possibleValuesToDropdownOptions(field.possibleValues!);
      const selectedOption = dropdownOptions.find((option) => option.value === field.value);

      return <>
        <Dropdown
          identifier={ field.name }
          options={ dropdownOptions }
          label={ field.label }
          onChange={ onFieldUpdated }
          selectedOption={ selectedOption }
          placeholderText={ t('edit_questionnaire_dropdown_placeholder') }
          disabled={ disabled }
          allowSelectPlaceholder={ true }
        />
      </>;

    case QuestionnaireFieldType.STRING:
      return <>
        <TextField
          identifier={ field.name }
          value={ field.value ?? '' }
          onChange={ onFieldUpdated }
          label={ field.label }
          disabled={ disabled }
        />
      </>;

    default:
      throw Error('Unknown field type');
  }
}


function possibleValuesToSwitchOptions(possibleValues: Map<string, string>): SwitchOption[] {
  const switchOptions: SwitchOption[] = [];
  possibleValues.forEach((value, key) => {
    switchOptions.push({ value: key, label: value });
  });
  return switchOptions;
}

function possibleValuesToDropdownOptions(possibleValues: Map<string, string>): DropdownOption[] {
  const dropdownOptions: DropdownOption[] = [];
  possibleValues.forEach((value, key) => {
    dropdownOptions.push({ value: key, text: value });
  });
  return dropdownOptions;
}
