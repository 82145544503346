import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { EmailTextField } from "features/common/ui/forms/EmailTextField";
import { TextField } from "features/common/ui/forms/TextField";
import { Layout } from "features/common/ui/layout/Layout";
import { FormEvent, useState } from "react";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { useTranslation } from "react-i18next";
import * as routes from "routes";
import { validateProvinceUserAddForm } from "features/province/domain/validation";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ProvinceUser } from "features/province/domain/models";
import * as provinceServices from "features/province/domain/provinceServices";
import { useNavigate } from "react-router";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { ValidationResult } from "features/validation/domain/models";

export function ProvinceUserAdd() {
  const { t } = useTranslation();
  const provinceUser = {
    id: 0,
    firstName: '',
    lastName: '',
    jobTitle: 'Provincie medewerker',
    phone: '',
    email: ''
  }

  return <ScopeGate requiredScope={ Scope.MANAGE_USERS }>
    <Layout>
      <PageIntro>

        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('province_users_header_back_to_overview') } targetUrl={ routes.PROVINCE_USERS } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('province_users_header_title') }</h1>
        </PageIntroItem>

      </PageIntro>

      <div className="content-wrapper">
        <div className="inner-wrapper">
          <ProvinceUserDataForm initialValues={ provinceUser } />
        </div>
      </div>

    </Layout>
  </ScopeGate>;
}

interface ProvinceUserDataFormProps {
  initialValues: ProvinceUser
}

function ProvinceUserDataForm(props: ProvinceUserDataFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const sendAddRequest = async () => {
    setValidationResult(undefined);

    // Client side validate
    const validationResult = validateProvinceUserAddForm(firstName, lastName, jobTitle, phone, email, t);
    if (!validationResult.isValid) {
      setValidationResult(validationResult);
      return;
    }

    const result = await provinceServices.createProvinceUser({
      id: 0,
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      phone: phone,
      email: email,
    });

    if (!result.success) {
      setValidationResult(result.validationResult);
      return;
    }

    navigate(routes.PROVINCE_USERS);
  }

  const { executeFetch, fetchStatus } = useFetch<void>(sendAddRequest, false);

  const [firstName, setFirstName] = useState(props.initialValues.firstName);
  const [lastName, setLastName] = useState(props.initialValues.lastName);
  const [jobTitle, setJobTitle] = useState(props.initialValues.jobTitle);
  const [phone, setPhone] = useState(props.initialValues.phone);
  const [email, setEmail] = useState(props.initialValues.email);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    executeFetch();
  }

  return <form className="form form--user-info" onSubmit={ onSubmit } noValidate={ true }>
    <div className="form-row">
      <TextField identifier='first-name' value={ firstName } onChange={ setFirstName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('province_users_first_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='last-name' value={ lastName } onChange={ setLastName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('province_users_last_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='job-title' value={ jobTitle } onChange={ setJobTitle } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('province_users_job_title') } />
    </div>
    <div className="form-row">
      <TextField identifier='phone' value={ phone } onChange={ setPhone } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('province_users_phone') } />
    </div>
    <div className="form-row">
      <EmailTextField identifier='email' value={ email } onChange={ setEmail } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('province_users_email') } />
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('province_users_add_unknown_error') } />
      }

      {
        validationResult
        && !validationResult.isValid
        && <ValidationErrorBox mainMessage={ t('user_validation_title') } messages={ validationResult.messages } />
      }

    </div>


    <div className="form-actions">
      <div className="form-action form-action--large">
        {
          fetchStatus === FetchStatus.pending &&
          <button
            className="form-button"
            type='submit'
            disabled
          >
            { t('province_users_add_is_saving') }
          </button>
        }

        {
          fetchStatus !== FetchStatus.pending &&
          <button
            className="form-button"
            type='submit'
          >
            { t('province_users_add') }
          </button>
        }
      </div>
    </div>

  </form>
}
