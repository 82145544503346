import { UpdatePasswordResponse, UpdateUserResponse } from "features/user/data/models";

export function mapUpdateUserSuccessResponse(): UpdateUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapUpdateUserValidationErrors(json: any): UpdateUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}


export function mapUpdatePasswordSuccessResponse(): UpdatePasswordResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapUpdatePasswordValidationErrors(json: any): UpdatePasswordResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}
