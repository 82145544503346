import { getUser } from "features/auth/domain/authOperations";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "redux/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { Retry } from "features/common/ui/Retry";
import FullPageLoadingIndicator from "features/common/ui/FullPageLoadingIndicator";

interface RequireAuthProps {
  children?: React.ReactNode;
}

export default function VerifyAuth(props: RequireAuthProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isGettingUser, setIsGettingUser] = useState(true);
  const [hasUnknownError, setHasUnknownError] = useState(false);

  const fetchUser = useCallback(async () => {
    setIsGettingUser(true);
    setHasUnknownError(false);

    try {
      const getUserAction = await dispatch(getUser());
      unwrapResult(getUserAction);
    } catch (exception) {
      if (exception !== 'NotLoggedInException') {
        setHasUnknownError(true);
      }
    }

    setIsGettingUser(false);
  }, [dispatch]);


  useEffect(() => {
    fetchUser();
  }, [fetchUser]);


  if (hasUnknownError) {
    return <>
      <Retry onConfirmed={ fetchUser } title={ t('general_unknown_error') } />
    </>;
  }

  if (isGettingUser) {
    return <>
      <FullPageLoadingIndicator />
    </>;
  }

  return <>
    { props.children }
  </>;
}