import { Address, Establishment } from "features/enterprise/domain/models";
import { MobiscanUser } from "features/mobiscan/overview/domain/models";
import { ValidationResult } from "features/validation/domain/models";

export interface QuestionnairePageData {
  draftId: string,
  name: string,
  tabs: QuestionnaireTab[],
}

export interface QuestionnaireTab {
  name: string,
  confirmed: boolean,
  fields: QuestionnaireField[]
}

export interface QuestionnaireField {
  name: string,
  label: string,
  type: QuestionnaireFieldType,
  value?: string,
  possibleValues?: Map<string, string>
}

export enum QuestionnaireFieldType {
  POTENTIAL = 'Potential',
  BIG_DECIMAL = 'BigDecimal',
  COMPANY_BICYCLE_CONTRACT_TYPE = 'CompanyBicycleContractType',
  STRING = 'String',
  INTEGER = 'Integer',
}

export enum FileProcessingStatus {
  vacant,
  pending,
  finished,
}

export interface UploadPersonnelResult {
  validationResult: ValidationResult,
}

export interface Personnel {
  fileProcessingStatus: FileProcessingStatus,
  timeTableErrors?: PersonnelParseError[],
  transportErrors?: PersonnelParseError[],
}

export interface PersonnelParseError {
  id: number,
  row: number,
  address: Address,
  wrongValue: string,
  error: string,
}

export interface PersonnelParseErrorCorrection {
  parseError: PersonnelParseError,
  correctionValue: string,
}

export interface Draft {
  id: string,

  infrastructureTabFinished: boolean,
  policyTabFinished: boolean,
  personnelTabFinished: boolean,
  readyToSubmit: boolean,

  enterpriseNumber: string,
  establishment: Establishment,
  createdBy: MobiscanUser,
  geocoded: boolean,
  benchmarkSector?: string,
  mobiscanSituation?: string,
  creationDateTime: string,
  modificationDateTime: Date,
  version: number,
}

export interface UpdateQuestionnaireFieldsDto {
  [key: string]: string | undefined
}
