import { Layout } from "features/common/ui/layout/Layout";
import { AlreadyRegistered } from "features/register/ui/cases/AlreadyRegistered";
import { ConfirmEstablishment } from "features/register/ui/cases/ConfirmEstablishment";
import { NoEstablishments } from "features/register/ui/cases/NoEstablishments";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RootState, useAppSelector } from "redux/store";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";

export function RegisterStepTwoPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const enterpriseRegistrationInfo = useAppSelector((state: RootState) => state.registration.enterpriseInfo);

  useEffect(() => {
    if (enterpriseRegistrationInfo == null || enterpriseRegistrationInfo.enterprise == null) {
      navigate(routes.REGISTER);
    }
  }, [navigate, enterpriseRegistrationInfo]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('register_page_header_back') } targetUrl={ routes.LOGIN } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('register_page_header_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <PageContent />
  </Layout>;
}


function PageContent() {
  const enterpriseRegistrationInfo = useAppSelector((state: RootState) => state.registration.enterpriseInfo);

  if (!enterpriseRegistrationInfo) {
    return <></>;
  } else if (enterpriseRegistrationInfo.registered === true) {
    return <AlreadyRegistered enterprise={ enterpriseRegistrationInfo.enterprise } />;
  } else if (enterpriseRegistrationInfo!.enterprise!.establishments.length === 0) {
    return <NoEstablishments />;
  } else {
    return <ConfirmEstablishment enterprise={ enterpriseRegistrationInfo.enterprise } />
  }
}
