import ForgotPasswordPage from "features/auth/ui/ForgotPasswordPage";
import LoginPage from "features/auth/ui/LoginPage";
import RegisterPage from "features/register/ui/RegisterPage";
import VerifyAuth from "features/auth/ui/VerifyAuth";
import HomePage from "features/home/ui/HomePage";
import { Navigate, Route, Routes } from "react-router";
import { useAppSelector } from "redux/store";
import './styles/global-styles.scss';
import { ResetPasswordPage } from "features/auth/ui/ResetPasswordPage";
import { RegisterStepTwoPage } from "features/register/ui/RegisterStepTwoPage";
import { RegisterFinishedPage } from "features/register/ui/RegisterFinishedPage";
import { UserRole } from "features/auth/domain/models";
import ProvinceHomePage from "features/province/ui/ProvinceHomePage";
import { UserPage } from "features/user/ui/UserPage";
import { PasswordPage } from "features/user/ui/PasswordPage";
import { EstablishmentsPage } from "features/enterprise/ui/EstablishmentsPage";
import { AddEstablishmentPage } from "features/enterprise/ui/AddEstablishmentPage";
import CreateDraftPage from "features/mobiscan/draft/ui/CreateDraftPage";
import EditDraftPage from "features/mobiscan/draft/ui/EditDraftPage";
import QuestionnairePage from "features/mobiscan/draft/ui/questionnaire/QuestionnairePage";
import { EditPersonnelPage } from "features/mobiscan/draft/ui/EditPersonnelPage";
import ReportPage from "features/mobiscan/report/ui/ReportPage";
import { EnterpriseUserOverview } from "features/enterprise/ui/EnterpriseUserOverview";
import { EnterpriseUserAdd } from "features/enterprise/ui/EnterpriseUserAdd";
import RegistrationDetailPage from "features/province/ui/registration/RegistrationDetailPage";
import * as routes from "routes";
import { ProvinceUserOverview } from "features/province/ui/user/ProvinceUserOverview";
import { ProvinceUserAdd } from "features/province/ui/user/ProvinceUserAdd";
import { SharedReportPage } from "features/mobiscan/report/ui/SharedReportPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const userLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const userRole = useAppSelector((state) => state.auth.user?.role);


  //TODO: check transition animation: https://codesandbox.io/s/6l1li?file=/src/index.tsx
  return (
    <QueryClientProvider client={ queryClient }>
      <div className="mobiscan-app">
        <VerifyAuth>
          <Routes>
            {/* Auth doesn't matter */ }
            <Route path={ routes.MOBISCAN_SHARED_REPORT } element={ <SharedReportPage /> } />

            {/* Logged in */ }
            { userLoggedIn
              ? <>
                <Route path={ routes.HOME } element={ <HomePage /> } />
                <Route path={ routes.USER } element={ <UserPage homeRoute={ routes.HOME } changePasswordRoute={ routes.USER_CHANGE_PASSWORD } /> } />
                <Route path={ routes.USER_CHANGE_PASSWORD } element={ <PasswordPage homeRoute={ routes.HOME } userRoute={ routes.USER } /> } />
                <Route path={ routes.ESTABLISHMENTS } element={ <EstablishmentsPage /> } />
                <Route path={ routes.ESTABLISHMENTS_ADD } element={ <AddEstablishmentPage /> } />
                <Route path={ routes.ENTERPRISE_USERS } element={ <EnterpriseUserOverview /> } />
                <Route path={ routes.ENTERPRISE_USERS_ADD } element={ <EnterpriseUserAdd /> } />

                <Route path={ routes.CREATE_DRAFT } element={ <CreateDraftPage /> } />
                <Route path={ routes.EDIT_DRAFT } element={ <EditDraftPage /> } />
                <Route path={ routes.DRAFT_EDIT_QUESTIONNAIRE } element={ <QuestionnairePage /> } />
                <Route path={ routes.DRAFT_EDIT_PERSONNEL } element={ <EditPersonnelPage /> } />

                <Route path={ routes.MOBISCAN_REPORT } element={ <ReportPage /> } />

                { //Default path is different in case you're a superuser
                  (userRole === UserRole.PROVINCE || userRole === UserRole.ADMIN)
                    ? <>
                      <Route path={ routes.PROVINCE_HOME } element={ <ProvinceHomePage /> } />
                      <Route path={ routes.PROVINCE_USER } element={ <UserPage homeRoute={ routes.PROVINCE_HOME } changePasswordRoute={ routes.PROVINCE_USER_CHANGE_PASSWORD } /> } />
                      <Route path={ routes.PROVINCE_USER_CHANGE_PASSWORD } element={ <PasswordPage homeRoute={ routes.PROVINCE_HOME } userRoute={ routes.PROVINCE_USER } /> } />
                      <Route path={ routes.PROVINCE_USERS } element={ <ProvinceUserOverview /> } />
                      <Route path={ routes.PROVINCE_USERS_ADD } element={ <ProvinceUserAdd /> } />
                      <Route path={ routes.PROVINCE_REGISTRATION_DETAIL } element={ <RegistrationDetailPage /> } />
                      <Route path="*" element={ <Navigate to={ routes.PROVINCE_HOME } replace /> } />
                    </>
                    : <Route path="*" element={ <Navigate to={ routes.HOME } replace /> } />
                }
              </>
              : <>     {/* Not logged in */ }
                <Route path={ routes.LOGIN } element={ <LoginPage /> } />
                <Route path={ routes.REGISTER } element={ <RegisterPage /> } />
                <Route path={ routes.REGISTER_STEP_TWO } element={ <RegisterStepTwoPage /> } />
                <Route path={ routes.REGISTER_FINISHED } element={ <RegisterFinishedPage /> } />
                <Route path={ routes.FORGOT_PASSWORD } element={ <ForgotPasswordPage /> } />
                <Route path={ routes.RESET_PASSWORD } element={ <ResetPasswordPage /> } />
                <Route path="*" element={ <Navigate to={ routes.LOGIN } replace /> } />
              </>
            }
          </Routes>
        </VerifyAuth>
      </div>
    </QueryClientProvider>
  );
}

export default App;
