import { IframeModal } from 'features/common/ui/IframeModal';
import { useState } from 'react';

interface ModalButtonProps {
  children?: React.ReactNode,
  targetUrl: string,
  iframeTitle?: string, // accessibility of iframe
  modalContentLabel?: string, // accessibility of modal
  className?: string,
}

export function ModalButton(props: ModalButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  }

  return <>
    <IframeModal
      isOpen={ modalVisible }
      onRequestClose={ hideModal }
      targetUrl={ props.targetUrl }
      iframeTitle={ props.iframeTitle }
      contentLabel={ props.modalContentLabel }
    />
    <button type="button" className={ props.className } onClick={ showModal }>
      { props.children }
    </button>
  </>;
}
