import { unwrapResult } from "@reduxjs/toolkit";
import { login } from "features/auth/domain/authOperations";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import styles from "./LoginPage.module.scss";
import { ReactComponent as IconList } from 'assets/inline-svg/icons/icon__list.svg';
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";
import { PasswordTextField } from "features/common/ui/forms/PasswordTextField";

export default function LoginPage() {
  const { t } = useTranslation();

  return <Layout>
    <PageIntro>

      <PageIntroItem position={ IntroPosition.top }>
        <h1 className={ styles.introTitle }>{ t('login_page_header_title') }</h1>
      </PageIntroItem>

      <PageIntroItem position={ IntroPosition.left }>
        <PageIntroLeft />
      </PageIntroItem>

      <PageIntroItem position={ IntroPosition.right }>
        <PageIntroRight />
      </PageIntroItem>

    </PageIntro>

    <div className="content-wrapper">
      <div className={ styles.loginRegisterContainer }>
        <LoginCta />
        <RegistrationCta />
      </div>
    </div>
  </Layout>;
}

function PageIntroLeft() {
  const { t } = useTranslation();

  return <div className={ styles.intro }>
    <div className={ styles.introText }>
      <p>{ t('general_mobiscan_description_1') }</p>
      <br />
      <p>{ t('general_mobiscan_description_2') }</p>
    </div>
    <div className={ styles.introMore }>
      <div className={ styles.introMoreTitle }>{ t('login_page_header_cta_title') }</div>
      <div className={ styles.introMoreText } dangerouslySetInnerHTML={ { __html: t('login_page_header_cta') } }></div>
    </div>
  </div>
}

function PageIntroRight() {
  const { t } = useTranslation();

  return <div className={ styles.introListHolder }>
    <ul className={ styles.introList }>
      <li>
        <IconArrow />
        <span>{ t('login_page_header_item1') }</span>
      </li>
      <li>
        <IconArrow />
        <span>{ t('login_page_header_item2') }</span>
      </li>
      <li>
        <IconArrow />
        <span>{ t('login_page_header_item3') }</span>
      </li>
      <li>
        <IconArrow />
        <span>{ t('login_page_header_item4') }</span>
      </li>
    </ul>
    <div className={ styles.introOptions }>
      <div className={ styles.introOptionsTitle }>{ t('login_page_header_links_title') }</div>
      <ul className={ styles.introOptionsList }>
        <li>
          <IconArrow />
          <a href="https://vimeo.com/906757244/845af267d0?share=copy" target="_blank" rel="noopener noreferrer">
            <span>{ t('login_page_header_links_link_1') }</span>
          </a>
        </li>
        <li>
          <IconArrow />
          <a href="https://vimeo.com/906755541/d05e699e30?share=copy" target="_blank" rel="noopener noreferrer">
            <span>{ t('login_page_header_links_link_2') }</span>
          </a>
        </li>
      </ul>
    </div>
  </div>;
}

function LoginCta() {
  const { t } = useTranslation();

  return <div className={ styles.loginBlock }>
    <div className={ styles.loginBlockInner }>
      <h2 className={ styles.blockTitle }>{ t('login_page_login_cta_title') }</h2>
      <LoginForm />
    </div>
  </div>;
}

function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasInvalidCredentials, setHasInvalidCredentials] = useState(false);
  const [hasUnknownError, setHasUnknownError] = useState(false);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoggingIn(true);
    setHasUnknownError(false);
    setHasInvalidCredentials(false);

    try {
      const loginData = { email, password, };
      const loginAction = await dispatch(login(loginData));
      const loginResult = unwrapResult(loginAction);

      setHasInvalidCredentials(!loginResult.success);
      if (loginResult.success) {
        //TODO: redirect to another page?
      }
    } catch (exception) {
      setHasUnknownError(true);
    }

    setIsLoggingIn(false);
  };

  return <>
    {
      hasUnknownError && <ErrorBox errorMessage={ t('login_page_login_cta_unknown_error') } />
    }
    {
      hasInvalidCredentials === true && <ErrorBox errorMessage={ t('login_page_login_cta_invalid_credentials') } />
    }

    <form className={ styles.loginForm } onSubmit={ onFormSubmit }>

      <div className={ styles.loginFormRow }>
        <div className="form-item">
          <label htmlFor="emailaddress">{ t('label_emailaddress') }</label>
          <input id="emailaddress" className={ styles.input } type="email" placeholder={ t('placeholder_emailaddress') } value={ email } onChange={ (e) => setEmail(e.target.value) } disabled={ isLoggingIn } required />
        </div>
      </div>

      <div className={ styles.loginFormRow }>
        <div className="form-item">
          <PasswordTextField identifier="password" value={ password } onChange={ setPassword } disabled={ isLoggingIn } required={ true } label={ t('label_password') } />
        </div>
      </div>

      {
        isLoggingIn
          ? <LoadingIndicator />
          : <div className={ styles.loginFormActions }>
            <button type="submit" className="form-button">
              { t('login_page_login_cta_login_button') }
            </button>
          </div>
      }

      <div className={ styles.passwordResetHolder }>
        <Link to={ routes.FORGOT_PASSWORD } className="link-text">{ t('login_page_login_cta_forgot_password') }</Link>
      </div>

    </form>
  </>;
}

function RegistrationCta() {
  const { t } = useTranslation();

  return <div className={ styles.registerBlock }>
    <div className={ styles.registerBlockInner }>

      <h2 className={ styles.registerTitle }>{ t('login_page_register_cta_title') }</h2>

      <div className={ styles.registerText }>
        <div className="text-content">
          <p dangerouslySetInnerHTML={ { __html: t('login_page_register_cta_body') } }></p>
        </div>
      </div>

      <ul className={ styles.registerList }>
        <li>
          <IconList />
          <span>{ t('login_page_register_cta_item1') }</span>
        </li>
        <li>
          <IconList />
          <span>{ t('login_page_register_cta_item2') }</span>
        </li>
        <li>
          <IconList />
          <span>{ t('login_page_register_cta_item3') }</span>
        </li>
        <li>
          <IconList />
          <span>{ t('login_page_register_cta_item4') }</span>
        </li>
        <li>
          <IconList />
          <span>{ t('login_page_register_cta_item5') }</span>
        </li>
      </ul>

      <p className={ styles.registerCoverageText }>{ t('login_page_register_cta_body_2') }</p>

      <Link to={ routes.REGISTER } className="btn-secondary">{ t('login_page_register_cta_link') }</Link>

    </div>
  </div>
}
