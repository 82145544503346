import React, { useId } from 'react'
import { TextField } from "features/common/ui/forms/TextField";
import { Address, CustomEstablishmentInput } from "features/enterprise/domain/models";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDelete } from 'assets/inline-svg/icons/icon__delete.svg';
import styles from "./CustomEstablishmentsInput.module.scss";
import { ValidationErrorBox } from 'features/validation/ui/ValidationErrorBox';

const createNewCustomEstablishment = () => {
  return {
    name: '',
    address: {
      street: '',
      houseNumber: '',
      box: '',
      city: '',
      postalCode: '',
      country: 'BE',
    },
  };
};

interface CustomEstablishmentsInputProps {
  enableAddButton?: boolean,
  addButtonLabel?: boolean,
  customEstablishments?: CustomEstablishmentInput[],
  onCustomEstablishmentChanged?: (establishment: CustomEstablishmentInput[]) => void,
}

export function CustomEstablishmentsInput(props: CustomEstablishmentsInputProps) {

  const onCustomEstablishmentChanged = (index: number, establishment: CustomEstablishmentInput) => {
    const establishments = [...props.customEstablishments!];
    establishments[index] = establishment;
    setCustomEstablishments(establishments);
  };

  const onDeleteCustomEstablishment = (index: number) => {
    const establishments = [...props.customEstablishments!];
    establishments.splice(index, 1);
    setCustomEstablishments(establishments);
  }

  const onAddCustomEstablishmentClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    addCustomEstablishmentField();
  };

  const addCustomEstablishmentField = () => {
    const newEstablishment = createNewCustomEstablishment();
    setCustomEstablishments([...props.customEstablishments!, newEstablishment]);
  };

  const setCustomEstablishments = (establishments: CustomEstablishmentInput[]) => {
    props.onCustomEstablishmentChanged!(establishments);
  }

  return <>
    {
      props.customEstablishments?.map((establishment, i) =>
        <React.Fragment key={ i }>
          <EstablishmentAddressForm
            establishment={ establishment }
            onChanged={ (establishment) => onCustomEstablishmentChanged(i, establishment) }
            deleteButtonVisible={ true }
            onDelete={ () => onDeleteCustomEstablishment(i) }
          />
          <>
            {
              establishment.validationResult != null &&
              establishment.validationResult.isValid === false &&
              <ValidationErrorBox messages={ establishment.validationResult.messages } />
            }
          </>
        </React.Fragment>
      )
    }
    {
      props.enableAddButton &&
      <div className={ styles.addButtonWrapper }>
        <button className="btn-secondary btn-secondary--small btn-secondary--add" onClick={ onAddCustomEstablishmentClicked }><span>{ props.addButtonLabel }</span></button>
      </div>
    }
  </>;

}


interface EstablishmentAddressFormProps {
  establishment: CustomEstablishmentInput,
  onChanged: (establishment: CustomEstablishmentInput) => void,
  onDelete?: (establishment: CustomEstablishmentInput) => void,
  deleteButtonVisible?: boolean,
}

function EstablishmentAddressForm(props: EstablishmentAddressFormProps) {
  const { t } = useTranslation();
  const formControlId = useId();

  const onChanged = (establishment: CustomEstablishmentInput) => {
    props.onChanged(establishment);
  }

  const onAddressChanged = (address: Address) => {
    const newEstablishment = { ...props.establishment, address };
    props.onChanged(newEstablishment);
  }

  const onDeletePressed = () => {
    if (props.onDelete) {
      props.onDelete(props.establishment);
    }
  };

  return <>
    <div className={ styles.itemOrganization }>
      <div className={ styles.itemOrganizationForm }>
        <div className={ styles.formTitle }>{ t('general_manual_address') }</div>
        <form className={ styles.formAddAddress } onSubmit={ (e) => e.preventDefault() }>
          <div className={ styles.formRow }>
            <div className={ styles.FormItem }>
              <TextField identifier={ "establishment-name" + formControlId } value={ props.establishment.name } onChange={ (name) => onChanged({ ...props.establishment, name }) } label={ t('add_establishment_form_name') } required={ true } />
            </div>
          </div>
          <div className={ styles.formRow }>
            <div className={ styles.FormItem } data-width="50">
              <TextField identifier={ "address-street" + formControlId } value={ props.establishment.address.street } onChange={ (street) => onAddressChanged({ ...props.establishment.address, street }) } label={ t('add_establishment_form_address_street') } required={ true } />
            </div>
            <div className={ styles.FormItem } data-width="25">
              <TextField identifier={ "address-number" + formControlId } value={ props.establishment.address.houseNumber } onChange={ (houseNumber) => onAddressChanged({ ...props.establishment.address, houseNumber }) } label={ t('add_establishment_form_address_number') } required={ true } />
            </div>
            <div className={ styles.FormItem } data-width="25">
              <TextField identifier={ "address-box" + formControlId } value={ props.establishment.address.box ?? '' } onChange={ (box) => onAddressChanged({ ...props.establishment.address, box }) } label={ t('add_establishment_form_address_box') } />
            </div>
          </div>
          <div className={ styles.formRow }>
            <div className={ styles.FormItem } data-width="50">
              <TextField identifier={ "address-postal-code" + formControlId } value={ props.establishment.address.postalCode } onChange={ (postalCode) => onAddressChanged({ ...props.establishment.address, postalCode }) } label={ t('add_establishment_form_address_postcode') } required={ true } />
            </div>
            <div className={ styles.FormItem } data-width="50">
              <TextField identifier={ "address-city" + formControlId } value={ props.establishment.address.city } onChange={ (city) => onAddressChanged({ ...props.establishment.address, city }) } label={ t('add_establishment_form_address_city') } required={ true } />
            </div>
          </div>
        </form>
      </div>
      { props.deleteButtonVisible && <div className={ styles.itemOrganizationDelete }><DeleteButton onClick={ onDeletePressed } /></div> }
    </div>
  </>;
}


interface DeleteButtonProps {
  onClick: () => void,
}

function DeleteButton(props: DeleteButtonProps) {
  const { t } = useTranslation();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.onClick();
  };

  return <button className={ styles.removeOrganizationButton } onClick={ onClick }>
    <IconDelete />
    <span className="visually-hidden">{ t('general_remove_organization') }</span>
  </button>;
}
