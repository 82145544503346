import { ActivityDTO, ScheduleDTO, SituationDTO, TransportModeDTO } from "features/mobiscan/general/data/models";

export function mapActivityDTOFromJson(json: any): ActivityDTO[] {
  return json;
}

export function mapScheduleDTOFromJson(json: any): ScheduleDTO[] {
  return json;
}

export function mapTransportModeDTOFromJson(json: any): TransportModeDTO[] {
  return json;
}

export function mapSituationDTOFromJson(json: any): SituationDTO[] {
  return json;
}
