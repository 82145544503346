import { AppConfig } from "config/AppConfig";
import { UserRole } from "features/auth/domain/models";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapAddCustomEstablishmentSuccess, mapAddCustomEstablishmentValidationErrorsFromJson, mapCreateEnterpriseUserSuccessResponse, mapCreateEnterpriseUserValidationErrors, mapDeleteEnterpriseUserSuccessResponse, mapDeleteEnterpriseUserValidationErrors, mapEnterpriseUserListFromJsonList, mapEstablishmentsResponse, mapUpdateEnterpriseUserSuccessResponse, mapUpdateEnterpriseUserValidationErrors } from "features/enterprise/data/mappers";
import { AddCustomEstablishmentResponse, CreateEnterpriseUserResponse, CustomEstablishmentInputDTO, DeleteEnterpriseUserResponse, EnterpriseUserDTO, EnterpriseUserRequest, EnterpriseUsersRequest, EstablishmentsRequest, EstablishmentsResponse, UpdateEnterpriseUserResponse } from "features/enterprise/data/models";
import { EnterpriseUser } from "features/enterprise/domain/models";

export interface EnterpriseApi {
  fetchEnterpriseUsers(request: EnterpriseUsersRequest): Promise<EnterpriseUserDTO[]>;
  createEnterpriseUser(provinceUser: EnterpriseUser): Promise<CreateEnterpriseUserResponse>;
  deleteEnterpriseUser(id: number): Promise<DeleteEnterpriseUserResponse>;
  updateEnterpriseUser(request: EnterpriseUserRequest): Promise<UpdateEnterpriseUserResponse>;
  getEstablishments(request: EstablishmentsRequest): Promise<EstablishmentsResponse>;
  requestDeleteEnterprise(cbeNumber: string): Promise<void>;
  deleteEstablishment(cbeNumber: string, establishmentId: number): Promise<void>;
  addEstablishment(cbeNumber: string, establishmentCbe: string): Promise<void>;
  addCustomEstablishment(cbeNumber: string, customEstablishment: CustomEstablishmentInputDTO): Promise<AddCustomEstablishmentResponse>;
}

export class EnterpriseApiImpl implements EnterpriseApi {

  async fetchEnterpriseUsers(request: EnterpriseUsersRequest): Promise<EnterpriseUserDTO[]> {
    const url = AppConfig.API_URL + `/users?cbeNumber=${request.cbeNumber}`;
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapEnterpriseUserListFromJsonList(json.users);
    }

    throw new ApiException(response.status);
  }

  async createEnterpriseUser(enterpriseUser: EnterpriseUser): Promise<CreateEnterpriseUserResponse> {
    const url = AppConfig.API_URL + '/users';

    const establishmentPermissions = enterpriseUser.establishmentPermissions?.map((establishmentPermission) => {
      return {
        establishmentId: establishmentPermission.establishment.id,
        orgUserPermission: establishmentPermission.permission
      };
    });

    const newEnterpriseUser = {
      firstName: enterpriseUser.firstName,
      lastName: enterpriseUser.lastName,
      jobTitle: enterpriseUser.jobTitle,
      phone: enterpriseUser.phone,
      email: enterpriseUser.email,
      role: enterpriseUser.role,
      establishmentPermissions: establishmentPermissions,
    };

    const options = { method: 'POST', body: JSON.stringify(newEnterpriseUser) };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapCreateEnterpriseUserSuccessResponse();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapCreateEnterpriseUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

  async deleteEnterpriseUser(id: number): Promise<DeleteEnterpriseUserResponse> {
    const url = AppConfig.API_URL + '/users/' + id;
    const options = { method: 'DELETE' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapDeleteEnterpriseUserSuccessResponse();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapDeleteEnterpriseUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

  async updateEnterpriseUser(request: EnterpriseUserRequest): Promise<UpdateEnterpriseUserResponse> {
    const establishmentPermissions = request.establishmentPermissions?.map((establishmentPermission) => {
      return {
        establishmentId: establishmentPermission.establishment.id,
        orgUserPermission: establishmentPermission.permission
      };
    });

    const enterpriseUser = {
      role: request.role,
      establishmentPermissions: request.role === UserRole.ORG_USER ? establishmentPermissions : []
    };

    const url = AppConfig.API_URL + `/users/${request.id}/role`;
    const options = { method: 'PATCH', body: JSON.stringify(enterpriseUser) };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapUpdateEnterpriseUserSuccessResponse();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapUpdateEnterpriseUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

  async getEstablishments(request: EstablishmentsRequest): Promise<EstablishmentsResponse> {
    const url = AppConfig.API_URL + `/enterprises/${request.cbeNumber}/establishments`;
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapEstablishmentsResponse(json);
    }

    throw new ApiException(response.status);
  }

  async requestDeleteEnterprise(cbeNumber: string): Promise<void> {
    const url = AppConfig.API_URL + `/enterprises/${cbeNumber}/delete`;
    const options = { method: 'POST', };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async deleteEstablishment(cbeNumber: string, establishmentId: number): Promise<void> {
    const url = AppConfig.API_URL + `/enterprises/${cbeNumber}/establishments/${establishmentId}`;
    const options = { method: 'DELETE', };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async addEstablishment(cbeNumber: string, establishmentCbe: string): Promise<void> {
    const url = AppConfig.API_URL + `/enterprises/${cbeNumber}/establishments/${establishmentCbe}`;
    const options = { method: 'POST', };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async addCustomEstablishment(cbeNumber: string, customEstablishment: CustomEstablishmentInputDTO): Promise<AddCustomEstablishmentResponse> {
    const url = AppConfig.API_URL + `/enterprises/${cbeNumber}/establishments`;
    const payload = JSON.stringify(customEstablishment);
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapAddCustomEstablishmentSuccess();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapAddCustomEstablishmentValidationErrorsFromJson(json);
    }

    throw new ApiException(response.status);
  }

}
