export interface Coordinates {
  latitude: number,
  longitude: number,
}


// A key for now. Can be extended
export type MapLayerCollectionKey = string;

export const nmbsStationLayerKey = 'nmbs_stations';
export const basicRadiusesLayerKey = 'basic_radius_collection';
