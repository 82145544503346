export const HOME = '/home';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const REGISTER_STEP_TWO = '/register-step-two';
export const REGISTER_FINISHED = '/register-finished';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';

export const USER = '/user';
export const USER_CHANGE_PASSWORD = '/user/change-password';

export const ESTABLISHMENTS = '/establishments';
export const ESTABLISHMENTS_ADD = '/establishments/add';
export const ENTERPRISE_USERS = '/mobiscan/users';
export const ENTERPRISE_USERS_ADD = '/mobiscan/users/add';

export const CREATE_DRAFT = '/drafts/create';
export const EDIT_DRAFT = '/drafts/:id/edit';
export const DRAFT_EDIT_QUESTIONNAIRE = '/drafts/:id/questionnaire/:pageName';
export const DRAFT_EDIT_PERSONNEL = '/drafts/:id/edit-personnel';

export const MOBISCAN_REPORT = '/mobiscan/:id';
export const MOBISCAN_SHARED_REPORT = '/mobiscan/:id/share/:shareToken';

export const PROVINCE_HOME = '/province/home';
export const PROVINCE_USER = '/province/user';
export const PROVINCE_USER_CHANGE_PASSWORD = '/province/user/change-password';
export const PROVINCE_USERS = '/province/users';
export const PROVINCE_USERS_ADD = '/province/users/add';
export const PROVINCE_REGISTRATION_DETAIL = '/province/registration/:cbeNumber';

export const generateEditDraftRoute = (id: string) => EDIT_DRAFT.replace(':id', id);
export const generateEditQuestionnaireRoute = (id: string, pageName: string) => DRAFT_EDIT_QUESTIONNAIRE.replace(':id', id).replace(':pageName', pageName);
export const generateEditPersonnelRoute = (id: string) => DRAFT_EDIT_PERSONNEL.replace(':id', id);
export const generateReportRoute = (id: string) => MOBISCAN_REPORT.replace(':id', id);
export const generateShareReportRoute = (id: string, shareToken: string) => MOBISCAN_SHARED_REPORT.replace(':id', id).replace(':shareToken', shareToken);
export const generateProvinceRegistrationDetail = (id: string) => PROVINCE_REGISTRATION_DETAIL.replace(':cbeNumber', id);