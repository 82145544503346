import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import * as chartServices from 'features/chart/domain/chartServices';
import { ChartSettings } from 'features/chart/domain/models';
import { roundNumber } from 'features/common/util/math';
import { Establishment } from 'features/enterprise/domain/models';
import { TransportMode } from 'features/mobiscan/general/domain/models';
import { Employee } from 'features/mobiscan/report/domain/models';
import { TFunction } from 'i18next';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



interface PotentialShiftChartProps {
  transportModes: TransportMode[],
  employees: Employee[], // Expects all employees
  establishment: Establishment,
}


export function PotentialShiftChart(props: PotentialShiftChartProps) {
  const { t } = useTranslation();

  const extraChartSettings: ChartSettings = {
    getPopupTitleCallback,
    getPopupBodyCallback,
    getPopupFooterCallback: (context) => getPopupFooterCallback(context, t),
    yAxisLabelText: t('chart_potential_y_label'),
    yAxisLabelFont: {
      size: 13
    },
    showVerticalGrid: false,
  };

  const options = chartServices.createChartOptions(extraChartSettings);
  const data = createData(props.employees, props.transportModes, props.establishment, t);

  return <div className='chart-scroll-outer'>
    <div className='chart-scroll-inner'>
      <Bar options={ options } data={ data } role="img" aria-label={ t('chart_potential_chart_label') } />
    </div>
  </div>;
}

const createData = (employees: Employee[], transportModes: TransportMode[], establishment: Establishment, t: TFunction) => {
  const getLabel = (transportModeId: string) => t('transport_mode_' + transportModeId.toLowerCase());
  const labels = transportModes.map((transportMode) => getLabel(transportMode.id));

  return {
    labels,
    datasets: [
      {
        label: establishment.name,
        data: transportModes.map((transportMode) => {
          const employeesCurrentWithMode = employees.filter((employee) => employee.transportMode.id === transportMode.id);
          return {
            count: employeesCurrentWithMode.length,
            percentage: roundNumber((employeesCurrentWithMode.length / employees.length) * 100),
            label: getLabel(transportMode.id),
          };
        }),
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'percentage'
        },
        backgroundColor: 'rgba(32, 176, 228, 1)',
        hoverBackgroundColor: 'rgba(32, 176, 228, .7)',
        borderRadius: 5,
        // barThickness: 20,
        // maxBarThickness: 30,
        // // barPercentage: 1, // doesn't work in combination with thickness values
        // barPercentage: 0.8,
      },
      {
        label: t('chart_potential_dataset_2_name'),
        data: transportModes.map((transportMode) => {
          const employeesCurrentWithMode = employees.filter((employee) => employee.transportMode.id === transportMode.id);

          const employeesWithPotential = employees.filter((employee) => employee.potentialTransportModes.length > 0);
          const employeesPotentialWithMode = employeesWithPotential.filter((employee) => employee.potentialTransportModes.map((potential) => potential.transportMode.id).includes(transportMode.id));

          const total = employeesCurrentWithMode.length + employeesPotentialWithMode.length;

          return {
            count: total,
            percentage: roundNumber((total / employees.length) * 100),
            label: getLabel(transportMode.id),
            employeesWithPotential: employeesPotentialWithMode.length,
          };
        }),
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'percentage'
        },
        backgroundColor: 'rgba(20, 49, 92, 1)',
        hoverBackgroundColor: 'rgba(20, 49, 92, .7)',
        borderRadius: 5,
        // barThickness: 20,
        // maxBarThickness: 30,
        // // barPercentage: 1, // doesn't work in combination with thickness values
        // barPercentage: 0.8,
      },
    ],
  };
};


const getPopupTitleCallback = (context: any) => {
  return context[0].dataset.label;
};

const getPopupBodyCallback = (context: any) => {
  return context.label;
};

const getPopupFooterCallback = (context: any, t: TFunction) => {
  const count = context[0].raw.count;
  const percentage = context[0].raw.percentage;
  const employeesWithPotential = context[0].raw.employeesWithPotential;

  let countLabel = t('chart_potential_tooltip_count', { total: count });

  if (employeesWithPotential && employeesWithPotential !== count) {
    countLabel = t('chart_potential_tooltip_count_potential', { total: count, potential: employeesWithPotential });
  }

  const percentageLabel = t('chart_potential_tooltip_percentage', { value: percentage, });

  return `${countLabel} \n${percentageLabel}`;
};
