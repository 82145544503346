import styles from "./SwitchField.module.scss";
export interface SwitchOption {
  value: string,
  label: string,
}

interface SwitchFieldProps {
  identifier: string; //To use as html#id
  label?: string;
  options: SwitchOption[],
  currentOption?: SwitchOption,
  onOptionSelected: (option: SwitchOption) => void,
  disabled?: boolean,
}

export function SwitchField(props: SwitchFieldProps) {

  const onChange = (option: SwitchOption, newValue: boolean) => {
    if (newValue && option !== props.currentOption) {
      props.onOptionSelected(option);
    }
  }

  return <fieldset className={ styles.switch }>
    <legend>{ props.label }</legend>
    <div className={ styles.switchItems }>
      {
        props.options.map((option, i) => (
          <SwitchItem
            key={ `${props.identifier}-${i}` }
            option={ option }
            onChange={ (newValue) => onChange(option, newValue) }
            isSelected={ props.currentOption?.value === option.value }
            identifier={ `${props.identifier}-${i}` }
            name={ `${props.identifier}` }
            disabled={ props.disabled }
          />
        ))
      }
    </div>
  </fieldset>;
}


interface SwitchItemProps {
  identifier: string; //To use as html#id
  name: string; // To group radiobuttons
  onChange: (newValue: boolean) => void,
  option: SwitchOption,
  isSelected: boolean,
  disabled?: boolean,
}

function SwitchItem(props: SwitchItemProps) {

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked);
  };

  return <div className={ styles.switchItem }>
    <input type="radio" id={ props.identifier } name={ props.name } checked={ props.isSelected } onChange={ onChange } disabled={ props.disabled } />
    <label htmlFor={ props.identifier }>{ props.option.label }</label >
  </div>;
}
