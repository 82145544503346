import { Coordinates } from "features/map/domain/models";

export function getClosestLongitudeLeft(center: Coordinates, coordinates: Coordinates[]) {
  const coordinatesLeft = coordinates.filter((coordinate) => coordinate.longitude < center.longitude);
  return getClosestLongitude(center, coordinatesLeft);
}

export function getClosestLongitudeRight(center: Coordinates, coordinates: Coordinates[]) {
  const coordinatesLeft = coordinates.filter((coordinate) => coordinate.longitude > center.longitude);
  return getClosestLongitude(center, coordinatesLeft);
}

const getClosestLongitude = (center: Coordinates, coordinates: Coordinates[]) => {
  let closest = null;
  let distance = null;
  for (const coordinate of coordinates) {
    if (closest == null) {
      closest = coordinate;
      distance = Math.abs(coordinate.longitude - center.longitude);
    } else if (Math.abs(coordinate.longitude - center.longitude) < distance!) {
      closest = coordinate;
      distance = Math.abs(coordinate.longitude - center.longitude);
    }
  }
  return closest;
};