import { EstablishmentPermission, UserRole } from "features/auth/domain/models";
import { enterpriseApi } from "features/common/data/repositories";
import { mapAddCustomEstablishmentResponse, mapCreateEnterpriseUserResponse, mapCustomEstablishmentInputToDto, mapDeleteEnterpriseUserResponse, mapEnterpriseUsersFromDTOs, mapEstablishmentsResponse, mapUpdateEnterpriseUserResponse } from "features/enterprise/domain/mappers";
import { AddCustomEstablishmentResult, CreateEnterpriseUserResult, CustomEstablishmentInput, DeleteEnterpriseUserResult, EnterpriseUser, Establishment } from "features/enterprise/domain/models";

export async function getEnterpriseUsers(cbeNumber: string): Promise<EnterpriseUser[]> {
  const dtos = await enterpriseApi.fetchEnterpriseUsers({ cbeNumber });
  return mapEnterpriseUsersFromDTOs(dtos);
}

export async function createEnterpriseUser(enterpriseUser: EnterpriseUser): Promise<CreateEnterpriseUserResult> {
  const result = await enterpriseApi.createEnterpriseUser(enterpriseUser);
  return mapCreateEnterpriseUserResponse(result);
}

export async function updateEnterpriseUser(id: number, role: UserRole, establishmentPermissions?: EstablishmentPermission[]) {
  const result = await enterpriseApi.updateEnterpriseUser({ id: id, role: role, establishmentPermissions: establishmentPermissions });
  return mapUpdateEnterpriseUserResponse(result);
}

export async function deleteEnterpriseUser(id: number): Promise<DeleteEnterpriseUserResult> {
  const result = await enterpriseApi.deleteEnterpriseUser(id);
  return mapDeleteEnterpriseUserResponse(result);
}

export async function getEstablishments(cbeNumber: string): Promise<Establishment[]> {
  const response = await enterpriseApi.getEstablishments({ cbeNumber });
  return mapEstablishmentsResponse(response);
}

export async function deleteEstablishment(cbeNumber: string, establishmentId: number): Promise<void> {
  return await enterpriseApi.deleteEstablishment(cbeNumber, establishmentId);
}

export async function requestDeleteEnterprise(cbeNumber: string): Promise<void> {
  return await enterpriseApi.requestDeleteEnterprise(cbeNumber);
}

export async function addEstablishment(cbeNumber: string, establishmentCbe: string): Promise<void> {
  return await enterpriseApi.addEstablishment(cbeNumber, establishmentCbe);
}

export async function addCustomEstablishment(cbeNumber: string, customEstablishmentInput: CustomEstablishmentInput): Promise<AddCustomEstablishmentResult> {
  const dto = mapCustomEstablishmentInputToDto(customEstablishmentInput);
  const response = await enterpriseApi.addCustomEstablishment(cbeNumber, dto);
  return mapAddCustomEstablishmentResponse(response);
}
