import { permissions, Scope } from "features/mobiscan/permissions/domain/definitions";
import { useAppSelector } from "redux/store";

interface ScopeGateProps {
  requiredScope: Scope,
  children?: React.ReactNode,
}

export default function ScopeGate(props: ScopeGateProps) {
  const user = useAppSelector((state) => state.auth.user!);
  const allowedScopesForUser = permissions[user.role];
  if (allowedScopesForUser.indexOf(props.requiredScope) >= 0) {
    return <>{ props.children }</>
  } else {
    return <></>;
  }
}