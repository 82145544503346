import { MobiscanOverviewItem } from "features/mobiscan/overview/domain/models";
import { ReactNode, useCallback, useEffect, useState } from "react";
import * as mobiscanServices from "features/mobiscan/overview/domain/mobiscanServices";
import { useTranslation } from "react-i18next";
import { Retry } from "features/common/ui/Retry";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { MobiscanList } from "features/mobiscan/overview/ui/MobiscanList";
import { Link } from "react-router-dom";
import styles from "./MobiscanOverview.module.scss";
import { ReactComponent as IconRoute } from 'assets/inline-svg/icons/icon__route.svg';
import { Establishment } from "features/enterprise/domain/models";
import * as routes from "routes";
import Select, { SingleValue } from 'react-select';
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import { ReactComponent as IconSelect } from 'assets/inline-svg/icons/icon__select.svg';
import { MagdaEstablishment } from "features/magda/domain/models";

export function MobiscanOverview() {
  const { t } = useTranslation();
  const [mobiscanList, setMobiscanList] = useState<MobiscanOverviewItem[] | null>(null);
  const [selectedEstablishmentFilter, setSelectedEstablishmentFilter] = useState<Establishment | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchMobiscans = useCallback(async () => {
    setErrorMessage(null);
    try {
      const mobiscans = await mobiscanServices.getMobiscans();
      setMobiscanList(mobiscans);
    } catch (e: any) {
      console.log('Error in getting mobiscans:', e);
      setErrorMessage(t('general_unknown_error'));
    }
  }, [t])

  useEffect(() => {
    fetchMobiscans();
  }, [fetchMobiscans]);

  const filterMobiscans = (): MobiscanOverviewItem[] => {
    if (selectedEstablishmentFilter) {
      return mobiscanList!.filter((mobiscan) => {
        return mobiscan.establishment.id === selectedEstablishmentFilter.id;
      });
    } else {
      return mobiscanList!;
    }
  }

  const distinctEstablishments = mobiscanServices.distinctEstablishmentsFromMobiscanList(mobiscanList ? mobiscanList : []);

  return (
    <article className={ styles.container }>

      <header className={ styles.header }>
        <div className={ styles.headerInner }>

          <div className={ styles.headerBlockFilter }>
            <div className={ styles.headerBlockFilterInner }>
              <div className={ styles.headerFilter }>
                <div className={ styles.headerFilterTitleBlock }>
                  <IconRoute />
                  <h2 className={ styles.headerFilterTitle }>
                    { t('mobiscan_overview_title') }
                  </h2>
                </div>
                <div className={ styles.headerFilterContentBlock }>
                  <div className={ styles.headerFilterContentBlockText }>
                    { t('mobiscan_overview_filter_description') }
                  </div>
                  <MobiscanFilter
                    establishments={ distinctEstablishments }
                    onUpdateSelectedEstablishment={ setSelectedEstablishmentFilter }
                    selectedEstablishment={ selectedEstablishmentFilter }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={ styles.headerBlockActions }>
            <div className={ styles.headerBlockActionsInner }>
              <h3 className={ styles.headerBlockActionsTitle }>{ t('mobiscan_overview_add_mobiscan_title') }</h3>
              <div className={ styles.headerBlockActionsDescription }>
                <p>{ t('mobiscan_overview_add_mobiscan_description') }</p>
              </div>
              <div className={ styles.headerBlockActionsLinks }>
                <Link to={ routes.CREATE_DRAFT } className="btn-secondary btn-secondary--add">
                  <span>{ t('mobiscan_overview_add_mobiscan_button') }</span>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </header>

      {
        (() => {
          if (errorMessage != null) {
            return <Retry onConfirmed={ fetchMobiscans } title={ t('mobiscan_overview_error') } extraInfo={ errorMessage } />
          } else if (mobiscanList == null) {
            return <LoadingIndicator />
          } else {
            return <MobiscanList mobiscans={ filterMobiscans() } refreshMobiscans={ fetchMobiscans } />
          }
        })()
      }

    </article>
  );
}

interface MobiscanFilterProps {
  establishments: Establishment[];
  onUpdateSelectedEstablishment: (establishment: Establishment | null) => void,
  selectedEstablishment: Establishment | null,
}

interface FilterOption {
  establishment: Establishment | MagdaEstablishment | null,
  identifier: string, // id for Establishment, cbeNumber for MagdaEstablishment
  name: string,
}

function MobiscanFilter(props: MobiscanFilterProps) {
  const { t } = useTranslation();

  const allOption: FilterOption = {
    establishment: null,
    identifier: 'all',
    name: t('mobiscan_overview_filter_all'),
  };

  const options: FilterOption[] = [
    allOption,
    ...props.establishments.map((establishment) => (
      {
        establishment,
        identifier: establishment.id.toString(),
        name: establishment.name ?? t('establishment_name_unknown'),
      }
    ))
  ];


  const handleDropdownChange = (dropdownValue: SingleValue<FilterOption>) => {
    if (!dropdownValue) {
      return;
    }
    if (dropdownValue?.identifier === allOption.identifier) {
      props.onUpdateSelectedEstablishment(null);
    } else {
      props.onUpdateSelectedEstablishment(
        props.establishments.find((establishment) => establishment.id.toString() === dropdownValue?.identifier)!
      );
    }
  }

  const formatOption = (option: FilterOption): ReactNode => {
    return <>
      <div className="react-select__organization">
        <span className="react-select__organization__info">
          <span className="react-select__organization__name">
            { option.name }
          </span>
          {
            option.establishment &&
            <span className="react-select__organization__address">
              { formatAddressLine1(option.establishment.address) }, { formatAddressLine2(option.establishment.address) }
            </span>
          }
        </span>
        {
          option.establishment && option.establishment.cbeNumber &&
          <span className="react-select__organization__number">{ t('establishment_selector_cbe_number', { cbeNumber: option.establishment.cbeNumber }) }</span>
        }
      </div>
    </>;
  };

  const currentValue = options.find((option) => option.identifier === props.selectedEstablishment?.id.toString()) ?? allOption;

  return <div className="react-select-container">
    <Select
      classNamePrefix="react-select"
      aria-label={ t('establishment_selector_label') }
      value={ currentValue }
      onChange={ handleDropdownChange }
      options={ options }
      getOptionLabel={ (option) => option.name }
      getOptionValue={ (option) => option.identifier }
      formatOptionLabel={ (option) => formatOption(option) }
      isSearchable={ true }
    />
    <div className="react-select-icon">
      <IconSelect />
    </div>
  </div>;
}
