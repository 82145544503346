import { TextField } from "features/common/ui/forms/TextField";
import { RegisteredEnterprise } from "features/province/domain/models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as provinceServices from "features/province/domain/provinceServices";
import styles from "./EnterpriseFilter.module.scss";

interface EnterpriseFilterProps {
  companies: RegisteredEnterprise[]
  onFilteredCompaniesUpdated: (filteredCompanies: RegisteredEnterprise[]) => void,
}

export default function EnterpriseFilter(props: EnterpriseFilterProps) {
  const { t } = useTranslation();
  const { companies, onFilteredCompaniesUpdated } = props;
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    onFilteredCompaniesUpdated(provinceServices.filterCompanies(companies, searchText));
  }, [searchText, companies, onFilteredCompaniesUpdated]);

  return <div className={ styles.container }>
    <TextField
      identifier='province_companies_searchfield'
      value={ searchText }
      onChange={ setSearchText }
      label={ t('province_companies_search_title') }
      placeholder={ t('province_companies_search_placeholder') }
    />
  </div>
}

