import { AppConfig } from "config/AppConfig";
import { mobiscanReportApi } from "features/common/data/repositories";
import { mapCommuteByTransportModesFromDto, mapCommuteDistancesFromDto, mapEmployeesByCityFromDto, mapEmployeeFromDto, mapMobiscanFromDto, mapEmployeesByScheduleFromDto, mapReportPolicyFromDto, mapReportInfrastructureFromDto, mapIsolinesCollectionFromDto, mapSummaryFromDto, mapShareTokenFromDto, mapMeasuresAnsInspirationsFromDto as mapMeasuresAndInspirationsFromDto } from "features/mobiscan/report/domain/mappers";
import { CommuteByTransportModes, CommuteDistances, Employee, EmployeesBySchedule, EmployeesOfCity, IsolineCollection, MeasuresAndInspirations, Mobiscan, ReportInfrastructure, ReportPolicy, Summary } from "features/mobiscan/report/domain/models";
import * as routes from "routes";

export async function getMobiscan(mobiscanId: string): Promise<Mobiscan> {
  const dto = await mobiscanReportApi.getMobiscan(mobiscanId);
  return mapMobiscanFromDto(dto);
}

export async function getSummary(mobiscanId: string): Promise<Summary> {
  const dto = await mobiscanReportApi.getSummary(mobiscanId);
  return mapSummaryFromDto(dto);
}

export async function getCommuteDistances(mobiscanId: string): Promise<CommuteDistances> {
  const dto = await mobiscanReportApi.getCommuteDistances(mobiscanId);
  return mapCommuteDistancesFromDto(dto);
}

export async function getCommuteByTransportModes(mobiscanId: string): Promise<CommuteByTransportModes> {
  const dto = await mobiscanReportApi.getCommuteDistances(mobiscanId);
  const commuteByTransportModes = mapCommuteByTransportModesFromDto(dto);
  // PWVM-693
  const benchmarksWithoutVlaanderen = commuteByTransportModes.modalSplitBenchmarks.filter((benchmark) => benchmark.name !== 'Vlaanderen');
  return {
    ...commuteByTransportModes,
    modalSplitBenchmarks: benchmarksWithoutVlaanderen,
  };
}

export async function getEmployees(mobiscanId: string): Promise<Employee[]> {
  const dtos = await mobiscanReportApi.getEmployees(mobiscanId);
  return dtos.map(mapEmployeeFromDto);
}

export async function getEmployeesByCity(mobiscanId: string): Promise<EmployeesOfCity[]> {
  const dto = await mobiscanReportApi.getEmployeesByCity(mobiscanId);
  return mapEmployeesByCityFromDto(dto);
}

export async function getEmployeesBySchedule(mobiscanId: string): Promise<EmployeesBySchedule[]> {
  const dto = await mobiscanReportApi.getEmployeesBySchedule(mobiscanId);
  return mapEmployeesByScheduleFromDto(dto);
}

export async function getReportPolicy(mobiscanId: string): Promise<ReportPolicy> {
  const dto = await mobiscanReportApi.getPolicy(mobiscanId);
  return mapReportPolicyFromDto(dto);
}

export async function getReportInfrastructure(mobiscanId: string): Promise<ReportInfrastructure> {
  const dto = await mobiscanReportApi.getInfrastructure(mobiscanId);
  return mapReportInfrastructureFromDto(dto);
}

export async function getMeasuresAndInspirations(mobiscanId: string): Promise<MeasuresAndInspirations> {
  const dto = await mobiscanReportApi.getMeasuresAndInspirations(mobiscanId);
  return mapMeasuresAndInspirationsFromDto(dto);
}

export async function getIsolines(mobiscanId: string): Promise<IsolineCollection[]> {
  const dto = await mobiscanReportApi.getIsolines(mobiscanId);
  return mapIsolinesCollectionFromDto(dto);
}

export async function getPotentialExportBlob(mobiscanId: string): Promise<Blob> {
  return mobiscanReportApi.getPotentialExportBlob(mobiscanId);
}

export async function getShareUrl(mobiscanId: string): Promise<string> {
  const dto = await mobiscanReportApi.getShareToken(mobiscanId);
  const token = mapShareTokenFromDto(dto);
  const shareUrl = routes.generateShareReportRoute(mobiscanId, token);
  return `${AppConfig.FRONT_URL}${shareUrl}`;
}