import { LatLngExpression } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { Coordinates } from "features/map/domain/models";
import styles from "./MapComponent.module.scss";

interface MapComponentProps {
  center: Coordinates,
  children?: (JSX.Element | JSX.Element[] | false)[],
}

export default function MapComponent(props: MapComponentProps) {
  const center: LatLngExpression = [props.center.latitude, props.center.longitude];

  return <div className={ styles.MapComponent }>
    <MapContainer center={ center } zoom={ 9 } scrollWheelZoom={ true } style={ { width: '800px', height: '500px' } }>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        props.children
      }
    </MapContainer>
  </div>
}
