import { RegisteredEnterprise } from "features/province/domain/models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as provinceServices from "features/province/domain/provinceServices";
import { Retry } from "features/common/ui/Retry";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { formatDateToSlashString } from "features/common/util/date";
import EnterpriseFilter from "features/province/ui/enterprise/EnterpriseFilter";
import { formatCbeNumber } from "features/common/util/enterprise";
import { LoginAsEnterpriseButton } from "features/province/ui/enterprise/LoginAsEnterpriseButton";
import styles from "./EnterpriseOverview.module.scss";
import { MessageBox } from "features/common/ui/boxes/MessageBox";

export function EnterpriseOverview() {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState<RegisteredEnterprise[] | null>(null);
  const [filteredCompanies, setFilteredCompanies] = useState<RegisteredEnterprise[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setErrorMessage(null);
    try {
      const companies = await provinceServices.getRegisteredCompanies();
      setCompanies(companies);
    } catch (e: any) {
      setErrorMessage(e.toString());
    }
  }

  return <div>

    <section className={ styles.intro }>
      <div className={ styles.introInner }>
        <h2 className={ styles.pageTitle }>
          { t('province_companies_title') }
        </h2>
      </div>
    </section>

    <section className={ styles.overview }>
      <div className={ styles.overviewInner }>
        {
          (() => {
            if (errorMessage != null) {
              return <Retry onConfirmed={ fetchCompanies } title={ t('general_unknown_error') } />
            } else if (companies == null) {
              return <LoadingIndicator />
            } else {
              return <>

                <div className={ styles.searchBlock }>
                  <EnterpriseFilter companies={ companies } onFilteredCompaniesUpdated={ setFilteredCompanies } />
                </div>

                <div className={ styles.resultBlock }>
                  <div className="data-table-wrapper">

                    {
                      filteredCompanies.length === 0 &&
                      <MessageBox message={ t('province_companies_empty') } />
                    }

                    {
                      filteredCompanies.length > 0 &&
                      <table className="data-table">
                        <thead>
                          <tr>
                            <th>{ t('province_companies_table_organisation') }</th>
                            <th>{ t('province_companies_table_cbe_number') }</th>
                            <th>{ t('province_companies_table_active_since') }</th>
                            <th>{ t('province_companies_table_mobiscan_amount') }</th>
                            <th>
                              <span className="visually-hidden">
                                { t('province_companies_table_mobiscan_actions') }
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filteredCompanies.map((enterprise) => {
                              return <tr key={ enterprise.cbeNumber }>
                                <td>{ enterprise.name }</td>
                                <td>{ formatCbeNumber(enterprise.cbeNumber) }</td>
                                <td>{ formatDateToSlashString(enterprise.registrationDate) }</td>
                                <td>{ enterprise.mobiscans }</td>
                                <td data-align="right">
                                  <LoginAsEnterpriseButton enterprise={ enterprise } />
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    }

                  </div>
                </div>

              </>
            }
          })()
        }
      </div>
    </section>
  </div >
}
