import { IntroPosition, PageIntroItem } from 'features/common/ui/layout/PageIntroItem';
import React from 'react';
import styles from './PageIntro.module.scss';

interface PageIntroProps {
  children: JSX.Element[] | JSX.Element,
  className?: string,
}

export function PageIntro(props: PageIntroProps) {
  const children = Array.isArray(props.children) ? props.children : [props.children];
  const introItems = getChildrenOfType(children, PageIntroItem);

  const topItems = introItems.filter(item => item.props.position === IntroPosition.top);
  const leftItems = introItems.filter(item => item.props.position === IntroPosition.left);
  const rightItems = introItems.filter(item => item.props.position === IntroPosition.right);
  const bottomItems = introItems.filter(item => item.props.position === IntroPosition.bottom);

  return <section className={ `${styles.container} ${props.className}` }>
    <div className={ styles.inner }>

      { topItems.length > 0 &&
        <div className={ styles.top }>
          { topItems.map((item, i) => <div key={ i }>{ item }</div>) }
        </div>
      }

      { (leftItems.length > 0 || rightItems.length > 0) &&
        <div className={ styles.leftright }>

          { leftItems.length > 0 &&
            <div className={ styles.left }>
              <div className={ styles.leftInner }>
                { leftItems.map((item, i) => <div key={ i }>{ item }</div>) }
              </div>
            </div>
          }

          { rightItems.length > 0 &&
            <div className={ styles.right }>
              <div className={ styles.rightInner }>
                { rightItems.map((item, i) => <div key={ i }>{ item }</div>) }
              </div>
            </div>
          }

        </div>
      }

      { bottomItems.length > 0 &&
        <div className={ styles.bottom }>
          { bottomItems.map((item, i) => <div key={ i }>{ item }</div>) }
        </div>
      }

      <div className={ styles.brandLogoFloater }>
        <img src="/images/logos/logo-westvlaanderen--shadow.png" width={ 266 } height={ 244 } lang="nl" alt="West-Vlaanderen. De gedreven Provincie." />
      </div>

    </div>
  </section>;
}

// Helper

function getChildrenOfType(children: JSX.Element[], type: any) {
  const items: JSX.Element[] = [];
  React.Children.forEach(children, (child) => {
    if (child && child.type === type) {
      items.push(child);
    }
  });
  return items;
}
