import { useTranslation } from "react-i18next";
import styles from "./Retry.module.scss";

interface RetryProps {
  title: string;
  extraInfo?: string;
  onConfirmed: () => void;
}

export function Retry(props: RetryProps) {
  const { t } = useTranslation();

  return <div className={ styles.container }>
    <div className={ styles.box }>
      <div className={ styles.header }>
        <p><strong>{ props.title }</strong></p>
      </div>
      { props.extraInfo &&
        <div className={ styles.info }>
          <p>{ props.extraInfo }</p>
        </div>
      }
      <div className={ styles.actions }>
        <button type="button" onClick={ props.onConfirmed } className={ styles.retryAction }>
          { t('general_retry') }
        </button>
      </div>
    </div>
  </div>;
}
