import { MagdaApi } from "features/magda/data/MagdaApi";
import { CrossroadsResponse } from "features/magda/data/models";

export class MagdaMockApi implements MagdaApi {

  async getEnterprise(cbeNumber: string): Promise<CrossroadsResponse> {
    await new Promise(r => setTimeout(r, 1000));

    switch (cbeNumber) {
      // Case 0: enterprise not found
      case "0":
        return {
          success: false,
          validationCodes: ['REASON_CODE_ENTERPRISE_NOT_FOUND'],
        };

      // Case 1: enterprise inactive
      case "1":
        return {
          success: false,
          validationCodes: ['REASON_CODE_INACTIVE_ENTERPRISE'],
        };


      // Case 2: no establishments in WVL
      case "2":
        return {
          success: true,
          validationCodes: [],
          registered: false,
          enterprise: {
            name: "Duo nv",
            cbeNumber: "0123456789",
            establishments: {
              WVL: [],
            },
          },
        };

      // Case 3: not registered & 1 establishment
      case "3":
        return {
          success: true,
          validationCodes: [],
          registered: false,
          enterprise: {
            name: "Duo nv",
            cbeNumber: "0123456789",
            establishments: {
              WVL: [
                {
                  name: 'De paddenhoek',
                  cbeNumber: 'est_1',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '1',
                    box: 'A',
                    postalCode: '8000',
                    city: 'Hoofdgemeente',
                    country: 'BE',
                  },
                },
              ],
            },
          },
        };

      // Case 4: not registered & multiple establishments
      case "4":
        return {
          success: true,
          validationCodes: [],
          registered: false,
          enterprise: {
            name: "Duo nv",
            cbeNumber: "0123456789",
            establishments: {
              WVL: [
                {
                  name: 'De paddenhoek',
                  cbeNumber: 'est_1',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '1',
                    box: 'A',
                    postalCode: '8000',
                    city: 'Hoofdgemeente',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kikkerhoek',
                  cbeNumber: 'est_2',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: 'A',
                    postalCode: '8800',
                    city: 'Hoofdgemeente2',
                    country: 'BE',
                  },
                },
                {
                  name: 'De paardenhoek',
                  cbeNumber: 'est_3',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: undefined,
                    postalCode: '8800',
                    city: 'Hoofdgemeente3',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kattenhoek',
                  cbeNumber: 'est_4',
                  address: {
                    street: 'Straatnaam4',
                    houseNumber: '4',
                    box: 'D',
                    postalCode: '8200',
                    city: 'Hoofdgemeente4',
                    country: 'BE',
                  },
                },
              ],
            },
          },
        };

      // Case 5: already registered
      case "5":
        return {
          success: true,
          validationCodes: [],
          registered: true,
          enterprise: {
            name: "Duo nv",
            cbeNumber: "0123456789",
            establishments: {
              WVL: [
                {
                  name: 'De paddenhoek',
                  cbeNumber: 'est_1',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '1',
                    box: 'A',
                    postalCode: '8000',
                    city: 'Hoofdgemeente',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kikkerhoek',
                  cbeNumber: 'est_2',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: 'A',
                    postalCode: '8800',
                    city: 'Hoofdgemeente2',
                    country: 'BE',
                  },
                },
                {
                  name: 'De paardenhoek',
                  cbeNumber: 'est_3',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: undefined,
                    postalCode: '8800',
                    city: 'Hoofdgemeente3',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kattenhoek',
                  cbeNumber: 'est_4',
                  address: {
                    street: 'Straatnaam4',
                    houseNumber: '4',
                    box: 'D',
                    postalCode: '8200',
                    city: 'Hoofdgemeente4',
                    country: 'BE',
                  },
                },
              ],
            },
          },
        };

      // same as case 5
      default:
        return {
          success: true,
          validationCodes: [],
          registered: true,
          enterprise: {
            name: "Duo nv",
            cbeNumber: "0123456789",
            establishments: {
              WVL: [
                {
                  name: 'De paddenhoek',
                  cbeNumber: 'est_1',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '1',
                    box: 'A',
                    postalCode: '8000',
                    city: 'Hoofdgemeente',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kikkerhoek',
                  cbeNumber: 'est_2',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: 'A',
                    postalCode: '8800',
                    city: 'Hoofdgemeente2',
                    country: 'BE',
                  },
                },
                {
                  name: 'De paardenhoek',
                  cbeNumber: 'est_3',
                  address: {
                    street: 'Straatnaam',
                    houseNumber: '2',
                    box: undefined,
                    postalCode: '8800',
                    city: 'Hoofdgemeente3',
                    country: 'BE',
                  },
                },
                {
                  name: 'De Kattenhoek',
                  cbeNumber: 'est_4',
                  address: {
                    street: 'Straatnaam4',
                    houseNumber: '4',
                    box: 'D',
                    postalCode: '8200',
                    city: 'Hoofdgemeente4',
                    country: 'BE',
                  },
                },
              ],
            },
          },
        };
    }

  }
}