import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { useTranslation } from "react-i18next";
import styles from "./CommutingTab.module.scss";
import tabMiniStyles from "features/common/ui/tabs/Tab.miniStyles.module.scss";
import { CommuteGeneralChart } from "features/mobiscan/report/ui/tabs/charts/CommuteGeneralChart";
import { CommuteDistanceChart } from "features/mobiscan/report/ui/tabs/charts/CommuteDistanceChart";
import { useCallback } from "react";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { CommuteByTransportModes, CommuteDistances, Mobiscan } from "features/mobiscan/report/domain/models";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { TransportMode } from "features/mobiscan/general/domain/models";
import { CommuteTransportModeChart } from "features/mobiscan/report/ui/tabs/charts/CommuteTransportModeChart";

interface CommutingTabProps {
  mobiscan: Mobiscan,
}

export function CommutingTab(props: CommutingTabProps) {
  const { t } = useTranslation();

  return <>
    <article className={ styles.container }>
      <header className={ styles.header }>
        <div className={ styles.headerInner }>
          <h2 className={ styles.headerTitle }>
            { t('report_tab_mobility_tab_commuting_title') }
          </h2>
        </div>
      </header>

      <div className={ styles.content }>
        <div className={ styles.contentInner }>
          <TabContainer
            titleWrapperClassName={ tabMiniStyles.miniTabsWrapper }
            panelWrapperClassName={ tabMiniStyles.miniPanelsWrapper }>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_commuting_tab_general') } /></TabTitle>
              <TabPanel><GeneralChart mobiscan={ props.mobiscan } /></TabPanel>
            </Tab>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_commuting_tab_distance') } /></TabTitle>
              <TabPanel><DistanceChart mobiscan={ props.mobiscan } /></TabPanel>
            </Tab>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_commuting_tab_transport') } /></TabTitle>
              <TabPanel><TransportModeChart mobiscan={ props.mobiscan } /></TabPanel>
            </Tab>
          </TabContainer>
        </div>
      </div>
    </article >
  </>;
}


interface GeneralChartProps {
  mobiscan: Mobiscan,
}

function GeneralChart(props: GeneralChartProps) {

  const { t } = useTranslation();

  const fetchCommuteDistances = useCallback(async () => {
    return await reportServices.getCommuteDistances(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<CommuteDistances>(fetchCommuteDistances);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const commuteDistances = fetchResult!;
    return <>
      <div className={ styles.generalChartContent }>
        <CommuteGeneralChart
          commuteDistances={ commuteDistances }
          establishment={ props.mobiscan.establishment }
        />
      </div>
    </>;
  }
  return <LoadingIndicator />;
}


interface DistanceChartProps {
  mobiscan: Mobiscan,
}

function DistanceChart(props: DistanceChartProps) {
  const { t } = useTranslation();

  const fetchCommuteDistances = useCallback(async () => {
    return await reportServices.getCommuteDistances(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const fetchTransportModes = useCallback(async () => {
    return await mobiscanGeneralServices.getTransportModes();
  }, []);

  const transportFetch = useFetch<TransportMode[]>(fetchTransportModes);
  const commuteFetch = useFetch<CommuteDistances>(fetchCommuteDistances);

  if (commuteFetch.fetchStatus === FetchStatus.unknownError || transportFetch.fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (commuteFetch.fetchStatus === FetchStatus.success && transportFetch.fetchStatus === FetchStatus.success) {
    const commuteDistances = commuteFetch.fetchResult!;
    const transportModes = transportFetch.fetchResult!;

    return <>
      <CommuteDistanceChart commuteDistances={ commuteDistances } transportModes={ transportModes } />
    </>;
  }

  return <LoadingIndicator />;
}


interface TransportModeChartProps {
  mobiscan: Mobiscan,
}

function TransportModeChart(props: TransportModeChartProps) {
  const { t } = useTranslation();

  const fetchCommuteByTransportModes = useCallback(async () => {
    return await reportServices.getCommuteByTransportModes(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<CommuteByTransportModes>(fetchCommuteByTransportModes);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const commuteByTransportModes = fetchResult!;

    return <>
      <div className={ styles.meansOfTransportChart }>
        <CommuteTransportModeChart commuteByTransportModes={ commuteByTransportModes } />
      </div>
    </>;
  }

  return <LoadingIndicator />;
}
