import { BicycleInfrastructureDTO, BicyclePolicyDto, CarInfrastructureDTO, CarPolicyDTO, CarpoolPolicyDTO, CommunicationPolicyDTO, PersonnelCorrectionDto, PolicyExtrasDTO, PublicTransitPolicyDTO, QuestionnaireFieldDTO, QuestionnairePageDataDTO, QuestionnaireTabDTO } from "features/mobiscan/draft/data/models";
import { mapAddressFromJson, mapEstablishmentFromJson } from "features/enterprise/data/mappers";
import { DraftDTO, PersonnelResponse, PersonnelParseErrorDto, UploadPersonnelResponse } from "features/mobiscan/draft/data/models";
import { PersonnelParseErrorCorrection } from "features/mobiscan/draft/domain/models";
import { mapMobiscanUserDTOFromJson } from "features/mobiscan/overview/data/mappers";

export function mapQuestionnairePageDTOsFromJson(json: any): QuestionnairePageDataDTO {
  return {
    draftId: json.draftId,
    name: json.pageName,
    tabs: json.tabs.map(mapQuestionnaireTabDTOFromJson)
  };
}

export function mapQuestionnaireTabDTOFromJson(json: any): QuestionnaireTabDTO {
  return {
    name: json.tabName,
    confirmed: json.confirmed,
    fields: json.fields.map(mapQuestionnaireFieldDTOFromJson),
  }
}

export function mapQuestionnaireFieldDTOFromJson(json: any): QuestionnaireFieldDTO {
  return {
    name: json.fieldName,
    label: json.label,
    type: json.type,
    value: json.value,
    possibleValues: json.possibleValues ? new Map(Object.entries(json.possibleValues)) : undefined,
  }
}

export function mapGetPersonnelFinishedResponse(json: any): PersonnelResponse {
  const errors = json.errors as any[];
  return {
    finishedResponse: {
      mobiscanDraftId: json.mobiscanDraftId,
      processingErrors: errors.map(mapPersonnelParseErrorDto),
    },
  };
}

function mapPersonnelParseErrorDto(json: any): PersonnelParseErrorDto {
  return {
    id: json.id,
    row: json.row,
    address: mapAddressFromJson(json.address),
    wrongValue: json.wrongValue,
    error: json.error,
  };
}

export function mapGetPersonnelPendingResponse(): PersonnelResponse {
  return {
    pendingResponse: {},
  };
}

export function mapGetPersonnelVacantResponse(): PersonnelResponse {
  return {
    vacantResponse: {},
  };
}


export function mapUploadPersonnelSuccess(): UploadPersonnelResponse {
  return {
    successResponse: {},
  };
}

export function mapUploadPersonnelUnprocessable(): UploadPersonnelResponse {
  return {
    unprocessableResponse: {},
  };
}

export function mapUploadPersonnelValidationErrors(json: any): UploadPersonnelResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => ({ code: reason.code, location: reason.location, }));

  return {
    validationResponse: {
      validationCodes: errorCodes,
    },
  };
}

export function mapPersonnelCorrectionToDto(corrections: PersonnelParseErrorCorrection[]): PersonnelCorrectionDto {
  return {
    rows: corrections.map(correction => ({
      id: correction.parseError.id,
      value: correction.correctionValue,
    })),
  };
}

export function mapDraftFromJson(json: any): DraftDTO {
  return {
    id: json.id,
    enterpriseNumber: json.enterpriseNumber,
    geocoded: json.geocoded,
    benchmarkSector: json.benchmarkSector,
    mobiscanSituation: json.mobiscanSituation,
    creationDateTime: json.creationDateTime,
    modificationDateTime: json.modificationDateTime,
    version: json.version,
    createdBy: mapMobiscanUserDTOFromJson(json.createdBy),
    establishment: mapEstablishmentFromJson(json.establishment),
    bicycleInfrastructure: mapBicycleInfrastructureFromJson(json.bicycleInfrastructure),
    carInfrastructure: mapCarInfrastructureFromJson(json.carInfrastructure),
    bicyclePolicy: mapBicyclePolicyFromJson(json.bicyclePolicy),
    publicTransitPolicy: mapPublicTransitPolicyFromJson(json.publicTransitPolicy),
    carpoolPolicy: mapCarpoolPolicyFromJson(json.carpoolPolicy),
    carPolicy: maCarPolicyFromJson(json.carPolicy),
    communicationPolicy: mapCommunicationPolicyFromJson(json.communicationPolicy),
    policyExtras: mapPolicyExtrasFromJson(json.policyExtras),
    personnel: json.personnel,
  };
}

function mapBicycleInfrastructureFromJson(json: any): BicycleInfrastructureDTO {
  return {
    parkingSpots: json.parkingSpots,
    parkingSpotsCovered: json.parkingSpotsCovered,
    parkingSpotsSecured: json.parkingSpotsSecured,
    chargingSpots: json.chargingSpots,
    showers: json.showers,
    changingRoom: json.changingRoom,
    confirmed: json.confirmed,
  };
}

function mapCarInfrastructureFromJson(json: any): CarInfrastructureDTO {
  return {
    parkingSpots: json.parkingSpots,
    chargingSpots: json.chargingSpots,
    chargingSpotsPublic: json.chargingSpotsPublic,
    reservedCarpoolSpots: json.reservedCarpoolSpots,
    confirmed: json.confirmed,
  };
}

function mapBicyclePolicyFromJson(json: any): BicyclePolicyDto {
  return {
    bicycleCompensation: json.bicycleCompensation,
    companyBicycles: json.companyBicycles,
    contractType: json.contractType,
    maintenancePossible: json.maintenancePossible,
    roadsideAssistance: json.roadsideAssistance,
    awarenessCampaign: json.awarenessCampaign,
    otherMeasures: json.otherMeasures,
    bicycleMeasuresDescription: json.bicycleMeasuresDescription,
    confirmed: json.confirmed,
  };
}

function mapPublicTransitPolicyFromJson(json: any): PublicTransitPolicyDTO {
  return {
    collectiveTransportation: json.collectiveTransportation,
    infoDistribution: json.infoDistribution,
    freeTramBus: json.freeTramBus,
    freeTrain: json.freeTrain,
    confirmed: json.confirmed,
  };
}

function mapCarpoolPolicyFromJson(json: any): CarpoolPolicyDTO {
  return {
    carpoolDatabase: json.carpoolDatabase,
    infoDistribution: json.infoDistribution,
    compensation: json.compensation,
    returnGuarantee: json.returnGuarantee,
    confirmed: json.confirmed,
  };
}

function maCarPolicyFromJson(json: any): CarPolicyDTO {
  return {
    fullyElectricVehicles: json.fullyElectricVehicles,
    paidParking: json.paidParking,
    mobilityBudget: json.mobilityBudget,
    alternativeMobilityBudget: json.alternativeMobilityBudget,
    confirmed: json.confirmed,
  };
}

function mapCommunicationPolicyFromJson(json: any): CommunicationPolicyDTO {
  return {
    annualCampaign: json.annualCampaign,
    multimodalAccessibilityInfo: json.multimodalAccessibilityInfo,
    confirmed: json.confirmed,
  };
}

function mapPolicyExtrasFromJson(json: any): PolicyExtrasDTO {
  return {
    mobilityCoordinator: json.mobilityCoordinator,
    mobilityPlan: json.mobilityPlan,
    movingBonus: json.movingBonus,
    workFromHomePossible: json.workFromHomePossible,
    workFromHomeCompensation: json.workFromHomeCompensation,
    multipleCompensationsPossible: json.multipleCompensationsPossible,
    other: json.other,
    confirmed: json.confirmed,
  };
}
