import { userApi } from "features/common/data/repositories";
import { UpdatePasswordRequest, UpdateUserRequest } from "features/user/data/models";
import { mapUpdatePasswordResponse, mapUpdateUserResponse } from "features/user/domain/mappers";
import { UpdatePasswordResult, UpdateUserResult } from "features/user/domain/models";

export async function updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResult> {
  const result = await userApi.updateUser(updateUserRequest);
  return mapUpdateUserResponse(result);
}

export async function updatePassword(updatePasswordRequest: UpdatePasswordRequest): Promise<UpdatePasswordResult> {
  const result = await userApi.updatePassword(updatePasswordRequest);
  return mapUpdatePasswordResponse(result);
}
