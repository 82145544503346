import { ValidatedResponse } from "features/validation/data/models";
import { ValidationResult } from "features/validation/domain/models";
import i18n from "i18n";

interface ValidationCodeLabelPair {
  code: string,
  labelKey: string,
}

export function mapApiValidationErrorToValidationResult(codeMap: ValidationCodeLabelPair[], response: ValidatedResponse, defaultErrorLabelsKey: string = 'general_validation_error_message'): ValidationResult {
  const validationErrorLabels: string[] = [];
  response.validationCodes.forEach(code => {
    const codeObject = codeMap.find((codeObject) => codeObject.code === code);
    if (codeObject) {
      const label = i18n.t(codeObject.labelKey);
      validationErrorLabels.push(label);
    } else {
      console.log('No label found for code: ' + code);
      validationErrorLabels.push(i18n.t(defaultErrorLabelsKey, { code, }));
    }
  });

  return {
    isValid: response.validationCodes.length === 0,
    messages: validationErrorLabels.map(label => ({ message: label, })),
  }
}