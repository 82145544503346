import { authStorage, authApi, httpClient, provinceStorage } from "features/common/data/repositories";
import { ForgotPasswordResult, LoginResult, ResetPasswordResult, User } from "features/auth/domain/models";
import { mapLoginResponse, mapResetPasswordResponse, mapUser } from "features/auth/domain/mappers";

export async function login(email: string, password: string): Promise<LoginResult> {
  const result = await authApi.login(email, password);

  if (result.success === true && result.token != null) {
    httpClient.setAccessToken(result.token);
    await authStorage.persistToken(result.token);
  } else {
    await authStorage.deleteToken();
    await provinceStorage.deleteProvinceEnterpriseCbeNumber();
  }

  return mapLoginResponse(result);
}

export async function logout(): Promise<void> {
  httpClient.setAccessToken(undefined);
  await authStorage.deleteToken();

  httpClient.setProvinceCbeNumber(undefined);
  await provinceStorage.deleteProvinceEnterpriseCbeNumber();
}

export async function getUser(): Promise<User> {
  const token = await authStorage.getToken();
  httpClient.setAccessToken(token);

  const provinceCbeNumber = await provinceStorage.getProvinceEnterpriseCbeNumber();
  httpClient.setProvinceCbeNumber(provinceCbeNumber);

  const result = await authApi.getUser();
  result.isMasquerading = provinceCbeNumber !== undefined;

  return mapUser(result);
}

export async function forgotPassword(email: string): Promise<ForgotPasswordResult> {
  try {
    await authApi.forgotPassword(email);
    return { success: true };
  } catch (exception) {
    return { success: false };
  }
}

export async function resetPassword(token: string, password: string): Promise<ResetPasswordResult> {
  const result = await authApi.resetPassword(token, password);
  return mapResetPasswordResponse(result);
}