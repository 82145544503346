import * as authOperations from "features/auth/domain/authOperations";
import { NotLoggedInException } from "features/common/exceptions/NotLoggedInException";
import { store } from "redux/store";

export class HttpClient {

  private accessToken?: string;
  private provinceCbeNumber?: string;
  private shareToken?: string;

  authorizedFetch(url: string, options?: RequestInit, headers = this.createDefaultHeaders()): Promise<Response> {
    if (this.accessToken == null) {
      throw new NotLoggedInException();
    }

    const authHeaders = this.createAuthorizedHeaders();

    let requestHeaders = this.overwriteHeaders(authHeaders, options?.headers ?? {});
    requestHeaders = this.overwriteHeaders(requestHeaders, headers);
    const requestOptions = { ...options, headers: requestHeaders, };

    return this.executeAuthorizedRequest(url, requestOptions);
  }

  unauthorizedFetch(url: string, options?: RequestInit, headers = this.createDefaultHeaders()): Promise<Response> {
    const requestHeaders = this.overwriteHeaders(options?.headers ?? {}, headers);
    const requestOptions = { ...options, headers: requestHeaders, };

    return fetch(url, requestOptions);
  }

  /**
   * Is a share token is set, this function will send a request to the API with the share token
   * If no share token is present, an authorizedFetch will take place
   * @param url 
   * @param options 
   * @param headers 
   * @returns
   */
  shareTokenOrAuthorizedFetch(url: string, options?: RequestInit, headers = this.createDefaultHeaders()): Promise<Response> {
    if (this.shareToken == null) {
      return this.authorizedFetch(url, options, headers);
    }

    const shareHeaders = this.createShareHeaders();

    let requestHeaders = this.overwriteHeaders(shareHeaders, options?.headers ?? {});
    requestHeaders = this.overwriteHeaders(requestHeaders, headers);
    const requestOptions = { ...options, headers: requestHeaders, };

    return fetch(url, requestOptions);
  }

  setAccessToken(token: string | undefined) {
    this.accessToken = token;
  }

  setProvinceCbeNumber(cbeNumber: string | undefined) {
    this.provinceCbeNumber = cbeNumber;
  }

  setShareToken(shareToken: string | undefined) {
    this.shareToken = shareToken;
  }

  private async executeAuthorizedRequest(url: string, options?: RequestInit): Promise<Response> {
    const response = await fetch(url, options);
    if (this.isTokenExpired(response)) {
      await store.dispatch(authOperations.logout());
      throw new NotLoggedInException();
    } else {
      return response;
    }
  }

  private overwriteHeaders(headers: HeadersInit, overwriteWith: HeadersInit) {
    return {
      ...headers,
      ...overwriteWith,
    };
  }

  private createAuthorizedHeaders() {
    let extendedHeaders: HeadersInit = {
      'MOBISCAN-TOKEN': this.accessToken!,
    };

    if (this.provinceCbeNumber) {
      extendedHeaders['MOBISCAN-ENTERPRISE-CONTEXT'] = this.provinceCbeNumber;
    }

    return extendedHeaders;
  }

  private createShareHeaders() {
    let extendedHeaders: HeadersInit = {
      'MOBISCAN-SHARE-TOKEN': this.shareToken!,
    };

    return extendedHeaders;
  }

  private createDefaultHeaders(): HeadersInit {
    return {
      'Content-Type': 'application/json',
    };
  }

  private isTokenExpired(response: Response) {
    return response.status === 403;
  }
}
