import { mapEstablishmentFromJson } from "features/enterprise/data/mappers";
import { GeneralPotentialDTO, MobiscanOverviewItemDTO, MobiscanUserDTO } from "features/mobiscan/overview/data/models";


export function mapMobiscanOverviewItemDTOsFromJsonList(jsonList: any[]): MobiscanOverviewItemDTO[] {
  return jsonList.map((json) => mapMobiscanOverviewItemDTOFromJson(json));
}

export function mapMobiscanOverviewItemDTOFromJson(json: any): MobiscanOverviewItemDTO {
  return {
    id: json.id,
    establishment: mapEstablishmentFromJson(json.establishment),
    startedBy: mapMobiscanUserDTOFromJson(json.startedBy),
    startedDateTime: json.startedDateTime,
    requestedBy: json.requestedBy ? mapMobiscanUserDTOFromJson(json.requestedBy) : undefined,
    requestedDateTime: json.requestedDateTime ? json.requestedDateTime : undefined,
    modificationDateTime: json.modificationDateTime,
    status: json.status,
    potential: json.potential ? mapGeneralPotentialDTOFromJson(json.potential) : undefined,
  }
}

export function mapMobiscanUserDTOFromJson(json: any): MobiscanUserDTO {
  return {
    userId: json.userId,
    firstName: json.firstName,
    lastName: json.lastName,
    email: json.email
  }
}

export function mapGeneralPotentialDTOFromJson(json: any): GeneralPotentialDTO {
  return {
    durableActualPercentage: json.durableActualPercentage,
    durablePotentialPercentage: json.durablePotentialPercentage,
  }
}
