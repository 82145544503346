import { Layout } from "features/common/ui/layout/Layout";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { InfrastructureTab } from "features/mobiscan/report/ui/tabs/InfrastructureTab";
import { MeasuresTab } from "features/mobiscan/report/ui/tabs/MeasuresTab";
import { MobilityProfileTab } from "features/mobiscan/report/ui/tabs/MobilityProfileTab";
import { PotentialTab } from "features/mobiscan/report/ui/tabs/PotentialTab";
import { SummaryTab } from "features/mobiscan/report/ui/tabs/SummaryTab";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportActions } from "features/mobiscan/report/ui/ReportActions";
import { ReactComponent as IconDownload } from 'assets/inline-svg/icons/icon__download.svg';
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import { ReactComponent as IconLocation } from 'assets/inline-svg/icons/icon__location.svg';
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import styles from "./Report.module.scss";
import tabHeaderStyles from "features/common/ui/tabs/Tab.headerStyles.module.scss";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { Mobiscan } from "features/mobiscan/report/domain/models";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { formatDateToExpandedString } from "features/common/util/date";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import * as routes from "routes";
import { downloadBlob } from "features/mobiscan/report/domain/util";
import { ShareModal } from "features/mobiscan/report/ui/ShareModal";
import { LoadingIndicator, LoadingIndicatorDisplayType } from "features/common/ui/LoadingIndicator";
import FullPageLoadingIndicator from "features/common/ui/FullPageLoadingIndicator";
import { TreffikUrls } from "features/i18n/TreffikUrls";

interface MobiscanReportProps {
  mobiscanId: string,
  hideActions?: boolean,
  hideBackButton?: boolean,
}

export function Report(props: MobiscanReportProps) {
  const { t } = useTranslation();
  const [tabPanelToRender, setTabPanelToRender] = useState<JSX.Element>(<></>);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const fetchMobiscan = useCallback(async () => {
    return await reportServices.getMobiscan(props.mobiscanId);
  }, [props.mobiscanId]);

  const { fetchResult, fetchStatus } = useFetch<Mobiscan>(fetchMobiscan);

  const scrollToMeasuresTab = () => {
    setSelectedTabIndex(4);
    // This is really, really, really dirty..
    // But for some reason, passing the pos of another component didn't work..
    window.scrollTo({ top: 918, behavior: 'smooth' });
  };

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }
  if (fetchStatus === FetchStatus.success) {
    const mobiscan = fetchResult!;
    return <Layout>
      <div>
        <PageIntro className={ styles.introBlock }>

          <PageIntroItem position={ IntroPosition.left }>
            {
              props.hideBackButton !== true &&
              <BackButton label={ t('report_header_back') } targetUrl={ routes.HOME } displayType={ BackButtonType.box } />
            }

            <PageIntroLeft mobiscan={ mobiscan } />
          </PageIntroItem>

          {
            props.hideActions !== true
              ? <PageIntroItem position={ IntroPosition.right }>
                <ActionsBox mobiscan={ mobiscan } />
              </PageIntroItem>
              : <></>
          }

          <PageIntroItem position={ IntroPosition.bottom }>
            <TabContainer
              onRenderTabPanel={ setTabPanelToRender }
              title={ t('report_header_nav_title') }
              titleWrapperClassName={ tabHeaderStyles.headerTabsWrapper }
              selectedTabIndex={ selectedTabIndex }
              onUpdateTabIndex={ setSelectedTabIndex }
            >
              <Tab>
                <TabTitle><BasicTabTitle text={ t('report_tab_summary') } /></TabTitle>
                <TabPanel><SummaryTab mobiscan={ mobiscan } onGoToMeasuresClicked={ scrollToMeasuresTab } /></TabPanel>
              </Tab>
              <Tab>
                <TabTitle><BasicTabTitle text={ t('report_tab_mobility_profile') } /></TabTitle>
                <TabPanel><MobilityProfileTab mobiscan={ mobiscan } /></TabPanel>
              </Tab>
              <Tab>
                <TabTitle><BasicTabTitle text={ t('report_tab_potential') } /></TabTitle>
                <TabPanel><PotentialTab mobiscan={ mobiscan } /></TabPanel>
              </Tab>
              <Tab>
                <TabTitle><BasicTabTitle text={ t('report_tab_infrastructure') } /></TabTitle>
                <TabPanel><InfrastructureTab mobiscan={ mobiscan } /></TabPanel>
              </Tab>
              <Tab>
                <TabTitle><BasicTabTitle text={ t('report_tab_measures') } /></TabTitle>
                <TabPanel><MeasuresTab mobiscan={ mobiscan } /></TabPanel>
              </Tab>
            </TabContainer>
          </PageIntroItem>

        </PageIntro>

        { tabPanelToRender }

      </div>
      {
        props.hideActions !== true &&
        <ReportActions mobiscan={ mobiscan } />
      }
    </Layout >;
  }

  // Fetch status = pending
  return <FullPageLoadingIndicator />;
}



interface PageIntroLeftProps {
  mobiscan: Mobiscan,
}

function PageIntroLeft(props: PageIntroLeftProps) {
  const { t } = useTranslation();

  const addressLabel = `${formatAddressLine1(props.mobiscan.establishment.address)}, ${formatAddressLine2(props.mobiscan.establishment.address)}`;

  return <div>
    <h1 className={ styles.reportTitle }>
      <span className={ styles.reportName }>{ t('report_header_title', { establishmentName: props.mobiscan.establishment.name }) }</span>
      <span className={ styles.reportDate }>{ formatDateToExpandedString(props.mobiscan.requestedDateTime) }</span>
    </h1>
    <address className={ styles.reportAddress }>
      <IconLocation />
      <span>{ addressLabel }</span>
    </address>
  </div>;
}



interface ActionsBoxProps {
  mobiscan: Mobiscan,
}

function ActionsBox(props: ActionsBoxProps) {
  const { t } = useTranslation();

  const fetchExportBlob = useCallback(async () => {
    const blob = await reportServices.getPotentialExportBlob(props.mobiscan.id);
    downloadBlob(blob);
  }, [props.mobiscan.id]);

  const { fetchStatus, executeFetch } = useFetch<void>(fetchExportBlob, false);

  return <>
    <nav className={ styles.actionsBox } aria-labelledby="actions-box-title">
      <h2 id="actions-box-title" className="visually-hidden">
        { t('report_header_actions_title') }
      </h2>
      <ul className={ styles.actionsBoxActions }>
        <li className={ styles.actionsBoxAction }>
          <ShareButton mobiscan={ props.mobiscan } />
        </li>
        <li className={ styles.actionsBoxAction }>
          <ModalButton targetUrl={ TreffikUrls.contactUrl } className={ styles.actionsBoxActionLink }>
            <img src="/images/icons/report-nav/icon__reportnav__advice.svg" alt="" />
            <span className={ styles.actionsBoxActionLinkText }>{ t('report_header_action_contact') }</span>
            <span className={ styles.actionsBoxActionIcon }><IconArrow /></span>
          </ModalButton>
        </li>
        <li className={ styles.actionsBoxAction }>
          <button onClick={ executeFetch } className={ styles.actionsBoxActionLink } disabled={ fetchStatus === FetchStatus.pending }>
            <img src="/images/icons/report-nav/icon__reportnav__template.svg" alt="" />
            <span className={ styles.actionsBoxActionLinkText }>{ t('report_header_action_export') }</span>
            <span className={ `${styles.actionsBoxActionIcon} ${styles.actionsBoxActionIconCircled}` }><IconDownload /></span>
          </button>
          {
            fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
          }
        </li>
      </ul>
      <div className={ styles.actionsBoxBottom }>
        <ModalButton targetUrl={ TreffikUrls.reportInfoUrl } className={ styles.actionsBoxRemoveButton }>
          { t('report_header_action_info') }
        </ModalButton>
      </div>
    </nav>
  </>;
}

interface ShareButtonProps {
  mobiscan: Mobiscan,
}

function ShareButton(props: ShareButtonProps) {
  const { t } = useTranslation();

  const [shareUrl, setShareUrl] = useState<string>('');
  const [modalVisible, setModalVisible] = useState(false);

  const getShareUrl = async () => {
    const shareUrl = await reportServices.getShareUrl(props.mobiscan.id);
    setShareUrl(shareUrl);
    setModalVisible(true);
  };

  const { fetchStatus, executeFetch } = useFetch(getShareUrl, false);

  return <>
    <ShareModal
      isOpen={ modalVisible }
      onCloseRequest={ () => setModalVisible(false) }
      shareUrl={ shareUrl }
    />
    {
      fetchStatus === FetchStatus.pending && <LoadingIndicator displayType={ LoadingIndicatorDisplayType.minimal } />
    }
    {
      fetchStatus !== FetchStatus.pending && <>
        <button type="button" onClick={ executeFetch } className={ styles.actionsBoxActionLink } >
          <img src="/images/icons/report-nav/icon__reportnav__share.svg" alt="" />
          <span className={ styles.actionsBoxActionLinkText }>{ t('report_header_action_share') }</span>
          <span className={ styles.actionsBoxActionIcon }><IconArrow /></span>
        </button>
      </>
    }
  </>;
}
