import { TabTitleChildProps } from "features/common/ui/tabs/TabContainer";

interface ImageTabTitleProps extends TabTitleChildProps {
  text: string,
  imageSrc: string,
}

export function ImageTabTitle(props: ImageTabTitleProps) {

  return <>
    <img src={ props.imageSrc } alt="" />
    <span>{ props.text }</span>
  </>;

}
