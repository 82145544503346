import { ModalButton } from "features/common/ui/ModalButton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/a11y";
import { ReactComponent as IconPrevious } from 'assets/inline-svg/icons/icon__previous.svg';
import { ReactComponent as IconNext } from 'assets/inline-svg/icons/icon__next.svg';
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import styles from "./MeasuresTab.module.scss";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { Inspiration, Measure, MeasuresAndInspirations, Mobiscan } from "features/mobiscan/report/domain/models";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { NavigationOptions } from "swiper/types";

interface MeasuresTabProps {
  mobiscan: Mobiscan,
}

export function MeasuresTab(props: MeasuresTabProps) {
  const { t } = useTranslation();

  const fetchMeasuresAndInspirations = useCallback(async () => {
    return await reportServices.getMeasuresAndInspirations(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<MeasuresAndInspirations>(fetchMeasuresAndInspirations);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const measures = fetchResult!.measures;
    const inspirations = fetchResult!.inspirations;

    return <>
      <section className={ styles.chartBlock }>
        <header className={ styles.chartBlockHeader }>
          <h2 className={ styles.chartBlockTitle }>
            { t('report_tab_measures_title') }
          </h2>
          <ModalButton targetUrl={ TreffikUrls.measuresHelpInfoUrl } className="btn-info">
            <IconInfo />
            <span>{ t('report_tab_measures_contact') }</span>
          </ModalButton>
        </header>
        {/* Chart hidden per feedback client, is not removed entirely because may still be wanted in the future */ }
        {/* <div className={ styles.chartBlockContent }>
          <MeasuresChart measures={ measures } />
        </div> */}
      </section>

      <section className={ styles.slidersBlock }>
        <MeasuresList measures={ measures } />
        <InspirationsList inspirations={ inspirations } />
      </section>

    </>;
  }

  return <LoadingIndicator />;
}


interface MeasuresListProps {
  measures: Measure[],
}

function MeasuresList({ measures }: MeasuresListProps) {
  const { t } = useTranslation();

  // Sort measures. Add them to 2 rows
  const measuresSorted = measures.sort((a, b) => b.effect - a.effect || a.effort - b.effort);
  const measuresEmpty = measures.length === 0;

  const slides = [];
  for (let i = 0; i < measuresSorted.length; i += 2) {
    const current = measuresSorted[i];
    const next = i + 1 < measuresSorted.length ? measuresSorted[i + 1] : undefined;

    slides.push(
      <SwiperSlide key={ `measures-${i}` }>
        <MeasureTile measure={ current } />
        { next && <MeasureTile measure={ next } /> }
      </SwiperSlide>
    );
  }

  return (
    <SliderSection title={ t('report_tab_measures_measures') } displaySliderButtons={ !measuresEmpty }>
      {
        measuresEmpty
          ? <p>{ t('report_tab_measures_measures_empty') }</p>
          : slides
      }
    </SliderSection>
  );
}


interface InspirationsListProps {
  inspirations: Inspiration[],
}

function InspirationsList({ inspirations }: InspirationsListProps) {
  const { t } = useTranslation();
  const inspirationsEmpty = inspirations.length === 0;

  const slides = [];
  for (let i = 0; i < inspirations.length; i += 2) {
    const current = inspirations[i];
    const next = i + 1 < inspirations.length ? inspirations[i + 1] : undefined;

    slides.push(
      <SwiperSlide key={ `inspirations-${i}` }>
        <SliderTile imageUrl={ current.imageUrl } tagName={ current.tag } tagColor={ current.tagColor } detailUrl={ current.detailUrl } title={ current.title } />
        { next && <SliderTile imageUrl={ next.imageUrl } tagName={ next.tag } tagColor={ next.tagColor } detailUrl={ next.detailUrl } title={ next.title } /> }
      </SwiperSlide>
    );
  }

  return (
    <SliderSection title={ t('report_tab_measures_inspirations_title') } subTitle={ t('report_tab_measures_inspirations_subtitle') } displaySliderButtons={ !inspirationsEmpty }>
      {
        inspirationsEmpty
          ? <p>{ t('report_tab_measures_inspirations_empty') }</p>
          : slides
      }
    </SliderSection >
  );
}



interface SliderSectionProps {
  title: string,
  subTitle?: string,
  displaySliderButtons: boolean,
  children: React.ReactNode,
}

function SliderSection(props: SliderSectionProps) {
  const { t } = useTranslation();

  const sliderPrevRef = useRef<HTMLButtonElement>(null);
  const sliderNextRef = useRef<HTMLButtonElement>(null);

  return <>
    <article className={ styles.sliderBlock }>
      <div className={ styles.sliderBlockSectionTitleHolder }>
        <h2 className={ styles.sliderBlockSectionTitle }>
          { props.title }
        </h2>
      </div>
      <header className={ styles.sliderBlockHeader }>
        {
          props.subTitle &&
          <h3 className={ styles.sliderBlockTitle }>
            { props.subTitle }
          </h3>
        }

        <div className={ styles.sliderNav }>
          {
            props.displaySliderButtons && <>
              <button type="button" className={ styles.sliderNavButton } ref={ sliderPrevRef }>
                <span className="visually-hidden">{ t('label_prev_slider') }</span>
                <IconPrevious />
              </button>
              <button type="button" className={ styles.sliderNavButton } ref={ sliderNextRef }>
                <span className="visually-hidden">{ t('label_next_slider') }</span>
                <IconNext />
              </button>
            </>
          }
        </div>
      </header>
      <div className={ styles.sliderBlockContent }>
        <Slider nextButton={ sliderNextRef } previousButton={ sliderPrevRef }>
          { props.children }
        </Slider>
      </div>
    </article>
  </>;
}


interface SliderProps {
  nextButton: React.RefObject<HTMLButtonElement>,
  previousButton: React.RefObject<HTMLButtonElement>,
  children: React.ReactNode,
}

function Slider(props: SliderProps) {
  return <>
    <Swiper
      modules={ [Navigation, A11y] }
      threshold={ 20 } // prevents 'drag'-clicks..
      spaceBetween={ 24 }
      watchSlidesProgress={ true }
      slidesPerView={ 1 }
      slidesPerGroup={ 1 }
      navigation={ {
        prevEl: props.previousButton.current,
        nextEl: props.nextButton.current,
      } }
      onBeforeInit={ (swiper) => {
        const navigation = swiper.params!.navigation as NavigationOptions;
        navigation.prevEl = props.previousButton.current;
        navigation.nextEl = props.nextButton.current;
      } }
      breakpoints={ {
        480: {
          slidesPerView: 2,
        },
        980: {
          slidesPerView: 3,
        },
      } }
    >
      { props.children }
    </Swiper>
  </>;
}

interface SliderTileProps {
  imageUrl: string,
  detailUrl: string,
  tagName: string,
  tagColor: string,
  title: string,
  children?: React.ReactNode,
}

function SliderTile(props: SliderTileProps) {

  return <>
    <article className={ styles.measureTile }>
      <div className={ styles.measureTileImageBlock }>
        <div className={ styles.measureTileImageBlockInner }>
          <img src={ props.imageUrl } alt={ props.imageUrl } className={ styles.measureTileImage } />
          <TagOverlay tagName={ props.tagName } tagColor={ props.tagColor } />
        </div>
      </div>
      <div className={ styles.measureTileContentBlock }>
        <h4 className={ styles.measureTileTitle }>
          <a href={ props.detailUrl } target="_blank" rel="noopener noreferrer" className={ styles.measureTileTitleButton }>
            <span className={ styles.measureTileTitleText }>
              { props.title }
            </span>
            <span className={ styles.measureTileTitleIcon }>
              <IconArrow />
            </span>
          </a>
        </h4>
        { props.children }
      </div>

      <a href={ props.detailUrl } target="_blank" rel="noopener noreferrer" aria-hidden="true" tabIndex={ -1 } className={ styles.measureTileOverlayLink }>
        <span className="visually-hidden">{ props.tagName }</span>
      </a>
    </article>
  </>;
}


interface MeasureTileProps {
  measure: Measure,
}

function MeasureTile(props: MeasureTileProps) {
  const measure = props.measure;
  return <SliderTile imageUrl={ measure.imageUrl } tagName={ measure.tag } tagColor={ measure.tagColor } detailUrl={ measure.detailUrl } title={ measure.title }>
    <div className={ styles.measureTileRatings }>
      <ScoreIndicator label={ t('report_tab_measures_score_effort') } value={ props.measure.effort } max={ 5 } />
      <ScoreIndicator label={ t('report_tab_measures_score_effect') } value={ props.measure.effect } max={ 5 } />
    </div>
  </SliderTile>;
}


interface TagOverlayProps {
  tagName: string,
  tagColor: string,
}

function TagOverlay(props: TagOverlayProps) {
  const customStyles = {
    background: props.tagColor,
    color: props.tagColor === '#FFFFFF' ? '#14315C' : '#FFFFFF'
  }

  return <ul className={ styles.measureTileTags }>
    {/* TODO: styling: tagColor is now hex  */ }
    <li className={ styles.measureTileTag } style={ customStyles }>{ props.tagName }</li>
  </ul >;
}


interface ScoreIndicatorProps {
  label: string,
  value: number,
  max: number,
}

function ScoreIndicator(props: ScoreIndicatorProps) {

  const renderCircles = () => {
    const result = [];

    for (let i = 0; i < props.max; i++) {
      const active = i < props.value;
      result.push(
        <li key={ i } data-active={ active ? "true" : "false" }></li>
      );
    }

    return result;
  };

  return <div className={ styles.measureTileRating }>
    <p className={ styles.measureTileRatingTitle }>
      { props.label }
      <span className="visually-hidden">: { props.value }</span>
    </p>
    <ul>
      {
        renderCircles()
      }
    </ul>
  </div>
}
