import { ModalButton } from "features/common/ui/ModalButton";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";
import * as routes from "routes";
import styles from "./Header.module.scss";
import * as provinceServices from "features/province/domain/provinceServices";
import { getUser } from "features/auth/domain/authOperations";
import { useNavigate } from "react-router";
import { UserRole } from "features/auth/domain/models";
import { TreffikUrls } from "features/i18n/TreffikUrls";


export function Header() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.user!);

  const goBack = async () => {
    provinceServices.logoutFromEnterprise();
    await dispatch(getUser());
    navigate(routes.PROVINCE_HOME);
  };

  let homeRoute = routes.HOME;
  if (user !== undefined && (user.role === UserRole.ADMIN || user.role === UserRole.PROVINCE) && !user.isMasquerading) {
    homeRoute = routes.PROVINCE_HOME;
  }

  return <>
    {
      user && user.isMasquerading &&
      <div className={ styles.masqueradeNotification }>
        <span>{ t('header_masquerade_as', { enterprise: user.enterprise!.name }) }</span>
        <button className="btn-primary" onClick={ goBack }>{ t('header_go_back') }</button>
      </div>
    }
    <header className={ styles.header }>
      <div className={ styles.inner }>

        <div className={ styles.logo }>
          <a href={ homeRoute }>
            <img src="/images/logos/logo-mobiscan--outline.svg" alt="Mobiscan" width={ 168 } height={ 32 } />
          </a>
        </div>

        <nav className={ styles.nav } aria-labelledby="main-navigation">
          <h2 id="main-navigation" className="visually-hidden">
            { t('header_nav_title') }
          </h2>
          <ul className={ styles.navlist }>
            <li className={ styles.navitem }>
              <ModalButton
                targetUrl={ TreffikUrls.contactUrl }
                iframeTitle={ t('header_contact_a11y_iframe_title') }
                modalContentLabel={ t('header_contact_a11y_modal_content_label') }>
                { t('header_contact') }
              </ModalButton>
            </li>
            <li className={ styles.navitem }>
              <a href={ t('header_content_website_link') } target="_blank" rel="noreferrer">
                { t('header_content_website') }</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </>;
}
