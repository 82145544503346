import { TextField } from "features/common/ui/forms/TextField";
import { useTranslation } from "react-i18next";
import styles from "./ProvinceMobiscanFilter.module.scss";
import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";

interface ProvinceMobiscanFilterProps {
  provinceMobiscanStatusOptions: DropdownOption[],
  updatedProvinceMobiscanSearch: string,
  onUpdateProvinceMobiscanSearch: (provinceMobiscanSearch: string) => void,
  selectedProvinceMobiscanStatus: DropdownOption,
  onSelectProvinceMobiscanStatus: (option: DropdownOption) => void
}

export default function ProvinceMobiscanFilter(props: ProvinceMobiscanFilterProps) {
  const { t } = useTranslation();

  const handleDropdownChange = (dropdownValue?: string) => {
    const selectedProvinceMobiscanStatusOption = props.provinceMobiscanStatusOptions.find((provinceMobiscanStatusOption) => {
      return provinceMobiscanStatusOption.value === dropdownValue;
    });

    props.onSelectProvinceMobiscanStatus(selectedProvinceMobiscanStatusOption!);
  };

  return <form className={ styles.form } onSubmit={ (e) => e.preventDefault() }>
    <div className={ styles.formItem }>
      <TextField
        identifier='province_mobiscans_searchfield'
        value={ props.updatedProvinceMobiscanSearch }
        label={ t('province_mobiscans_search_title') }
        placeholder={ t('province_mobiscans_search_placeholder') }
        onChange={ props.onUpdateProvinceMobiscanSearch }
      />
    </div>
    <div className={ styles.formItem }>
      <Dropdown
        identifier="section"
        options={ props.provinceMobiscanStatusOptions }
        selectedOption={ props.selectedProvinceMobiscanStatus }
        label={ t('province_mobiscans_status_title') }
        placeholderText={ t('edit_personnel_tab_template_situation_options_placeholder') }
        onChange={ handleDropdownChange }
      />
    </div>
  </form>
}
