import { Marker, Popup, } from "react-leaflet";
import { Employee } from "features/mobiscan/report/domain/models";
import { createLeafletMapIcon } from "features/map/ui/MapIconHelper";

interface EmployeeLayerProps {
  employees: Employee[],
  getIcon: (employee: Employee) => JSX.Element,
  getTooltipContent: (employee: Employee) => JSX.Element,
}

export function EmployeeLayer(props: EmployeeLayerProps) {
  return <>
    {
      props.employees.map((employee) => {
        const icon = props.getIcon ? props.getIcon(employee) : undefined;
        const leafletIcon = icon ? createLeafletMapIcon(icon) : undefined;
        return <Marker key={ 'employee_' + employee.id } position={ [employee.coordinates.latitude, employee.coordinates.longitude] } icon={ leafletIcon } >
          {
            props.getTooltipContent &&
            <Popup closeButton={ false } >
              { props.getTooltipContent(employee) }
            </Popup>
          }
        </Marker>
      })
    }
  </>;
}
