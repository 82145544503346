import { Permission, UserRole } from "features/auth/domain/models";

export enum Scope {
  MANAGE_USERS,
  MANAGE_ESTABLISHMENTS,
  REQUEST_MOBISCAN_DELETE,
  CONFIRM_MOBISCAN_DELETE,
  REQUEST_ESTABLISHMENT_DELETE,
  CONFIRM_ESTABLISHMENT_DELETE,
  REQUEST_ORG_DELETE,
  CONFIRM_ORG_DELETE,
}

export const permissions: { [key: string]: Scope[] } = {
  [UserRole.ADMIN]: [
    Scope.MANAGE_USERS,
    Scope.MANAGE_ESTABLISHMENTS,
    Scope.CONFIRM_MOBISCAN_DELETE,
    Scope.CONFIRM_ESTABLISHMENT_DELETE,
    Scope.CONFIRM_ORG_DELETE,
  ],
  [UserRole.PROVINCE]: [
    Scope.MANAGE_USERS,
    Scope.MANAGE_ESTABLISHMENTS,
    Scope.CONFIRM_MOBISCAN_DELETE,
    Scope.CONFIRM_ESTABLISHMENT_DELETE,
    Scope.CONFIRM_ORG_DELETE,
  ],
  [UserRole.ORG_ADMIN]: [
    Scope.MANAGE_USERS,
    Scope.MANAGE_ESTABLISHMENTS,
    Scope.REQUEST_MOBISCAN_DELETE,
    Scope.REQUEST_ESTABLISHMENT_DELETE,
    Scope.REQUEST_ORG_DELETE,
  ],
  [UserRole.ORG_USER]: [],
}


export enum EstablishmentScope {
  CREATE_NEW_MOBISCAN,
  EDIT_MOBISCAN_DRAFT,
  DELETE_MOBISCAN_DRAFT,
  VIEW_MOBISCAN,
}

export const establishmentPermissions: { [key: string]: EstablishmentScope[] } = {
  [Permission.READ_WRITE]: [
    EstablishmentScope.CREATE_NEW_MOBISCAN,
    EstablishmentScope.EDIT_MOBISCAN_DRAFT,
    EstablishmentScope.DELETE_MOBISCAN_DRAFT,
    EstablishmentScope.VIEW_MOBISCAN,
  ],
  [Permission.READ]: [
    EstablishmentScope.VIEW_MOBISCAN,
  ]
}
