import { mobiscanApi } from "features/common/data/repositories";
import { Establishment } from "features/enterprise/domain/models";
import { mapMobiscanOverviewItemsFromDTOs } from "features/mobiscan/overview/domain/mappers";
import { MobiscanOverviewItem } from "features/mobiscan/overview/domain/models";


export async function getMobiscans(): Promise<MobiscanOverviewItem[]> {
  const dtos = await mobiscanApi.fetchMobiscans();

  return mapMobiscanOverviewItemsFromDTOs(dtos);
}

export function distinctEstablishmentsFromMobiscanList(mobiscans: MobiscanOverviewItem[]): Establishment[] {
  var establishments: Establishment[] = [];
  mobiscans.forEach((mobiscan) => {
    if (establishments.find((establishment) => mobiscan.establishment.id === establishment.id) === undefined) {
      establishments.push(mobiscan.establishment);
    }
  });

  return establishments;
}