import { EnterpriseInfo, MagdaEstablishment } from "features/magda/domain/models";
import { EstablishmentPayload, JoinEnterpriseRequest, JoinEnterpriseResponse, RegisterRequest, RegisterResponse } from "features/register/data/models";
import { JoinEnterpriseResult, RegisterResult, RegistrationUserInput } from "features/register/domain/models";
import { mapApiValidationErrorToValidationResult } from "features/validation/domain/mappers";


const registerValidationCodes = [
  { code: 'INVALID_PARAMETER', labelKey: 'register_validation_email_exists' },
];

export function mapRegisterResponse(registerResponse: RegisterResponse): RegisterResult {
  const validationResult = mapApiValidationErrorToValidationResult(registerValidationCodes, registerResponse);
  return {
    success: registerResponse.success,
    validationResult,
  };
}

export function mapJoinEnterpriseResponse(joinEnterpriseResponse: JoinEnterpriseResponse): JoinEnterpriseResult {
  return joinEnterpriseResponse;
}

export function mapUserInputToRegistrationRequest(enterpriseInfo: EnterpriseInfo, userInput: RegistrationUserInput): RegisterRequest {
  const establishments = userInput.selectedEstablishments.map(establishment => mapSelectedEstablishment(establishment));

  return {
    firstName: userInput.firstName,
    lastName: userInput.lastName,
    jobTitle: userInput.jobTitle,
    phone: userInput.phone,
    email: userInput.email,
    password: userInput.password,
    enterprise: {
      cbeNumber: enterpriseInfo.enterprise.cbeNumber,
      establishments,
    },
  };
};

export function mapUserInputToJoinEnterpriseRequest(userInput: RegistrationUserInput, cbeNumber: string): JoinEnterpriseRequest {
  return {
    firstName: userInput.firstName,
    lastName: userInput.lastName,
    jobTitle: userInput.jobTitle,
    phone: userInput.phone,
    email: userInput.email,
    cbeNumber: cbeNumber,
  };
}

const mapSelectedEstablishment = (establishment: MagdaEstablishment): EstablishmentPayload => {
  return {
    cbeNumber: establishment.cbeNumber,
  }
};