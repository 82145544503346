import { validatePassword } from "features/auth/domain/validation";
import { RegistrationUserInput } from "features/register/domain/models";
import * as helpers from "features/validation/domain/helpers";
import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import { TFunction } from "react-i18next";

export function validateCbeForm(cbeNumber: string, t: TFunction): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(cbeNumber)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_cbe_number_empty')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}

export function validateRegisterForm(userInput: RegistrationUserInput, t: TFunction, passwordRequired = true): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(userInput.firstName)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_first_name_empty')));
  }

  if (helpers.isNullOrWhitespace(userInput.lastName)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_last_name_empty')));
  }

  if (helpers.isNullOrWhitespace(userInput.jobTitle)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_job_title_empty')));
  }

  if (helpers.isNullOrWhitespace(userInput.phone)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_phone_empty')));
  }

  if (helpers.isNullOrWhitespace(userInput.email)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_email_empty')));
  } else if (!helpers.isEmail(userInput.email)) {
    validationMessages.push(helpers.validationMessageFromString(t('register_validation_email_incorrect')));
  }

  if (passwordRequired) {
    const passwordValidationMessages = validatePassword(userInput.password, t);
    passwordValidationMessages.forEach((validationMessage) => validationMessages.push(validationMessage));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}