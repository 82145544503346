import { JoinEnterpriseRequest, JoinEnterpriseResponse, RegisterRequest, RegisterResponse } from "features/register/data/models";
import { RegisterApi } from "features/register/data/RegisterApi";

export class RegisterMockApi implements RegisterApi {

  async register(registerRequest: RegisterRequest): Promise<RegisterResponse> {
    await new Promise(r => setTimeout(r, 2000));
    if (registerRequest.firstName === 'mock-error') {
      return {
        success: false,
        validationCodes: [],
      };
    }
    return {
      success: true,
      validationCodes: [],
    };
  }

  async requestJoinEnterprise(joinEnterpriseRequest: JoinEnterpriseRequest): Promise<JoinEnterpriseResponse> {
    await new Promise(r => setTimeout(r, 2000));
    if (joinEnterpriseRequest.firstName === 'mock-error') {
      return { success: false, };
    }
    return { success: true, };
  }

}
