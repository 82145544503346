import { mapIso8601StringToDate } from "features/common/util/date";
import { roundNumber } from "features/common/util/math";
import { mapCoordinatesFromDto } from "features/map/domain/mappers";
import { mapScheduleFromDto, mapTransportModeFromDto } from "features/mobiscan/general/domain/mappers";
import { TransportCategory, TransportMode } from "features/mobiscan/general/domain/models";
import { BenchmarkValuesDTO, CommuteDistancesDTO, DistanceSegmentDTO, EmployeeDTO, EmployeesByCityDTO, EmployeesByScheduleDTO, IsolineDTO, IsolinesCollectionDTO, MeasuresAnsInspirationsDTO, MobiscanDTO, ModalSplitBenchmarkDTO, ModalSplitValueDTO, PolygonDTO, PotentialTransportModeDTO, ReportPolicyDTO, ShareTokenDTO, SummaryDTO } from "features/mobiscan/report/data/models";
import { BenchmarkValues, CommuteByTransportModes, CommuteDistances, CommuteQuantityByDistance, Employee, EmployeesBySchedule, EmployeesOfCity, Isoline, IsolineCollection, MeasuresAndInspirations, Mobiscan, ModalSplitBenchmark, ModalSplitValue, Polygon, PotentialTransportMode, ReportPolicy, Summary, TransportModeSegment } from "features/mobiscan/report/domain/models";
import { roundToNextMultipleOf } from "features/mobiscan/report/domain/util";

export function mapMobiscanFromDto(dto: MobiscanDTO): Mobiscan {
  return {
    ...dto,
    startedDateTime: mapIso8601StringToDate(dto.startedDateTime),
    requestedDateTime: mapIso8601StringToDate(dto.requestedDateTime),
    modificationDateTime: mapIso8601StringToDate(dto.modificationDateTime),
    establishmentCoordinates: mapCoordinatesFromDto(dto.latitudeLongitude),
  };
}

export function mapSummaryFromDto(dto: SummaryDTO): Summary {
  return {
    ...dto,
    commuteDistance: mapBenchmarkValuesFromDto(dto.commuteDistance, 10, metersToKilometers),
    commuteDuration: mapBenchmarkValuesFromDto(dto.commuteDuration, 5),
    carbonDioxide: mapBenchmarkValuesFromDto(dto.carbonDioxide, 50),
    percentageDurable: mapBenchmarkValuesFromDto(dto.percentageDurable, 100),
    bicycleParkingSpotsPercentage: mapBenchmarkValuesFromDto(dto.bicycleParkingSpotsPercentage, 100),
    carParkingSpotsPercentage: mapBenchmarkValuesFromDto(dto.carParkingSpotsPercentage, 100),
  };
}

const defaultFormatter = (number: number) => number;
const metersToKilometers = (number: number) => roundNumber(number / 1000, 1);

function mapBenchmarkValuesFromDto(dto: BenchmarkValuesDTO, roundToMultiplyOf: number, numberFormatter: (number: number) => number = defaultFormatter): BenchmarkValues {
  const highestValue = dto.actual > dto.benchmark ? dto.actual : dto.benchmark;
  return {
    actual: numberFormatter(dto.actual),
    benchmark: numberFormatter(dto.benchmark),
    maxValue: roundToNextMultipleOf(numberFormatter(highestValue), roundToMultiplyOf),
  };
}

export function mapCommuteDistancesFromDto(dto: CommuteDistancesDTO): CommuteDistances {
  return {
    totalCount: dto.totalCount,
    modalSplit: Object.keys(dto.modalSplit).map((transportMode) => mapModalSplitValueFromDto(transportMode, dto.modalSplit[transportMode])),
    distanceSegments: dto.distanceSegments.map((segment) => ({
      count: segment.count,
      kmMin: segment.kmMin,
      kmMax: segment.kmMax,
      modalSplit: Object.keys(segment.modalSplit).map((transportMode) => mapModalSplitValueFromDto(transportMode, segment.modalSplit[transportMode])),
    })),
  };
}

function mapModalSplitValueFromDto(transportMode: string, dto: ModalSplitValueDTO): ModalSplitValue {
  return {
    transportMode: mapTransportModeFromDto(transportMode),
    count: dto.count,
    percentage: dto.percentage,
  };
}


export function mapCommuteByTransportModesFromDto(dto: CommuteDistancesDTO): CommuteByTransportModes {
  return {
    totalCount: dto.totalCount,
    transportModeSegments: getTransportModeSegments(dto.distanceSegments),
    modalSplitBenchmarks: dto.modalSplitBenchmarks.map(combineCarTransportModesForBenchmarks).map(mapBenchmarkModalSplitFromDto),
  };
}

function mapBenchmarkModalSplitFromDto(dto: ModalSplitBenchmarkDTO): ModalSplitBenchmark {
  return {
    name: dto.name,
    modalSplit: Object.keys(dto.modalSplit).map((transportMode) => ({
      transportMode: mapTransportModeFromDto(transportMode),
      percentage: dto.modalSplit[transportMode],
    })),
  };
}

//TODO: v2: move this logic to API with a new endpoint
//TODO: v2 move sortIndexes of TransportModes to api aswell
//! Might've been a better idea to put this logic in the back-end
function getTransportModeSegments(distanceSegments: DistanceSegmentDTO[]): TransportModeSegment[] {
  const result: TransportModeSegment[] = [];

  // Loop json distance segments
  for (const distanceSegment of distanceSegments) {
    const modalSplitTransportModes = Object.keys(distanceSegment.modalSplit);

    // Loop modal splits for each segment
    for (const modalSplitTransportMode of modalSplitTransportModes) {
      const values = distanceSegment.modalSplit[modalSplitTransportMode]; // ModalSplitValueDTO

      // Search if the transportmode exists in result yet
      let transportModeSegment = result.find((segment) => segment.transportMode.id === modalSplitTransportMode);
      if (transportModeSegment == null) {
        transportModeSegment = {
          count: 0,
          transportMode: mapTransportModeFromDto(modalSplitTransportMode),
          quantityByDistance: [],
        };
        result.push(transportModeSegment);
      }
      transportModeSegment.count += values.count;
      transportModeSegment.quantityByDistance.push({
        kmMin: distanceSegment.kmMin,
        kmMax: distanceSegment.kmMax,
        count: values.count,
        percentage: 0,
      });
    }
  }

  // PWVM-438
  // Group PERSONAL_CAR & COMPANY_CAR
  const personalCarSegment = result.find((segment) => segment.transportMode.id === 'PERSONAL_CAR')!;
  const personalCarIndex = result.indexOf(personalCarSegment);
  result.splice(personalCarIndex, 1);

  const companyCarSegment = result.find((segment) => segment.transportMode.id === 'COMPANY_CAR')!;
  const companyCarIndex = result.indexOf(companyCarSegment);
  result.splice(companyCarIndex, 1);

  const groupedSegment = combineTransportModeSegments('GROUP_CAR', personalCarSegment, companyCarSegment, TransportCategory.Other);
  result.push(groupedSegment);

  // Calculate percentages
  for (const segment of result) {
    segment.quantityByDistance.forEach((qty) => {
      qty.percentage = (qty.count / segment.count) * 100;
    });
  }

  return result;
}

//TODO: remove this in v2 with the new endpoint
// Helper
function combineTransportModeSegments(id: string, a: TransportModeSegment, b: TransportModeSegment, category: TransportCategory): TransportModeSegment {
  const quantityByDistance: CommuteQuantityByDistance[] = a.quantityByDistance.map((quantityA) => {
    const quantityB = b.quantityByDistance.find((qtyB) => qtyB.kmMax === quantityA.kmMax && qtyB.kmMin === quantityA.kmMin)!;
    return {
      count: quantityA.count + quantityB.count,
      kmMax: quantityA.kmMax,
      kmMin: quantityA.kmMin,
      percentage: quantityA.percentage,
    };
  });
  const transportMode: TransportMode = {
    id,
    sortIndex: a.transportMode.sortIndex,
    category,
  };
  const groupedSegment: TransportModeSegment = {
    transportMode,
    count: a.count + b.count,
    quantityByDistance,
  };

  return groupedSegment;
}

//TODO: remove this in v2 with the new endpoint
function combineCarTransportModesForBenchmarks(benchmark: ModalSplitBenchmarkDTO): ModalSplitBenchmarkDTO {
  const modalSplit = { ...benchmark.modalSplit };

  const personalCar = modalSplit.PERSONAL_CAR;
  delete modalSplit.PERSONAL_CAR

  const companyCar = modalSplit.COMPANY_CAR;
  delete modalSplit.COMPANY_CAR;

  modalSplit['GROUP_CAR'] = personalCar + companyCar;

  return {
    name: benchmark.name,
    modalSplit,
  };
}


export function mapEmployeesByCityFromDto(dto: EmployeesByCityDTO): EmployeesOfCity[] {
  return Object.keys(dto.counts).map(cityName => {
    const values = dto.counts[cityName];
    return {
      cityName,
      count: values.count,
      percentage: values.percentage,
    };
  });
}

export function mapEmployeeFromDto(dto: EmployeeDTO): Employee {
  return {
    ...dto,
    coordinates: mapCoordinatesFromDto(dto.latitudeLongitude),
    schedule: mapScheduleFromDto(dto.schedule),
    transportMode: mapTransportModeFromDto(dto.transportMode),
    potentialTransportModes: dto.potentialTransportModes.map(mapPotentialTransportMode),
  };
}

export function mapPotentialTransportMode(dto: PotentialTransportModeDTO): PotentialTransportMode {
  return {
    transportMode: mapTransportModeFromDto(dto.transportMode),
    meters: dto.meters,
    seconds: dto.seconds,
  };
}

export function mapEmployeesByScheduleFromDto(dto: EmployeesByScheduleDTO): EmployeesBySchedule[] {
  return Object.keys(dto.counts).map(scheduleId => {
    const values = dto.counts[scheduleId];
    return {
      schedule: mapScheduleFromDto(scheduleId),
      count: values.count,
      percentage: values.percentage,
    };
  });
}

export function mapReportPolicyFromDto(dto: ReportPolicyDTO): ReportPolicy {
  return dto;
}

export function mapReportInfrastructureFromDto(dto: ReportPolicyDTO): ReportPolicy {
  return dto;
}

export function mapMeasuresAnsInspirationsFromDto(dto: MeasuresAnsInspirationsDTO): MeasuresAndInspirations {
  return dto;
}

export function mapIsolinesCollectionFromDto(dto: IsolinesCollectionDTO): IsolineCollection[] {
  return Object.keys(dto).map((groupName) => ({
    name: groupName,
    isolines: Object.keys(dto[groupName]).map((name) => mapIsolineFromDTO(dto[groupName][name], name)),
  }));
}

function mapIsolineFromDTO(dto: IsolineDTO, name: string): Isoline {
  return {
    name,
    polygons: dto.polygons.map(mapPolygon),
    connections: dto.connections,
  };
}

function mapPolygon(dto: PolygonDTO): Polygon {
  return {
    outer: dto.outer.map(mapCoordinatesFromDto),
    inner: dto.inner.map(mapCoordinatesFromDto),
  };
}


export function mapShareTokenFromDto(dto: ShareTokenDTO): string {
  return dto.value;
}