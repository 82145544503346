import { useFetch, FetchStatus } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { Establishment } from "features/enterprise/domain/models";
import { EstablishmentDropdown, establishmentToOption } from "features/enterprise/ui/EstablishmentDropdown";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { FormEvent, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/store";
import styles from "./CreateDraftPage.module.scss";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import * as routes from "routes";
import { MessageBox } from "features/common/ui/boxes/MessageBox";
import { ModalButton } from "features/common/ui/ModalButton";
import { TreffikUrls } from "features/i18n/TreffikUrls";

export default function CreateDraftPage() {
  const user = useAppSelector((state) => state.auth.user!);
  const { t } = useTranslation();


  const fetchEstablishments = useCallback(async () => {
    const establishments = await enterpriseServices.getEstablishments(user.enterprise!.cbeNumber);
    return establishments;
  }, [user.enterprise]);

  const { fetchStatus, fetchResult } = useFetch(fetchEstablishments);

  return <Layout>
    {
      <>
        <PageIntro>
          <PageIntroItem position={ IntroPosition.left }>
            <BackButton label={ t('create_mobiscan_header_back') } targetUrl={ routes.HOME } displayType={ BackButtonType.box } />
            <h1 className="page-title">{ t('create_mobiscan_header_title') }</h1>
          </PageIntroItem>
        </PageIntro>

        {
          fetchStatus === FetchStatus.pending && <LoadingIndicator />
        }

        <div role="alert"> {/* "alert" content will be read out */ }
          {
            fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('create_mobiscan_unknown_error') } />
          }
        </div>

        {
          fetchStatus === FetchStatus.success &&
          <section className={ styles.layout }>
            <div className={ styles.layoutColumns }>
              <div className={ styles.layoutInfoColumn }>
                <InfoPanel />
              </div>
              <div className={ styles.layoutActionColumn }>
                <ActionsPanel establishments={ fetchResult! } />
              </div>
            </div>
          </section>
        }
      </>
    }
  </Layout>
}


function InfoPanel() {
  const { t } = useTranslation();

  return <article className={ styles.infopanel }>
    <div className={ styles.infopanelContent }>
      <div className="text-content">
        <p>{ t('create_mobiscan_info_content') }</p>
        <br />
        <p>{ t('create_mobiscan_info_options_title') }</p>
        <ul>
          <li>{ t('create_mobiscan_info_options_1') }</li>
          <li>{ t('create_mobiscan_info_options_2') }</li>
          <li>{ t('create_mobiscan_info_options_3') }</li>
        </ul>
        <Trans
          i18nKey={ 'create_mobiscan_info_help' }
          components={ {
            anchor: <ModalButton
              targetUrl={ TreffikUrls.contactUrl }
              iframeTitle={ t('create_mobiscan_info_help_a11y_iframe_title') }
              modalContentLabel={ t('create_mobiscan_info_help_a11y_modal_content_label') }
              className={ styles.infoLink } />
          } }
        />
      </div>
    </div>
  </article>;
}


interface ActionsPanelProps {
  establishments: Establishment[],
}

function ActionsPanel(props: ActionsPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedEstablishment, setSelectedEstablishment] = useState<Establishment | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const createDraft = async () => {
    setIsLoading(true);
    try {
      const draft = await mobiscanDraftServices.createDraft(selectedEstablishment!);
      navigate(routes.generateEditDraftRoute(draft.id));
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(t('general_unknown_error'));
    }
  }

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    createDraft();
  }

  return props.establishments.length === 0
    ? <MessageBox message={ t('establishments_empty') } />
    : <article className={ styles.actionspanel }>
      <header className={ styles.actionspanelHeader }>
        <h2 className={ styles.actionspanelTitle }>
          { t('create_mobiscan_actions_title') }
        </h2>
        <div className={ styles.actionspanelHeaderActions }>
          <ScopeGate requiredScope={ Scope.MANAGE_ESTABLISHMENTS }>
            <Link to={ routes.ESTABLISHMENTS_ADD } className={ styles.actionspanelHeaderAction }>{ t('create_mobiscan_actions_manage_establishments') }</Link>
          </ScopeGate>
        </div>
      </header>
      <form onSubmit={ onFormSubmit } className={ styles.actionspanelForm }>
        <EstablishmentDropdown
          value={ selectedEstablishment ? establishmentToOption(selectedEstablishment) : null }
          options={ props.establishments.map(establishment => establishmentToOption(establishment)) }
          disabledOptions={ selectedEstablishment ? [establishmentToOption(selectedEstablishment)] : [] }
          onValueChanged={ (option) => setSelectedEstablishment(option.establishment as Establishment) }
        />
        <div className={ styles.actionspanelFormActions }>
          { isLoading
            ? <LoadingIndicator />
            : <button type="submit" className="btn-primary" disabled={ selectedEstablishment === null }>
              { t('create_mobiscan_actions_create_cta') }
            </button> }
        </div>
        { errorMessage && <div>
          <p>{ errorMessage }</p>
        </div>
      /** TODO: style this error message */ }

      </form>
    </article>;
}
