import { mapEstablishmentPermissions } from "features/auth/domain/mappers";
import { UserRole } from "features/auth/domain/models";
import { AddCustomEstablishmentResponse, CreateEnterpriseUserResponse, CustomEstablishmentInputDTO, DeleteEnterpriseUserResponse, EnterpriseUserDTO, EstablishmentsResponse, UpdateEnterpriseUserResponse } from "features/enterprise/data/models";
import { AddCustomEstablishmentResult, CreateEnterpriseUserResult, CustomEstablishmentInput, DeleteEnterpriseUserResult, EnterpriseUser, Establishment, UpdateEnterpriseUserResult } from "features/enterprise/domain/models";
import { mapApiValidationErrorToValidationResult } from "features/validation/domain/mappers";

const createEnterpriseUserValidationCodes = [
  { code: 'EMAIL_NOT_ACCEPTABLE', labelKey: 'mobiscan_users_add_validation_email_exists' },
];

const updateEnterpriseUserValidationCodes = [
  { code: 'USER_NOT_FOUND', labelKey: 'mobiscan_users_delete_validation_not_found' },
  { code: 'CANNOT_MODIFY_ONLY_ORG_ADMIN', labelKey: 'mobiscan_users_delete_validation_only_admin' }
];

const deleteEnterpriseUserValidationCodes = [
  { code: 'USER_NOT_FOUND', labelKey: 'mobiscan_users_delete_validation_not_found' },
  { code: 'CANNOT_MODIFY_ONLY_ORG_ADMIN', labelKey: 'mobiscan_users_delete_validation_only_admin' }
];

export function mapEnterpriseUsersFromDTOs(dtos: EnterpriseUserDTO[]): EnterpriseUser[] {
  return dtos.map(mapEnterpriseUserFromDTO);
}

function mapEnterpriseUserFromDTO(dto: EnterpriseUserDTO): EnterpriseUser {
  return {
    id: dto.id,
    enterprise: dto.enterprise,
    firstName: dto.firstName,
    lastName: dto.lastName,
    jobTitle: dto.jobTitle,
    phone: dto.phone,
    email: dto.email,
    role: UserRole[dto.role as keyof typeof UserRole],
    establishmentPermissions: mapEstablishmentPermissions(dto.establishmentPermissions),
  }
}

export function mapEstablishmentsResponse(response: EstablishmentsResponse): Establishment[] {
  return response.establishments;
}

export function mapCustomEstablishmentInputToDto(customEstablishment: CustomEstablishmentInput): CustomEstablishmentInputDTO {
  return {
    name: customEstablishment.name,
    street: customEstablishment.address.street,
    houseNumber: customEstablishment.address.houseNumber,
    box: customEstablishment.address.box,
    postalCode: customEstablishment.address.postalCode,
    city: customEstablishment.address.city,
    country: customEstablishment.address.country,
  };
}


const crossroadsValidationCodes = [
  { code: 'ESTABLISHMENT_ALREADY_REGISTERED', labelKey: 'add_establishment_validation_already_exists' },
  { code: 'NOT_IN_WEST_FLANDERS', labelKey: 'add_establishment_validation_not_in_west_flanders' },
  { code: 'ADDRESS_NOT_FOUND', labelKey: 'add_establishment_validation_address_not_found' },
];

export function mapAddCustomEstablishmentResponse(response: AddCustomEstablishmentResponse): AddCustomEstablishmentResult {
  const validationResult = mapApiValidationErrorToValidationResult(crossroadsValidationCodes, response);

  return {
    success: response.success,
    validationResult,
  };
}

export function mapCreateEnterpriseUserResponse(createEnterpriseUserResponse: CreateEnterpriseUserResponse): CreateEnterpriseUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(createEnterpriseUserValidationCodes, createEnterpriseUserResponse);
  return {
    success: createEnterpriseUserResponse.success,
    validationResult,
  };
}

export function mapUpdateEnterpriseUserResponse(updateEnterpriseUserResponse: UpdateEnterpriseUserResponse): UpdateEnterpriseUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(updateEnterpriseUserValidationCodes, updateEnterpriseUserResponse);
  return {
    success: updateEnterpriseUserResponse.success,
    validationResult,
  };
}

export function mapDeleteEnterpriseUserResponse(deleteEnterpriseUserResponse: DeleteEnterpriseUserResponse): DeleteEnterpriseUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(deleteEnterpriseUserValidationCodes, deleteEnterpriseUserResponse);
  return {
    success: deleteEnterpriseUserResponse.success,
    validationResult,
  };
}