// Removes all non-number characters
export function cleanCbeNumber(cbeNumber: string): string {
  const allowedChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  let result = '';
  for (let i = 0; i < cbeNumber.length; i++) {
    const char = cbeNumber.charAt(i);
    if (allowedChars.includes(char)) {
      result += char;
    }
  }
  return result;
}