import { mobiscanGeneralApi } from "features/common/data/repositories";
import { mapActivityFromDto, mapScheduleFromDto, mapSituationFromDto, mapTransportModeFromDto } from "features/mobiscan/general/domain/mappers";
import { Activity, Schedule, Situation, TransportMode } from "features/mobiscan/general/domain/models";

export async function getActivities(): Promise<Activity[]> {
  const activitiesDto = await mobiscanGeneralApi.fetchActivities();
  return activitiesDto.map(mapActivityFromDto);
}

export async function getSchedules(): Promise<Schedule[]> {
  const schedulesDto = await mobiscanGeneralApi.fetchSchedules();
  return schedulesDto.map(mapScheduleFromDto);
}

export async function getTransportModes(): Promise<TransportMode[]> {
  const transportModesDto = await mobiscanGeneralApi.fetchTransportModes();
  return transportModesDto.map(mapTransportModeFromDto);
}

export async function getSituations(): Promise<Situation[]> {
  const situationDto = await mobiscanGeneralApi.fetchSituations();
  return situationDto.map(mapSituationFromDto);
}
