import { getUser } from "features/auth/domain/authOperations";
import { RegisteredEnterprise } from "features/province/domain/models"
import * as provinceServices from "features/province/domain/provinceServices";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAppDispatch } from "redux/store";
import * as routes from "routes";
import styles from "./LoginAsEnterpriseButton.module.scss";

interface LoginAsEnterpriseButtonProps {
  enterprise: RegisteredEnterprise
}

export function LoginAsEnterpriseButton(props: LoginAsEnterpriseButtonProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoginAsClicked = useCallback(async () => {
    setIsLoading(true);
    await provinceServices.loginAsEnterprise(props.enterprise.cbeNumber);
    await dispatch(getUser());
    navigate(routes.HOME);
  }, [dispatch, navigate, props.enterprise.cbeNumber]);

  return <>
    {
      isLoading
        ? <button className={ styles.loginAsEnterpriseButton } disabled>{ t('province_companies_table_login_as_loading') }</button>
        : <button className={ styles.loginAsEnterpriseButton } onClick={ (e) => handleLoginAsClicked() }>
          { t('province_companies_table_login_as') }
          <span className="visually-hidden">- { props.enterprise.name }</span>
        </button>
    }
  </>;
}
