import { useTranslation } from "react-i18next";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import tabMiniStyles from "features/common/ui/tabs/Tab.miniStyles.module.scss";
import styles from "./PotentialTransportTab.module.scss";
import { Employee, IsolineCollection, Mobiscan } from "features/mobiscan/report/domain/models";
import { PotentialShiftMap } from "features/mobiscan/report/ui/tabs/maps/PotentialShiftMap";
import { Schedule, TransportCategory } from "features/mobiscan/general/domain/models";
import { useCallback } from "react";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { t } from "i18next";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { PotentialShiftChart } from "features/mobiscan/report/ui/tabs/charts/PotentialShiftChart";
import { MapLayerCollectionKey } from "features/map/domain/models";
import { getTransportModesByCategory } from "features/mobiscan/general/domain/transportModeUtil";


interface PotentialTransportTabProps {
  mobiscan: Mobiscan,
  titleLabelKey: string,
  noDataLabelKey: string,
  showPotentialLabelKey: string,
  transportCategory: TransportCategory,
  employees: Employee[],
  initialSelectedLayers?: MapLayerCollectionKey[],
}

export function PotentialTransportTab(props: PotentialTransportTabProps) {
  const { t } = useTranslation();

  const employeesWithPotential = props.employees.filter((employee) => employee.potentialTransportModes.find((potential) => potential.transportMode.category === props.transportCategory) != null);
  const employeesCurrently = props.employees.filter(employee => employee.transportMode.category === props.transportCategory);
  const totalPotential = employeesWithPotential.length + employeesCurrently.length;

  return < article className={ styles.container } >
    <header className={ styles.header }>
      <div className={ styles.headerInner }>
        <h2 className={ styles.headerTitle }>
          {
            employeesWithPotential.length === 0
              ? t(props.noDataLabelKey)
              : t(props.titleLabelKey, { currentAmount: employeesCurrently.length, potentialAmount: employeesWithPotential.length, total: totalPotential })
          }
        </h2>
      </div>
    </header>
    {
      employeesWithPotential.length > 0 &&
      <div className={ styles.content }>
        <div className={ styles.contentInner }>
          <TabContainer
            titleWrapperClassName={ tabMiniStyles.miniTabsWrapper }
            panelWrapperClassName={ tabMiniStyles.miniPanelsWrapper }>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_potential_map') } /></TabTitle>
              <TabPanel>
                <Map
                  mobiscan={ props.mobiscan }
                  transportCategory={ props.transportCategory }
                  employees={ props.employees }
                  initialSelectedLayers={ props.initialSelectedLayers }
                  showPotentialLabelKey={ props.showPotentialLabelKey }
                />
              </TabPanel>
            </Tab>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_potential_graph') } /></TabTitle>
              <TabPanel>
                <PotentialShiftChart
                  transportModes={ getTransportModesByCategory(props.transportCategory) }
                  employees={ props.employees }
                  establishment={ props.mobiscan.establishment }
                />
              </TabPanel>
            </Tab>
          </TabContainer>
        </div>
      </div>
    }
  </article>;
}


interface MapProps {
  mobiscan: Mobiscan,
  transportCategory: TransportCategory,
  employees: Employee[],
  showPotentialLabelKey: string,
  initialSelectedLayers?: MapLayerCollectionKey[],
}

function Map(props: MapProps) {

  const fetchSchedules = useCallback(async () => {
    return await mobiscanGeneralServices.getSchedules();
  }, []);

  const fetchIsolines = useCallback(async () => {
    return await reportServices.getIsolines(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const isolineFetch = useFetch<IsolineCollection[]>(fetchIsolines);
  const scheduleFetch = useFetch<Schedule[]>(fetchSchedules);

  if (isolineFetch.fetchStatus === FetchStatus.unknownError || scheduleFetch.fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (isolineFetch.fetchStatus === FetchStatus.success && scheduleFetch.fetchStatus === FetchStatus.success) {
    const isolines = isolineFetch.fetchResult!;
    const schedules = scheduleFetch.fetchResult!;

    return <div>
      <PotentialShiftMap
        transportCategory={ props.transportCategory }
        schedules={ schedules }
        employees={ props.employees }
        establishment={ props.mobiscan.establishment }
        establishmentCoordinates={ props.mobiscan.establishmentCoordinates }
        isolineCollections={ isolines }
        showPotentialLabelKey={ props.showPotentialLabelKey }
        initialSelectedLayers={ props.initialSelectedLayers }
      />
    </div>;
  }

  return <LoadingIndicator />;
}
