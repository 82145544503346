import { AppConfig } from "config/AppConfig";
import { UserRole } from "features/auth/domain/models";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapPendingRegistrationListFromJsonList, mapRegisteredEnterpriseListFromJsonList, mapProvinceUserListFromJsonList, mapCreateProvinceUserSuccessResponse, mapCreateProvinceUserValidationErrors, mapDeleteProvinceUserSuccessResponse, mapDeleteProvinceUserValidationErrors, mapProvinceMobiscanListFromJsonList } from "features/province/data/mappers";
import { PendingRegistrationDTO, RegisteredEnterpriseDTO, ProvinceUserDTO, CreateProvinceUserResponse, DeleteProvinceUserResponse, ProvinceMobiscanDTO } from "features/province/data/models";
import { ProvinceUser } from "features/province/domain/models";


export interface ProvinceApi {
  fetchPendingRegistrations(): Promise<PendingRegistrationDTO[]>;
  approveRegistration(cbeNumber: string): Promise<void>;
  rejectRegistration(cbeNumber: string): Promise<void>;
  fetchRegisteredEnterprises(): Promise<RegisteredEnterpriseDTO[]>;
  fetchProvinceMobiscans(): Promise<ProvinceMobiscanDTO[]>;
  fetchProvinceUsers(): Promise<ProvinceUserDTO[]>;
  createProvinceUser(provinceUser: ProvinceUser): Promise<CreateProvinceUserResponse>;
  deleteProvinceUser(id: number): Promise<DeleteProvinceUserResponse>;
}

export class ProvinceApiImpl implements ProvinceApi {

  async fetchPendingRegistrations(): Promise<PendingRegistrationDTO[]> {
    const url = AppConfig.API_URL + '/registrations/pending';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapPendingRegistrationListFromJsonList(json.registrations);
    }

    throw new ApiException(response.status);
  }

  async approveRegistration(cbeNumber: string): Promise<void> {
    const url = AppConfig.API_URL + '/registrations/' + cbeNumber + '/accept';
    const options = { method: 'PATCH' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status !== 200) {
      throw new ApiException(response.status, 'unexpected statuscode');
    }
  }

  async rejectRegistration(cbeNumber: string): Promise<void> {
    const url = AppConfig.API_URL + '/registrations/' + cbeNumber + '/reject';
    const options = { method: 'PATCH' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status !== 200) {
      throw new ApiException(response.status, 'unexpected statuscode');
    }
  }

  async fetchRegisteredEnterprises(): Promise<RegisteredEnterpriseDTO[]> {
    const url = AppConfig.API_URL + '/organizations?term=';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapRegisteredEnterpriseListFromJsonList(json.organizations);
    }

    throw new ApiException(response.status);
  }

  async fetchProvinceMobiscans(): Promise<ProvinceMobiscanDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapProvinceMobiscanListFromJsonList(json.mobiscans);
    }

    throw new ApiException(response.status);
  }

  async fetchProvinceUsers(): Promise<ProvinceUserDTO[]> {
    const url = AppConfig.API_URL + '/users';
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapProvinceUserListFromJsonList(json.users);
    }

    throw new ApiException(response.status);
  }

  async createProvinceUser(provinceUser: ProvinceUser): Promise<CreateProvinceUserResponse> {
    const newProvinceUser = {
      firstName: provinceUser.firstName,
      lastName: provinceUser.lastName,
      jobTitle: provinceUser.jobTitle,
      phone: provinceUser.phone,
      email: provinceUser.email,
      role: UserRole.PROVINCE,
      establishmentPermissions: [],
    };

    const url = AppConfig.API_URL + '/users';
    const options = { method: 'POST', body: JSON.stringify(newProvinceUser) };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapCreateProvinceUserSuccessResponse();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapCreateProvinceUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

  async deleteProvinceUser(id: number): Promise<DeleteProvinceUserResponse> {
    const url = AppConfig.API_URL + '/users/' + id;
    const options = { method: 'DELETE' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return mapDeleteProvinceUserSuccessResponse();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapDeleteProvinceUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

}