import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterResult } from "features/register/domain/models";
import { RootState } from "redux/store";
import * as registrationServices from "features/register/domain/registrationServices";
import { initialState, setEnterpriseRegistrationInfo, setJoinEnterpriseResult, setRegisterResult, setRegistrationUserInput } from "features/register/domain/redux";
import { mapUserInputToJoinEnterpriseRequest, mapUserInputToRegistrationRequest } from "features/register/domain/mappers";
import { JoinEnterpriseResponse } from "features/register/data/models";
import { cleanCbeNumber } from "features/magda/domain/util";
import { EnterpriseInfoResponse } from "features/magda/domain/models";
import * as magdaServices from "features/magda/domain/magdaServices";

interface ThunkApiState {
  state: RootState;
}

export const resetState = createAsyncThunk(
  'registration/reset',
  async (_, thunkApi) => {
    thunkApi.dispatch(setEnterpriseRegistrationInfo(initialState.enterpriseInfo));
    thunkApi.dispatch(setRegistrationUserInput(initialState.registrationUserInput));
    thunkApi.dispatch(setRegisterResult(initialState.registerResult));
  }
);

export const getEnterpriseRegistrationInfo = createAsyncThunk<EnterpriseInfoResponse, string, ThunkApiState>(
  'registration/enterpriseRegistrationInfo',
  async (cbeNumber, thunkApi) => {
    try {
      const result = await magdaServices.getEnterpriseRegistrationInfo(cbeNumber);

      if (result.enterpriseInfo) {
        const enterpriseInfo = result.enterpriseInfo;
        thunkApi.dispatch(setEnterpriseRegistrationInfo(enterpriseInfo));

        if (enterpriseInfo.enterprise != null) {
          const userInput = thunkApi.getState().registration.registrationUserInput;
          // If the user has entered an establishment number; select that one
          // Otherwise: set all establishments selected
          const inputCbeNumber = cleanCbeNumber(userInput.cbeNumber);
          const establishmentInput = enterpriseInfo.enterprise.establishments.find(establishment => establishment.cbeNumber === inputCbeNumber);
          const selectedEstablishments = establishmentInput ? [establishmentInput] : enterpriseInfo.enterprise.establishments;
          thunkApi.dispatch(setRegistrationUserInput({ ...userInput, selectedEstablishments, }));
        }
      }

      return result;
    } catch (e) {
      console.error('error in getEnterpriseRegistrationInfo', e);
      return thunkApi.rejectWithValue('error in getEnterpriseRegistrationInfo');
    }
  }
);

export const register = createAsyncThunk<RegisterResult, void, ThunkApiState>(
  'registration/register',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setRegisterResult(undefined));

      const userInput = thunkApi.getState().registration.registrationUserInput;
      const enterpriseInfo = thunkApi.getState().registration.enterpriseInfo;
      const request = mapUserInputToRegistrationRequest(enterpriseInfo!, userInput);
      const result = await registrationServices.register(request);

      thunkApi.dispatch(setRegisterResult(result));

      return result;
    } catch (e) {
      console.error('error in register', e);
      return thunkApi.rejectWithValue('error in register');
    }
  }
);

export const requestJoinEnterprise = createAsyncThunk<JoinEnterpriseResponse, void, ThunkApiState>(
  'registration/joinEnterprise',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setJoinEnterpriseResult(undefined));
      const cbeNumber = thunkApi.getState().registration.enterpriseInfo?.enterprise.cbeNumber;
      if (!cbeNumber) {
        throw Error('invalid state');
      }

      const userInput = thunkApi.getState().registration.registrationUserInput;
      const request = mapUserInputToJoinEnterpriseRequest(userInput, cbeNumber);
      const result = await registrationServices.requestJoinEnterprise(request);

      thunkApi.dispatch(setJoinEnterpriseResult(result));

      return result;
    } catch (e) {
      console.error('error in joinEnterprise', e);
      return thunkApi.rejectWithValue('error in joinEnterprise');
    }
  }
);