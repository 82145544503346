import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginResult } from "features/auth/domain/models";
import { RootState } from "redux/store";
import * as authServices from "features/auth/domain/authServices";
import { setLoggedIn, setUser } from "features/auth/domain/redux";
import { NotLoggedInException } from "features/common/exceptions/NotLoggedInException";

interface ThunkApiState {
  state: RootState;
}

export const login = createAsyncThunk<LoginResult, { email: string; password: string }, ThunkApiState>(
  'auth/login',
  async ({ email, password, }, thunkApi) => {
    try {
      const result = await authServices.login(email, password);
      if (result.success) {
        await thunkApi.dispatch(getUser());
      }

      thunkApi.dispatch(setLoggedIn(result.success));
      return result;
    } catch (e) {
      console.error('error logging in', e);
      return thunkApi.rejectWithValue('error logging in');
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, thunkApi) => {
    try {
      await authServices.logout();
      thunkApi.dispatch(setUser(undefined));
      thunkApi.dispatch(setLoggedIn(false));
    } catch (e) {
      console.error('error logging in', e);
      return thunkApi.rejectWithValue('error logging in');
    }
  }
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, thunkApi) => {
    try {
      const result = await authServices.getUser();

      thunkApi.dispatch(setUser(result));
      thunkApi.dispatch(setLoggedIn(true));

      return result;
    } catch (exception) {
      if (exception instanceof NotLoggedInException) {
        return thunkApi.rejectWithValue(exception.name);
      }
      return thunkApi.rejectWithValue('error getting user data');
    }
  }
);