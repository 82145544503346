import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { Mobiscan, ReportInfrastructure, ReportInfrastructureField, ReportInfrastructureTab, ReportPolicy, ReportPolicyField, ReportPolicyTab } from "features/mobiscan/report/domain/models";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./InfrastructureTab.module.scss";

interface InfrastructureTabProps {
  mobiscan: Mobiscan
}

export function InfrastructureTab(props: InfrastructureTabProps) {
  const { t } = useTranslation();

  const fetchPolicy = useCallback(async () => {
    return await reportServices.getReportPolicy(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const fetchInfrastructure = useCallback(async () => {
    return await reportServices.getReportInfrastructure(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const policyFetch = useFetch<ReportPolicy>(fetchPolicy);
  const infrastructureFetch = useFetch<ReportInfrastructure>(fetchInfrastructure);

  if (policyFetch.fetchStatus === FetchStatus.unknownError || infrastructureFetch.fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (policyFetch.fetchStatus === FetchStatus.success && infrastructureFetch.fetchStatus === FetchStatus.success) {
    const policy = policyFetch.fetchResult!;
    const infrastructure = infrastructureFetch.fetchResult!;

    return <>
      <section className={ styles.infrastructure }>
        <div className={ styles.infrastructureInner }>
          <article className={ styles.infrastructureCategory }>
            <h2 className={ styles.infrastructureCategoryTitle }>{ t('report_tab_infrastructure_infrastructure_title') }</h2>
            <p className={ styles.infrastructureCategorySubtitle }>{ t('report_tab_infrastructure_infrastructure_note') }</p>
            {
              infrastructure.tabs.map((tab) => {
                return <Section key={ tab.tabName } tab={ tab } />;
              })
            }
          </article>

          <article className={ styles.infrastructureCategory }>
            <h2 className={ styles.infrastructureCategoryTitle }>{ t('report_tab_infrastructure_policy_title') }</h2>
            <p className={ styles.infrastructureCategorySubtitle }>{ t('report_tab_infrastructure_policy_note') }</p>
            {
              policy.tabs.map((tab) => {
                return <Section key={ tab.tabName } tab={ tab } />;
              })
            }
          </article>

        </div>
      </section>
    </>;
  }
  return <LoadingIndicator />
}


interface SectionProps {
  tab: ReportPolicyTab | ReportInfrastructureTab,
}

function Section(props: SectionProps) {
  const { t } = useTranslation();
  const label = t('report_tab_infrastructure_' + props.tab.tabName.toLowerCase());
  const image = getImageForTab(props.tab.tabName);

  const shouldDisplayField = (field: ReportInfrastructureField | ReportPolicyField) => {
    return (field.type === 'Potential' && field.value != null)
      || (field.type === 'BigDecimal' && field.value != null)
      || (field.type === 'Integer' && field.value != null);
  };

  return <section className={ styles.section }>
    <h3 className={ styles.sectionTitle }>
      <img src={ image } alt="" loading="lazy" />
      { label }
    </h3>
    <ul className={ styles.sectionList }>
      {
        props.tab.fields
          .filter((field) => shouldDisplayField(field))
          .map((field) => {
            return <li key={ field.fieldName } className={ styles.sectionListItem }>
              <Item field={ field } />
            </li>;
          })
      }
    </ul>
  </section>;
}


interface ItemProps {
  field: ReportInfrastructureField | ReportPolicyField,
}

function Item(props: ItemProps) {

  const isNumber = props.field.type === 'BigDecimal' || props.field.type === 'Integer';

  return <div className={ styles.sectionItem }>
    <div className={ styles.sectionItemStatusWrapper }>
      <div className={ styles.sectionItemStatus }>
        <CheckIcon field={ props.field } />
      </div>
    </div>
    <div className={ styles.sectionItemText }>
      { props.field.label }
    </div>
    { isNumber ? <div className={ styles.sectionItemNumber }>{ props.field.value }</div> : '' }
  </div>;
}


interface CheckIconProps {
  field: ReportInfrastructureField | ReportPolicyField,
}

function CheckIcon(props: CheckIconProps) {
  const value = props.field.value;

  const potentialClassNames = [
    { value: 'no', className: styles.sectionItemStatusNo },
    { value: 'future', className: styles.sectionItemStatusFuture },
    { value: 'yes', className: styles.sectionItemStatusYes },
  ];

  const getClassName = () => {
    if (props.field.type === 'Potential') {
      return potentialClassNames.find((c) => c.value.toLowerCase() === props.field.value.toLowerCase())?.className;
    } else if (props.field.type === 'BigDecimal' || props.field.type === 'Integer') {
      return props.field.value === 0
        ? styles.sectionItemStatusNo
        : styles.sectionItemStatusYes;
    }
  }


  return <div className={ getClassName() }>
    <span className="visually-hidden">{ value }</span>
  </div>;
}


function getImageForTab(tabName: string) {
  switch (tabName) {
    case 'carInfrastructure':
    case 'carpoolPolicy':
    case 'carPolicy':
      return '/images/icons/icon__transport-type-car.svg';
    case 'bicycleInfrastructure':
    case 'bicyclePolicy':
      return '/images/icons/icon__transport-type-bike.svg';
    case 'publicTransitPolicy':
      return '/images/icons/icon__transport-type-train.svg';
    case 'communicationPolicy':
    case 'policyExtras':
      return '/images/icons/icon__transport-type-misc.svg';
    default:
      throw Error(`Image for ${tabName} not found`);
  }
}
