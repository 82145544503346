import React, { useEffect, useState } from "react";


interface TabContainerProps {
  children: JSX.Element[],
  title?: string,
  className?: string,
  titleWrapperClassName?: string,
  panelWrapperClassName?: string,

  //Allows the panel for the selected tab to be rendered outside of the tab container
  onRenderTabPanel?: (panel: JSX.Element) => void,

  //Allows parent component to self-manage the current tab index
  selectedTabIndex?: number,
  onUpdateTabIndex?: (tabIndex: number) => void,
}

export function TabContainer(props: TabContainerProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(props.selectedTabIndex ? props.selectedTabIndex : 0);

  // Tab index management
  useEffect(() => {
    if (props.selectedTabIndex != null) {
      setSelectedTabIndex(props.selectedTabIndex);
    }
  }, [props.selectedTabIndex, setSelectedTabIndex]);

  const handleTabClicked = (index: number) => {
    setSelectedTabIndex(index);
    if (props.onUpdateTabIndex) {
      props.onUpdateTabIndex(index);
    }
  }

  // Render determination
  let tabTitles: JSX.Element[] = [];
  let tabPanels: JSX.Element[] = [];
  React.Children.forEach(props.children, (containerChild, index) => {
    if (containerChild.type === Tab) {
      const tabChildren: JSX.Element[] = containerChild.props.children;
      React.Children.forEach(tabChildren, (tabChild, index) => {
        if (tabChild.type === TabTitle) {
          tabTitles.push(tabChild);
        }
        if (tabChild.type === TabPanel) {
          tabPanels.push(tabChild);
        }
      });
    }
  });

  const onRenderTabPanel = props.onRenderTabPanel;
  const tabPanelToRender = tabPanels[selectedTabIndex];
  useEffect(() => {
    if (onRenderTabPanel) {
      onRenderTabPanel(tabPanelToRender);
    }
    // TODO: figure out how to remove this warning - tabPanelToRender "changes" everytime because it's recalculated everytime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRenderTabPanel, selectedTabIndex]);

  return <div className={ props.className }>
    <nav className={ props.titleWrapperClassName } aria-label={ props.title }>
      <ul >
        {
          tabTitles.map((tabTitle, index) => {
            const isSelected = index === selectedTabIndex;

            return <li key={ 'tab_' + index }>
              <button type="button" onClick={ () => handleTabClicked(index) } aria-current={ isSelected } disabled={ tabTitle.props.disabled }>
                { React.cloneElement(tabTitle.props.children, { isSelected: isSelected }) }
              </button>
            </li>
          })
        }
      </ul>
    </nav>


    {
      props.onRenderTabPanel == null && <div className={ props.panelWrapperClassName }>
        { tabPanelToRender }
      </div>
    }
  </div>;
}

interface TabProps {
  children: JSX.Element[],
}

export function Tab(props: TabProps): JSX.Element {
  return <>

  </>;
}


interface TabTitleProps {
  children: React.ReactNode,
  disabled?: boolean,
}

export function TabTitle(props: TabTitleProps): JSX.Element {
  return <>

  </>
}

interface TabPanelProps {
  children: React.ReactNode,
}

export function TabPanel(props: TabPanelProps): JSX.Element {
  return <>
    { props.children }
  </>
}

export interface TabTitleChildProps {
  isSelected?: boolean,
}
