import { createSlice } from "@reduxjs/toolkit";
import { SetEnterpriseRegistrationInfoAction, SetJoinEnterpriseResultAction, SetRegistrationResultAction, SetRegistrationUserInputAction } from "features/register/domain/actions";
import { RegistrationSliceState } from "features/register/domain/models";

export const initialState: RegistrationSliceState = {
  enterpriseInfo: undefined,
  registrationUserInput: {
    cbeNumber: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phone: '',
    email: '',
    password: '',
    selectedEstablishments: [],
  },
  registerResult: undefined,
  joinEnterpriseResult: undefined,
}

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setEnterpriseRegistrationInfo: (state, action: SetEnterpriseRegistrationInfoAction) => {
      state.enterpriseInfo = action.payload;
    },
    setRegistrationUserInput: (state, action: SetRegistrationUserInputAction) => {
      state.registrationUserInput = action.payload;
    },
    setRegisterResult: (state, action: SetRegistrationResultAction) => {
      state.registerResult = action.payload;
    },
    setJoinEnterpriseResult: (state, action: SetJoinEnterpriseResultAction) => {
      state.joinEnterpriseResult = action.payload;
    },
  }
});

export const {
  setEnterpriseRegistrationInfo,
  setRegistrationUserInput,
  setRegisterResult,
  setJoinEnterpriseResult,
} = registrationSlice.actions;

export default registrationSlice.reducer;