import { CrossroadsResponse, MagdaEnterpriseDTO } from "features/magda/data/models";
import { EnterpriseInfoResponse, MagdaEnterprise } from "features/magda/domain/models";
import { mapApiValidationErrorToValidationResult } from "features/validation/domain/mappers";


const crossroadsValidationCodes = [
  { code: 'REASON_CODE_ENTERPRISE_NOT_FOUND', labelKey: 'register_validation_enterprise_not_found' },
  { code: 'REASON_CODE_INACTIVE_ENTERPRISE', labelKey: 'register_validation_enterprise_inactive' },
];

export function mapCrossroadsResponse(crossroadsResponse: CrossroadsResponse): EnterpriseInfoResponse {
  const validationResult = mapApiValidationErrorToValidationResult(crossroadsValidationCodes, crossroadsResponse);

  let enterpriseInfo = undefined;
  if (validationResult.isValid) {
    const enterprise = crossroadsResponse.enterprise!;
    const mappedEnterprise = mapEnterpriseDTO(enterprise);
    enterpriseInfo = {
      registered: crossroadsResponse.registered!,
      enterprise: mappedEnterprise,
    };
  }

  return {
    success: crossroadsResponse.success,
    validationResult,
    enterpriseInfo,
  };
}

export function mapEnterpriseDTO(enterpriseDTO: MagdaEnterpriseDTO): MagdaEnterprise {
  const sortedEstablishments = [...enterpriseDTO.establishments.WVL].sort((a, b) => {
    const nameA = a.name ?? '';
    const nameB = b.name ?? '';
    return nameA.localeCompare(nameB);
  });

  return {
    name: enterpriseDTO.name,
    cbeNumber: enterpriseDTO.cbeNumber,
    establishments: sortedEstablishments,
  };
}