import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import React, { useCallback, useEffect, useState } from "react";
import { PendingRegistration } from "features/province/domain/models";
import * as provinceServices from "features/province/domain/provinceServices";
import { Retry } from "features/common/ui/Retry";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { formatDateToSlashString } from "features/common/util/date";
import RegistrationActions from "features/province/ui/registration/RegistrationActions";
import * as routes from "routes";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import styles from "./RegistrationDetailPage.module.scss";
import { ProvinceUserMenu } from "features/province/ui/ProvinceUserMenu";

export default function RegistrationDetailPage() {
  const { t } = useTranslation();
  const { cbeNumber } = useParams();
  const [pendingRegistration, setPendingRegistration] = useState<PendingRegistration | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchRegistration = useCallback(async () => {
    setErrorMessage(null);
    try {
      const registration = await provinceServices.getPendingRegistration(cbeNumber!);
      if (registration) {
        setPendingRegistration(registration);
      } else {
        setErrorMessage(t('province_registration_detail_invalid_number'));
      }
    } catch (e: any) {
      setErrorMessage(e.toString());
    }
  }, [cbeNumber, t]);

  useEffect(() => {
    fetchRegistration();
  }, [fetchRegistration]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('province_registration_detail_back') } targetUrl={ routes.PROVINCE_HOME } displayType={ BackButtonType.box } />
        <div className={ styles.introBlock }>
          <h1 className={ styles.introTitle }>{ t('province_home_title') }</h1>
          <p className={ styles.introDescription }>{ t('general_mobiscan_description_1') }</p>
          <br />
          <p className={ styles.introDescription }>{ t('general_mobiscan_description_2') }</p>
        </div>
      </PageIntroItem>
      <PageIntroItem position={ IntroPosition.right }>
        <ProvinceUserMenu />
      </PageIntroItem>
    </PageIntro>

    <section className={ styles.content }>
      {
        (() => {
          if (errorMessage != null) {
            return <Retry onConfirmed={ fetchRegistration } title={ errorMessage } extraInfo={ errorMessage } />
          } else if (pendingRegistration == null) {
            return <LoadingIndicator />
          } else {
            return <>
              <div className={ styles.contentMain }>
                <h2 className={ styles.pageTitle }>{ t('province_registration_detail_title', { dateString: formatDateToSlashString(pendingRegistration.date) }) }</h2>
                <div className={ styles.organisationInfo }>
                  <h3> { t('province_registration_detail_organisation_title') }</h3>
                  <RegistrationInfoFieldSet fields={ [
                    {
                      title: t('province_registration_detail_organisation_title'),
                      value: pendingRegistration.enterprise.name,
                    },
                    {
                      title: t('province_registration_detail_organisation_cbe_number'),
                      value: pendingRegistration.enterprise.cbeNumber,
                    },
                  ] } />
                  <h3> { t('province_registration_detail_request_by_title') }</h3>
                  <RegistrationInfoFieldSet fields={ [
                    {
                      title: t('province_registration_detail_request_by_firstname'),
                      value: pendingRegistration.user.firstName,
                    },
                    {
                      title: t('province_registration_detail_request_by_lastname'),
                      value: pendingRegistration.user.lastName,
                    },
                    {
                      title: t('province_registration_detail_request_by_phone'),
                      value: pendingRegistration.user.phone,
                    },
                    {
                      title: t('province_registration_detail_request_by_email'),
                      value: pendingRegistration.user.email,
                    },
                  ] } />
                  <h3> { t('province_registration_detail_establishments_title') }</h3>
                  {
                    pendingRegistration.establishments.map((establishment) => {
                      return <RegistrationInfoFieldSet key={ establishment.cbeNumber } fields={ [
                        {
                          title: t('province_registration_detail_establishments_name'),
                          value: establishment.name,
                        },
                        {
                          title: t('province_registration_detail_establishments_cbe_number'),
                          value: establishment.cbeNumber,
                        },
                      ] } />
                    })
                  }
                </div>
              </div>
              <div className={ styles.contentAside }>
                <RegistrationActions registration={ pendingRegistration } />
              </div>
            </>
          }
        })()
      }
    </section>
  </Layout>
}

interface Field {
  title: string,
  value: string,
}

interface RegistrationInfoFieldSetProps {
  fields: Field[]
}

function RegistrationInfoFieldSet(props: RegistrationInfoFieldSetProps) {
  return <>
    <dl>
      { props.fields.map((field) => {
        return <React.Fragment key={ field.title }>
          <dt>{ field.title }</dt>
          <dd>{ field.value }</dd>
        </React.Fragment>;
      }) }
    </dl>
  </>
}



