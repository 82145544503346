import { MobiscanGeneralApi } from "features/mobiscan/general/data/MobiscanGeneralApi";
import { ActivityDTO, ScheduleDTO, SituationDTO, TransportModeDTO } from "features/mobiscan/general/data/models";

export class MobiscanGeneralMockApi implements MobiscanGeneralApi {

  async fetchActivities(): Promise<ActivityDTO[]> {
    await new Promise(r => setTimeout(r, 1000));
    return [
      "Land/tuin/bosbouw en visserij",
      "Industrie",
      "Energie, water en afval",
      "Bouw",
      "Groothandel",
      "Kleinhandel",
      "Transport, logistiek en post",
      "Horeca",
      "Informatie en telecommmunicatie",
      "Zakelijke dienstverlening",
      "Openbaar bestuur: bovenlokaal bestuur (excl. onderwijs)",
      "Openbaar bestuur: gemeentelijk bestuur (excl. onderwijs)",
      "Openbaar bestuur: openbare orde, civiele veiligheid en brandweer",
      "Openbaar bestuur: andere",
      "Onderwijs",
      "Gezondheidszorg: Ziekenhuizen",
      "Gezondheidszorg: Overige menselijke gezondheidszorg",
      "Gezondheidszorg: Maatschappelijke dienstverlening met huisvesting",
      "Gezondheidszorg: Maatschappelijke dienstverlening zonder huisvesting",
      "Kunst, amusement en recreatie",
      "Andere"
    ];
  }

  async fetchSchedules(): Promise<ScheduleDTO[]> {
    await new Promise(r => setTimeout(r, 1000));
    return [
      "FIXED",
      "ROLLING_HOURS",
      "SHIFT_2",
      "SHIFT_3",
      "SHIFT_WEEKEND",
      "SHIFT_NIGHT",
      "IRREGULAR",
      "OTHER"
    ];
  }

  async fetchTransportModes(): Promise<TransportModeDTO[]> {
    await new Promise(r => setTimeout(r, 1000));
    return [
      "PEDESTRIAN",
      "BIKE",
      "E_BIKE",
      "PEDELEC",
      "BUS_TRAM",
      "TRAIN",
      "COLLECTIVE_COMPANY",
      "CARPOOL",
      "PERSONAL_CAR",
      "COMPANY_CAR",
      "MOTORBIKE",
      "MOPED",
      "E_STEP"
    ];
  }

  async fetchSituations(): Promise<SituationDTO[]> {
    await new Promise(r => setTimeout(r, 1000));
    return [
      "NOT_SEASONAL",
      "WINTER",
      "SUMMER"
    ];
  }

}