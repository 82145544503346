import styles from './CircleMapIcon.module.scss';

interface CircleMapIconProps {
  dataType: string;
  label: string;
}

export function CircleMapIcon(props: CircleMapIconProps) {
  return <div data-type={ props.dataType } className={ styles.outerIcon }>
    <div data-type={ props.dataType } className={ styles.innerIcon }>
      <span className="visually-hidden">{ props.label }</span>
    </div>
  </div>;
}
