import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUser } from "features/auth/domain/authOperations";
import { UpdatePasswordRequest, UpdateUserRequest } from "features/user/data/models";
import { UpdatePasswordResult, UpdateUserResult } from "features/user/domain/models";
import * as userServices from "features/user/domain/userServices";
import { RootState } from "redux/store";

interface ThunkApiState {
  state: RootState;
}

export const updateUser = createAsyncThunk<UpdateUserResult, UpdateUserRequest, ThunkApiState>(
  'user/update',
  async (request, thunkApi) => {
    try {
      const result = await userServices.updateUser(request);
      await thunkApi.dispatch(getUser()); // Update user object stored in redux

      return result;
    } catch (exception) {
      console.log(exception);
      return thunkApi.rejectWithValue('error updating user');
    }
  }
);

export const updatePassword = createAsyncThunk<UpdatePasswordResult, UpdatePasswordRequest, ThunkApiState>(
  'user/updatePassword',
  async (request, thunkApi) => {
    try {
      const result = await userServices.updatePassword(request);
      return result;
    } catch (exception) {
      console.log(exception);
      return thunkApi.rejectWithValue('error updating password');
    }
  }
);