import { ValidationMessage } from "features/validation/domain/models";

export function validationMessageFromString(message: string): ValidationMessage {
  return {
    message,
  };
}

export function isString(obj: string): boolean {
  return typeof obj === 'string';
}

export function isNumber(obj: number): boolean {
  return typeof obj === 'number';
}

export function isBoolean(obj: boolean): boolean {
  return typeof obj === 'boolean';
}

export function isHigherThan(number: number, min: number): boolean {
  return number > min;
}

export function isHigherOrEqual(number: number, min: number): boolean {
  return number >= min;
}

export function isLowerThan(number: number, max: number): boolean {
  return number < max;
}

export function isLowerOrEqual(number: number, max: number): boolean {
  return number <= max;
}

export function isTrue(obj: boolean): boolean {
  return obj === true;
}

export function isEmpty(str: string): boolean {
  return str.length === 0;
}

export function isNull(obj?: any): boolean {
  return obj == null || typeof obj == 'undefined';
}

export function isWhitespace(str: string): boolean {
  return str.trim().length === 0;
}

export function isNullOrWhitespace(str?: string): boolean {
  return isNull(str) || isWhitespace(str ?? '');
}

export function stringContains(str: string, needle: string): boolean {
  return str.includes(needle);
}

export function stringContainsOneOf(str: string, needles: string[]): boolean {
  for (const needle of needles) {
    if (stringContains(str, needle) === true) {
      return true;
    }
  }
  return false;
}

export function isEmail(str?: string): boolean {
  if (!isNullOrWhitespace(str)) {
    const regEx = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    const matchResult = str?.match(regEx);
    if (matchResult != null && matchResult !== undefined) {
      return matchResult && matchResult.length > 0;
    }
  }

  return false;
}
