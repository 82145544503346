import { ModalButton } from "features/common/ui/ModalButton";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";
import { ReactComponent as IconMakeItFly } from 'assets/inline-svg/logos/logo-makeitfly.svg';
import { TreffikUrls } from "features/i18n/TreffikUrls";

export function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return <footer className={ styles.footer }>

    <div className={ styles.footerInner }>
      <div className={ styles.container }>
        <div className={ styles.nav }>
          <ul className={ styles.list }>
            <li className={ styles.item }>
              { t('footer_copyright', { currentYear }) }
            </li>
            <li className={ styles.item }>
              <ModalButton
                targetUrl={ TreffikUrls.privacyUrl }
                iframeTitle={ t('footer_privacy') }
                modalContentLabel={ t('footer_privacy') }>
                { t('footer_privacy') }
              </ModalButton>
            </li>
            <li className={ styles.item }>
              <ModalButton
                targetUrl={ TreffikUrls.termsConditionsUrl }
                iframeTitle={ t('footer_terms') }
                modalContentLabel={ t('footer_terms') }>
                { t('footer_terms') }
              </ModalButton>
            </li>
            <li className={ styles.item }>
              <a href="//makeitfly.group" target="_blank" rel="noopener noreferrer" className={ styles.makeitflyLink }>
                <span lang="en">made by <span className='visually-hidden'>make it fly</span></span>
                <IconMakeItFly />
                <span className='visually-hidden'> - { t('general_target_blank_link') }</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className={ styles.brandLogoFloater }>
      <img src="/images/logos/logo-westvlaanderen.png" width={ 286 } height={ 246 } lang="nl" alt="West-Vlaanderen. De gedreven Provincie." loading="lazy" />
    </div>

  </footer>;
}
