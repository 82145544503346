import React, { useId } from 'react'
import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";
import { EstablishmentDropdown, establishmentToOption } from "features/enterprise/ui/EstablishmentDropdown";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconDelete } from 'assets/inline-svg/icons/icon__delete.svg';
import { EstablishmentPermission, Permission } from "features/auth/domain/models";
import { Establishment } from "features/enterprise/domain/models";
import styles from "./PermissionEditor.module.scss";

interface PermissionsEditorProps {
  establishments: Establishment[],
  selectedEstablishments: Establishment[],
  selectedEstablishmentChanged: (establishment: Establishment) => void,
  establishmentPermission: EstablishmentPermission,
  permissionChanged: (establishmentPermission: EstablishmentPermission) => void,
  onDeletePressed: () => void,
}

export function PermissionsEditor(props: PermissionsEditorProps) {
  const { t } = useTranslation();
  const permissions = [Permission.READ, Permission.READ_WRITE];
  const permissionDropdownId = 'permission' + useId();

  const onDeletePressed = () => {
    props.onDeletePressed();
  };

  const onPermissionChanged = (optionValue?: string) => {
    const newEstablishmentPermission = { ...props.establishmentPermission };
    newEstablishmentPermission.permission = Permission[optionValue! as keyof typeof Permission];
    props.permissionChanged(newEstablishmentPermission);
  };

  const permissionOptions: DropdownOption[] = permissions.map(permission => ({
    value: permission,
    text: t('permission_' + permission.toLowerCase()),
  }));

  return <div className={ styles.permissions }>
    <div className={ styles.permissionsChoice }>
      <div className={ styles.permissionEstablishment }>
        <EstablishmentDropdown
          value={ establishmentToOption(props.establishmentPermission.establishment) }
          options={ props.establishments.map(establishment => establishmentToOption(establishment)) }
          disabledOptions={ props.selectedEstablishments.map(establishment => establishmentToOption(establishment)) }
          onValueChanged={ (option) => props.selectedEstablishmentChanged(option.establishment as Establishment) }
        />
      </div>
      <div className={ styles.permissionRole }>
        <Dropdown
          identifier={ permissionDropdownId }
          options={ permissionOptions }
          selectedOption={ permissionOptions.find(permission => permission.value === props.establishmentPermission.permission) }
          onChange={ onPermissionChanged }
          label={ (t('roles_editor_custom_permissions')) }
          labelHidden={ true }
        />
      </div>
    </div>
    <DeleteButton onClick={ onDeletePressed } establishment={ props.establishmentPermission.establishment } />
  </div>;
}

interface DeleteButtonProps {
  onClick: () => void,
  establishment: Establishment;
}

function DeleteButton(props: DeleteButtonProps) {
  const { t } = useTranslation();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.onClick();
  };

  return <button type="button" className={ styles.removeOrganizationButton } onClick={ onClick }>
    <span className="visually-hidden">
      { t('general_remove_organization') }: { props.establishment.name }
    </span>
    <IconDelete />
  </button>;
}
