import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { Layout } from "features/common/ui/layout/Layout";
import { MessageBox } from "features/common/ui/boxes/MessageBox";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as routes from "routes";
import styles from "./ProvinceUserOverview.module.scss";
import * as provinceServices from "features/province/domain/provinceServices";
import { ProvinceUser } from "features/province/domain/models";
import { Link } from "react-router-dom";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ValidationResult } from "features/validation/domain/models";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUser } from "features/auth/domain/authOperations";

export function ProvinceUserOverview() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchProvinceUsers = useCallback(async () => {
    return provinceServices.getProvinceUsers();
  }, []);

  const { executeFetch, fetchStatus, fetchResult } = useFetch<ProvinceUser[]>(fetchProvinceUsers);

  const onDeleteUserSuccess = () => {
    executeFetch();
  }

  const fetchUser = useCallback(async () => {
    provinceServices.logoutFromEnterprise();
    await dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <Layout>
    <PageIntro>

      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('province_users_header_back') } targetUrl={ routes.PROVINCE_HOME } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('province_users_header_title') }</h1>
      </PageIntroItem>

    </PageIntro>

    <section className={ styles.pageContent }>
      <ProvinceUserOverviewActions />

      {
        fetchStatus === FetchStatus.pending && <LoadingIndicator />
      }

      {
        fetchStatus === FetchStatus.success && <ProvinceUserOverviewList provinceUsers={ fetchResult! } onDeleteUserSuccess={ onDeleteUserSuccess } />
      }

      <div role="alert"> {/* "alert" content will be read out */ }
        {
          fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
        }
      </div>

    </section>

  </Layout>;
}

function ProvinceUserOverviewActions() {
  const { t } = useTranslation();

  return <div className={ styles.topActions }>
    <div className={ styles.topActionsInner }>
      <nav className={ styles.topActionItems } aria-label={ t('province_users_nav_title') }>
        <div className={ styles.topActionItem }>
          <Link to={ routes.PROVINCE_USERS_ADD } className="btn-primary btn-primary--add">{ t('province_users_add_user_cta') }</Link>
        </div>
      </nav>
    </div>
  </div>;
}

interface ProvinceUserOverviewListProps {
  provinceUsers: ProvinceUser[],
  onDeleteUserSuccess: () => void,
}

function ProvinceUserOverviewList(props: ProvinceUserOverviewListProps) {
  const { t } = useTranslation();

  const onDeleteUserSuccess = async () => {
    props.onDeleteUserSuccess();
  };

  return <div className={ styles.overview }>
    <div className={ styles.overviewInner }>
      <div className={ styles.resultBlock }>
        {
          props.provinceUsers.length === 0
            ? <MessageBox message={ t('province_users_empty') } />
            : <div className="data-table-wrapper">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>{ t('province_users_table_name') }</th>
                    <th>{ t('province_users_table_email') }</th>
                    <th>
                      <span className="visually-hidden">
                        { t('province_users_table_actions') }
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.provinceUsers.map((provinceUser) =>
                      <ProvinceUserOverviewListItem key={ provinceUser.id } provinceUser={ provinceUser! } onDeleteUserSuccess={ onDeleteUserSuccess } />
                    )
                  }
                </tbody>
              </table>
            </div>
        }
      </div>
    </div>
  </div>;
}

interface ProvinceUserOverviewListItemProps {
  provinceUser: ProvinceUser,
  onDeleteUserSuccess: () => void,
}

function ProvinceUserOverviewListItem(props: ProvinceUserOverviewListItemProps) {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const deleteUser = useCallback(async () => {
    setValidationResult(undefined);

    const result = await provinceServices.deleteProvinceUser(props.provinceUser.id);

    if (!result.success) {
      setValidationResult(result.validationResult);
      return;
    }

    props.onDeleteUserSuccess();
  }, [props]);

  const { executeFetch, fetchStatus } = useFetch<void>(deleteUser, false);

  return <tr>
    <td>{ props.provinceUser.firstName } { props.provinceUser.lastName }</td>
    <td>{ props.provinceUser.email }</td>
    <td data-align="right">{ }
      <button
        className={ styles.deleteButton }
        onClick={ executeFetch }
        disabled={ user.id === props.provinceUser.id || fetchStatus === FetchStatus.pending }
      >
        { t('province_users_table_delete') }
      </button>

      {
        validationResult
        && !validationResult.isValid
        && <ErrorBox errorMessage={ t(validationResult.messages[0].message) } />
      }

      {
        fetchStatus === FetchStatus.unknownError
        && <p>{ t('general_unknown_error') }</p>
      }
    </td>
  </tr>;
}
