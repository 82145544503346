import { useTranslation } from "react-i18next";
import { ReactComponent as IconDownload } from 'assets/inline-svg/icons/icon__download.svg';
import styles from "./ReportActions.module.scss";
import { Mobiscan } from "features/mobiscan/report/domain/models";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { downloadBlob } from "features/mobiscan/report/domain/util";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { useCallback, useState } from "react";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ShareModal } from "features/mobiscan/report/ui/ShareModal";

interface ReportActionsProps {
  mobiscan: Mobiscan,
}

export function ReportActions(props: ReportActionsProps) {
  const { t } = useTranslation();

  const fetchExportBlob = useCallback(async () => {
    const blob = await reportServices.getPotentialExportBlob(props.mobiscan.id);
    downloadBlob(blob);
  }, [props.mobiscan.id]);

  const { fetchStatus, executeFetch } = useFetch<void>(fetchExportBlob, false);

  return <section className={ styles.container }>
    <div className={ styles.inner }>

      <h2 className="visually-hidden">
        { t('report_actions_title') }
      </h2>

      <ul className={ styles.list }>
        <li className={ styles.listItem }>

          <article className={ styles.option }>
            <div className={ styles.optionContent }>
              <div className={ styles.optionContentImage }>
                <img src="/images/icons/icon__export.svg" alt="" />
              </div>
              <div className={ styles.optionContentMain }>
                <h3 className={ styles.optionTitle }>
                  <button type="button" onClick={ executeFetch } disabled={ fetchStatus === FetchStatus.pending }>
                    { t('report_actions_export_title') }
                  </button>
                </h3>
                <div className={ styles.optionText }>
                  { t('report_actions_export_info') }
                </div>
              </div>
            </div>
            <div className={ styles.optionArrowHolder }>
              <IconDownload />
            </div>
          </article>

          {
            fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
          }

        </li>
        <li className={ styles.listItem }>

          <ShareButton mobiscan={ props.mobiscan } />

        </li>
      </ul>

    </div>
  </section>;
}

interface ShareButtonProps {
  mobiscan: Mobiscan,
}

function ShareButton(props: ShareButtonProps) {
  const { t } = useTranslation();

  const [shareUrl, setShareUrl] = useState<string>('');
  const [modalVisible, setModalVisible] = useState(false);

  const getShareUrl = async () => {
    const shareUrl = await reportServices.getShareUrl(props.mobiscan.id);
    setShareUrl(shareUrl);
    setModalVisible(true);
  };

  const { fetchStatus, executeFetch } = useFetch(getShareUrl, false);

  return <>

    <ShareModal
      isOpen={ modalVisible }
      onCloseRequest={ () => setModalVisible(false) }
      shareUrl={ shareUrl }
    />

    {
      fetchStatus === FetchStatus.pending &&
      <div className={ styles.optionLoadingWrapper }>
        <LoadingIndicator />
      </div>
    }

    {
      fetchStatus !== FetchStatus.pending && <>
        <article className={ styles.option }>
          <div className={ styles.optionContent }>
            <div className={ styles.optionContentImage }>
              <img src="/images/icons/report-nav/icon__reportnav__share.svg" alt="" />
            </div>
            <div className={ styles.optionContentMain }>
              <h3 className={ styles.optionTitle }>
                <button type="button" onClick={ executeFetch }>
                  { t('report_actions_share_title') }
                </button>
              </h3>
              <div className={ styles.optionText }>
                { t('report_actions_share_info') }
              </div>
            </div>
          </div>
          <div className={ styles.optionArrowHolder }>
            <IconDownload />
          </div>
        </article>
      </>
    }
  </>;
}
