import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import * as chartServices from 'features/chart/domain/chartServices';
import { ChartSettings } from 'features/chart/domain/models';
import { roundNumber } from 'features/common/util/math';
import { Establishment } from 'features/enterprise/domain/models';
import { CommuteByTransportModes, ModalSplitBenchmark, ModalSplitBenchmarkValue, TransportModeSegment } from 'features/mobiscan/report/domain/models';
import { TFunction } from 'i18next';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface ModalSplitChartProps {
  commuteByTransportModes: CommuteByTransportModes,
  establishment: Establishment,
  benchmarkToCompare?: ModalSplitBenchmark,
}


export function ModalSplitChart(props: ModalSplitChartProps) {
  const { t } = useTranslation();

  const extraChartSettings: ChartSettings = {
    getPopupTitleCallback,
    getPopupBodyCallback,
    getPopupFooterCallback: (context) => getPopupFooterCallback(context, t),
    yAxisLabelText: t('report_tab_mobility_tab_modal_split_chart_y'),
    yAxisLabelFont: {
      size: 16
    },
    showVerticalGrid: false,
  };

  const options = chartServices.createChartOptions(extraChartSettings);
  const data = createData(props.commuteByTransportModes, props.establishment, t, props.benchmarkToCompare);

  return <div className='chart-scroll-outer'>
    <div className='chart-scroll-inner'>
      <Bar options={ options } data={ data } role="img" aria-label={ t('report_tab_mobility_tab_modal_split_chart_label') } />
    </div>
  </div>;
}

const createData = (commuteByTransportModes: CommuteByTransportModes, establishment: Establishment, t: TFunction, benchmarkToCompare?: ModalSplitBenchmark) => {
  const getLabel = (transportMode: string) => t('transport_mode_' + transportMode.toLowerCase());
  const datasets = [];

  const establishmentSegments = getEstablishmentSegments(commuteByTransportModes);
  const establishmentDataset = {
    label: establishment.name,
    data: establishmentSegments.map((segment) => {
      return {
        count: segment.count,
        percentage: roundNumber((segment.count / commuteByTransportModes.totalCount) * 100),
        label: getLabel(segment.transportMode.id),
      };
    }),
    parsing: {
      xAxisKey: 'label',
      yAxisKey: 'percentage'
    },
    backgroundColor: 'rgba(32, 176, 228, 1)',
    hoverBackgroundColor: 'rgba(32, 176, 228, .7)',
    borderRadius: 5,
    // barThickness: 20,
    // maxBarThickness: 30,
    // // barPercentage: 1, // doesn't work in combination with thickness values
    // barPercentage: 0.8,
  };
  datasets.push(establishmentDataset);

  if (benchmarkToCompare != null) {
    const benchmarkSegments = getBenchmarkSegments(benchmarkToCompare);
    const benchmarkDataset = {
      label: benchmarkToCompare.name,
      data: benchmarkSegments.map((benchmark) => {
        return {
          percentage: roundNumber(benchmark.percentage),
          label: getLabel(benchmark.transportMode.id),
        };
      }),
      parsing: {
        xAxisKey: 'label',
        yAxisKey: 'percentage'
      },
      backgroundColor: 'rgba(20, 49, 92, 1)',
      hoverBackgroundColor: 'rgba(20, 49, 92, .7)',
      borderRadius: 5,
      // barThickness: 20,
      // maxBarThickness: 30,
      // // barPercentage: 1, // doesn't work in combination with thickness values
      // barPercentage: 0.8,
    };
    datasets.push(benchmarkDataset);
  }

  const labels = establishmentSegments.map((segment) => getLabel(segment.transportMode.id));
  return {
    labels,
    datasets,
  };
};

const BIKE_GROUP = ['BIKE', 'E_BIKE', 'PEDELEC', 'E_STEP'];
const getEstablishmentSegments = (commuteByTransportModes: CommuteByTransportModes): TransportModeSegment[] => {
  const allSegments = commuteByTransportModes.transportModeSegments.sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex);

  // Groups BIKE, E_BIKE, PEDELEC and E_STEP under BIKE
  const mainSegments: TransportModeSegment[] = [];
  const bikeSegments: TransportModeSegment[] = [];
  allSegments.forEach((x) => (BIKE_GROUP.includes(x.transportMode.id) ? bikeSegments : mainSegments).push(x));

  const groupedBikeSegment: TransportModeSegment = {
    count: bikeSegments.reduce((prev, cur) => prev + cur.count, 0),
    transportMode: bikeSegments[0].transportMode, // Should be BIKE
    quantityByDistance: [],
  };

  mainSegments.push(groupedBikeSegment);
  return mainSegments.sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex);
}

const getBenchmarkSegments = (benchmark: ModalSplitBenchmark): ModalSplitBenchmarkValue[] => {
  const allSegments = benchmark.modalSplit.sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex);

  // Groups BIKE, E_BIKE, PEDELEC and E_STEP under BIKE
  const mainSegments: ModalSplitBenchmarkValue[] = [];
  const bikeSegments: ModalSplitBenchmarkValue[] = [];
  allSegments.forEach((x) => (BIKE_GROUP.includes(x.transportMode.id) ? bikeSegments : mainSegments).push(x));

  const groupedBikeSegment: ModalSplitBenchmarkValue = {
    percentage: bikeSegments.reduce((prev, cur) => prev + cur.percentage, 0),
    transportMode: bikeSegments[0].transportMode, // Should be BIKE
  };

  mainSegments.push(groupedBikeSegment);
  return mainSegments.sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex);
}


const getPopupTitleCallback = (context: any) => {
  return context[0].dataset.label;
};

const getPopupBodyCallback = (context: any) => {
  return context.label;
};

const getPopupFooterCallback = (context: any, t: TFunction) => {
  const count = context[0].raw.count;
  const percentage = context[0].raw.percentage;

  const countLabel = t('report_tab_mobility_tab_modal_split_chart_tooltip_count', { value: count, });
  const percentageLabel = t('report_tab_mobility_tab_modal_split_chart_tooltip_percentage', { value: percentage, });

  return count != null
    ? `${countLabel} \n${percentageLabel}`
    : `${percentageLabel}`;
};
