import wvlStationsGeoJson from "assets/geojson/wvl_nmbs_stations.json";
import stationMarkerIcon from "assets/images/logomark-nmbs.svg";
import { marker, Icon } from 'leaflet';
import { GeoJSON } from "react-leaflet";
import { useTranslation } from "react-i18next";

const stationIcon = new Icon({
  iconUrl: stationMarkerIcon,
  iconSize: [30, 20],
})

export function NmbsStationsLayer() {
  const data = wvlStationsGeoJson as GeoJSON.GeoJsonObject;
  const { t } = useTranslation();

  const onEachFeature = (feature: any, layer: any) => {
    //TODO: See if it's necessary to style this popup further
    const name = feature.properties?.naam;
    if (name != null) {
      layer.bindPopup(feature.properties?.naam);
    }
  };

  const pointToLayer = (feature: any, latLong: any) => {
    let markerAltText = t('map_marker_nmbs');
    const name = feature.properties?.naam;
    if (name != null) {
      markerAltText += name;
    }

    return marker(latLong, {
      icon: stationIcon,
      alt: markerAltText
    });
  };

  return <>
    <GeoJSON
      data={ data }
      onEachFeature={ onEachFeature }
      pointToLayer={ pointToLayer }
    >
    </GeoJSON>
  </>;
}
