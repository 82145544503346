import * as helpers from "features/validation/domain/helpers";
import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import { TFunction } from "react-i18next";

export function validateResetPassword(password: string, t: TFunction): ValidationResult {
  const validationMessages: ValidationMessage[] = validatePassword(password, t);

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}

const MIN_PASSWORD_LENGTH = 8;
const SPECIAL_CHARACTERS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
const NUMBERS = '0123456789';
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export function validatePassword(password: string, t: TFunction): ValidationMessage[] {
  const validationMessages: ValidationMessage[] = [];

  // Validate minimum length
  if (helpers.isLowerThan(password.length, MIN_PASSWORD_LENGTH)) {
    validationMessages.push(helpers.validationMessageFromString(t('password_validation_too_short', { minPasswordLength: MIN_PASSWORD_LENGTH, })));
  }

  // Validate at least one special character
  const specialCharacters = SPECIAL_CHARACTERS.split(''); // As character array
  if (helpers.stringContainsOneOf(password, specialCharacters) === false) {
    validationMessages.push(helpers.validationMessageFromString(t('password_validation_special_character', { specialCharacters: SPECIAL_CHARACTERS, })));
  }

  // Validate at least one number
  const numbers = NUMBERS.split(''); // As character array
  if (helpers.stringContainsOneOf(password, numbers) === false) {
    validationMessages.push(helpers.validationMessageFromString(t('password_validation_number')));
  }

  // Validate at least one letter
  const alphabet = ALPHABET.split(''); // As character array
  if (helpers.stringContainsOneOf(password, alphabet) === false) {
    validationMessages.push(helpers.validationMessageFromString(t('password_validation_letter')));
  }

  return validationMessages;
}