import { Layout } from "features/common/ui/layout/Layout";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { EnterpriseUser, Establishment } from "features/enterprise/domain/models";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import { RoleEditor } from "features/mobiscan/permissions/ui/RoleEditor";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/store";
import styles from "./EnterpriseUserOverview.module.scss";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import * as routes from "routes";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { useCallback, useState } from "react";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { MessageBox, MessageBoxType } from "features/common/ui/boxes/MessageBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { EstablishmentPermission, UserRole } from "features/auth/domain/models";
import { Link } from "react-router-dom";
import { ValidationResult } from "features/validation/domain/models";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import { ConfirmDeleteModal } from "features/common/ui/ConfirmDeleteModal";

export function EnterpriseUserOverview() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);

  const fetchEnterpriseUsers = useCallback(async () => {
    return enterpriseServices.getEnterpriseUsers(user.enterprise!.cbeNumber);
  }, [user.enterprise]);

  const fetchEstablishments = useCallback(async () => {
    return enterpriseServices.getEstablishments(user.enterprise!.cbeNumber);
  }, [user.enterprise]);

  const enterpriseUsersFetch = useFetch<EnterpriseUser[]>(fetchEnterpriseUsers);
  const establishmentsFetch = useFetch<Establishment[]>(fetchEstablishments);

  const onDeleteUserSuccess = () => {
    enterpriseUsersFetch.executeFetch();
  }

  return <ScopeGate requiredScope={ Scope.MANAGE_USERS }>
    <Layout>
      <PageIntro>

        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('mobiscan_users_header_back') } targetUrl={ routes.HOME } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('mobiscan_users_header_title') }</h1>
        </PageIntroItem>

      </PageIntro>

      <section className={ styles.pageContent }>

        <EnterpriseUserOverviewActions />

        {
          (enterpriseUsersFetch.fetchStatus === FetchStatus.pending || establishmentsFetch.fetchStatus === FetchStatus.pending)
          && <LoadingIndicator />
        }

        {
          (enterpriseUsersFetch.fetchStatus === FetchStatus.success && establishmentsFetch.fetchStatus === FetchStatus.success)
          && <EnterpriseUserOverviewList enterpriseUsers={ enterpriseUsersFetch.fetchResult! } establishments={ establishmentsFetch.fetchResult! } onDeleteUserSuccess={ onDeleteUserSuccess } />
        }

        <div role="alert"> {/* "alert" content will be read out */ }
          {
            (enterpriseUsersFetch.fetchStatus === FetchStatus.unknownError || establishmentsFetch.fetchStatus === FetchStatus.unknownError)
            && <ErrorBox errorMessage={ t('general_unknown_error') } />
          }
        </div>

      </section>
    </Layout>
  </ScopeGate>;
}

function EnterpriseUserOverviewActions() {
  const { t } = useTranslation();

  return <div className={ styles.topActions }>
    <div className={ styles.topActionsInner }>
      <nav className={ styles.topActionItems } aria-label={ t('mobiscan_users_nav_title') }>
        <div className={ styles.topActionItem }>
          <ModalButton targetUrl={ TreffikUrls.roleInfoUrl } className="btn-info">
            <IconInfo />
            <span>{ t('mobiscan_users_roles_info_cta') }</span>
          </ModalButton>
        </div>
        <div className={ styles.topActionItem }>
          <Link to={ routes.ENTERPRISE_USERS_ADD } className="btn-primary btn-primary--add">{ t('mobiscan_users_add_user_cta') }</Link>
        </div>
      </nav>
    </div>
  </div>;
}

interface EnterpriseUserOverviewListProps {
  enterpriseUsers: EnterpriseUser[],
  establishments: Establishment[],
  onDeleteUserSuccess: () => void
}

function EnterpriseUserOverviewList(props: EnterpriseUserOverviewListProps) {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [hasKnownError, setHasKnownError] = useState(false);// When no ORG_ADMIN or ORG_USER w/o custom permissions
  const [hasUnknownError, setHasUnknownError] = useState(false);
  const [enterpriseUsers, setEnterpriseUsers] = useState<EnterpriseUser[]>(props.enterpriseUsers);

  const checkForKnownErrors = () => {
    const adminUser = enterpriseUsers.find((enterpriseUser) => enterpriseUser.role === UserRole.ORG_ADMIN);
    const invalidUser = enterpriseUsers.find((enterpriseUser) => enterpriseUser.role === UserRole.ORG_USER && enterpriseUser.establishmentPermissions?.length === 0);

    setHasKnownError(adminUser === undefined || invalidUser !== undefined);
  };

  const updateEnterpriseUser = async (enterpriseUser: EnterpriseUser) => {
    return await enterpriseServices.updateEnterpriseUser(enterpriseUser.id, enterpriseUser.role, enterpriseUser.establishmentPermissions);
  }

  const onSaveClicked = useCallback(async () => {
    setIsSaving(true);

    const results = await Promise.allSettled(enterpriseUsers.map((enterpriseUser) => updateEnterpriseUser(enterpriseUser)));
    const error = results.find((result) => result.status === 'rejected' || (result.status === 'fulfilled' && !result.value.success));

    setHasUnknownError(error !== undefined);
    setIsSaving(false);
    setSaved(true);
  }, [enterpriseUsers]);

  const setEnterpriseUserRole = (enterpriseUser: EnterpriseUser, role: UserRole) => {
    const copy = [...enterpriseUsers];
    const index = copy.indexOf(enterpriseUser);

    copy[index].role = role;
    setEnterpriseUsers(copy);
    setSaved(false);
    checkForKnownErrors();
  };

  const setEnterpriseUserEstablishmentPermissions = (enterpriseUser: EnterpriseUser, establishmentPermissions: EstablishmentPermission[]) => {
    const copy = [...enterpriseUsers];
    const index = copy.indexOf(enterpriseUser);

    copy[index].establishmentPermissions = establishmentPermissions;
    setEnterpriseUsers(copy);
    setSaved(false);
    checkForKnownErrors();
  }

  return <>

    <div className={ styles.userForm }>

      {
        enterpriseUsers.map(enterpriseUser =>
          <EnterpriseUserOverviewListItem key={ enterpriseUser.email } enterpriseUser={ enterpriseUser } establishments={ props.establishments } setEnterpriseUserRole={ setEnterpriseUserRole } setEnterpriseUserEstablishmentPermissions={ setEnterpriseUserEstablishmentPermissions } onDeleteUserSuccess={ props.onDeleteUserSuccess } />
        )
      }

      <div className={ styles.userFormActions }>
        <div className={ styles.userFormActionsInner }>

          {
            isSaving &&
            <button
              className="btn-primary"
              onClick={ onSaveClicked }
              disabled
            >
              { t('mobiscan_users_save_cta_is_saving') }
            </button>
          }

          {
            !isSaving &&
            <button
              className="btn-primary"
              onClick={ onSaveClicked }
              disabled={ hasKnownError }
            >
              { t('mobiscan_users_save_cta') }
            </button>
          }

          {
            hasUnknownError && <ErrorBox errorMessage={ t('mobiscan_users_save_cta_unknown_error') } />
          }
        </div>
      </div>

      <div className={ styles.userFormActions }>
        <div className={ styles.userFormActionsInner }>
          {
            saved && <MessageBox type={ MessageBoxType.success } message={ t('mobiscan_users_save_cta_success') } />
          }
        </div>
      </div>
    </div>

  </>;
}

interface EnterpriseUserOverviewListItemProps {
  enterpriseUser: EnterpriseUser,
  establishments: Establishment[],
  setEnterpriseUserRole: (enterpriseUser: EnterpriseUser, role: UserRole) => void,
  setEnterpriseUserEstablishmentPermissions: (enterpriseUser: EnterpriseUser, establishmentPermissions: EstablishmentPermission[]) => void,
  onDeleteUserSuccess: () => void
}

function EnterpriseUserOverviewListItem(props: EnterpriseUserOverviewListItemProps) {
  return <article className={ styles.userItem }>
    <div className={ styles.userItemInner }>
      <header className={ styles.userItemHeader }>
        <EnterpriseUserInfo enterpriseUser={ props.enterpriseUser } onDeleteUserSuccess={ props.onDeleteUserSuccess } />
      </header>
      <div className={ styles.userItemContent }>
        <div className={ styles.userRoles }>
          <RoleEditor radioKey={ props.enterpriseUser.id } enterpriseUser={ props.enterpriseUser } role={ props.enterpriseUser.role } establishments={ props.establishments } establishmentPermissions={ props.enterpriseUser.establishmentPermissions ?? [] } setEnterpriseUserRole={ props.setEnterpriseUserRole } setEnterpriseUserEstablishmentPermissions={ props.setEnterpriseUserEstablishmentPermissions } />
        </div>
      </div>
    </div>
  </article>;
}

interface EnterpriseUserInfoProps {
  enterpriseUser: EnterpriseUser,
  onDeleteUserSuccess: () => void
}

function EnterpriseUserInfo(props: EnterpriseUserInfoProps) {
  const { t } = useTranslation();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const onDeleteClicked = () => {
    setConfirmModalVisible(true);
  };

  const deleteUser = useCallback(async () => {
    setConfirmModalVisible(false);
    setValidationResult(undefined);

    const result = await enterpriseServices.deleteEnterpriseUser(props.enterpriseUser.id);

    if (!result.success) {
      setValidationResult(result.validationResult);
      return;
    }

    props.onDeleteUserSuccess();
  }, [props]);

  const { fetchStatus } = useFetch<void>(deleteUser, false);

  return <>
    <ConfirmDeleteModal
      isOpen={ confirmModalVisible }
      onAccept={ deleteUser }
      onCancel={ () => setConfirmModalVisible(false) }
    />

    <div className={ styles.userBlock }>
      <h2 className={ styles.userName }>
        { props.enterpriseUser.firstName } { props.enterpriseUser.lastName }
      </h2>
      <div className={ styles.userFunction }>
        { props.enterpriseUser.jobTitle }
      </div>
      <div className={ styles.userMail }>
        <a href={ `mailto:${props.enterpriseUser.email}` }>{ props.enterpriseUser.email }</a>
      </div>
      <div className={ styles.userPhone }>
        <a href={ `tel:${props.enterpriseUser.phone}` }>{ props.enterpriseUser.phone }</a>
      </div>
      <button
        className={ styles.userRemoveBtn }
        onClick={ onDeleteClicked }
      >
        { t('mobiscan_users_remove_user_cta') }<span className="visually-hidden">: { props.enterpriseUser.firstName } { props.enterpriseUser.lastName }</span>
      </button>

      {
        validationResult
        && !validationResult.isValid
        && <ErrorBox errorMessage={ t(validationResult.messages[0].message) } />
      }

      {
        fetchStatus === FetchStatus.unknownError
        && <p>{ t('general_unknown_error') }</p>
      }
    </div>
  </>
    ;
}
