import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapCrossroadsResponse, mapCrossroadsValidationErrors } from "features/magda/data/mappers";
import { CrossroadsResponse } from "features/magda/data/models";

export interface MagdaApi {
  getEnterprise(cbeNumber: string): Promise<CrossroadsResponse>,
}

export class MagdaApiImpl implements MagdaApi {

  async getEnterprise(cbeNumber: string): Promise<CrossroadsResponse> {
    const url = AppConfig.API_URL + '/crossroads?number=' + cbeNumber;
    const response = await httpClient.unauthorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapCrossroadsResponse(json);
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapCrossroadsValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

}