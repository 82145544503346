import { useTranslation } from "react-i18next";
import styles from "./FullPageLoadingIndicator.module.scss";

export default function FullPageLoadingIndicator() {
  const { t } = useTranslation();

  return <div className={ styles.loader }>
    <div className={ styles.inner }>
      <div className={ styles.bike }>
        <img src="/images/icons/icon__active-transport.svg" alt="Loading" />
      </div>

      {/* <div className={ styles.car }>
        <svg width="164" height="162" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
          <path className={ styles.tires } d="M48.248 134.521h-8.713c-2.403 0-4.356-1.929-4.356-4.303v-16.96c0-.556.461-1.012 1.025-1.012h.195c.477 0 .892.324 1 .785l.414 1.766c1.04 4.461 5.002 7.574 9.636 7.574h4.13c.564 0 1.025.456 1.025 1.013v6.834c0 2.374-1.952 4.303-4.356 4.303Zm-11.019-15.805v11.502c0 1.256 1.035 2.278 2.306 2.278h8.713c1.27 0 2.306-1.022 2.306-2.278v-5.822h-3.106c-4.294 0-8.107-2.217-10.219-5.68ZM125.123 134.521h-8.713c-2.403 0-4.356-1.929-4.356-4.303v-6.834c0-.557.461-1.013 1.025-1.013h4.131c4.633 0 8.595-3.113 9.635-7.574l.415-1.766c.108-.461.523-.785.999-.785h.195c.564 0 1.025.456 1.025 1.012v16.96c0 2.374-1.952 4.303-4.356 4.303Zm-11.019-10.125v5.822c0 1.255 1.035 2.278 2.306 2.278h8.713c1.271 0 2.306-1.023 2.306-2.278v-11.502c-2.111 3.463-5.924 5.68-10.219 5.68h-3.106Z" fill="#14315C" />
          <path d="M117.209 124.395H47.447c-5.591 0-10.373-3.756-11.634-9.142l-2.65-11.346c-1.378-5.892 1.943-11.825 7.724-13.795.405-.137.825-.304 1.286-.502 7.288-3.164 23.052-5.209 40.154-5.209 17.102 0 32.867 2.045 40.154 5.21.456.197.876.364 1.286.5 5.781 1.975 9.102 7.903 7.724 13.796l-2.65 11.346c-1.261 5.386-6.042 9.142-11.634 9.142h.002ZM82.328 86.427c-16.84 0-32.278 1.979-39.33 5.037-.507.222-.979.405-1.44.562-4.787 1.635-7.534 6.545-6.396 11.426l2.65 11.345c1.04 4.46 5.002 7.573 9.635 7.573h69.761c4.633 0 8.595-3.113 9.635-7.573l2.65-11.345c1.138-4.88-1.609-9.791-6.396-11.426-.461-.157-.933-.34-1.44-.562-7.052-3.058-22.49-5.037-39.33-5.037h.001Z" fill="#14315C" />
          <path d="M50.297 111.74c-4.1 0-7.431-3.291-7.431-7.341 0-4.05 3.33-7.34 7.43-7.34s7.432 3.29 7.432 7.34-3.331 7.341-7.431 7.341Zm0-12.656c-2.968 0-5.381 2.384-5.381 5.315 0 2.932 2.413 5.316 5.38 5.316 2.968 0 5.382-2.384 5.382-5.316 0-2.931-2.414-5.315-5.381-5.315ZM114.36 111.74c-4.1 0-7.431-3.291-7.431-7.341 0-4.05 3.331-7.34 7.431-7.34s7.432 3.29 7.432 7.34-3.332 7.341-7.432 7.341Zm0-12.656c-2.967 0-5.381 2.384-5.381 5.315 0 2.932 2.414 5.316 5.381 5.316 2.968 0 5.382-2.384 5.382-5.316 0-2.931-2.414-5.315-5.382-5.315Z" fill="#14315C" />
          <path d="M45.296 90.553c-.251 0-.502-.091-.692-.268a1.008 1.008 0 0 1-.307-.972l3.562-15.254c1.711-7.326 8.22-12.439 15.825-12.439h37.284c7.611 0 14.12 5.113 15.826 12.439l3.561 15.254c.082.354-.035.729-.307.972-.272.248-.656.329-1.01.217-7.99-2.551-21.715-4.075-36.71-4.075-14.996 0-28.72 1.524-36.72 4.075-.102.03-.21.05-.312.05Zm37.034-6.151c14.185 0 27.296 1.357 35.639 3.665l-3.167-13.552c-1.492-6.4-7.18-10.87-13.827-10.87h-37.29c-6.647 0-12.336 4.47-13.833 10.87l-3.167 13.552c8.349-2.308 21.458-3.665 35.645-3.665Z" fill="#14315C" />
        </svg>
      </div> */}
      <div>{ t('label_loading') }</div>
    </div>
  </div>
}
