import { mapIso8601StringToDate } from "features/common/util/date";
import { MobiscanStatus } from "features/mobiscan/overview/domain/models";
import { PendingEnterpriseDTO, PendingEstablishmentDTO, PendingRegistrationDTO, PendingUserDTO, RegisteredEnterpriseDTO, ProvinceUserDTO, CreateProvinceUserResponse, DeleteProvinceUserResponse, ProvinceMobiscanDTO } from "features/province/data/models";
import { PendingEnterprise, PendingEstablishment, PendingRegistration, PendingUser, RegisteredEnterprise, ProvinceUser, CreateProvinceUserResult, DeleteProvinceUserResult, ProvinceMobiscan, DraftProvinceMobiscan, InProgressProvinceMobiscan, CompletedProvinceMobiscan } from "features/province/domain/models";
import { mapApiValidationErrorToValidationResult } from "features/validation/domain/mappers";


const createProvinceUserValidationCodes = [
  { code: 'EMAIL_NOT_ACCEPTABLE', labelKey: 'province_users_add_validation_email_exists' },
];

const deleteProvinceUserValidationCodes = [
  { code: 'USER_NOT_FOUND', labelKey: 'province_users_delete_validation_not_found' },
  { code: 'CANNOT_MODIFY_ONLY_PROVINCE', labelKey: 'province_users_delete_validation_only_admin' },
];


export function mapPendingRegistrationListFromDTOs(dtos: PendingRegistrationDTO[]): PendingRegistration[] {
  return dtos.map(mapPendingRegistrationFromDTO);
}

export function mapPendingRegistrationFromDTO(dto: PendingRegistrationDTO): PendingRegistration {
  return {
    date: mapIso8601StringToDate(dto.date),
    enterprise: mapPendingEnterpriseFromDTO(dto.enterprise),
    user: mapPendingUserFromDTO(dto.user),
    establishments: mapPendingEstablishmentListFromDTOs(dto.establishments),
  }
}

function mapPendingEnterpriseFromDTO(dto: PendingEnterpriseDTO): PendingEnterprise {
  return {
    name: dto.name,
    cbeNumber: dto.cbeNumber,
  }
}

function mapPendingUserFromDTO(dto: PendingUserDTO): PendingUser {
  return {
    firstName: dto.firstName,
    lastName: dto.lastName,
    phone: dto.phone,
    email: dto.email,
  }
}

function mapPendingEstablishmentListFromDTOs(dtos: PendingEstablishmentDTO[]): PendingEstablishment[] {
  return dtos.map(mapPendingEstablishmentFromDTO);
}

function mapPendingEstablishmentFromDTO(dto: PendingEstablishmentDTO): PendingEstablishment {
  return {
    name: dto.name,
    cbeNumber: dto.cbeNumber,
  }
}


export function mapRegisteredEnterpriseListFromDTOs(dtos: RegisteredEnterpriseDTO[]): RegisteredEnterprise[] {
  return dtos.map(mapRegisteredEnterpriseFromDTO);
}

function mapRegisteredEnterpriseFromDTO(dto: RegisteredEnterpriseDTO): RegisteredEnterprise {
  return {
    name: dto.name,
    cbeNumber: dto.cbeNumber,
    registrationDate: mapIso8601StringToDate(dto.registrationDate),
    mobiscans: dto.mobiscans,
  }
}


export function mapProvinceMobiscanListFromDTOs(dtos: ProvinceMobiscanDTO[]): ProvinceMobiscan[] {
  return dtos.map(mapProvinceMobiscanFromDTO);
}

function mapProvinceMobiscanFromDTO(dto: ProvinceMobiscanDTO): ProvinceMobiscan {
  if (dto.status === MobiscanStatus.DRAFT) {
    return mapDraftProvinceMobiscanFromDTO(dto);
  } else if (dto.status === MobiscanStatus.IN_PROGRESS) {
    return mapInProgressProvinceMobiscanFromDTO(dto);
  } else if (dto.status === MobiscanStatus.COMPLETE) {
    return mapCompletedProvinceMobiscanFromDTO(dto);
  } else {
    throw Error('invalid mobiscan status');
  }
}

function mapDraftProvinceMobiscanFromDTO(dto: ProvinceMobiscanDTO): DraftProvinceMobiscan {
  return {
    id: dto.id,
    enterprise: dto.enterprise,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDateTime: mapIso8601StringToDate(dto.startedDateTime),
    requestedBy: undefined,
    requestedDateTime: undefined,
    status: MobiscanStatus.DRAFT
  }
}

function mapInProgressProvinceMobiscanFromDTO(dto: ProvinceMobiscanDTO): InProgressProvinceMobiscan {
  return {
    id: dto.id,
    enterprise: dto.enterprise,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDateTime: mapIso8601StringToDate(dto.startedDateTime),
    requestedBy: dto.requestedBy!,
    requestedDateTime: mapIso8601StringToDate(dto.requestedDateTime!),
    status: MobiscanStatus.IN_PROGRESS
  }
}

function mapCompletedProvinceMobiscanFromDTO(dto: ProvinceMobiscanDTO): CompletedProvinceMobiscan {
  return {
    id: dto.id,
    enterprise: dto.enterprise,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDateTime: mapIso8601StringToDate(dto.startedDateTime),
    requestedBy: dto.requestedBy!,
    requestedDateTime: mapIso8601StringToDate(dto.requestedDateTime!),
    status: MobiscanStatus.COMPLETE
  }
}

export function mapProvinceUsersFromDTOs(dtos: ProvinceUserDTO[]): ProvinceUser[] {
  return dtos.map(mapProvinceUserFromDTO);
}

function mapProvinceUserFromDTO(dto: ProvinceUserDTO): ProvinceUser {
  return {
    id: dto.id,
    firstName: dto.firstName,
    lastName: dto.lastName,
    jobTitle: dto.jobTitle,
    phone: dto.phone,
    email: dto.email,
  }
}


export function mapCreateProvinceUserResponse(createProvinceUserResponse: CreateProvinceUserResponse): CreateProvinceUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(createProvinceUserValidationCodes, createProvinceUserResponse);
  return {
    success: createProvinceUserResponse.success,
    validationResult,
  };
}


export function mapDeleteProvinceUserResponse(deleteProvinceUserResponse: DeleteProvinceUserResponse): DeleteProvinceUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(deleteProvinceUserValidationCodes, deleteProvinceUserResponse);
  return {
    success: deleteProvinceUserResponse.success,
    validationResult,
  };
}