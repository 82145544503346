import { PendingRegistrationDTO, RegisteredEnterpriseDTO, ProvinceUserDTO, CreateProvinceUserResponse, DeleteProvinceUserResponse, ProvinceMobiscanDTO } from "features/province/data/models";
import { ProvinceApi } from "features/province/data/ProvinceApi";
import { ProvinceUser } from "features/province/domain/models";


export class ProvinceMockApi implements ProvinceApi {

  async fetchPendingRegistrations(): Promise<PendingRegistrationDTO[]> {
    await new Promise(r => setTimeout(r, 1000));

    return [
      {
        date: '2022-06-23',
        enterprise: {
          name: 'Bedrijf met een heel lange naam NV',
          cbeNumber: '0409832522',
        },
        user: {
          firstName: 'Jefke',
          lastName: 'Piefpoef',
          email: 'jefke.piefpoef@bedrijfmetlangenaam.be',
          phone: '+32 45 67 89',
        },
        establishments: [
          {
            name: 'Vestiging 1',
            cbeNumber: 'a123456789',
          },
          {
            name: 'Vestiging 2',
            cbeNumber: 'b123456789',
          },
          {
            name: 'Vestiging 3',
            cbeNumber: 'c123456789',
          },
        ],
      },
      {
        date: '2022-05-17',
        enterprise: {
          name: 'Een ander bedrijf',
          cbeNumber: '0459418625',
        },
        user: {
          firstName: 'Karel',
          lastName: 'De Groote',
          email: 'karel.de.groote@anderbedrijf.be',
          phone: '+32 45 67 89',
        },
        establishments: [
          {
            name: 'Vestiging 1',
            cbeNumber: 'a123456789',
          },
          {
            name: 'Vestiging 2',
            cbeNumber: 'b123456789',
          },
          {
            name: 'Vestiging 3',
            cbeNumber: 'c123456789',
          },
        ],
      },
    ]
  }

  async approveRegistration(cbeNumber: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async rejectRegistration(cbeNumber: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async fetchRegisteredEnterprises(): Promise<RegisteredEnterpriseDTO[]> {
    await new Promise(r => setTimeout(r, 1000));

    return [
      {
        name: 'Duo nv',
        cbeNumber: '0459418625',
        registrationDate: '2022-03-05',
        mobiscans: 1234,
      },
      {
        name: 'Nascom nv',
        cbeNumber: '0409832522',
        registrationDate: '2022-05-05',
        mobiscans: 4,
      },
      {
        name: 'Entity One BV',
        cbeNumber: '0207494678',
        registrationDate: '2022-06-07',
        mobiscans: 4,
      },
      {
        name: 'Corecrew BVBA',
        cbeNumber: '0423758455',
        registrationDate: '2021-04-08',
        mobiscans: 4,
      },
      {
        name: 'Mediasoft BVBA',
        cbeNumber: '0469480295',
        registrationDate: '2022-08-04',
        mobiscans: 2,
      },
    ]
  }

  async fetchProvinceMobiscans(): Promise<ProvinceMobiscanDTO[]> {
    await new Promise(r => setTimeout(r, 1000));

    return [
      {
        id: '1',
        enterprise: {
          name: 'Organisatie 1',
          cbeNumber: '000000',
          confirmed: true,
        },
        establishment: {
          id: 1,
          name: "Vestiging 1",
          address: {
            street: "Straat",
            houseNumber: "1",
            postalCode: "8000",
            city: "Brugge",
            country: 'BE',
          },
        },
        startedBy: {
          userId: '1',
          firstName: "Katrien",
          lastName: "Vancraeynest",
          email: "katrien.vancraeynest@west-vlaanderen.be",
        },
        startedDateTime: '2022-08-12T10:07:50.791',
        requestedBy: {
          userId: '1',
          firstName: "Katrien",
          lastName: "Vancraeynest",
          email: "katrien.vancraeynest@west-vlaanderen.be",
        },
        requestedDateTime: '2022-08-12T10:07:50.791',
        status: 'DRAFT'
      }
    ];
  }

  async fetchProvinceUsers(): Promise<ProvinceUserDTO[]> {
    await new Promise(r => setTimeout(r, 1000));

    return [
      {
        id: 1,
        firstName: 'Katrien',
        lastName: 'Vancraeynest',
        jobTitle: 'Provincie medewerker',
        phone: '050 00 00 00',
        email: 'Katrien.Vancraeynest@west-vlaanderen.be',
      },
      {
        id: 2,
        firstName: 'Sofie',
        lastName: 'Vanhooren',
        jobTitle: 'Provincie medewerker',
        phone: '050 00 00 00',
        email: 'Sofie.Vanhooren@west-vlaanderen.be',
      }
    ];
  }

  async createProvinceUser(provinceUser: ProvinceUser): Promise<CreateProvinceUserResponse> {
    await new Promise(r => setTimeout(r, 1000));

    if (provinceUser.firstName === '400') {
      throw new Error('mock error');
    }

    return {
      success: true,
      validationCodes: [],
    };
  }

  async deleteProvinceUser(id: number): Promise<DeleteProvinceUserResponse> {
    await new Promise(r => setTimeout(r, 1000));

    return {
      success: true,
      validationCodes: [],
    };
  }

}