import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { ModalButton } from "features/common/ui/ModalButton";
import { ImageTabTitle } from "features/common/ui/tabs/ImageTabTitle";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { PotentialTransportTab } from "features/mobiscan/report/ui/tabs/potential/PotentialTransportTab";
import { useTranslation } from "react-i18next";
import styles from "./PotentialTab.module.scss";
import tabSubStyles from "features/common/ui/tabs/Tab.subStyles.module.scss";
import { Employee, Mobiscan } from "features/mobiscan/report/domain/models";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { useCallback } from "react";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { TransportCategory, TransportMode } from "features/mobiscan/general/domain/models";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import { nmbsStationLayerKey } from "features/map/domain/models";

interface PotentialTabProps {
  mobiscan: Mobiscan
}

export function PotentialTab(props: PotentialTabProps) {
  const { t } = useTranslation();

  const fetchEmployees = useCallback(async () => {
    return await reportServices.getEmployees(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const fetchTransportModes = useCallback(async () => {
    return await mobiscanGeneralServices.getTransportModes();
  }, []);

  const employeeFetch = useFetch<Employee[]>(fetchEmployees);
  const transportModeFetch = useFetch<TransportMode[]>(fetchTransportModes);

  return <>
    <section className={ styles.intro }>
      <div className={ styles.introInner }>
        <div className={ styles.introTitleHolder }>
          <h2 className={ styles.pageTitle }>
            { t('report_tab_potential_title') }
          </h2>
          <span className={ styles.introTitleAdditional }>
            { t('report_tab_potential_title_employees', { employeeCount: props.mobiscan.employeeCount }) }
          </span>
        </div>
      </div>
    </section>
    {
      employeeFetch.fetchStatus === FetchStatus.success &&
      transportModeFetch.fetchStatus === FetchStatus.success &&
      <TabContent employees={ employeeFetch.fetchResult! } mobiscan={ props.mobiscan } transportModes={ transportModeFetch.fetchResult! } />
    }
    {
      (employeeFetch.fetchStatus === FetchStatus.unknownError ||
        transportModeFetch.fetchStatus === FetchStatus.unknownError) &&
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    }
    {
      (employeeFetch.fetchStatus === FetchStatus.pending ||
        transportModeFetch.fetchStatus === FetchStatus.pending) &&
      <LoadingIndicator />
    }
  </>;
}


interface TabContentProps {
  employees: Employee[],
  mobiscan: Mobiscan,
  transportModes: TransportMode[],
}

function TabContent(props: TabContentProps) {
  const { t } = useTranslation();

  return <>
    <section className={ styles.infoBoxHolder }>
      <MessageBox
        icon={ MessageBoxIcon.info }
        message={ <p dangerouslySetInnerHTML={ { __html: t('report_tab_potential_info') } }></p> }
        actions={
          <ModalButton targetUrl={ TreffikUrls.potentialCalculationInfoUrl }>
            { t('report_tab_potential_info_cta') }
          </ModalButton>
        } />
    </section>

    <section className={ styles.tabsBlock }>
      <TabContainer
        title={ t('report_tab_potential_nav_title') }
        titleWrapperClassName={ tabSubStyles.subTabsWrapper }
        panelWrapperClassName={ tabSubStyles.subPanelsWrapper }>
        <Tab>
          <TabTitle><ImageTabTitle imageSrc='/images/icons/potential-tabs/icon__potentialtabs__active-transport.svg' text={ t('report_tab_potential_tab_active_transport') } /></TabTitle>
          <TabPanel>
            <PotentialTransportTab
              key={ 'PotentialTransportTab-Active' }
              employees={ props.employees }
              mobiscan={ props.mobiscan }
              titleLabelKey="report_tab_potential_tab_active_transport_title"
              noDataLabelKey="report_tab_potential_tab_active_transport_title_no_data"
              showPotentialLabelKey="report_tab_potential_tab_active_transport_show_potential"
              transportCategory={ TransportCategory.ActiveTransport }
            />
          </TabPanel>
        </Tab>
        <Tab>
          <TabTitle><ImageTabTitle imageSrc='/images/icons/potential-tabs/icon__potentialtabs__collective-transport.svg' text={ t('report_tab_potential_tab_collective_transport') } /></TabTitle>
          <TabPanel>
            <PotentialTransportTab
              key={ 'PotentialTransportTab-Collective' }
              employees={ props.employees }
              mobiscan={ props.mobiscan }
              titleLabelKey="report_tab_potential_tab_collective_transport_title"
              noDataLabelKey="report_tab_potential_tab_collective_transport_title_no_data"
              showPotentialLabelKey="report_tab_potential_tab_collective_transport_show_potential"
              initialSelectedLayers={ [nmbsStationLayerKey] }
              transportCategory={ TransportCategory.CollectiveTransport }
            />
          </TabPanel>
        </Tab>
        <Tab>
          <TabTitle><ImageTabTitle imageSrc='/images/icons/potential-tabs/icon__potentialtabs__carpool.svg' text={ t('report_tab_potential_tab_carpool') } /></TabTitle>
          <TabPanel>
            <PotentialTransportTab
              key={ 'PotentialTransportTab-Carpool' }
              employees={ props.employees }
              mobiscan={ props.mobiscan }
              titleLabelKey="report_tab_potential_tab_carpool_title"
              noDataLabelKey="report_tab_potential_tab_carpool_title_no_data"
              showPotentialLabelKey="report_tab_potential_tab_carpool_show_potential"
              transportCategory={ TransportCategory.Carpool }
            />
          </TabPanel>
        </Tab>
      </TabContainer>
    </section>
  </>;
}
