export interface Activity {
  label: string,
}

export interface Schedule {
  id: string,
}

export interface TransportMode {
  id: string,
  sortIndex: number,
  category: TransportCategory,
}

export enum TransportCategory {
  ActiveTransport,
  CollectiveTransport,
  Carpool,
  Other,
}

export interface Situation {
  id: string,
}
