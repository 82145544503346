
import { MobiscanApi } from "features/mobiscan/overview/data/MobiscanApi";
import { MobiscanOverviewItemDTO } from "features/mobiscan/overview/data/models";

export class MobiscanMockApi implements MobiscanApi {

  async fetchMobiscans(): Promise<MobiscanOverviewItemDTO[]> {
    await new Promise(r => setTimeout(r, 1000));

    return [
      {
        id: 'mob_1',
        requestedDateTime: '2022-07-07T15:26:30.183',
        modificationDateTime: '2022-07-07T15:26:30.183',
        startedDateTime: '2022-07-07T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'DRAFT',
      },
      {
        id: 'mob_2',
        requestedDateTime: '2022-07-07T15:26:30.183',
        modificationDateTime: '2022-07-07T15:26:30.183',
        startedDateTime: '2022-07-07T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'IN_PROGRESS',
      },
      {
        id: 'mob_3',
        requestedDateTime: '2022-07-08T15:26:30.183',
        modificationDateTime: '2022-07-08T15:26:30.183',
        startedDateTime: '2022-07-07T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'COMPLETED',
        potential: {
          durableActualPercentage: 10,
          durablePotentialPercentage: 54,
        }
      },
      {
        id: 'mob_4',
        requestedDateTime: '2022-07-09T15:26:30.183',
        modificationDateTime: '2022-07-09T15:26:30.183',
        startedDateTime: '2022-07-09T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'DRAFT',
      },
      {
        id: 'mob_5',
        requestedDateTime: '2022-07-04T15:26:30.183',
        modificationDateTime: '2022-04-07T15:26:30.183',
        startedDateTime: '2022-07-04T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'IN_PROGRESS',
      },
      {
        id: 'mob_6',
        requestedDateTime: '2022-07-10T15:26:30.183',
        modificationDateTime: '2022-07-10T15:26:30.183',
        startedDateTime: '2022-07-10T15:26:30.183',
        establishment: {
          id: 1,
          cbeNumber: 'est_1',
          name: 'De paddenhoek',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Hoofdgemeente',
            country: 'BE',
          },
          activity: 'Openbare instelling',
        },
        startedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        requestedBy: {
          userId: "3",
          firstName: "Karel",
          lastName: "De Groote",
          email: "karel.de.groote@duo-brugge.be",
        },
        status: 'COMPLETED',
        potential: {
          durableActualPercentage: 10,
          durablePotentialPercentage: 54,
        }
      },
    ];
  }

}
