import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as provinceServices from "features/province/domain/provinceServices";
import * as dateUtil from "features/common/util/date";
import { ProvinceMobiscan } from "features/province/domain/models";
import styles from "./ProvinceMobiscanOverview.module.scss";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { Retry } from "features/common/ui/Retry";
import { useNavigate } from "react-router-dom";
import { MobiscanStatus } from "features/mobiscan/overview/domain/models";
import * as routes from "routes";
import ProvinceMobiscanFilter from "features/province/ui/mobiscan/ProvinceMobiscanFilter";
import { getUser } from "features/auth/domain/authOperations";
import { useAppDispatch } from "redux/store";
import { DropdownOption } from "features/common/ui/forms/Dropdown";
import { MessageBox } from "features/common/ui/boxes/MessageBox";
import { useFetch } from "features/common/hooks/UseFetch";

export function ProvinceMobiscanOverview() {
  const { t } = useTranslation();
  const [provinceMobiscans, setProvinceMobiscans] = useState<ProvinceMobiscan[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const provinceMobiscanStatusAllOption = {
    value: 'ALL',
    text: t('province_mobiscans_table_status_all')
  };

  const provinceMobiscanStatusOptions = [
    provinceMobiscanStatusAllOption,
    ...[
      {
        value: MobiscanStatus.DRAFT,
        text: t('province_mobiscans_table_status_draft')
      },
      {
        value: MobiscanStatus.IN_PROGRESS,
        text: t('province_mobiscans_table_status_in_progress')
      },
      {
        value: MobiscanStatus.COMPLETE,
        text: t('province_mobiscans_table_status_completed')
      },
    ]
  ];

  const [provinceMobiscanSearchTextFilter, setProvinceMobiscanSearchTextFilter] = useState<string>('');
  const [provinceMobiscanStatustFilter, setProvinceMobiscanStatustFilter] = useState<DropdownOption>(provinceMobiscanStatusAllOption);

  const fetchProvinceMobiscans = useCallback(async () => {
    setErrorMessage(null);
    try {
      const provinceMobiscans = await provinceServices.getProvinceMobiscans();
      setProvinceMobiscans(provinceMobiscans);
    } catch (e: any) {
      console.error(e);
      setErrorMessage(e.toString());
    }
  }, []);

  useFetch(fetchProvinceMobiscans, true);

  const filteredProvinceMobiscans = provinceMobiscans
    ? provinceServices.filterProvinceMobiscans(provinceMobiscans, provinceMobiscanSearchTextFilter, provinceMobiscanStatustFilter)
    : null;

  return <div>

    <section className={ styles.intro }>
      <div className={ styles.introInner }>
        <h2 className={ styles.pageTitle }>
          { t('province_mobiscans_title') }
        </h2>
      </div>
    </section>

    <section className={ styles.overview }>
      <div className={ styles.overviewInner }>
        {
          (() => {
            if (errorMessage != null) {
              return <Retry onConfirmed={ fetchProvinceMobiscans } title={ t('general_unknown_error') } />
            } else if (filteredProvinceMobiscans == null) {
              return <LoadingIndicator />
            } else {
              return <>
                <div className={ styles.searchBlock }>
                  <ProvinceMobiscanFilter
                    provinceMobiscanStatusOptions={ provinceMobiscanStatusOptions }
                    updatedProvinceMobiscanSearch={ provinceMobiscanSearchTextFilter }
                    onUpdateProvinceMobiscanSearch={ setProvinceMobiscanSearchTextFilter }
                    selectedProvinceMobiscanStatus={ provinceMobiscanStatustFilter }
                    onSelectProvinceMobiscanStatus={ setProvinceMobiscanStatustFilter }
                  />
                </div>

                <div className={ styles.resultsBlock }>
                  <div className="data-table-wrapper">

                    {
                      filteredProvinceMobiscans.length === 0 &&
                      <MessageBox message={ t('province_mobiscans_empty') } />
                    }

                    {
                      filteredProvinceMobiscans.length > 0 &&
                      <table className="data-table">
                        <thead>
                          <tr>
                            <th>{ t('province_mobiscans_table_organisation') }</th>
                            <th>{ t('province_mobiscans_table_establishment') }</th>
                            <th>{ t('province_mobiscans_table_started_by') }</th>
                            <th>{ t('province_mobiscans_table_started_on') }</th>
                            <th>{ t('province_mobiscans_table_request_by') }</th>
                            <th>{ t('province_mobiscans_table_request_on') }</th>
                            <th>{ t('province_mobiscans_table_status') }</th>
                            <th>
                              <span className="visually-hidden">
                                { t('province_registrations_table_request_actions') }
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filteredProvinceMobiscans.map((provinceMobiscan) => {
                              switch (provinceMobiscan.status) {
                                case MobiscanStatus.DRAFT:
                                  return <ProvinceMobiscanOverviewDraftItem key={ provinceMobiscan.id } provinceMobiscan={ provinceMobiscan } />;
                                case MobiscanStatus.IN_PROGRESS:
                                  return <ProvinceMobiscanOverviewInProgressItem key={ provinceMobiscan.id } provinceMobiscan={ provinceMobiscan } />;
                                case MobiscanStatus.COMPLETE:
                                  return <ProvinceMobiscanOverviewCompletedItem key={ provinceMobiscan.id } provinceMobiscan={ provinceMobiscan } />;
                              }

                              throw Error('invalid mobiscan status');
                            })
                          }
                        </tbody>
                      </table>
                    }

                  </div>
                </div>
              </>
            }
          })()
        }
      </div>
    </section>
  </div>;
}

interface ProvinceMobiscanOverviewItemProps {
  provinceMobiscan: ProvinceMobiscan
}

function ProvinceMobiscanOverviewDraftItem(props: ProvinceMobiscanOverviewItemProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToDraft = async () => {
    setIsLoading(true);
    provinceServices.loginAsEnterprise(props.provinceMobiscan.enterprise.cbeNumber);
    await dispatch(getUser());
    navigate(routes.generateEditDraftRoute(props.provinceMobiscan.id));
  };

  return <tr>
    <td>{ props.provinceMobiscan.enterprise.name }</td>
    <td>{ props.provinceMobiscan.establishment.name }</td>
    <td>{ props.provinceMobiscan.startedBy.firstName } { props.provinceMobiscan.startedBy.lastName }</td>
    <td>{ dateUtil.formatDateToSlashString(props.provinceMobiscan.startedDateTime) }</td>
    <td>-</td>
    <td>-</td>
    <td>{ t('province_mobiscans_table_status_draft') }</td>
    <td data-align="right">
      {
        isLoading
          ? <button className="data-table-action" disabled>{ t('province_mobiscans_table_action_draft') }</button>
          : <button className="data-table-action" onClick={ navigateToDraft }>
            { t('province_mobiscans_table_action_draft') }
            <span className="visually-hidden">- { props.provinceMobiscan.enterprise.name }, { props.provinceMobiscan.establishment.name }</span>
          </button>
      }

    </td>
  </tr>;
}

function ProvinceMobiscanOverviewInProgressItem(props: ProvinceMobiscanOverviewItemProps) {
  const { t } = useTranslation();

  return <tr>
    <td>{ props.provinceMobiscan.enterprise.name }</td>
    <td>{ props.provinceMobiscan.establishment.name }</td>
    <td>{ props.provinceMobiscan.startedBy.firstName } { props.provinceMobiscan.startedBy.lastName }</td>
    <td>{ dateUtil.formatDateToSlashString(props.provinceMobiscan.startedDateTime) }</td>
    <td>{ props.provinceMobiscan.requestedBy!.firstName } { props.provinceMobiscan.requestedBy!.lastName }</td>
    <td>{ dateUtil.formatDateToSlashString(props.provinceMobiscan.requestedDateTime!) }</td>
    <td>{ t('province_mobiscans_table_status_in_progress') }</td>
    <td data-align="right">
      <button className="data-table-action" disabled>
        { t('province_mobiscans_table_action_in_progress') }
      </button>
    </td>
  </tr>;
}

function ProvinceMobiscanOverviewCompletedItem(props: ProvinceMobiscanOverviewItemProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToReport = async () => {
    setIsLoading(true);
    provinceServices.loginAsEnterprise(props.provinceMobiscan.enterprise.cbeNumber);
    await dispatch(getUser());
    navigate(routes.generateReportRoute(props.provinceMobiscan.id));
  };

  return <tr>
    <td>{ props.provinceMobiscan.enterprise.name }</td>
    <td>{ props.provinceMobiscan.establishment.name }</td>
    <td>{ props.provinceMobiscan.startedBy.firstName } { props.provinceMobiscan.startedBy.lastName }</td>
    <td>{ dateUtil.formatDateToSlashString(props.provinceMobiscan.startedDateTime) }</td>
    <td>{ props.provinceMobiscan.requestedBy!.firstName } { props.provinceMobiscan.requestedBy!.lastName }</td>
    <td>{ dateUtil.formatDateToSlashString(props.provinceMobiscan.requestedDateTime!) }</td>
    <td>{ t('province_mobiscans_table_status_completed') }</td>
    <td data-align="right">
      {
        isLoading
          ? <button className="data-table-action" disabled>{ t('province_mobiscans_table_action_completed') }</button>
          : <button className="data-table-action" onClick={ navigateToReport }>
            { t('province_mobiscans_table_action_completed') }
            <span className="visually-hidden">- { props.provinceMobiscan.enterprise.name }, { props.provinceMobiscan.establishment.name }</span>
          </button>
      }

    </td>
  </tr>;
}
