import { EstablishmentPermissionDTO, LoginResponse, ResetPasswordResponse, UserResponse } from "features/auth/data/models";
import { EstablishmentPermission, Permission, LoginResult, ResetPasswordResult, User, UserRole } from "features/auth/domain/models";

export function mapLoginResponse(loginResponse: LoginResponse): LoginResult {
  return loginResponse;
}

export function mapResetPasswordResponse(resetPasswordResponse: ResetPasswordResponse): ResetPasswordResult {
  return resetPasswordResponse;
}

export function mapUser(user: UserResponse): User {
  return {
    id: user.id,
    enterprise: user.enterprise,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    jobTitle: user.jobTitle,
    phone: user.phone,
    role: UserRole[user.role as keyof typeof UserRole],
    establishmentPermissions: mapEstablishmentPermissions(user.establishmentPermissions),
    isMasquerading: user.isMasquerading
  };
}

export function mapEstablishmentPermissions(dtos?: EstablishmentPermissionDTO[]): EstablishmentPermission[] | undefined {
  if (dtos) {
    return dtos.map((dto) => {
      return {
        establishment: dto.establishment,
        permission: Permission[dto.permission as keyof typeof Permission],
      }
    });
  }
}