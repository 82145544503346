import React from 'react';
import styles from "./Dropdown.module.scss";
import { ReactComponent as IconArrow } from 'assets/inline-svg/forms/form__select-arrow.svg';

interface DropdownProps {
  identifier: string; //To use as html#id
  placeholderText?: string;
  options: DropdownOption[];
  selectedOption?: DropdownOption;
  label: string;
  onChange: (value?: string) => void;
  labelHidden?: boolean;
  disabled?: boolean;
  required?: boolean;
  allowSelectPlaceholder?: boolean;
  hideAsterisk?: boolean;
}

export interface DropdownOption {
  value: string;
  text: string;
}

export default function Dropdown(props: DropdownProps) {
  const placeholderValue = 'placeholder_value';

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (placeholderValue === e.target.value) {
      props.onChange(undefined);
    } else {
      props.onChange(e.target.value);
    }
  };

  const selectValue = props.selectedOption ? props.selectedOption.value : placeholderValue;
  const labelClasses = props.labelHidden ? 'visually-hidden' : '';

  return (
    <div className={ styles.formItem }>
      <label htmlFor={ props.identifier } className={ labelClasses }>
        { props.label }
        { props.required && !props.hideAsterisk && (
          <span> *</span>
        ) }
      </label>
      <div className={ styles.selectWrapper }>
        <select name={ props.identifier } id={ props.identifier } onChange={ onChange } value={ selectValue } disabled={ props.disabled } required={ props.required }>
          {
            props.placeholderText
            && (selectValue === placeholderValue || props.allowSelectPlaceholder === true)
            && (<option value={ placeholderValue } disabled={ props.allowSelectPlaceholder === false }>{ props.placeholderText }</option>)
          }
          {
            props.options.map((option) => {
              return <option key={ option.value } value={ option.value }>{ option.text }</option>;
            })
          }
        </select>
        <div className={ styles.selectArrow }>
          <IconArrow />
        </div>
      </div>
    </div>
  );
}
