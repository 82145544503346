import { CoordinatesDTO } from "features/map/data/models";
import { Coordinates } from "features/map/domain/models";
import { Isoline } from "features/mobiscan/report/domain/models";

export function mapCoordinatesFromDto(dto: CoordinatesDTO): Coordinates {
  return dto;
}

export function mapGeoJsonFromIsoline(isoline: Isoline): GeoJSON.GeoJsonObject {
  return {
    type: "FeatureCollection",
    features: isoline.polygons.map((polygon, i) => {
      return {
        type: "Feature",
        id: i,
        geometry: {
          type: "Polygon",
          coordinates: [
            polygon.outer.map(outer => [outer.longitude, outer.latitude])
          ]
        },
      }
    }
    )
  } as GeoJSON.GeoJsonObject;
}