import { ActivityDTO, ScheduleDTO, SituationDTO, TransportModeDTO } from "features/mobiscan/general/data/models";
import { Activity, Schedule, Situation, TransportMode } from "features/mobiscan/general/domain/models";
import { getTransportCategory, getTransportModeSortIndex } from "features/mobiscan/general/domain/transportModeUtil";

export function mapActivityFromDto(dto: ActivityDTO): Activity {
  return {
    label: dto,
  };
}

export function mapScheduleFromDto(dto: ScheduleDTO): Schedule {
  return {
    id: dto,
  };
}

export function mapTransportModeFromDto(dto: TransportModeDTO): TransportMode {
  return {
    id: dto,
    sortIndex: getTransportModeSortIndex(dto),
    category: getTransportCategory(dto),
  };
}

export function mapSituationFromDto(dto: SituationDTO): Situation {
  return {
    id: dto,
  };
}
