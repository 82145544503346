import { mapGeoJsonFromIsoline } from "features/map/domain/mappers";
import { Coordinates } from "features/map/domain/models";
import { getClosestLongitudeLeft, getClosestLongitudeRight } from "features/map/ui/CoordinatesUtil";
import { TextMarker } from "features/map/ui/TextMarker";
import { Isoline, IsolineCollection } from "features/mobiscan/report/domain/models";
import { useTranslation } from "react-i18next";
import { GeoJSON, LayerGroup } from "react-leaflet";

interface IsolineLayerProps {
  isolineCollection: IsolineCollection,
  center: Coordinates,
}

export function IsolineLayer(props: IsolineLayerProps) {
  const { t } = useTranslation();

  return <LayerGroup>
    {
      props.isolineCollection.isolines.map((isoline) => {
        const data = mapGeoJsonFromIsoline(isoline);
        const style = { fill: false, weight: 2, color: '#14315C', dashArray: '5' };
        const markerText = t('map_isoline_' + isoline.name.toLowerCase());
        const textMarkerCoordinates = calculateIsolineTextMarkerCoordinates(isoline, props.center);

        return <GeoJSON key={ `isoline-${isoline.name}` } data={ data } style={ style }>
          {
            textMarkerCoordinates &&
            <TextMarker
              coordinates={ textMarkerCoordinates }
              text={ markerText }
            />
          }
        </GeoJSON>;
      })
    }
  </LayerGroup >;
}


function calculateIsolineTextMarkerCoordinates(isoline: Isoline, mapCenter: Coordinates) {
  const coordinatesBelowCenter = isoline.polygons[0].outer.filter((coordinate) => coordinate.latitude < mapCenter.latitude);
  const closestLeft = getClosestLongitudeLeft(mapCenter, coordinatesBelowCenter);
  const closestRight = getClosestLongitudeRight(mapCenter, coordinatesBelowCenter);

  if (!closestLeft || !closestRight) {
    return undefined;
  }

  const averageLatitude = (closestLeft.latitude + closestRight.latitude) / 2;

  return {
    latitude: averageLatitude,
    longitude: mapCenter.longitude,
  };
}
