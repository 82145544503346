import { Checkbox } from "features/common/ui/forms/CheckboxSmall";
import { MapLayerCollectionKey } from "features/map/domain/models";
import { useTranslation } from "react-i18next";
import styles from "./MapLayerCollectionSelector.module.scss";


interface MapLayerCollectionSelectorProps {
  availableLayerCollections: MapLayerCollectionKey[],
  selectedLayerCollection: MapLayerCollectionKey[];
  onSelectedLayerCollectionUpdated: (selectedLayerCollections: MapLayerCollectionKey[]) => void,
}

export default function MapLayerCollectionSelector(props: MapLayerCollectionSelectorProps) {
  const { t } = useTranslation();

  const handleSelectedLayersChange = (changedLayerCollection: MapLayerCollectionKey, newValue: boolean) => {
    const collection = props.availableLayerCollections.find(layerCollection => layerCollection === changedLayerCollection)!;

    let updatedList = [...props.selectedLayerCollection];
    if (newValue) {
      updatedList = [...props.selectedLayerCollection, collection];
    } else {
      updatedList.splice(props.selectedLayerCollection.indexOf(collection), 1);
    }

    props.onSelectedLayerCollectionUpdated(updatedList);
  }

  return <fieldset className={ styles.fieldset }>
    <legend className={ styles.fieldsetLegend }>
      <span className={ styles.fieldsetLabel }>
        { t('map_layer_filter_layer') }
      </span>
    </legend>
    <div className={ styles.fieldsetContent }>
      {
        props.availableLayerCollections.map((layer) => {
          return <div key={ `collection-${layer}` } className={ styles.formItem }>
            <Checkbox
              identifier={ `collection-${layer}` }
              checked={ props.selectedLayerCollection.find(selected => selected === layer) != null }
              onChange={ (value) => handleSelectedLayersChange(layer, value) }>
              { t('map_layer_' + layer) }
            </Checkbox>
          </div>
        })
      }
    </div>
  </fieldset>
}


