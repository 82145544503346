import { PathOptions, StyleFunction } from "leaflet";
import { GeoJSON } from "react-leaflet";
import wvlTownBordersGeoJson from "assets/geojson/wvl_town_borders.json";
import { renderToString } from "react-dom/server";

interface TownBorderLayerProps {
  townBorderStyle?: PathOptions | StyleFunction<any>,
  getPopup?: (clickedCityName: string) => JSX.Element | undefined,
}

export function TownBorderLayer(props: TownBorderLayerProps) {
  const style = props.townBorderStyle ?? { fill: false, weight: 1, };

  const onEachFeature = (feature: any, layer: any) => {
    if (props.getPopup != null) {
      const cityName = feature.properties.NAME;
      const popupElement = props.getPopup(cityName);
      if (popupElement) {
        layer.bindPopup(renderToString(popupElement));
      }
    }
  };

  return <>
    <GeoJSON
      data={ wvlTownBordersGeoJson as GeoJSON.GeoJsonObject }
      style={ style }
      onEachFeature={ onEachFeature }
    />;
  </>;
}