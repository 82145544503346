import { Checkbox } from "features/common/ui/forms/CheckboxSmall";
import { Establishment } from "features/enterprise/domain/models";
import { MapLayerCollectionKey } from "features/map/domain/models";
import MapEmployeeFilter from "features/map/ui/MapEmployeeFilter";
import MapLayerCollectionSelector from "features/map/ui/MapLayerCollectionSelector";
import { Schedule, TransportMode } from "features/mobiscan/general/domain/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MapFilterLegend.module.scss";

interface MapFilterLegendProps {
  establishment: Establishment,
  transportModes: TransportMode[],
  schedules: Schedule[],
  showPotentialCheckbox: boolean,
  availableLayerCollections: MapLayerCollectionKey[],
  showPotentialLabelKey?: string,
  initialSelectedLayers?: MapLayerCollectionKey[],
  onEmployeeFiltersUpdated: (selectedTransportModes: TransportMode[], showEmployeesWithoutPotential: boolean, selectedSchedule?: Schedule) => void,
  onSelectedLayersUpdated: (selectedLayers: MapLayerCollectionKey[]) => void,
}

export function MapFilterLegend(props: MapFilterLegendProps) {

  const [showEmployeesWithoutPotential, setShowEmployeesWithoutPotential] = useState<boolean>(props.showPotentialCheckbox ?? true);
  const [selectedTransportModes, setSelectedTransportModes] = useState<TransportMode[]>(props.transportModes);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | undefined>(undefined);
  const [selectedLayers, setSelectedLayers] = useState<MapLayerCollectionKey[]>(props.initialSelectedLayers ?? []);

  const { t } = useTranslation();

  const handleUpdateFilters = (transportModes: TransportMode[], workSchedule?: Schedule) => {
    setSelectedTransportModes(transportModes);
    setSelectedSchedule(workSchedule);
    props.onEmployeeFiltersUpdated(transportModes, showEmployeesWithoutPotential, workSchedule);
  };

  const handlePotentialCheckboxValueChange = (value: boolean) => {
    setShowEmployeesWithoutPotential(value);
    props.onEmployeeFiltersUpdated(selectedTransportModes, value, selectedSchedule);
  };

  const onSelectedLayersUpdated = (selectedLayerCollections: MapLayerCollectionKey[]) => {
    setSelectedLayers(selectedLayerCollections);
    props.onSelectedLayersUpdated(selectedLayerCollections);
  };


  return <div className={ styles.MapFilterLegend }>
    <div className={ styles.MapFilterLegendSection }>
      <div className={ styles.MapFilterLegendMarker }></div>
      <h3 className={ styles.MapFilterLegendTitle }>
        <span className="visually-hidden">{ t('chart_potential_filter_label') }:</span>
        { props.establishment.name }
      </h3>
    </div>
    <form className={ styles.MapFilterLegendForm }>
      <div className={ styles.MapFilterLegendSection }>
        <MapEmployeeFilter onUpdateFilters={ handleUpdateFilters } transportModes={ props.transportModes } schedules={ props.schedules } />
      </div>
      <div className={ styles.MapFilterLegendSection }>
        <MapLayerCollectionSelector
          availableLayerCollections={ props.availableLayerCollections }
          onSelectedLayerCollectionUpdated={ onSelectedLayersUpdated }
          selectedLayerCollection={ selectedLayers }
        />
      </div>
      {
        props.showPotentialCheckbox &&
        <div className={ styles.MapFilterLegendSection }>
          <Checkbox
            identifier="show-potential"
            checked={ showEmployeesWithoutPotential }
            onChange={ handlePotentialCheckboxValueChange }
          >
            <div className={ styles.potentialLabel }>
              <span className={ styles.potentialIcon }></span>
              { props.showPotentialLabelKey
                ? t(props.showPotentialLabelKey)
                : t('chart_potential_filter_shift') }
            </div>
          </Checkbox>
        </div>
      }
    </form>
  </div>;
}
