import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import { PersonnelParseError, PersonnelParseErrorCorrection } from "features/mobiscan/draft/domain/models";
import { useTranslation } from "react-i18next";
import styles from "./CorrectionTable.module.scss";

interface CorrectionTableProps {
  title: string,
  dropdownOptions: DropdownOption[],
  corrections: PersonnelParseErrorCorrection[],
  onCorrectionValueChanged: (correction: PersonnelParseErrorCorrection) => void,
}

export function CorrectionTable(props: CorrectionTableProps) {
  const { t } = useTranslation();


  return <div className={ styles.timetableContainer } role="region" aria-labelledby="correctionTableCaption" tabIndex={ 0 }>
    <table className={ styles.timetable }>
      <caption id="correctionTableCaption" className="visually-hidden">
        { props.title }
      </caption>
      <thead>
        <tr>
          <th>{ t('edit_personnel_tab_timetable_address') }</th>
          <th>{ t('edit_personnel_tab_timetable_incorrect_input') }</th>
          <th>{ t('edit_personnel_tab_timetable_correction') }</th>
        </tr>
      </thead>
      <tbody>
        {
          props.corrections.map((correction, i) => {
            return <TableRow
              key={ `parse-error-${i}` }
              identifier={ `parse-error-${i}` }
              dropdownOptions={ props.dropdownOptions }
              onOptionChanged={ (newOption) => {
                correction.correctionValue = newOption.value;
                props.onCorrectionValueChanged(correction);
              } }
              parseError={ correction.parseError }
              selectedValue={ correction.correctionValue }
            />;
          })
        }
      </tbody>
    </table>
  </div>;
}


interface TableRowProps {
  identifier: string,
  dropdownOptions: DropdownOption[],
  onOptionChanged: (newOption: DropdownOption) => void,
  parseError: PersonnelParseError,
  selectedValue: string,
}

function TableRow(props: TableRowProps) {

  const selectedOption = props.dropdownOptions.find(option => option.value === props.selectedValue);

  return <tr>
    <td>
      { formatAddressLine1(props.parseError.address) + formatAddressLine2(props.parseError.address) }
    </td>
    <td>
      { props.parseError.wrongValue }
    </td>
    <td>
      <Dropdown
        identifier={ props.identifier }
        options={ props.dropdownOptions }
        selectedOption={ selectedOption }
        label=""
        labelHidden={ true }
        onChange={ (value) => props.onOptionChanged(props.dropdownOptions.find(option => option.value === value)!) }
      />
    </td>
  </tr>;
}
