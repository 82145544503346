import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { useTranslation } from "react-i18next";
import tabMiniStyles from "features/common/ui/tabs/Tab.miniStyles.module.scss";
import styles from "./EmployeeResidenceTab.module.scss";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { useCallback } from "react";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { Employee, EmployeesOfCity, IsolineCollection, Mobiscan } from "features/mobiscan/report/domain/models";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { EmployeeCommuneMap } from "features/mobiscan/report/ui/tabs/maps/EmployeeCommuneMap";
import { EmployeeResidenceMap } from "features/mobiscan/report/ui/tabs/maps/EmployeeResidenceMap";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { Schedule, TransportMode } from "features/mobiscan/general/domain/models";


interface EmployeeResidenceTabProps {
  mobiscan: Mobiscan,
}

export function EmployeeResidenceTab(props: EmployeeResidenceTabProps) {
  const { t } = useTranslation();

  const fetchSchedules = useCallback(async () => {
    return await mobiscanGeneralServices.getSchedules();
  }, []);

  const fetchTransportModes = useCallback(async () => {
    return await mobiscanGeneralServices.getTransportModes();
  }, []);

  const transportFetch = useFetch<TransportMode[]>(fetchTransportModes);
  const schedulesFetch = useFetch<Schedule[]>(fetchSchedules);

  if (schedulesFetch.fetchStatus === FetchStatus.unknownError || transportFetch.fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (schedulesFetch.fetchStatus === FetchStatus.success && transportFetch.fetchStatus === FetchStatus.success) {

    const transportModes = transportFetch.fetchResult!;
    const schedules = schedulesFetch.fetchResult!;

    return <article className={ styles.container }>
      <header className={ styles.header }>
        <div className={ styles.headerInner }>
          <h2 className={ styles.headerTitle }>
            { t('report_tab_mobility_tab_employee_residence_title') }
          </h2>
        </div>
      </header>

      <div className={ styles.content }>
        <div className={ styles.contentInner }>
          <TabContainer
            titleWrapperClassName={ tabMiniStyles.miniTabsWrapper }
            panelWrapperClassName={ tabMiniStyles.miniPanelsWrapper }>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_employee_residence_tab_commune') } /></TabTitle>
              <TabPanel><CommuneMap mobiscan={ props.mobiscan } schedules={ schedules } transportModes={ transportModes } /></TabPanel>
            </Tab>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_employee_residence_tab_residence') } /></TabTitle>
              <TabPanel><ResidenceMap mobiscan={ props.mobiscan } schedules={ schedules } transportModes={ transportModes } /></TabPanel>
            </Tab>
            <Tab>
              <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_employee_residence_tab_table') } /></TabTitle>
              <TabPanel><Table mobiscan={ props.mobiscan } /></TabPanel>
            </Tab>
          </TabContainer>
        </div>
      </div>
    </article>;
  }
  return <LoadingIndicator />
}


interface CommuneMapProps {
  mobiscan: Mobiscan,
  schedules: Schedule[],
  transportModes: TransportMode[],
}

function CommuneMap(props: CommuneMapProps) {
  const { t } = useTranslation();

  const fetchEmployeesByCity = useCallback(async () => {
    return await reportServices.getEmployeesByCity(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<EmployeesOfCity[]>(fetchEmployeesByCity);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const employeesByCity = fetchResult!;
    return <>
      <div className={ styles.communeMap }>
        <EmployeeCommuneMap
          employeesByCity={ employeesByCity }
          transportModes={ props.transportModes }
          schedules={ props.schedules }
          establishment={ props.mobiscan.establishment }
          establishmentCoordinates={ props.mobiscan.establishmentCoordinates }
        />
      </div>
    </>;
  }
  return <LoadingIndicator />
}


interface ResidenceMapProps {
  mobiscan: Mobiscan,
  schedules: Schedule[],
  transportModes: TransportMode[],
}

function ResidenceMap(props: ResidenceMapProps) {
  const { t } = useTranslation();

  const fetchEmployees = useCallback(async () => {
    return await reportServices.getEmployees(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const fetchIsolines = useCallback(async () => {
    return await reportServices.getIsolines(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const employeeFetch = useFetch<Employee[]>(fetchEmployees);
  const isolinesFetch = useFetch<IsolineCollection[]>(fetchIsolines);

  if (employeeFetch.fetchStatus === FetchStatus.unknownError || isolinesFetch.fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (employeeFetch.fetchStatus === FetchStatus.success && isolinesFetch.fetchStatus === FetchStatus.success) {
    const employees = employeeFetch.fetchResult!;
    const isolines = isolinesFetch.fetchResult!;

    return <>
      <div className={ styles.residenceMap }>
        <EmployeeResidenceMap
          employees={ employees }
          transportModes={ props.transportModes }
          schedules={ props.schedules }
          establishment={ props.mobiscan.establishment }
          establishmentCoordinates={ props.mobiscan.establishmentCoordinates }
          isolineCollections={ isolines }
        />
      </div>
    </>;
  }
  return <LoadingIndicator />
}


interface TableProps {
  mobiscan: Mobiscan,
}

function Table(props: TableProps) {
  const { t } = useTranslation();

  const fetchEmployeesByCity = useCallback(async () => {
    return await reportServices.getEmployeesByCity(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<EmployeesOfCity[]>(fetchEmployeesByCity);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const employeesByCity = fetchResult!.sort((a, b) => b.count - a.count);

    return <div className="data-table-wrapper">
      <table className="data-table white-zebra">
        <thead>
          <tr>
            <th>{ t('report_tab_mobility_tab_employee_residence_tab_table_commune') }</th>
            <th>{ t('report_tab_mobility_tab_employee_residence_tab_table_employee_count') }</th>
            <th>{ t('report_tab_mobility_tab_employee_residence_tab_table_percent') }</th>
          </tr>
        </thead>
        <tbody>
          {
            employeesByCity.map((city) => {
              return <TableRow key={ `row-${city.cityName}` } communeName={ city.cityName } employeeCount={ city.count } percent={ city.percentage } />;
            })
          }
        </tbody>
      </table>
    </div>;
  }
  return <LoadingIndicator />;
}


interface TableRowProps {
  communeName: string,
  employeeCount: number,
  percent: number,
}

function TableRow(props: TableRowProps) {
  return <tr>
    <td>{ props.communeName }</td>
    <td>{ props.employeeCount }</td>
    <td>{ props.percent }%</td>
  </tr>;
}
