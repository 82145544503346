import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { Establishment } from "features/enterprise/domain/models";
import { mapQuestionnairePageDTOsFromJson, } from "features/mobiscan/draft/data/mappers";
import { PersonnelCorrectionDto, QuestionnairePageDataDTO, ActivityDTO } from "features/mobiscan/draft/data/models";
import { mapDraftFromJson, mapGetPersonnelFinishedResponse, mapGetPersonnelPendingResponse, mapGetPersonnelVacantResponse, mapUploadPersonnelSuccess, mapUploadPersonnelUnprocessable, mapUploadPersonnelValidationErrors } from "features/mobiscan/draft/data/mappers";
import { DraftDTO, PersonnelResponse, UploadPersonnelResponse } from "features/mobiscan/draft/data/models";
import { UpdateQuestionnaireFieldsDto } from "features/mobiscan/draft/domain/models";

export interface MobiscanDraftApi {
  createDraft(establishment: Establishment): Promise<DraftDTO>;
  submitDraft(draftId: string): Promise<void>;
  deleteDraft(draftId: string): Promise<void>;

  uploadPersonnelFile(draftId: string, file: File): Promise<UploadPersonnelResponse>;
  getPersonnel(draftId: string): Promise<PersonnelResponse>;
  getDraft(draftId: string): Promise<DraftDTO>;

  saveSituation(draftId: string, situation: string): Promise<void>;
  saveTimetableCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void>;
  saveTransportationCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void>;
  updateBenchmarkSector(draftId: string, activityDto: ActivityDTO): Promise<void>;

  fetchQuestionnairePage(draftId: string, page: string): Promise<QuestionnairePageDataDTO>;
  updateQuestionnaireAnswers(draftId: string, page: string, section: string, requestDto: UpdateQuestionnaireFieldsDto): Promise<void>;
}

export class MobiscanDraftApiImpl implements MobiscanDraftApi {

  async createDraft(establishment: Establishment): Promise<DraftDTO> {
    const url = AppConfig.API_URL + '/mobiscan/drafts/';
    const payload = {
      establishmentId: establishment.id,
    };

    const options = { method: 'POST', body: JSON.stringify(payload), };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapDraftFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async submitDraft(draftId: string): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/submit`;

    const options = { method: 'PATCH' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async deleteDraft(draftId: string): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}`;

    const options = { method: 'DELETE' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async uploadPersonnelFile(draftId: string, file: File): Promise<UploadPersonnelResponse> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/personnel`;

    const formData = new FormData();
    formData.append('personnelFile', file);

    const options = { method: 'POST', body: formData };
    const response = await httpClient.authorizedFetch(url, options, {}); // form-data headers are calculated automatically 

    if (response.status === 202) {
      return mapUploadPersonnelSuccess();
    }

    if (response.status === 400) {
      const json = await response.json();
      return mapUploadPersonnelValidationErrors(json);
    }

    if (response.status === 422) {
      return mapUploadPersonnelUnprocessable();
    }

    throw new ApiException(response.status);
  }

  async getPersonnel(draftId: string): Promise<PersonnelResponse> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/personnel`;
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapGetPersonnelFinishedResponse(json);
    }
    if (response.status === 202) {
      return mapGetPersonnelPendingResponse();
    }
    if (response.status === 204) {
      return mapGetPersonnelVacantResponse();
    }

    throw new ApiException(response.status);
  }

  async getDraft(draftId: string): Promise<DraftDTO> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}`;
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapDraftFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async saveSituation(draftId: string, situation: string): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/situation`;
    const payload = {
      situation,
    };
    const options = { method: 'PATCH', body: JSON.stringify(payload), };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async saveTimetableCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/personnel/schedule`;
    const payload = JSON.stringify(corrections);
    const options = { method: 'PUT', body: payload, };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async saveTransportationCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/personnel/transportation`;
    const payload = JSON.stringify(corrections);
    const options = { method: 'PUT', body: payload, };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async updateBenchmarkSector(draftId: string, activityDto: ActivityDTO): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/benchmark`;

    const payload = JSON.stringify(activityDto);
    const options = { method: 'PATCH', body: payload };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

  async fetchQuestionnairePage(draftId: string, page: string): Promise<QuestionnairePageDataDTO> {
    const url = AppConfig.API_URL + '/mobiscan/drafts/' + draftId + '/' + page;
    const response = await httpClient.authorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return mapQuestionnairePageDTOsFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async updateQuestionnaireAnswers(draftId: string, page: string, section: string, requestDto: UpdateQuestionnaireFieldsDto): Promise<void> {
    const url = AppConfig.API_URL + `/mobiscan/drafts/${draftId}/${page}/${section}`;

    const payload = JSON.stringify(requestDto);
    const options = { method: 'PUT', body: payload };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      return;
    }

    throw new ApiException(response.status);
  }

}
