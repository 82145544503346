import { Coordinates } from "features/map/domain/models";
import { Marker, Popup, } from "react-leaflet";
import { createLeafletMapIcon } from "features/map/ui/MapIconHelper";
import { EstablishmentMapIcon } from "features/map/ui/EstablishmentMapIcon";
import { Establishment } from "features/enterprise/domain/models";

interface EstablishmentLayerProps {
  establishment: Establishment,
  establishmentLocation: Coordinates,
}

export function EstablishmentLayer(props: EstablishmentLayerProps) {
  const icon = createLeafletMapIcon(<EstablishmentMapIcon label={ props.establishment.name } />);

  return <>
    <Marker key="establishment_location" position={ [props.establishmentLocation.latitude, props.establishmentLocation.longitude] } icon={ icon } >
      <Popup closeButton={ false } >
        <strong>{ props.establishment.name }</strong>
      </Popup>
    </Marker>;
  </>;
}
