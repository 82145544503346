interface TreffikUrlCollection {
  contactUrl: string,
  privacyUrl: string,
  termsConditionsUrl: string,
  roleInfoUrl: string,
  draftInfoUrl: string,
  draftPersonnelInfoUrl: string, // wordt gebruikt in QuestionnairePage.tsx:getTreffikInfoUrl
  draftPolicyInfoUrl: string, // wordt gebruikt in QuestionnairePage.tsx:getTreffikInfoUrl
  draftInfrastructureInfoUrl: string, // wordt gebruikt in QuestionnairePage.tsx:getTreffikInfoUrl
  reportInfoUrl: string,
  potentialCalculationInfoUrl: string,
  measuresHelpInfoUrl: string,
}

export const TreffikUrls: TreffikUrlCollection = {
  contactUrl: process.env.REACT_APP_TREFFIK_CONTACT!,
  privacyUrl: process.env.REACT_APP_TREFFIK_PRIVACY!,
  termsConditionsUrl: process.env.REACT_APP_TREFFIK_TERMS_CONDITIONS!,
  roleInfoUrl: process.env.REACT_APP_TREFFIK_ROLE_INFO!,
  draftInfoUrl: process.env.REACT_APP_TREFFIK_DRAFT_INFO!,
  draftPersonnelInfoUrl: process.env.REACT_APP_TREFFIK_DRAFT_PERSONNEL!,
  draftPolicyInfoUrl: process.env.REACT_APP_TREFFIK_DRAFT_POLICY!,
  draftInfrastructureInfoUrl: process.env.REACT_APP_TREFFIK_DRAFT_INFRASTRUCTURE!,
  reportInfoUrl: process.env.REACT_APP_TREFFIK_REPORT_INFO!,
  potentialCalculationInfoUrl: process.env.REACT_APP_TREFFIK_POTENTIAL_CALCULATION!,
  measuresHelpInfoUrl: process.env.REACT_APP_TREFFIK_MEASURES_HELP!,
}
