import { mapEnterpriseFromJson } from "features/auth/data/mappers";
import { AddCustomEstablishmentResponse, AddressDTO, CreateEnterpriseUserResponse, DeleteEnterpriseUserResponse, EnterpriseUserDTO, EstablishmentDTO, EstablishmentsResponse, UpdateEnterpriseUserResponse } from "features/enterprise/data/models";

export function mapEstablishmentsResponse(json: any): EstablishmentsResponse {
  return {
    cbeNumber: json.cbeNumber,
    establishments: (json.establishments as any[]).map(mapEstablishmentFromJson),
  };
}

export function mapEstablishmentFromJson(json: any): EstablishmentDTO {
  return {
    id: json.id,
    cbeNumber: json.cbeNumber,
    name: json.name,
    activity: json.activity,
    address: mapAddressFromJson(json.address),
  };
}

export function mapAddressFromJson(json: any): AddressDTO {
  return {
    street: json.street,
    houseNumber: json.houseNumber,
    box: json.box,
    postalCode: json.postalCode,
    city: json.city,
    country: json.country,
  };
}

export function mapEnterpriseUserListFromJsonList(jsonList: any[]): EnterpriseUserDTO[] {
  return jsonList.map((json) => mapEnterpriseUserFromJson(json));
}

function mapEnterpriseUserFromJson(json: any): EnterpriseUserDTO {
  return {
    id: json.id,
    enterprise: mapEnterpriseFromJson(json.enterprise),
    firstName: json.firstName,
    lastName: json.lastName,
    jobTitle: json.jobTitle,
    phone: json.phone,
    email: json.email,
    role: json.role,
    establishmentPermissions: json.establishmentPermissions,
  };
}

export function mapAddCustomEstablishmentSuccess(): AddCustomEstablishmentResponse {
  return {
    success: true,
    validationCodes: [],
  };
}

export function mapAddCustomEstablishmentValidationErrorsFromJson(json: any): AddCustomEstablishmentResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}


export function mapCreateEnterpriseUserSuccessResponse(): CreateEnterpriseUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapCreateEnterpriseUserValidationErrors(json: any): CreateEnterpriseUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}


export function mapDeleteEnterpriseUserSuccessResponse(): DeleteEnterpriseUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapDeleteEnterpriseUserValidationErrors(json: any): DeleteEnterpriseUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}

export function mapUpdateEnterpriseUserSuccessResponse(): UpdateEnterpriseUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapUpdateEnterpriseUserValidationErrors(json: any): UpdateEnterpriseUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}