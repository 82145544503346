import { Activity } from "features/mobiscan/general/domain/models";
import * as helpers from "features/validation/domain/helpers";
import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import { TFunction } from "i18next";

export function validatePersonnelSituation(t: TFunction, situation?: string): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(situation)) {
    validationMessages.push(helpers.validationMessageFromString(t('edit_personnel_tab_template_situation_validation_missing_situation')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}

export function validateSubmitForm(t: TFunction, legalChecked: boolean, selectedActivity?: Activity): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (!helpers.isTrue(legalChecked)) {
    validationMessages.push(helpers.validationMessageFromString(t('edit_draft_form_validation_legal')));
  }

  if (helpers.isNull(selectedActivity)) {
    validationMessages.push(helpers.validationMessageFromString(t('edit_draft_form_validation_activity')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}