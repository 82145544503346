import styles from './MessageBox.module.scss';
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';

export enum MessageBoxIcon {
  info,
}

export enum MessageBoxType {
  success = 'success',
}

export enum MessageBoxDisplayType {
  noBorder = 'no-border',
  minimal = 'minimal',
}

interface MessageBoxProps {
  icon?: MessageBoxIcon,
  title?: string,
  message: string | React.ReactNode,
  actions?: React.ReactNode,
  type?: MessageBoxType,
  displayType?: MessageBoxDisplayType,
}

export function MessageBox(props: MessageBoxProps) {
  let iconImage;
  if (props.icon === MessageBoxIcon.info) {
    iconImage = <IconInfo />;
  }

  return <div className={ styles.box } data-type={ props.type } data-displaytype={ props.displayType }>

    {
      iconImage &&
      <div className={ styles.iconHolder }>
        { iconImage }
      </div>
    }

    <div className={ styles.contentHolder }>
      <div className={ styles.content }>
        { props.title && <div className={ styles.title }>{ props.title }</div> }
        <div className={ styles.message }>{ props.message }</div>
      </div>

      { props.actions &&
        <div className={ styles.contentActions }>
          { props.actions }
        </div>
      }
    </div>

  </div>;
}
