import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { Retry } from "features/common/ui/Retry";
import { formatCbeNumber } from "features/common/util/enterprise";
import { formatDateToSlashString } from "features/common/util/date";
import { PendingRegistration } from "features/province/domain/models";
import * as provinceServices from "features/province/domain/provinceServices";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as routes from "routes";
import styles from "./RegistrationOverview.module.scss";
import { MessageBox } from "features/common/ui/boxes/MessageBox";

export function RegistrationOverview() {
  const { t } = useTranslation();
  const [registrations, setRegistrations] = useState<PendingRegistration[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchPendingRegistrations();
  }, []);

  const fetchPendingRegistrations = async () => {
    setErrorMessage(null);
    try {
      const registrations = await provinceServices.getPendingRegistrations();
      setRegistrations(registrations);
    } catch (e: any) {
      console.log(e);
      setErrorMessage(e.toString());
    }
  }

  return <div>

    <section className={ styles.intro }>
      <div className={ styles.introInner }>
        <h2 className={ styles.pageTitle }>
          { t('province_registrations_title') }
        </h2>
      </div>
    </section>

    <section className={ styles.overview }>
      <div className={ styles.overviewInner }>
        {
          (() => {
            if (errorMessage != null) {
              return <Retry onConfirmed={ fetchPendingRegistrations } title={ t('general_unknown_error') } />
            } else if (registrations == null) {
              return <LoadingIndicator />
            } else {
              return <div className={ styles.resultsView }>
                <div className="data-table-wrapper">

                  {
                    registrations.length === 0 &&
                    <MessageBox message={ t('province_registrations_empty') } />
                  }

                  {
                    registrations.length > 0 &&
                    <table className="data-table">
                      <thead>
                        <tr>
                          <th>{ t('province_registrations_table_organisation') }</th>
                          <th>{ t('province_registrations_table_cbe_number') }</th>
                          <th>{ t('province_registrations_table_request_by') }</th>
                          <th>{ t('province_registrations_table_request_on') }</th>
                          <th>
                            <span className="visually-hidden">
                              { t('province_registrations_table_request_actions') }
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          registrations.map((registration) => {
                            return <tr key={ registration.enterprise.cbeNumber }>
                              <td>{ registration.enterprise.name }</td>
                              <td>{ formatCbeNumber(registration.enterprise.cbeNumber) }</td>
                              <td>{ registration.user.firstName + ' ' + registration.user.lastName }</td>
                              <td>{ formatDateToSlashString(registration.date) }</td>
                              <td data-align="right">
                                <Link to={ routes.generateProvinceRegistrationDetail(registration.enterprise.cbeNumber) } className="data-table-action">
                                  { t('province_registrations_table_view_registration') }<span className="visually-hidden">: { registration.enterprise.name }</span>
                                </Link>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  }

                </div>
              </div>
            }
          })()
        }
      </div>
    </section>
  </div>
}
