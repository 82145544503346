import { Layout } from "features/common/ui/layout/Layout";
import { useTranslation } from "react-i18next";
import { FormEvent, useState } from "react";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { useAppDispatch } from "redux/store";
import { validatePasswordForm } from "features/user/domain/validation";
import { unwrapResult } from "@reduxjs/toolkit";
import { updatePassword } from "features/user/domain/userOperations";
import { PasswordTextField } from "features/common/ui/forms/PasswordTextField";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { MessageBox, MessageBoxType } from "features/common/ui/boxes/MessageBox";
import styles from "./PasswordPage.module.scss";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";

interface PasswordPageProps {
  homeRoute: string,
  userRoute: string
}

export function PasswordPage(props: PasswordPageProps) {
  const { t } = useTranslation();

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('user_password_header_back') } targetUrl={ props.homeRoute } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('user_password_header_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <div className="content-wrapper">
      <div className="inner-wrapper">
        <BackButton label={ t('user_password_back') } targetUrl={ props.userRoute } displayType={ BackButtonType.text } />
        <h2 className={ styles.title }>{ t('user_password_title') }</h2>
        <PasswordForm />
      </div>
    </div>
  </Layout>;
}

function PasswordForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const sendUpdateRequest = async () => {
    // Client side validate
    const validationResult = validatePasswordForm(password, newPassword, t);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const request = { password, newPassword, };
    const updateAction = await dispatch(updatePassword(request));
    const result = unwrapResult(updateAction);
    return { validationResult: result.validationResult };
  }

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<void>(sendUpdateRequest, false);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    executeFetch();
  }

  return <form className="form form--reset-pasword" onSubmit={ onSubmit } noValidate={ true }>
    <div className="form-row">
      <PasswordTextField identifier='password' value={ password } onChange={ setPassword } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_password_form_password') } />
    </div>
    <div className="form-row">
      <PasswordTextField identifier='new-password' value={ newPassword } onChange={ setNewPassword } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_password_form_new_password') } />
    </div>
    <div className="form-row">
      <p>{ t('password_validation_requirements') }</p>
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('user_password_unknown_error') } />
      }

      {
        validationResult
        && !validationResult.isValid
        && <ValidationErrorBox mainMessage={ t('user_password_validation_title') } messages={ validationResult.messages } />
      }

      {
        fetchStatus === FetchStatus.success
        && <MessageBox type={ MessageBoxType.success } message={ t('user_password_update_success') } />
      }

    </div>

    {
      fetchStatus === FetchStatus.pending
        ? <LoadingIndicator />
        : <div className="form-actions"><div className="form-action form-action--large"><button className="form-button" type='submit'>{ t('user_password_form_submit') }</button></div></div>
    }
  </form>
}
