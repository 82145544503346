import { TabTitleChildProps } from "features/common/ui/tabs/TabContainer";

interface BasicTabTitleProps extends TabTitleChildProps {
  text: string,
}

export function BasicTabTitle(props: BasicTabTitleProps) {
  return <>
    <span>{ props.text }</span>
  </>;
}
