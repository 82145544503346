import { GeoJSON } from "react-leaflet";
import wvlBorderGeoJson from "assets/geojson/wvl_border.json";

export function ProvinceBorderLayer() {
  const style = { fill: false, color: '#90332e' };

  return <>
    <GeoJSON
      data={ wvlBorderGeoJson as GeoJSON.GeoJsonObject }
      style={ style }
    />;
  </>;
}
