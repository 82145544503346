import { TextField } from 'features/common/ui/forms/TextField';

interface EmailTextFieldProps {
  identifier: string; //To use as html#id
  value: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

export function EmailTextField(props: EmailTextFieldProps) {
  return (
    <TextField type='email' identifier={ props.identifier } value={ props.value } onChange={ props.onChange } label={ props.label } disabled={ props.disabled } required={ props.required } />
  );
}
