import { BasicTabTitle } from "features/common/ui/tabs/BasicTabTitle";
import { Tab, TabContainer, TabPanel, TabTitle } from "features/common/ui/tabs/TabContainer";
import { CommutingTab } from "features/mobiscan/report/ui/tabs/mobility/CommutingTab";
import { EmployeeResidenceTab } from "features/mobiscan/report/ui/tabs/mobility/EmployeeResidenceTab";
import { ModalSplitTab } from "features/mobiscan/report/ui/tabs/mobility/ModalSplitTab";
import { TimetableTab } from "features/mobiscan/report/ui/tabs/mobility/TimetableTab";
import { useTranslation } from "react-i18next";
import styles from "./MobilityProfileTab.module.scss";
import tabSubStyles from "features/common/ui/tabs/Tab.subStyles.module.scss";
import { Mobiscan } from "features/mobiscan/report/domain/models";


interface MobilityProfileTabProps {
  mobiscan: Mobiscan,
}

export function MobilityProfileTab(props: MobilityProfileTabProps) {
  const { t } = useTranslation();
  return <>
    <section className={ styles.intro }>
      <div className={ styles.introInner }>
        <div className={ styles.introTitleHolder }>
          <h2 className={ styles.pageTitle }>
            { t('report_tab_mobility_profile_title') }
          </h2>
          <span className={ styles.introTitleAdditional }>
            { t('report_tab_mobility_profile_title_employees', { employeeCount: props.mobiscan.employeeCount }) }
          </span>
        </div>
      </div>
    </section>

    <section className={ styles.tabsBlock }>
      <TabContainer
        title={ t('report_tab_mobility_profile_nav_title') }
        titleWrapperClassName={ tabSubStyles.subTabsWrapper }
        panelWrapperClassName={ tabSubStyles.subPanelsWrapper }>
        <Tab>
          <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_modal_split') } /></TabTitle>
          <TabPanel><ModalSplitTab mobiscan={ props.mobiscan } /></TabPanel>
        </Tab>
        <Tab>
          <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_employee_residence') } /></TabTitle>
          <TabPanel><EmployeeResidenceTab mobiscan={ props.mobiscan } /></TabPanel>
        </Tab>
        <Tab>
          <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_commuting') } /></TabTitle>
          <TabPanel><CommutingTab mobiscan={ props.mobiscan } /></TabPanel>
        </Tab>
        <Tab>
          <TabTitle><BasicTabTitle text={ t('report_tab_mobility_tab_timetable') } /></TabTitle>
          <TabPanel><TimetableTab mobiscan={ props.mobiscan } /></TabPanel>
        </Tab>
      </TabContainer>
    </section>
  </>;
}
