import { TransportMode } from "features/mobiscan/general/domain/models";
import { CommuteDistances, ModalSplitValue } from "features/mobiscan/report/domain/models";
import { HorizontalStackedBarChart, StackedBarSegment } from "features/mobiscan/report/ui/tabs/charts/HorizontalStackedBarChart";
import { useTranslation } from "react-i18next";
import styles from "./CommuteDistanceChart.module.scss";

interface CommuteDistanceChartProps {
  commuteDistances: CommuteDistances,
  transportModes: TransportMode[],
}

export function CommuteDistanceChart(props: CommuteDistanceChartProps) {
  const { t } = useTranslation();

  // Legend
  const legendLabels = props.transportModes
    .sort((a, b) => a.sortIndex - b.sortIndex)
    .map((transportMode) => {
      const transportId = transportMode.id.toLowerCase();

      return {
        label: t('transport_mode_' + transportId),
        dataType: transportId,
        className: styles.figureLegendItem,
      };
    });

  // Bar segments
  const mapModalSplitToSegments = (modalSplit: ModalSplitValue[]): StackedBarSegment[] => {
    return modalSplit
      .sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex)
      .map((values) => {
        const transportName = values.transportMode.id.toLowerCase();
        return {
          count: values.count,
          percentage: values.percentage,
          tooltipTitle: t('transport_mode_' + transportName),
          tooltipContent: t('report_tab_mobility_tab_commuting_tab_distance_chart_tooltip', { value: values.percentage }),
          dataType: transportName,
        };
      });
  }

  // Bars
  const bars = props.commuteDistances.distanceSegments.map((segment) => {
    const label = segment.kmMax != null
      ? `${segment.kmMin}-${segment.kmMax} km`
      : `>${segment.kmMin}km`;

    return {
      label: label,
      count: segment.count,
      segments: mapModalSplitToSegments(segment.modalSplit),
      className: styles.figureBarItem,
    };
  });

  return <>
    <HorizontalStackedBarChart
      legendLabels={ legendLabels }
      bars={ bars }
      a11yLabelGroupName={ t('report_tab_mobility_tab_commuting_tab_distance_chart_a11y_group_name') }
      a11yLabelCount={ t('report_tab_mobility_tab_commuting_tab_distance_chart_a11y_count') }
      a11yLabelComposition={ t('report_tab_mobility_tab_commuting_tab_distance_chart_a11y_composition') }
    />
  </>;
}
