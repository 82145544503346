// https://makeitfly.atlassian.net/wiki/spaces/PRO/pages/2581626944/Mobiliteitsgroepen

import { TransportCategory, TransportMode } from "features/mobiscan/general/domain/models";


// TODO: Probably better add the sortIndex field to the model returned by the API
const sortedTransportModes = [
  'PEDESTRIAN',
  'BIKE',
  'E_BIKE',
  'PEDELEC',
  'BUS_TRAM',
  'TRAIN',
  'COLLECTIVE_COMPANY',
  'CARPOOL',
  'PERSONAL_CAR',
  'COMPANY_CAR',
  'MOTORBIKE',
  'MOPED',
  'E_STEP',
];

// TODO: Probably better if this comes from API
const categories = [
  {
    category: TransportCategory.ActiveTransport,
    transportIds: ['PEDESTRIAN', 'BIKE', 'E_BIKE', 'PEDELEC'],
  },
  {
    category: TransportCategory.CollectiveTransport,
    transportIds: ['BUS_TRAM', 'TRAIN', 'COLLECTIVE_COMPANY'],
  },
  {
    category: TransportCategory.Carpool,
    transportIds: ['CARPOOL'],
  },
  {
    category: TransportCategory.Other,
    transportIds: ['PERSONAL_CAR', 'COMPANY_CAR', 'MOTORBIKE', 'MOPED', 'E_STEP', 'GROUP_CAR'],
  },
];

export function getTransportModeSortIndex(transportModeId: string) {
  return sortedTransportModes.findIndex((id) => id === transportModeId);
}

export function getTransportCategory(transportModeId: string): TransportCategory {
  const collection = categories.find((category) => category.transportIds.includes(transportModeId));

  if (!collection) {
    console.trace();
    throw new Error('Unknown transport id: ' + JSON.stringify(transportModeId));
  }

  return collection.category;
}

export function getTransportModesByCategory(category: TransportCategory): TransportMode[] {
  const collection = categories.find((coll) => coll.category === category)!;
  return collection.transportIds.map((id) => ({
    id,
    sortIndex: getTransportModeSortIndex(id),
    category,
  }));
}
