export class ApiException extends Error {
  statusCode: number;

  constructor(statusCode: number, message?: string) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'ApiException';

    this.statusCode = statusCode;
  }
}
