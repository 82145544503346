import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { PendingRegistration } from "features/province/domain/models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as provinceServices from "features/province/domain/provinceServices";
import styles from "./RegistrationActions.module.scss";

interface RegistrationActionsProps {
  registration: PendingRegistration
}

export default function RegistrationActions(props: RegistrationActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleResultFailed = (message: string, error: any) => {
    console.log('Error:', error);
    setIsLoading(false);
    setErrorMessage(message);
  }

  const handleGreatSuccess = (message: string) => {
    setIsLoading(false);
    setSuccessMessage(message);
  }

  const handleApproveClicked = async () => {
    setIsLoading(true);
    try {
      await provinceServices.approveRegistration(props.registration);
      handleGreatSuccess(t('province_registration_detail_approval_succeeded'));
    } catch (e) {
      handleResultFailed(t('general_unknown_error'), e);
    }
  }

  const handleRejectClicked = async () => {
    const confirmed = window.confirm(t('province_registration_detail_reject_confirm'));
    if (confirmed) {
      setIsLoading(true);
      try {
        await provinceServices.rejectRegistration(props.registration);
        handleGreatSuccess(t('province_registration_detail_rejection_succeeded'));
      } catch (e) {
        handleResultFailed(t('general_unknown_error'), e);
      }
    }
  }

  return <div className={ styles.buttonWrapper }>
    {
      isLoading
        ? <LoadingIndicator />
        : successMessage == null
          ? <>
            <button className={ styles.approve } onClick={ (e) => handleApproveClicked() }>{ t('province_registration_detail_approve') }</button>
            <button className={ styles.reject } onClick={ (e) => handleRejectClicked() }>{ t('province_registration_detail_reject') }</button>
          </>
          : <p>{ successMessage }</p>
    }
    {
      errorMessage && <p>{ errorMessage }</p>
    }
  </div>
}

