import { FetchStatus } from "features/common/hooks/UseFetch";
import { ValidationResult } from "features/validation/domain/models";
import { useCallback, useEffect, useState } from "react";


interface ValidatedFetchResult<T> {
  result?: T,
  validationResult?: ValidationResult,
}

export function useValidatedFetch<T>(fetchFunction: () => Promise<ValidatedFetchResult<T>>, immediate = true) {
  const [fetchStatus, setStatus] = useState<FetchStatus>(FetchStatus.idle);
  const [fetchResult, setResult] = useState<T | undefined>(undefined);
  const [validationResult, setValidationResult] = useState<ValidationResult | undefined>(undefined);
  const [fetchError, setError] = useState<unknown | null>(null);

  const resetFetch = useCallback(() => {
    setStatus(FetchStatus.idle);
    setResult(undefined);
    setValidationResult(undefined);
    setError(false);
  }, []);

  const executeFetch = useCallback(async () => {
    resetFetch();
    setStatus(FetchStatus.pending);
    try {
      const response = await fetchFunction();

      setValidationResult(response.validationResult);
      setResult(response.result);

      setStatus(FetchStatus.success);

      if (response.validationResult && !response.validationResult.isValid) {
        setStatus(FetchStatus.idle);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setStatus(FetchStatus.unknownError);
    }
  }, [fetchFunction, resetFetch]);


  useEffect(() => {
    if (immediate) {
      executeFetch();
    }
  }, [executeFetch, immediate]);

  return { executeFetch, resetFetch, fetchStatus, fetchResult, fetchError, validationResult };
};