import { forgotPassword } from "features/auth/domain/authServices";
import { EmailTextField } from "features/common/ui/forms/EmailTextField";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { MessageBox, MessageBoxType } from "features/common/ui/boxes/MessageBox";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import styles from "./ForgotPasswordPage.module.scss";
import * as routes from "routes";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";

export default function ForgotPasswordPage() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const fetchForgotPassword = useCallback(async () => {
    await forgotPassword(email);
  }, [email]);

  const { executeFetch, fetchStatus } = useFetch<void>(fetchForgotPassword, false);


  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('forgot_password_page_back') } targetUrl={ routes.LOGIN } displayType={ BackButtonType.box } />
        <h1 className={ styles.pageTitle }>
          { t('forgot_password_page_title') }
        </h1>
      </PageIntroItem>
    </PageIntro>

    <div className="content-wrapper">
      <div className="inner-wrapper">
        {
          fetchStatus === FetchStatus.success && <ForgotPasswordSuccess email={ email } />
        }
        {
          fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('forgot_password_page_unknown_error') } />
        }
        {
          (fetchStatus === FetchStatus.idle || fetchStatus === FetchStatus.pending)
          && <ForgotPasswordForm isFetching={ fetchStatus === FetchStatus.pending } email={ email } onEmailChanged={ setEmail } onSubmit={ executeFetch } />
        }
      </div>
    </div>
  </Layout>;
}

interface ForgotPasswordFormProps {
  isFetching: boolean,
  email: string,
  onSubmit: () => void,
  onEmailChanged: (email: string) => void,
}

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit();
  };

  return <form onSubmit={ onSubmit } className="form form--forgot-password">
    <EmailTextField identifier='email' label={ t('forgot_password_page_email') } value={ props.email } disabled={ props.isFetching } onChange={ props.onEmailChanged } required={ true } />
    {
      props.isFetching
        ? <LoadingIndicator />
        : <div className="form-actions"><button type="submit" className="btn-primary btn-primary--large">{ t('forgot_password_page_submit') }</button></div>
    }
  </form>;
}


interface ForgotPasswordSuccessProps {
  email: string,
}

function ForgotPasswordSuccess(props: ForgotPasswordSuccessProps) {
  const { t } = useTranslation();

  return <>
    <MessageBox type={ MessageBoxType.success } message={ t('forgot_password_page_success', { email: props.email, }) } />
  </>;
}
