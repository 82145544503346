import { EstablishmentDropdown, EstablishmentOption } from "features/enterprise/ui/EstablishmentDropdown";
import { ReactComponent as IconDelete } from 'assets/inline-svg/icons/icon__delete.svg';
import { useTranslation } from "react-i18next";
import styles from "./EstablishmentsSelector.module.scss";

interface EstablishmentsSelectorProps {
  options: EstablishmentOption[],
  selectedOptions: EstablishmentOption[],
  selectedEstablishmentsChanged: (newSelected: EstablishmentOption[]) => void,
  enableAddButton?: boolean,
  addButtonLabel?: boolean,
  mustHaveAtLeastOneSelected?: boolean,
}

export function EstablishmentsSelector(props: EstablishmentsSelectorProps) {
  const deleteButtonVisible = !props.mustHaveAtLeastOneSelected || props.selectedOptions.length > 1;

  const deselectEstablishment = (option: EstablishmentOption) => {
    const newEstablishments = props.selectedOptions.filter((e) => e.identifier !== option.identifier);
    props.selectedEstablishmentsChanged(newEstablishments);
  };

  const replaceEstablishment = (oldEstablishment: EstablishmentOption, newEstablishment: EstablishmentOption) => {
    const options = [...props.selectedOptions];
    const index = options.indexOf(oldEstablishment);

    options.splice(index, 1, newEstablishment);
    props.selectedEstablishmentsChanged(options);
  };

  const areAllEstablishmentsSelected = props.selectedOptions.length === props.options.length;
  const selectedIdentifiers = props.selectedOptions.map(option => option.identifier);

  const onAddEstablishmentClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    addEstablishmentRow();
  };

  const addEstablishmentRow = () => {
    const selected = props.selectedOptions;
    const notSelected = props.options.filter((option) => !selectedIdentifiers.includes(option.identifier));

    if (notSelected.length > 0) {
      selected.push(notSelected[0]);
      props.selectedEstablishmentsChanged(selected);
    }
  }

  return <div className={ styles.addOrganization }>
    {
      props.selectedOptions.map((option) =>
        <div className={ styles.itemOrganization } key={ option.identifier }>
          <div className={ styles.itemOrganizationChoice }>
            <EstablishmentDropdown
              value={ option }
              options={ props.options }
              disabledOptions={ props.selectedOptions }
              onValueChanged={ (newValue) => replaceEstablishment(option, newValue) }
            />
          </div>
          { deleteButtonVisible && <div className={ styles.itemOrganizationDelete }><DeleteButton onClick={ () => deselectEstablishment(option) } /></div> }
        </div>
      )
    }
    {
      props.enableAddButton && !areAllEstablishmentsSelected &&
      <div className={ styles.addButtonWrapper }>
        <button className="btn-secondary btn-secondary--small btn-secondary--add" onClick={ onAddEstablishmentClicked }><span>{ props.addButtonLabel }</span></button>
      </div>
    }
  </div>
}

interface DeleteButtonProps {
  onClick: () => void,
}

function DeleteButton(props: DeleteButtonProps) {
  const { t } = useTranslation();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.onClick();
  };

  return <button className={ styles.removeOrganizationButton } onClick={ onClick }>
    <IconDelete />
    <span className="visually-hidden">{ t('general_remove_organization') }</span>
  </button>;
}
