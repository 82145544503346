import { ValidationMessage } from "features/validation/domain/models";
import { useTranslation } from "react-i18next";
import styles from "./ValidationErrorBox.module.scss";

interface ValidationErrorBoxProps {
  mainMessage?: string,
  messages: ValidationMessage[],
}

export function ValidationErrorBox(props: ValidationErrorBoxProps) {
  const { t } = useTranslation();

  return <div className={ styles.errorBox }>
    { props.mainMessage && <p>{ props.mainMessage }</p> }
    <ul>
      { props.messages.map((message) => (
        <li key={ message.message }>{ t(message.message) }</li>
      )) }
    </ul>
  </div>;
}
