import { ReactComponent as IconStatusOpen } from 'assets/inline-svg/icons/icon__status-open.svg';
import { ReactComponent as IconStatusSuccess } from 'assets/inline-svg/icons/icon__status-success.svg';
import { ReactComponent as IconStatusIncomplete } from 'assets/inline-svg/icons/icon__status-incomplete.svg';
import styles from "./DraftInputTabTitle.module.scss";
import { TabTitleChildProps } from 'features/common/ui/tabs/TabContainer';

interface DraftInputTabTitleProps extends TabTitleChildProps {
  isComplete: boolean,
  label: string,
}

// TabLabel specific for the Mobiscan draft pages
// In other words: support for the 3 icons: "open", "complete", "incomplete"
export function DraftInputTabTitle(props: DraftInputTabTitleProps) {
  let image = null;

  if (props.isSelected) {
    image = <IconStatusOpen />; // Open
  } else if (props.isComplete) {
    image = <IconStatusSuccess />; // Not open & complete
  } else {
    image = <IconStatusIncomplete />; // Not open & incomplete
  }

  return <span className={ styles.tab } data-open={ (props.isSelected ? 'true' : 'false') } data-complete={ (props.isComplete ? 'true' : 'false') }>
    <span className={ styles.tabImage }>{ image }</span>
    <span className={ styles.tabLabel }>{ props.label }</span>
  </span>;
}
