import { Layout } from "features/common/ui/layout/Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FormEvent, useState } from "react";
import { TextField } from "features/common/ui/forms/TextField";
import { User } from "features/auth/domain/models";
import { EmailTextField } from "features/common/ui/forms/EmailTextField";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { useAppDispatch, useAppSelector } from "redux/store";
import { validateUserUpdateForm } from "features/user/domain/validation";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateUser } from "features/user/domain/userOperations";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { MessageBox, MessageBoxType } from "features/common/ui/boxes/MessageBox";
import styles from "./UserPage.module.scss";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";

interface UserPageProps {
  homeRoute: string,
  changePasswordRoute: string
}

export function UserPage(props: UserPageProps) {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('user_page_header_back') } targetUrl={ props.homeRoute } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('user_page_header_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <div className="content-wrapper">
      <div className="inner-wrapper">
        <TitleContainer changePasswordRoute={ props.changePasswordRoute } />
        <UserDataForm initialValues={ user } />
      </div>
    </div>
  </Layout>;
}

interface TitleContainerProps {
  changePasswordRoute: string
}

function TitleContainer(props: TitleContainerProps) {
  const { t } = useTranslation();

  return <div className={ styles.userInfo }>
    <h2 className={ styles.userInfoTitle }>
      { t('user_page_title') }
    </h2>
    <Link className="btn-secondary btn-secondary--small" to={ props.changePasswordRoute }>
      { t('user_page_change_password_cta') }
    </Link>
  </div>;
}

interface UserDataFormProps {
  initialValues: User,
}

function UserDataForm(props: UserDataFormProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();


  const sendUpdateRequest = async () => {
    // Client side validate
    const validationResult = validateUserUpdateForm(firstName, lastName, jobTitle, phone, email, t);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const request = { firstName, lastName, jobTitle, phone, email, };
    const updateAction = await dispatch(updateUser(request));
    const result = unwrapResult(updateAction);
    return { validationResult: result.validationResult };
  }

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<void>(sendUpdateRequest, false);

  const [firstName, setFirstName] = useState(props.initialValues.firstName);
  const [lastName, setLastName] = useState(props.initialValues.lastName);
  const [jobTitle, setJobTitle] = useState(props.initialValues.jobTitle);
  const [phone, setPhone] = useState(props.initialValues.phone);
  const [email, setEmail] = useState(props.initialValues.email);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    executeFetch();
  }

  return <form className="form form--user-info" onSubmit={ onSubmit } noValidate={ true }>
    <div className="form-row">
      <TextField identifier='first-name' value={ firstName } onChange={ setFirstName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_first_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='last-name' value={ lastName } onChange={ setLastName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_last_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='job-title' value={ jobTitle } onChange={ setJobTitle } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_job_title') } />
    </div>
    <div className="form-row">
      <TextField identifier='phone' value={ phone } onChange={ setPhone } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_phone') } />
    </div>
    <div className="form-row">
      <EmailTextField identifier='email' value={ email } onChange={ setEmail } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_email') } />
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('user_update_unknown_error') } />
      }

      {
        validationResult
        && !validationResult.isValid
        && <ValidationErrorBox mainMessage={ t('user_validation_title') } messages={ validationResult.messages } />
      }

      {
        validationResult && validationResult.isValid
        && fetchStatus === FetchStatus.success === true
        && <MessageBox type={ MessageBoxType.success } message={ t('user_update_success') } />
      }

    </div>

    {
      fetchStatus === FetchStatus.pending
        ? <LoadingIndicator />
        :
        <div className="form-actions">
          <div className="form-action form-action--large">
            <button className="form-button" type='submit'>{ t('user_page_form_submit') }</button>
          </div>
        </div>
    }

  </form>
}
