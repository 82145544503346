import { CommuteByTransportModes, CommuteQuantityByDistance } from "features/mobiscan/report/domain/models";
import { HorizontalStackedBarChart, StackedBarSegment } from "features/mobiscan/report/ui/tabs/charts/HorizontalStackedBarChart";
import { useTranslation } from "react-i18next";
import styles from "./CommuteTransportModeChart.module.scss";

interface CommuteTransportModeChartProps {
  commuteByTransportModes: CommuteByTransportModes,
}

export function CommuteTransportModeChart(props: CommuteTransportModeChartProps) {
  const { t } = useTranslation();

  // Legend
  const legendLabels = props.commuteByTransportModes.transportModeSegments[0].quantityByDistance.map((distances) => {
    const label = distances.kmMax != null
      ? `${distances.kmMin}-${distances.kmMax} km`
      : `>${distances.kmMin}km`;

    return {
      label: label,
      dataType: `${distances.kmMin}-${distances.kmMax}`,
      className: styles.figureLegendItem,
    };
  });

  // Bar segments
  const mapToBarSegment = (commuteQuantityByDistance: CommuteQuantityByDistance): StackedBarSegment => {
    const label = commuteQuantityByDistance.kmMax != null
      ? `${commuteQuantityByDistance.kmMin}-${commuteQuantityByDistance.kmMax} km`
      : `>${commuteQuantityByDistance.kmMin}km`;

    return {
      count: commuteQuantityByDistance.count,
      percentage: commuteQuantityByDistance.percentage,
      tooltipTitle: label,
      tooltipContent: t('report_tab_mobility_tab_commuting_tab_transport_chart_tooltip', { value: Math.round(commuteQuantityByDistance.percentage) }),
      dataType: `${commuteQuantityByDistance.kmMin}-${commuteQuantityByDistance.kmMax}`,
    };
  }

  // Bars
  const bars = props.commuteByTransportModes.transportModeSegments
    .sort((a, b) => a.transportMode.sortIndex - b.transportMode.sortIndex)
    .map((segment) => {
      return {
        label: t('transport_mode_' + segment.transportMode.id.toLowerCase()),
        count: segment.count,
        segments: segment.quantityByDistance.map(mapToBarSegment),
        className: styles.figureBarItem,
      };
    });

  return <>
    <HorizontalStackedBarChart
      legendLabels={ legendLabels }
      bars={ bars }
      a11yLabelGroupName={ t('report_tab_mobility_tab_commuting_tab_transport_chart_a11y_group_name') }
      a11yLabelCount={ t('report_tab_mobility_tab_commuting_tab_transport_chart_a11y_count') }
      a11yLabelComposition={ t('report_tab_mobility_tab_commuting_tab_transport_chart_a11y_composition') }
    />
  </>;
}
