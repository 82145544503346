import { Checkbox } from "features/common/ui/forms/CheckboxSmall";
import Dropdown, { DropdownOption } from "features/common/ui/forms/DropdownSmall";
import { Schedule, TransportMode } from "features/mobiscan/general/domain/models";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './MapEmployeeFilter.module.scss';

interface MapEmployeeFilterProps {
  onUpdateFilters: (selectedTransportModes: TransportMode[], schedule?: Schedule) => void,
  transportModes: TransportMode[],
  schedules: Schedule[],
}

export default function MapEmployeeFilter(props: MapEmployeeFilterProps) {
  const { t } = useTranslation();
  const [selectedTransportModes, setSelectedTransportModes] = useState<TransportMode[]>(props.transportModes);
  const [selectedWorkSchedule, setSelectedWorkSchedule] = useState<Schedule | undefined>(undefined);

  const handleTransportModeCheck = (transportId: string, checked: boolean) => {
    const toggledTransportMode = props.transportModes.find(transportMode => transportMode.id === transportId)!;

    const newList = checked
      ? [...selectedTransportModes, toggledTransportMode]
      : [...selectedTransportModes].filter((transportMode) => transportMode.id !== transportId);

    setSelectedTransportModes(newList);
    props.onUpdateFilters(newList, selectedWorkSchedule);
  }

  const handleSelectedWorkScheduleChange = (newValue?: string) => {
    if (newValue === 'all') {
      setSelectedWorkSchedule(undefined);
      props.onUpdateFilters(selectedTransportModes, undefined);
    } else {
      const schedule = props.schedules.find(schedule => schedule.id === newValue)!;
      setSelectedWorkSchedule(schedule);
      props.onUpdateFilters(selectedTransportModes, schedule);
    }
  }

  const selectAllSchedulesOption: DropdownOption = {
    value: 'all',
    text: t('map_employee_filter_schedule_all'),
  };

  const scheduleOptions: DropdownOption[] = [
    selectAllSchedulesOption,
    ...props.schedules.map((schedule) => ({
      value: schedule.id,
      text: t('schedule_' + schedule.id.toLowerCase()),
    }))
  ];

  const selectedScheduleOption = selectedWorkSchedule == null
    ? selectAllSchedulesOption
    : scheduleOptions.find(option => option.value === selectedWorkSchedule.id);


  return <>
    <div className={ styles.MapFilterLegendSubSection }>
      <Dropdown
        identifier="work-schedule-select"
        options={ scheduleOptions }
        label={ t('map_employee_filter_schedule') }
        onChange={ handleSelectedWorkScheduleChange }
        selectedOption={ selectedScheduleOption }
      />
    </div>
    <div className={ styles.MapFilterLegendSubSection }>
      <fieldset>
        <legend>
          <span className="visually-hidden">
            { t('map_employee_filter_transport_modes') }
          </span>
        </legend>
        {
          props.transportModes.map((mode) => {
            return <div key={ mode.id } className={ styles.transportNodeItem }>
              <Checkbox
                identifier={ 'filter-' + mode.id }
                checked={ selectedTransportModes.find(selectedMode => selectedMode.id === mode.id) != null }
                onChange={ (value) => handleTransportModeCheck(mode.id, value) }
              >
                {/* TODO: Style */ }
                <span data-type={ mode.id.toLowerCase() } className={ styles.transportMode }>{ t('transport_mode_' + mode.id.toLowerCase()) }</span>
              </Checkbox>
            </div>;
          })
        }
      </fieldset>
    </div>
  </>;
}
