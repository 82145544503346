import { Link } from "react-router-dom";
import styles from "./BackButton.module.scss";
import { ReactComponent as IconGoBack } from 'assets/inline-svg/icons/icon__go-back.svg';

export enum BackButtonType {
  box = 'box',
  text = 'text',
  inline = 'inline'
}

interface BackButtonProps {
  label: string,
  targetUrl: string,
  displayType: BackButtonType,
  onClick?: () => void,
}

export function BackButton(props: BackButtonProps) {
  return <Link className={ styles.link } to={ props.targetUrl } onClick={ props.onClick } data-displaytype={ props.displayType }>
    <IconGoBack />
    <span>{ props.label }</span>
  </Link>;
}
