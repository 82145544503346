import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import * as chartServices from 'features/chart/domain/chartServices';
import { ChartSettings } from 'features/chart/domain/models';
import { Establishment } from 'features/enterprise/domain/models';
import { EmployeesBySchedule } from 'features/mobiscan/report/domain/models';
import { TFunction } from 'i18next';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface TimetableChartProps {
  employeesBySchedule: EmployeesBySchedule[],
  establishment: Establishment,
}

export function TimetableChart(props: TimetableChartProps) {
  const { t } = useTranslation();

  const extraChartSettings: ChartSettings = {
    yAxisLabelText: t('report_tab_mobility_tab_timetable_chart_y'),
    yAxisLabelFont: {
      size: 16
    },
    displayLegend: false,
    getPopupTitleCallback,
    getPopupBodyCallback: (context) => getPopupBodyCallback(context, t),
    getPopupFooterCallback: (context) => getPopupFooterCallback(context, t),
    showVerticalGrid: false,
  };

  const options = chartServices.createChartOptions(extraChartSettings);
  const data = createData(props.employeesBySchedule, props.establishment, t);

  return <div className='chart-scroll-outer'>
    <div className='chart-scroll-inner'>
      <Bar options={ options } data={ data } role="img" aria-label={ t('report_tab_mobility_tab_timetable_chart_label') } />
    </div>
  </div>;
}


const createData = (employeesBySchedule: EmployeesBySchedule[], establishment: Establishment, t: TFunction) => {
  const labels = employeesBySchedule.map((values) => t('schedule_' + values.schedule.id.toLowerCase()));
  return {
    labels,
    datasets: [
      {
        label: establishment.name,
        data: employeesBySchedule.map((values, i) => {
          return {
            count: values.count,
            percentage: values.percentage,
            label: labels[i],
          };
        }),
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'percentage'
        },
        backgroundColor: 'rgba(32, 176, 228, 1)',
        hoverBackgroundColor: 'rgba(32, 176, 228, .7)',
        borderRadius: 5,
        // barThickness: 20,
        // maxBarThickness: 30,
        // // barPercentage: 1, // doesn't work in combination with thickness values
        // barPercentage: 0.8,
      },
    ],
  };
};


const getPopupTitleCallback = (context: any) => {
  return context[0].dataset.label;
};

const getPopupBodyCallback = (context: any, t: TFunction) => {
  const count = context.raw.count;
  const label = t('report_tab_mobility_tab_timetable_chart_tooltip_body', { value: count, });
  return label;
};

const getPopupFooterCallback = (context: any, t: TFunction) => {
  const percentage = context[0].raw.percentage;
  const label = t('report_tab_mobility_tab_timetable_chart_tooltip_footer', { value: percentage, });
  return label;
};
