import { CommuteDistancesDTO, EmployeeDTO, EmployeesByCityDTO, EmployeesByScheduleDTO, IsolinesCollectionDTO, MeasuresAnsInspirationsDTO, MobiscanDTO, ReportInfrastructureDTO, ReportPolicyDTO, ShareTokenDTO, SummaryDTO } from "features/mobiscan/report/data/models";
import { MobiscanReportApi } from "features/mobiscan/report/data/MobiscanReportApi";

export class MobiscanReportMockApi implements MobiscanReportApi {
  async getMobiscan(mobiscanId: string): Promise<MobiscanDTO> {
    return {
      id: 'mob_1',
      startedBy: {
        userId: "3",
        firstName: "Karel",
        lastName: "De Groote",
        email: "karel.de.groote@duo-brugge.be",
      },
      startedDateTime: '2022-07-07T15:26:30.183',
      requestedBy: {
        userId: "3",
        firstName: "Karel",
        lastName: "De Groote",
        email: "karel.de.groote@duo-brugge.be",
      },
      requestedDateTime: '2022-07-07T15:26:30.183',
      modificationDateTime: '2022-07-07T15:26:30.183',
      employeeCount: 10,
      enterpriseNumber: 'abc',
      latitudeLongitude: {
        latitude: 51.199342,
        longitude: 3.228792
      },
      potential: {
        durableActualPercentage: 10,
        durablePotentialPercentage: 20,
      },
      establishment: {
        id: 1,
        cbeNumber: 'est_1',
        name: 'De paddenhoek',
        address: {
          street: 'Straatnaam',
          houseNumber: '1',
          box: '',
          postalCode: '8000',
          city: 'Hoofdgemeente',
          country: 'BE',
        },
        activity: 'Openbare instelling',
      },
      status: 'DRAFT',
    };
  }

  async getCommuteDistances(mobiscanId: string): Promise<CommuteDistancesDTO> {
    return {
      "totalCount": 825,
      "modalSplit": {
        "MOPED": {
          "count": 63,
          "percentage": 7.600
        },
        "BIKE": {
          "count": 64,
          "percentage": 7.800
        },
        "COLLECTIVE_COMPANY": {
          "count": 63,
          "percentage": 7.600
        },
        "MOTORBIKE": {
          "count": 63,
          "percentage": 7.600
        },
        "PEDELEC": {
          "count": 64,
          "percentage": 7.800
        },
        "TRAIN": {
          "count": 63,
          "percentage": 7.600
        },
        "PEDESTRIAN": {
          "count": 64,
          "percentage": 7.800
        },
        "E_STEP": {
          "count": 64,
          "percentage": 7.800
        },
        "CARPOOL": {
          "count": 63,
          "percentage": 7.600
        },
        "PERSONAL_CAR": {
          "count": 63,
          "percentage": 7.600
        },
        "COMPANY_CAR": {
          "count": 63,
          "percentage": 7.600
        },
        "BUS_TRAM": {
          "count": 64,
          "percentage": 7.800
        },
        "E_BIKE": {
          "count": 64,
          "percentage": 7.800
        }
      },
      "modalSplitBenchmarks": [
        {
          "name": "Vlaanderen",
          "modalSplit": {
            "MOPED": 0.4300,
            "BIKE": 21.00,
            "COLLECTIVE_COMPANY": 0.9500,
            "MOTORBIKE": 1.400,
            "PEDELEC": 0.4300,
            "TRAIN": 2.800,
            "PEDESTRIAN": 1.900,
            "E_STEP": 0.4300,
            "CARPOOL": 2.500,
            "PERSONAL_CAR": 0.4300,
            "COMPANY_CAR": 0.4300,
            "BUS_TRAM": 1.600,
            "E_BIKE": 0.4300
          }
        },
        {
          "name": "West-Vlaanderen",
          "modalSplit": {
            "MOPED": 0.4300,
            "BIKE": 21.00,
            "COLLECTIVE_COMPANY": 0.9500,
            "MOTORBIKE": 1.400,
            "PEDELEC": 0.4300,
            "TRAIN": 2.800,
            "PEDESTRIAN": 1.900,
            "E_STEP": 0.4300,
            "CARPOOL": 2.500,
            "PERSONAL_CAR": 0.4300,
            "COMPANY_CAR": 0.4300,
            "BUS_TRAM": 1.600,
            "E_BIKE": 0.4300
          }
        }
      ],
      "distanceSegments": [
        {
          "count": 386,
          "kmMin": 0,
          "kmMax": 5,
          "modalSplit": {
            "MOPED": {
              "count": 27,
              "percentage": 7.000
            },
            "BIKE": {
              "count": 35,
              "percentage": 9.100
            },
            "COLLECTIVE_COMPANY": {
              "count": 29,
              "percentage": 7.500
            },
            "MOTORBIKE": {
              "count": 27,
              "percentage": 7.000
            },
            "PEDELEC": {
              "count": 34,
              "percentage": 8.800
            },
            "TRAIN": {
              "count": 33,
              "percentage": 8.500
            },
            "PEDESTRIAN": {
              "count": 31,
              "percentage": 8.000
            },
            "E_STEP": {
              "count": 27,
              "percentage": 7.000
            },
            "CARPOOL": {
              "count": 27,
              "percentage": 7.000
            },
            "PERSONAL_CAR": {
              "count": 29,
              "percentage": 7.500
            },
            "COMPANY_CAR": {
              "count": 28,
              "percentage": 7.300
            },
            "BUS_TRAM": {
              "count": 25,
              "percentage": 6.500
            },
            "E_BIKE": {
              "count": 34,
              "percentage": 8.800
            }
          }
        },
        {
          "count": 418,
          "kmMin": 5,
          "kmMax": 10,
          "modalSplit": {
            "MOPED": {
              "count": 34,
              "percentage": 8.100
            },
            "BIKE": {
              "count": 29,
              "percentage": 6.900
            },
            "COLLECTIVE_COMPANY": {
              "count": 32,
              "percentage": 7.700
            },
            "MOTORBIKE": {
              "count": 33,
              "percentage": 7.900
            },
            "PEDELEC": {
              "count": 30,
              "percentage": 7.200
            },
            "TRAIN": {
              "count": 29,
              "percentage": 6.900
            },
            "PEDESTRIAN": {
              "count": 31,
              "percentage": 7.400
            },
            "E_STEP": {
              "count": 35,
              "percentage": 8.400
            },
            "CARPOOL": {
              "count": 33,
              "percentage": 7.900
            },
            "PERSONAL_CAR": {
              "count": 32,
              "percentage": 7.700
            },
            "COMPANY_CAR": {
              "count": 31,
              "percentage": 7.400
            },
            "BUS_TRAM": {
              "count": 39,
              "percentage": 9.300
            },
            "E_BIKE": {
              "count": 30,
              "percentage": 7.200
            }
          }
        },
        {
          "count": 1,
          "kmMin": 10,
          "kmMax": 15,
          "modalSplit": {
            "MOPED": {
              "count": 0,
              "percentage": 0
            },
            "BIKE": {
              "count": 0,
              "percentage": 0
            },
            "COLLECTIVE_COMPANY": {
              "count": 0,
              "percentage": 0
            },
            "MOTORBIKE": {
              "count": 0,
              "percentage": 0
            },
            "PEDELEC": {
              "count": 0,
              "percentage": 0
            },
            "TRAIN": {
              "count": 0,
              "percentage": 0
            },
            "PEDESTRIAN": {
              "count": 1,
              "percentage": 100
            },
            "E_STEP": {
              "count": 0,
              "percentage": 0
            },
            "CARPOOL": {
              "count": 0,
              "percentage": 0
            },
            "PERSONAL_CAR": {
              "count": 0,
              "percentage": 0
            },
            "COMPANY_CAR": {
              "count": 0,
              "percentage": 0
            },
            "BUS_TRAM": {
              "count": 0,
              "percentage": 0
            },
            "E_BIKE": {
              "count": 0,
              "percentage": 0
            }
          }
        },
        {
          "count": 20,
          "kmMin": 15,
          "kmMax": 20,
          "modalSplit": {
            "MOPED": {
              "count": 2,
              "percentage": 10.0
            },
            "BIKE": {
              "count": 0,
              "percentage": 0
            },
            "COLLECTIVE_COMPANY": {
              "count": 2,
              "percentage": 10.0
            },
            "MOTORBIKE": {
              "count": 3,
              "percentage": 15.00
            },
            "PEDELEC": {
              "count": 0,
              "percentage": 0
            },
            "TRAIN": {
              "count": 1,
              "percentage": 5.00
            },
            "PEDESTRIAN": {
              "count": 1,
              "percentage": 5.00
            },
            "E_STEP": {
              "count": 2,
              "percentage": 10.0
            },
            "CARPOOL": {
              "count": 3,
              "percentage": 15.00
            },
            "PERSONAL_CAR": {
              "count": 2,
              "percentage": 10.0
            },
            "COMPANY_CAR": {
              "count": 4,
              "percentage": 20.0
            },
            "BUS_TRAM": {
              "count": 0,
              "percentage": 0
            },
            "E_BIKE": {
              "count": 0,
              "percentage": 0
            }
          }
        },
        {
          "count": 0,
          "kmMin": 20,
          "kmMax": 30,
          "modalSplit": {
            "MOPED": {
              "count": 0,
              "percentage": 0
            },
            "BIKE": {
              "count": 0,
              "percentage": 0
            },
            "COLLECTIVE_COMPANY": {
              "count": 0,
              "percentage": 0
            },
            "MOTORBIKE": {
              "count": 0,
              "percentage": 0
            },
            "PEDELEC": {
              "count": 0,
              "percentage": 0
            },
            "TRAIN": {
              "count": 0,
              "percentage": 0
            },
            "PEDESTRIAN": {
              "count": 0,
              "percentage": 0
            },
            "E_STEP": {
              "count": 0,
              "percentage": 0
            },
            "CARPOOL": {
              "count": 0,
              "percentage": 0
            },
            "PERSONAL_CAR": {
              "count": 0,
              "percentage": 0
            },
            "COMPANY_CAR": {
              "count": 0,
              "percentage": 0
            },
            "BUS_TRAM": {
              "count": 0,
              "percentage": 0
            },
            "E_BIKE": {
              "count": 0,
              "percentage": 0
            }
          }
        },
        {
          "count": 0,
          "kmMin": 30,
          "kmMax": 50,
          "modalSplit": {
            "MOPED": {
              "count": 0,
              "percentage": 0
            },
            "BIKE": {
              "count": 0,
              "percentage": 0
            },
            "COLLECTIVE_COMPANY": {
              "count": 0,
              "percentage": 0
            },
            "MOTORBIKE": {
              "count": 0,
              "percentage": 0
            },
            "PEDELEC": {
              "count": 0,
              "percentage": 0
            },
            "TRAIN": {
              "count": 0,
              "percentage": 0
            },
            "PEDESTRIAN": {
              "count": 0,
              "percentage": 0
            },
            "E_STEP": {
              "count": 0,
              "percentage": 0
            },
            "CARPOOL": {
              "count": 0,
              "percentage": 0
            },
            "PERSONAL_CAR": {
              "count": 0,
              "percentage": 0
            },
            "COMPANY_CAR": {
              "count": 0,
              "percentage": 0
            },
            "BUS_TRAM": {
              "count": 0,
              "percentage": 0
            },
            "E_BIKE": {
              "count": 0,
              "percentage": 0
            }
          }
        },
        {
          "count": 0,
          "kmMin": 50,
          "kmMax": undefined,
          "modalSplit": {
            "MOPED": {
              "count": 0,
              "percentage": 0
            },
            "BIKE": {
              "count": 0,
              "percentage": 0
            },
            "COLLECTIVE_COMPANY": {
              "count": 0,
              "percentage": 0
            },
            "MOTORBIKE": {
              "count": 0,
              "percentage": 0
            },
            "PEDELEC": {
              "count": 0,
              "percentage": 0
            },
            "TRAIN": {
              "count": 0,
              "percentage": 0
            },
            "PEDESTRIAN": {
              "count": 0,
              "percentage": 0
            },
            "E_STEP": {
              "count": 0,
              "percentage": 0
            },
            "CARPOOL": {
              "count": 0,
              "percentage": 0
            },
            "PERSONAL_CAR": {
              "count": 0,
              "percentage": 0
            },
            "COMPANY_CAR": {
              "count": 0,
              "percentage": 0
            },
            "BUS_TRAM": {
              "count": 0,
              "percentage": 0
            },
            "E_BIKE": {
              "count": 0,
              "percentage": 0
            }
          }
        }
      ]
    };
  }

  async getEmployees(mobiscanId: string): Promise<EmployeeDTO[]> {
    return [
      {
        "id": 7126,
        "latitudeLongitude": {
          "latitude": 51.332306,
          "longitude": 3.211361
        },
        "transportMode": "PEDESTRIAN",
        "potentialTransportModes": [],
        "travelMeters": 17413,
        "travelSeconds": 3280,
        "schedule": "ROLLING_HOURS"
      },
      {
        "id": 7127,
        "latitudeLongitude": {
          "latitude": 51.215872,
          "longitude": 3.225639
        },
        "transportMode": "BIKE",
        "potentialTransportModes": [
          {
            transportMode: 'BIKE',
            meters: 500,
            seconds: 1500,
          }
        ],
        "travelMeters": 2617,
        "travelSeconds": 546,
        "schedule": "SHIFT_2"
      },
      {
        "id": 7128,
        "latitudeLongitude": {
          "latitude": 51.215883,
          "longitude": 3.225698
        },
        "transportMode": "E_BIKE",
        "potentialTransportModes": [
          {
            transportMode: 'BIKE',
            meters: 500,
            seconds: 1500,
          }
        ],
        "travelMeters": 2612,
        "travelSeconds": 546,
        "schedule": "SHIFT_3"
      },
      {
        "id": 7129,
        "latitudeLongitude": {
          "latitude": 51.203996,
          "longitude": 3.239052
        },
        "transportMode": "PEDELEC",
        "potentialTransportModes": [
          {
            transportMode: 'BIKE',
            meters: 500,
            seconds: 1500,
          }
        ],
        "travelMeters": 1033,
        "travelSeconds": 230,
        "schedule": "SHIFT_WEEKEND"
      },
    ];
  }

  async getEmployeesByCity(mobiscanId: string): Promise<EmployeesByCityDTO> {
    return {
      counts: {
        'Brugge': {
          count: 25,
          percentage: 25,
        },
        'Maldegem': {
          count: 40,
          percentage: 40,
        },
        'Oostkamp': {
          count: 5,
          percentage: 20,
        },
        'Kortrijk': {
          count: 30,
          percentage: 30,
        },
        'Damme': {
          count: 60,
          percentage: 75,
        },
      },
    }
  }

  async getEmployeesBySchedule(mobiscanId: string): Promise<EmployeesByScheduleDTO> {
    return {
      counts: {
        "OTHER": {
          count: 112,
          percentage: 11
        },
        "SHIFT_3": {
          count: 113,
          percentage: 11
        },
        "SHIFT_2": {
          count: 111,
          percentage: 11
        },
        "IRREGULAR": {
          count: 111,
          percentage: 11
        },
        "SHIFT_NIGHT": {
          count: 112,
          percentage: 11
        },
        "SHIFT_WEEKEND": {
          count: 112,
          percentage: 11
        },
        "FIXED": {
          count: 218,
          percentage: 22
        },
        "ROLLING_HOURS": {
          count: 111,
          percentage: 11
        }
      }
    };
  }

  async getPolicy(mobiscanId: string): Promise<ReportPolicyDTO> {
    return {
      "mobiscanId": "4a6c4b6d-27a1-4fa6-b6bd-5f2a2b05cc38",
      "pageName": "policy",
      "tabs": [
        {
          "tabName": "bicyclePolicy",
          "fields": [
            {
              "fieldName": "awarenessCampaign",
              "label": "Organiseert/neemt je bedrijf/organisatie deel aan een sensibilisatiecampagne ter promotie fiets?",
              "type": "Potential",
              "value": "FUTURE"
            },
            {
              "fieldName": "bicycleCompensation",
              "label": "Biedt je bedrijf/organisatie fietsvergoeding woon-werkverkeer aan? (cent/km)",
              "type": "BigDecimal",
              "value": 1.00
            },
            {
              "fieldName": "companyBicycles",
              "label": "Biedt je bedrijf/organisatie bedrijfsfietsen aan voor de woon-werkverplaatsing (vb. leasing)? ",
              "type": "Potential",
              "value": "FUTURE"
            },
            {
              "fieldName": "contractType",
              "label": "ingeval ja/gepland: type contract",
              "type": "CompanyBicycleContractType",
              "value": "COMPANY_PURCHASE"
            },
            {
              "fieldName": "maintenancePossible",
              "label": "Biedt je bedrijf/organisatie fietsondershoud op de vestiging aan?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "otherMeasures",
              "label": "Voorziet je bedrijf/organisatie nog bijkomende andere fietsmaatregelen?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "otherMeasuresDescription",
              "label": "ingeval ja/gepland: specificeer aub",
              "type": "String",
              "value": "12"
            },
            {
              "fieldName": "roadsideAssistance",
              "label": "Biedt je bedrijf/organisatie fietsbijstand aan ingeval van pech?",
              "type": "Potential",
              "value": "NO"
            }
          ]
        },
        {
          "tabName": "publicTransitPolicy",
          "fields": [
            {
              "fieldName": "collectiveTransportation",
              "label": "Biedt je bedrijf/organisatie collectief bedrijfsvervoer (mini/autobus) aan aan je personeel?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "freeTrain",
              "label": "Biedt je bedrijf/organisatie gratis treinvervoer woon-werkverkeer aan aan je personeel?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "freeTramBus",
              "label": "Biedt je bedrijf/organisatie gratis bus/tramvervoer woon-werkverkeer aan aan je personeel?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "infoDistribution",
              "label": "Verspreidt je bedrijf/organisatie info over openbaar vervoer?",
              "type": "Potential",
              "value": "NO"
            }
          ]
        },
        {
          "tabName": "carpoolPolicy",
          "fields": [
            {
              "fieldName": "carpoolDatabase",
              "label": "Maakt je bedrijf/organisatie gebruik van een interne of externe carpooldatabank?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "compensation",
              "label": "Heeft je bedrijf/organisatie een carpoolpolicy?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "infoDistribution",
              "label": "Verspreidt je bedrijf/organisatie info over carpooling aan je personeel?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "policy",
              "label": "Voorziet je bedrijf/organisatie een terugreisgarantie voor carpoolers ingeval van calamiteiten?",
              "type": "Potential",
              "value": null
            },
            {
              "fieldName": "returnGuarantee",
              "label": "Voorziet je bedrijf/organisatie een specifieke financiële vergoeding voor carpoolers?",
              "type": "Potential",
              "value": "NO"
            }
          ]
        },
        {
          "tabName": "carPolicy",
          "fields": [
            {
              "fieldName": "alternativeMobilityOffers",
              "label": "Ingeval ja/gepland: Biedt je mobiliteitsbudget aan als alternatief voor bedrijfswagens?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "fullyElectricVehicles",
              "label": "Aantal volledig elektrische (bestel)wagens in je wagenpark?",
              "type": "Integer",
              "value": 3
            },
            {
              "fieldName": "mobilityBudget",
              "label": "Biedt je bedrijf/organisatie bedrijfswagens aan (=salariswagens die deel uitmaken van het loonpakket)?",
              "type": "Potential",
              "value": "FUTURE"
            },
            {
              "fieldName": "paidParking",
              "label": "Voert je bedrijf/organisatie een betalend parkeerbeleid door voor je personeel?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "salaryCars",
              "label": "Ingeval ja/gepland: Biedt je alternatieve mobiliteitsoplossingen aan voor bedrijfswagens (vb. cafetariaplan met mobiliteitsonderdeel)?",
              "type": "Potential",
              "value": null
            }
          ]
        },
        {
          "tabName": "communicationPolicy",
          "fields": [
            {
              "fieldName": "annualCampaign",
              "label": "Organiseert/neemt je bedrijf/organisatie deel aan een algemene mobiliteitscampagne (minstens 1x per jaar)?",
              "type": "Potential",
              "value": "YES"
            },
            {
              "fieldName": "multimodalAccessibilityInfo",
              "label": "Stelt je bedrijf/organisatie multimodale bereikbaarheidsinfo ter beschikking?",
              "type": "Potential",
              "value": null
            }
          ]
        },
        {
          "tabName": "policyExtras",
          "fields": [
            {
              "fieldName": "mobilityCoordinator",
              "label": "Is er een mobiliteitscoördinator aangesteld (persoon die mobiliteitsissues coördineert)?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "mobilityPlan",
              "label": "Beschikt je bedrijf/organisatie over een bedrijfsvervoerplan?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "movingBonus",
              "label": "Biedt je bedrijf/organisatie een verhuispremie aan aan personeelsleden die dichter bij het werk komen wonen?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "multipleCompensationsPossible",
              "label": "Biedt je bedrijf/organisatie mogelijkheid tot cumuleren verschillende werkgeversbijdragen voor verschillende vervoermodi?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "other",
              "label": "Andere: specificeer aub",
              "type": "String",
              "value": "12"
            },
            {
              "fieldName": "workFromHomeCompensation",
              "label": "Ingeval ja/gepland: Biedt je bedrijf/organisatie een vorm van thuiswerkvergoeding aan?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "workFromHomePossible",
              "label": "Is (gedeeltelijk) thuiswerk mogelijk voor (een deel van) je personeel?",
              "type": "Potential",
              "value": "NO"
            }
          ]
        }
      ]
    };
  }

  async getInfrastructure(mobiscanId: string): Promise<ReportInfrastructureDTO> {
    return {
      "mobiscanId": "4a6c4b6d-27a1-4fa6-b6bd-5f2a2b05cc38",
      "pageName": "infrastructure",
      "tabs": [
        {
          "tabName": "carInfrastructure",
          "fields": [
            {
              "fieldName": "chargingSpots",
              "label": "Aantal laadpunten voor elektrische wagens?",
              "type": "Integer",
              "value": 3
            },
            {
              "fieldName": "chargingSpotsPublic",
              "label": "Zijn deze laadpunten publiek beschikbaar?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "parkingSpots",
              "label": "Aantal autoparkeerplaatsen op de vestiging?",
              "type": "Integer",
              "value": 5
            },
            {
              "fieldName": "reservedCarpoolSpots",
              "label": "Zijn er voorbehouden carpoolparkeerplaatsen op de site?",
              "type": "Potential",
              "value": "FUTURE"
            }
          ]
        },
        {
          "tabName": "bicycleInfrastructure",
          "fields": [
            {
              "fieldName": "changingRoom",
              "label": "Is er kleedruimte voor fietsers beschikbaar?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "chargingSpots",
              "label": "Zijn er oplaadpunten/oplaadlockers voor elektrische fietsen beschikbaar?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "parkingSpots",
              "label": "Aantal fietsparkeerplaatsen op de vestiging?",
              "type": "Integer",
              "value": 2
            },
            {
              "fieldName": "parkingSpotsCovered",
              "label": "Zijn deze fietsparkeerplaatsen overdekt?",
              "type": "Potential",
              "value": "FUTURE"
            },
            {
              "fieldName": "parkingSpotsSecured",
              "label": "Zijn deze fietsparkeerplaatsen beveiligd?",
              "type": "Potential",
              "value": "NO"
            },
            {
              "fieldName": "showers",
              "label": "Zijn er douches voor fietsers beschikbaar?",
              "type": "Potential",
              "value": "NO"
            }
          ]
        }
      ]
    };
  }

  async getMeasuresAndInspirations(mobiscanId: string): Promise<MeasuresAnsInspirationsDTO> {
    return {
      measures: [
        {
          tag: 'MOCK-TAG',
          tagColor: '#FED87B',
          effect: 5,
          effort: 3,
          imageUrl: 'http://via.placeholder.com/350x250',
          detailUrl: 'http://via.placeholder.com/350x250',
          title: 'Goeie measure1',
          effortChart: 90,
          effectChart: 10,
        },
        {
          tag: 'MOCK-TAG-2',
          tagColor: '#FED87B',
          effect: 3,
          effort: 4,
          imageUrl: 'http://via.placeholder.com/350x250',
          detailUrl: 'http://via.placeholder.com/350x250',
          title: 'Goeie measure2',
          effortChart: 10,
          effectChart: 90,
        }
      ],
      inspirations: [
        {
          tag: 'MOCK-TAG',
          tagColor: '#FED87B',
          imageUrl: 'http://via.placeholder.com/350x250',
          detailUrl: 'http://via.placeholder.com/350x250',
          title: 'Inspirerend voorbeeld1',
        },
        {
          tag: 'MOCK-TAG-2',
          tagColor: '#FED87B',
          imageUrl: 'http://via.placeholder.com/350x250',
          detailUrl: 'http://via.placeholder.com/350x250',
          title: 'Inspirerend voorbeeld2',
        }
      ],
    };
  }

  async getIsolines(mobiscanId: string): Promise<IsolinesCollectionDTO> {
    return {
      "car": {
        "5000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.197662,
                  "longitude": 3.185349
                },
                {
                  "latitude": 51.183929,
                  "longitude": 3.280792
                },
                {
                  "latitude": 51.17569,
                  "longitude": 3.278046
                },
                {
                  "latitude": 51.172943,
                  "longitude": 3.269806
                },
                {
                  "latitude": 51.164703,
                  "longitude": 3.261566
                },
                {
                  "latitude": 51.16333,
                  "longitude": 3.257446
                },
                {
                  "latitude": 51.161957,
                  "longitude": 3.251953
                },
                {
                  "latitude": 51.15921,
                  "longitude": 3.24646
                },
                {
                  "latitude": 51.156464,
                  "longitude": 3.24234
                },
                {
                  "latitude": 51.194916,
                  "longitude": 3.181229
                },
                {
                  "latitude": 51.197662,
                  "longitude": 3.185349
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        },
        "30000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.219635,
                  "longitude": 2.877731
                },
                {
                  "latitude": 51.222382,
                  "longitude": 2.879791
                },
                {
                  "latitude": 51.310272,
                  "longitude": 3.088531
                },
                {
                  "latitude": 51.311646,
                  "longitude": 3.092651
                },
                {
                  "latitude": 51.311646,
                  "longitude": 3.098145
                },
                {
                  "latitude": 51.313019,
                  "longitude": 3.102264
                },
                {
                  "latitude": 51.315765,
                  "longitude": 3.105011
                },
                {
                  "latitude": 51.317139,
                  "longitude": 3.109131
                },
                {
                  "latitude": 51.145477,
                  "longitude": 2.915497
                },
                {
                  "latitude": 51.145477,
                  "longitude": 2.91275
                },
                {
                  "latitude": 51.142731,
                  "longitude": 2.910004
                },
                {
                  "latitude": 51.141357,
                  "longitude": 2.905884
                },
                {
                  "latitude": 51.141357,
                  "longitude": 2.883911
                },
                {
                  "latitude": 51.142731,
                  "longitude": 2.879791
                },
                {
                  "latitude": 51.145477,
                  "longitude": 2.879791
                },
                {
                  "latitude": 51.15097,
                  "longitude": 2.885284
                },
                {
                  "latitude": 51.153717,
                  "longitude": 2.893524
                },
                {
                  "latitude": 51.15921,
                  "longitude": 2.899017
                },
                {
                  "latitude": 51.16333,
                  "longitude": 2.900391
                },
                {
                  "latitude": 51.174316,
                  "longitude": 2.900391
                },
                {
                  "latitude": 51.178436,
                  "longitude": 2.899017
                },
                {
                  "latitude": 51.181183,
                  "longitude": 2.896271
                },
                {
                  "latitude": 51.183929,
                  "longitude": 2.896271
                },
                {
                  "latitude": 51.185303,
                  "longitude": 2.900391
                },
                {
                  "latitude": 51.185303,
                  "longitude": 2.905884
                },
                {
                  "latitude": 51.186676,
                  "longitude": 2.910004
                },
                {
                  "latitude": 51.190796,
                  "longitude": 2.911377
                },
                {
                  "latitude": 51.194916,
                  "longitude": 2.910004
                },
                {
                  "latitude": 51.194916,
                  "longitude": 2.907257
                },
                {
                  "latitude": 51.216888,
                  "longitude": 2.873611
                },
                {
                  "latitude": 51.219635,
                  "longitude": 2.877731
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        },
        "15000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.240234,
                  "longitude": 3.082352
                },
                {
                  "latitude": 51.241608,
                  "longitude": 3.085785
                },
                {
                  "latitude": 51.194916,
                  "longitude": 3.077545
                },
                {
                  "latitude": 51.197662,
                  "longitude": 3.085785
                },
                {
                  "latitude": 51.200409,
                  "longitude": 3.088531
                },
                {
                  "latitude": 51.201782,
                  "longitude": 3.092651
                },
                {
                  "latitude": 51.200409,
                  "longitude": 3.096771
                },
                {
                  "latitude": 51.192169,
                  "longitude": 3.099518
                },
                {
                  "latitude": 51.190796,
                  "longitude": 3.103638
                },
                {
                  "latitude": 51.192169,
                  "longitude": 3.107758
                },
                {
                  "latitude": 51.196289,
                  "longitude": 3.109131
                },
                {
                  "latitude": 51.201782,
                  "longitude": 3.109131
                },
                {
                  "latitude": 51.205902,
                  "longitude": 3.107758
                },
                {
                  "latitude": 51.208649,
                  "longitude": 3.105011
                },
                {
                  "latitude": 51.212769,
                  "longitude": 3.103638
                },
                {
                  "latitude": 51.216888,
                  "longitude": 3.105011
                },
                {
                  "latitude": 51.216888,
                  "longitude": 3.107758
                },
                {
                  "latitude": 51.214142,
                  "longitude": 3.110504
                },
                {
                  "latitude": 51.212769,
                  "longitude": 3.114624
                },
                {
                  "latitude": 51.212769,
                  "longitude": 3.120117
                },
                {
                  "latitude": 51.240234,
                  "longitude": 3.078918
                },
                {
                  "latitude": 51.240234,
                  "longitude": 3.082352
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        }
      },
      "bicycle": {
        "5000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.212769,
                  "longitude": 3.170242
                },
                {
                  "latitude": 51.214142,
                  "longitude": 3.173676
                },
                {
                  "latitude": 51.190796,
                  "longitude": 3.290405
                },
                {
                  "latitude": 51.185303,
                  "longitude": 3.290405
                },
                {
                  "latitude": 51.181183,
                  "longitude": 3.289032
                },
                {
                  "latitude": 51.178436,
                  "longitude": 3.280792
                },
                {
                  "latitude": 51.17569,
                  "longitude": 3.278046
                },
                {
                  "latitude": 51.16333,
                  "longitude": 3.213501
                },
                {
                  "latitude": 51.16333,
                  "longitude": 3.191528
                },
                {
                  "latitude": 51.164703,
                  "longitude": 3.187408
                },
                {
                  "latitude": 51.16745,
                  "longitude": 3.187408
                },
                {
                  "latitude": 51.170197,
                  "longitude": 3.190155
                },
                {
                  "latitude": 51.172943,
                  "longitude": 3.190155
                },
                {
                  "latitude": 51.174316,
                  "longitude": 3.186035
                },
                {
                  "latitude": 51.211395,
                  "longitude": 3.164749
                },
                {
                  "latitude": 51.212769,
                  "longitude": 3.166809
                },
                {
                  "latitude": 51.212769,
                  "longitude": 3.170242
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        },
        "30000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.168823,
                  "longitude": 2.840652
                },
                {
                  "latitude": 51.170197,
                  "longitude": 2.844086
                },

                {
                  "latitude": 51.38443,
                  "longitude": 3.398895
                },
                {
                  "latitude": 51.387177,
                  "longitude": 3.401642
                },
                {
                  "latitude": 51.38855,
                  "longitude": 3.405762
                },

                {
                  "latitude": 51.203156,
                  "longitude": 3.632355
                },
                {
                  "latitude": 51.200409,
                  "longitude": 3.635101
                },
                {
                  "latitude": 51.197662,
                  "longitude": 3.635101
                },
                {
                  "latitude": 51.194916,
                  "longitude": 3.632355
                },
                {
                  "latitude": 51.192169,
                  "longitude": 3.632355
                },
                {
                  "latitude": 51.189423,
                  "longitude": 3.635101
                },

                {
                  "latitude": 51.137238,
                  "longitude": 3.582916
                },
                {
                  "latitude": 51.137238,
                  "longitude": 3.585663
                },
                {
                  "latitude": 51.139984,
                  "longitude": 3.588409
                },
                {
                  "latitude": 51.141357,
                  "longitude": 3.592529
                },
                {
                  "latitude": 51.139984,
                  "longitude": 3.596649
                },
                {
                  "latitude": 51.135864,
                  "longitude": 3.598022
                },
                {
                  "latitude": 51.102905,
                  "longitude": 3.598022
                },
                {
                  "latitude": 51.098785,
                  "longitude": 3.596649
                },
                {
                  "latitude": 51.096039,
                  "longitude": 3.588409
                },

                {
                  "latitude": 51.164703,
                  "longitude": 2.835846
                },
                {
                  "latitude": 51.16745,
                  "longitude": 2.835159
                },
                {
                  "latitude": 51.168823,
                  "longitude": 2.837219
                },
                {
                  "latitude": 51.168823,
                  "longitude": 2.840652
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        },
        "15000 m": {
          "polygons": [
            {
              "outer": [
                {
                  "latitude": 51.203156,
                  "longitude": 3.037033
                },
                {
                  "latitude": 51.205902,
                  "longitude": 3.039093
                },

                {
                  "latitude": 51.183929,
                  "longitude": 3.039093
                },
                {
                  "latitude": 51.183929,
                  "longitude": 3.04184
                }
              ],
              "inner": []
            }
          ],
          "connections": []
        }
      }
    };
  }

  async getSummary(mobiscanId: string): Promise<SummaryDTO> {
    return {
      "mobiscanId": "f2667cd8-5cc5-4d7f-9daa-cb4d57728e3d",
      "totalEmployees": 2000,
      "durable": {
        "current": {
          "count": 1290,
          "percentage": 65.00
        },
        "potential": {
          "count": 2682,
          "percentage": 134.00
        }
      },
      "active": {
        "current": {
          "count": 1430,
          "percentage": 72.00
        },
        "potential": {
          "count": 0,
          "percentage": 0.00
        }
      },
      "collective": {
        "current": {
          "count": 454,
          "percentage": 23.00
        },
        "potential": {
          "count": 0,
          "percentage": 0.00
        }
      },
      "carpool": {
        "current": {
          "count": 2088,
          "percentage": 104.00
        },
        "potential": {
          "count": 0,
          "percentage": 0.00
        }
      },
      "motorizedTransport": {
        "current": {
          "count": 710,
          "percentage": 36.00
        },
        "potential": {
          "count": 0,
          "percentage": 0.00
        }
      },
      "commuteDistance": {
        "actual": 5286,
        "benchmark": 5000,
      },
      "commuteDuration": {
        "actual": 960.000000000,
        "benchmark": 1020.000000000
      },
      "carbonDioxide": {
        "actual": 0.00,
        "benchmark": 43
      },
      "percentageDurable": {
        "actual": 0.65,
        "benchmark": 27
      },
      "bicycleParkingSpotsPercentage": {
        "actual": 0.100,
        "benchmark": 12,
      },
      "carParkingSpotsPercentage": {
        "actual": 0.200,
        "benchmark": 59.05
      }
    };
  }

  async getPotentialExportBlob(mobiscanId: string): Promise<Blob> {
    throw new Error("Method not implemented.");
  }

  async getShareToken(mobiscanId: string): Promise<ShareTokenDTO> {
    return {
      mobiscanId: "70bb9a56-6e47-4183-a651-c24bc454edd9",
      value: "8VGww9WE40GKIwPJYNVOAk1DWiCVoKboRzuiFjvD4dj"
    };
  }

}