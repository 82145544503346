import { useCallback, useEffect, useState } from 'react';

export enum FetchStatus {
  idle,
  pending,
  success,
  unknownError,
}

export function useFetch<T>(fetchFunction: () => Promise<T>, immediate = true) {
  const [fetchStatus, setStatus] = useState<FetchStatus>(FetchStatus.idle);
  const [fetchResult, setResult] = useState<T | null>(null);
  const [fetchError, setError] = useState<unknown | null>(null);

  const resetFetch = useCallback(() => {
    setStatus(FetchStatus.idle);
    setResult(null);
    setError(false);
  }, []);

  const executeFetch = useCallback(async () => {
    resetFetch();
    setStatus(FetchStatus.pending);
    try {
      const response = await fetchFunction();
      setResult(response);
      setStatus(FetchStatus.success);
    } catch (error) {
      console.log(error);
      setError(error);
      setStatus(FetchStatus.unknownError);
    }
  }, [fetchFunction, resetFetch]);


  useEffect(() => {
    if (immediate) {
      executeFetch();
    }
  }, [executeFetch, immediate]);

  return { executeFetch, resetFetch, fetchStatus, fetchResult, fetchError };
};
