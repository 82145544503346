import { UpdatePasswordResponse, UpdateUserResponse } from "features/user/data/models";
import { UpdatePasswordResult, UpdateUserResult } from "features/user/domain/models";
import { mapApiValidationErrorToValidationResult } from "features/validation/domain/mappers";


const updateUserValidationCodes = [
  { code: 'EMAIL_NOT_ACCEPTABLE', labelKey: 'user_validation_email_exists' },
];

export function mapUpdateUserResponse(updateUserResponse: UpdateUserResponse): UpdateUserResult {
  const validationResult = mapApiValidationErrorToValidationResult(updateUserValidationCodes, updateUserResponse);
  return {
    success: updateUserResponse.success,
    validationResult,
  };
}



const updatePasswordValidationCodes = [
  { code: 'CURRENT_PASSWORD_INCORRECT', labelKey: 'user_password_validation_password_incorrect' },
];

export function mapUpdatePasswordResponse(updatePasswordResponse: UpdatePasswordResponse): UpdatePasswordResult {
  const validationResult = mapApiValidationErrorToValidationResult(updatePasswordValidationCodes, updatePasswordResponse);
  return {
    success: updatePasswordResponse.success,
    validationResult,
  };
}
