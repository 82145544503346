import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import Dropdown from "features/common/ui/forms/Dropdown";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { Draft, FileProcessingStatus } from "features/mobiscan/draft/domain/models";
import { UploadForm } from "features/mobiscan/draft/ui/personnel/UploadForm";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TemplateTab.module.scss";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { ValidationResult } from "features/validation/domain/models";
import * as validation from "features/mobiscan/draft/domain/validation";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { Situation } from "features/mobiscan/general/domain/models";

interface TemplateTabProps {
  draft: Draft,
  fileProcessingStatus: FileProcessingStatus,
  onSaveFinished: () => void,
  onFileUploadStart: () => void,
  onFileUploadFinished: () => void,
  onFileUploadError: () => void,
}

export function TemplateTab(props: TemplateTabProps) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  const onFileUploadStart = () => {
    setIsUploading(true);
    props.onFileUploadStart();
  };

  const onFileUploadFinished = () => {
    setIsUploading(false);
    props.onFileUploadFinished();
  };

  const onFileUploadError = () => {
    setIsUploading(false);
    props.onFileUploadError();
  };

  const fetchSituations = useCallback(async () => {
    const result = await mobiscanGeneralServices.getSituations();
    return result;
  }, []);

  const { fetchResult, fetchStatus } = useFetch<Situation[]>(fetchSituations);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }
  if (fetchStatus === FetchStatus.success) {
    const situations = fetchResult!;

    return <div>
      <h2 className={ styles.uploadTitle }>{ t('edit_personnel_tab_template_title') }</h2>

      <UploadForm
        draftId={ props.draft.id }
        onFileUploadStart={ onFileUploadStart }
        onFileUploadFinished={ onFileUploadFinished }
        onFileUploadError={ onFileUploadError }
        fileProcessingStatus={ props.fileProcessingStatus }
      />

      {
        props.fileProcessingStatus === FileProcessingStatus.finished &&
        <SituationContainer
          disabled={ isUploading || props.fileProcessingStatus !== FileProcessingStatus.finished }
          onSaveSuccess={ () => props.onSaveFinished() }
          draft={ props.draft }
          situations={ situations }
        />
      }

    </div>;
  }

  return <LoadingIndicator />;
}


interface SituationContainerProps {
  draft: Draft,
  disabled: boolean,
  onSaveSuccess: () => void,
  situations: Situation[],
}

function SituationContainer(props: SituationContainerProps) {
  const { t } = useTranslation();

  const dropdownOptions = props.situations.map(situation => ({
    value: situation.id,
    text: t('edit_personnel_tab_template_situation_options_' + situation.id.toLocaleLowerCase()),
  }));

  const [selectedSituation, setSelectedSituation] = useState(dropdownOptions.find(option => option.value === props.draft.mobiscanSituation));
  const [validationResult, setValidationResult] = useState<ValidationResult | undefined>();

  const saveSituation = useCallback(async () => {
    const clientSideValidation = validation.validatePersonnelSituation(t, selectedSituation?.value);
    if (!clientSideValidation.isValid) {
      setValidationResult(clientSideValidation);
      return;
    }

    await mobiscanDraftServices.saveSituation(props.draft.id, selectedSituation!.value);
    props.onSaveSuccess();
  }, [props, selectedSituation, t]);

  const saveFetch = useFetch<void>(saveSituation, false);

  const onSavePressed = () => {
    saveFetch.executeFetch();
  };

  return <>
    <div className={ styles.uploadDropdown }>
      <Dropdown
        identifier="activity"
        options={ dropdownOptions }
        selectedOption={ selectedSituation }
        placeholderText={ t('edit_personnel_tab_template_situation_options_placeholder') }
        label={ t('edit_personnel_tab_template_situation') }
        onChange={ (value) => setSelectedSituation(dropdownOptions.find(option => option.value === value)!) }
        disabled={ props.disabled || saveFetch.fetchStatus === FetchStatus.pending }
        required={ true }
        hideAsterisk={ true }
      />
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        saveFetch.fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
      }

      {
        validationResult && !validationResult.isValid && <ValidationErrorBox messages={ validationResult.messages } />
      }

    </div>

    <div className={ styles.uploadSubmit }>
      {
        saveFetch.fetchStatus === FetchStatus.pending
          ? <LoadingIndicator />
          : <button className="btn-primary" onClick={ onSavePressed } disabled={ props.disabled }>{ t('edit_personnel_tab_template_submit') }</button>
      }
    </div>
  </>;
}
