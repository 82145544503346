import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { CommuteByTransportModes, Mobiscan, ModalSplitBenchmark } from "features/mobiscan/report/domain/models";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { ModalSplitChart } from "features/mobiscan/report/ui/tabs/charts/ModalSplitChart";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ModalSplitTab.module.scss";

interface ModalSplitTabProps {
  mobiscan: Mobiscan,
}

export function ModalSplitTab(props: ModalSplitTabProps) {
  const { t } = useTranslation();

  const fetchCommuteByTransportModes = useCallback(async () => {
    return await reportServices.getCommuteByTransportModes(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<CommuteByTransportModes>(fetchCommuteByTransportModes);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const commuteByTransportModes = fetchResult!;

    return <TabContent mobiscan={ props.mobiscan } commuteByTransportModes={ commuteByTransportModes } />
  }
  return <LoadingIndicator />
}


interface BenchmarkOption extends DropdownOption {
  benchmark: ModalSplitBenchmark,
}

interface TabContentProps {
  mobiscan: Mobiscan,
  commuteByTransportModes: CommuteByTransportModes,
}

function TabContent(props: TabContentProps) {
  const { t } = useTranslation();

  const dropdownOptions: BenchmarkOption[] = props.commuteByTransportModes.modalSplitBenchmarks.map((benchmark, i) => ({
    value: `benchmark-${i}`,
    text: benchmark.name,
    benchmark,
  }));

  const [selectedOption, setSelectedOption] = useState<BenchmarkOption>(dropdownOptions[0]);

  const onDropdownValueChanged = (value?: string) => {
    const option = dropdownOptions.find((option) => option.value === value);
    setSelectedOption(option!);
  };

  return <article className={ styles.container }>
    <header className={ styles.header }>
      <div className={ styles.headerInner }>

        <div className={ styles.headerTitleBlock }>
          <h2 className={ styles.headerTitle }>
            { t('report_tab_mobility_tab_modal_split_title', { establishmentName: props.mobiscan.establishment.name }) }
          </h2>
        </div>

        <div className={ styles.headerActionsBlock }>
          <Dropdown
            identifier="modal-split-compare"
            options={ dropdownOptions }
            label={ t('report_tab_mobility_tab_modal_split_compare_dropdown_label') }
            labelHidden={ true }
            onChange={ onDropdownValueChanged }
            selectedOption={ selectedOption }
          />
        </div>

      </div>
    </header>

    <div className={ styles.content }>
      <div className={ styles.contentInner }>
        <ModalSplitChart
          commuteByTransportModes={ props.commuteByTransportModes }
          establishment={ props.mobiscan.establishment }
          benchmarkToCompare={ selectedOption.benchmark }
        />
      </div>
    </div>
  </article>;
}
