import { ChartSettings } from "features/chart/domain/models";

const defaultLegendFont = {
  size: 16,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultTooltipTitleFont = {
  size: 12,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultTooltipBodyFont = {
  size: 12,
  lineHeight: 1.5,
  weight: 'normal',
  family: 'Montserrat',
};

const defaultTooltipFooterFont = {
  size: 12,
  lineHeight: 1.5,
  weight: 'normal',
  family: 'Montserrat',
};

const defaultXAxisTickFont = {
  size: 16,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultXAxisLabelFont = {
  size: 24,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultYAxisTickFont = {
  size: 16,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultYAxisLabelFont = {
  size: 24,
  lineHeight: 1.5,
  weight: '600',
  family: 'Montserrat',
};

const defaultChartSettings: ChartSettings = {
  displayLegend: true,
  legendFont: defaultLegendFont,
  legendLabelColor: '#000000',

  tooltipBackgroundColor: '#ffffff',
  tooltipBorderColor: '#20b0e4',

  tooltipTitleColor: '#14315C',
  tooltipTitleFont: defaultTooltipTitleFont,

  tooltipBodyColor: '#16779c',
  tooltipBodyFont: defaultTooltipBodyFont,

  tooltipFooterColor: '#14315C',
  tooltipFooterFont: defaultTooltipFooterFont,

  xAxisTickRotation: 45,
  xAxisTickColor: '#14315C',
  xAxisTickFont: defaultXAxisTickFont,

  xAxisLabelColor: '#14315C',
  xAxisLabelFont: defaultXAxisLabelFont,

  yAxisTickColor: '#14315C',
  yAxisTickFont: defaultYAxisTickFont,

  yAxisLabelColor: '#14315C',
  yAxisLabelFont: defaultYAxisLabelFont,
};

export const arrowAxisPlugin = {
  id: 'arrowAxisPlugin',
  afterDatasetsDraw: (chart: any, args: any, pluginOptions: any) => {
    const {
      ctx,
      chartArea: {
        top,
        bottom,
        left,
        right,
      }
    } = chart;


    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = "#14315C";

    ctx.moveTo(left - 1, top + 3);
    ctx.lineTo(left + 5, top + 10);
    ctx.moveTo(left + 1, top + 3);
    ctx.lineTo(left - 5, top + 10);
    ctx.moveTo(left, top + 5);
    ctx.lineTo(left, bottom);
    ctx.lineTo(right - 5, bottom);
    ctx.moveTo(right - 3, bottom + 1)
    ctx.lineTo(right - 10, bottom - 5);
    ctx.moveTo(right - 3, bottom - 1);
    ctx.lineTo(right - 10, bottom + 5);
    ctx.stroke();
    ctx.closePath();
  }
};


export function createChartOptions(overrideDefaults?: ChartSettings) {
  const options = { ...defaultChartSettings, ...overrideDefaults };

  return {
    responsive: true,
    plugins: {
      arrowAxisPlugin: options.showArrowAxis === true,
      legend: {
        display: options.displayLegend,
        position: 'bottom' as const,
        labels: {
          boxWidth: 30, // 26
          boxHeight: 10, // 7
          color: options.legendLabelColor,
          font: options.legendFont,
        },
      },
      tooltip: {
        backgroundColor: options.tooltipBackgroundColor,
        padding: 14,
        cornerRadius: 10,
        // no boxshadow possible by default on tooltip
        // extra plugin is available but is not compatible with the react version
        // https://github.com/chartjs/Chart.js/issues/5768
        // so for now..
        // borderColor: '#dddddd',
        // borderColor: '#14315C',
        borderColor: options.tooltipBorderColor,
        borderWidth: 1,

        titleColor: options.tooltipTitleColor,
        titleFont: options.tooltipTitleFont,

        bodyColor: options.tooltipBodyColor,
        bodyFont: options.tooltipBodyFont,

        footerColor: options.tooltipFooterColor,
        footerFont: options.tooltipFooterFont,


        displayColors: false,
        callbacks: {
          title: options.getPopupTitleCallback,
          label: options.getPopupBodyCallback,
          footer: options.getPopupFooterCallback,
        }
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: options.showVerticalGrid,
          drawBorder: false,
        },
        title: {
          display: options.yAxisLabelText != null,
          text: options.xAxisLabelText,
          color: options.xAxisLabelColor,
          font: options.xAxisLabelFont,
        },
        ticks: {
          display: !options.xAxisTicksHidden,
          minRotation: options.xAxisTickRotation,
          maxRotation: options.xAxisTickRotation,
          color: options.xAxisTickColor,
          font: options.xAxisTickFont
        },
        max: options.xAxisMaxValue,
        beginAtZero: options.xAxisBeginAtZero,
      },
      yAxes: {
        grid: {
          display: options.showHorizontalGrid,
          borderColor: '#20B0E4',
          borderWidth: 2,
          tickColor: 'transparent',
          color: 'rgba(32, 176, 228, 0.44)',
          borderDash: [8, 6],
          drawBorder: false
        },
        title: {
          display: options.yAxisLabelText != null,
          text: options.yAxisLabelText,
          color: options.yAxisLabelColor,
          font: options.yAxisLabelFont,
        },
        ticks: {
          display: !options.yAxisTicksHidden,
          precision: 0,
          color: options.yAxisTickColor,
          font: options.yAxisTickFont,
        },
        max: options.yAxisMaxValue,
        beginAtZero: options.yAxisBeginAtZero,
      },
    },
  };
}
