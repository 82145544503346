import { Establishment } from "features/enterprise/domain/models";

export interface MobiscanOverviewItem {
  id: string,
  establishment: Establishment,
  startedBy: MobiscanUser,
  startedDate: Date,
  modifiedDate: Date,
  status: MobiscanStatus,
}

export interface DraftMobiscanOverviewItem extends MobiscanOverviewItem { }

export interface InProgressMobiscanOverviewItem extends MobiscanOverviewItem {
  requestedBy: MobiscanUser,
  requestedDate: Date
}

export interface CompletedMobiscanOverviewItem extends MobiscanOverviewItem {
  requestedBy: MobiscanUser,
  requestedDate: Date,
  durableActualPercentage: number,
  durablePotentialPercentage: number,
}

export enum MobiscanStatus {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETED',
}

export interface MobiscanUser {
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
}