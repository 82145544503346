import { UpdatePasswordRequest, UpdatePasswordResponse, UpdateUserRequest, UpdateUserResponse } from "features/user/data/models";
import { UserApi } from "features/user/data/UserApi";
export class UserMockApi implements UserApi {

  async updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResponse> {
    await new Promise(r => setTimeout(r, 2000));
    if (updateUserRequest.firstName === 'mock-error') {
      return {
        success: false,
        validationCodes: ['EMAIL_UNAVAILABLE'],
      };
    } else if (updateUserRequest.firstName === 'mock-error2') {
      throw new Error('mock error');
    } else {
      return {
        success: true,
        validationCodes: [],
      };
    }
  }

  async updatePassword(updatePasswordRequest: UpdatePasswordRequest): Promise<UpdatePasswordResponse> {
    await new Promise(r => setTimeout(r, 2000));
    if (updatePasswordRequest.password === 'mock-error') {
      throw new Error('mock error');
    } else if (updatePasswordRequest.password !== 'duo') {
      return {
        success: false,
        validationCodes: ['CURRENT_PASSWORD_INCORRECT'],
      };
    } else {
      return {
        success: true,
        validationCodes: [],
      };
    }
  }

}