import styles from './EstablishmentMapIcon.module.scss';

interface EstablishmentMapIconProps {
  label: string;
}

export function EstablishmentMapIcon(props: EstablishmentMapIconProps) {
  return <div className={ styles.MapMarker }>
    <span className="visually-hidden">{ props.label }</span>
  </div>;
}
