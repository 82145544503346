import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapUpdatePasswordSuccessResponse, mapUpdatePasswordValidationErrors, mapUpdateUserSuccessResponse, mapUpdateUserValidationErrors } from "features/user/data/mappers";
import { UpdatePasswordRequest, UpdatePasswordResponse, UpdateUserRequest, UpdateUserResponse } from "features/user/data/models";

export interface UserApi {
  updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResponse>,
  updatePassword(updatePasswordRequest: UpdatePasswordRequest): Promise<UpdatePasswordResponse>,
}

export class UserApiImpl implements UserApi {

  //TODO: moet nog getest worden
  async updateUser(updateUserRequest: UpdateUserRequest): Promise<UpdateUserResponse> {
    const url = AppConfig.API_URL + '/user';
    const payload = JSON.stringify(updateUserRequest);
    const options = { method: 'PUT', body: payload, };
    const response = await httpClient.authorizedFetch(url, options);


    if (response.status === 200) {
      return mapUpdateUserSuccessResponse();
    } else if (response.status === 400) {
      const json = await response.json();
      return mapUpdateUserValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

  //TODO: moet nog getest worden
  async updatePassword(updatePasswordRequest: UpdatePasswordRequest): Promise<UpdatePasswordResponse> {
    const url = AppConfig.API_URL + '/changepassword';
    const payload = JSON.stringify(updatePasswordRequest);
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.authorizedFetch(url, options);


    if (response.status === 200) {
      return mapUpdatePasswordSuccessResponse();
    } else if (response.status === 400) {
      const json = await response.json();
      return mapUpdatePasswordValidationErrors(json);
    }

    throw new ApiException(response.status);
  }

}