import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { MessageBox } from "features/common/ui/boxes/MessageBox";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import { EnterpriseTile } from "features/enterprise/ui/EnterpriseTile";
import { EstablishmentDropdown, EstablishmentOption, establishmentToOption } from "features/enterprise/ui/EstablishmentDropdown";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAppSelector } from "redux/store";
import { ReactComponent as IconLocation } from 'assets/inline-svg/icons/icon__location.svg';
import styles from './EstablishmentPage.module.scss';
import { getEstablishments } from "features/enterprise/domain/enterpriseServices";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { useCallback } from "react";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { Establishment } from "features/enterprise/domain/models";
import * as routes from "routes";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { Enterprise } from "features/auth/domain/models";
import { ConfirmDeleteModal } from "features/common/ui/ConfirmDeleteModal";

export function EstablishmentsPage() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);
  const navigate = useNavigate();


  if (user.enterprise == null) {
    //TODO: or throw error?
    navigate(routes.HOME);
  }

  const fetchEstablishments = useCallback(async () => {
    return getEstablishments(user.enterprise!.cbeNumber);
  }, [user.enterprise]);

  const { fetchStatus, fetchResult, executeFetch } = useFetch<Establishment[]>(fetchEstablishments);

  return <ScopeGate requiredScope={ Scope.MANAGE_ESTABLISHMENTS }>
    <Layout>
      <PageIntro>

        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('establishments_header_back') } targetUrl={ routes.HOME } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('establishments_header_title') }</h1>
        </PageIntroItem>

        <PageIntroItem position={ IntroPosition.right }>
          <EnterpriseTile enterprise={ user.enterprise! } />
        </PageIntroItem>

      </PageIntro>

      <div role="alert"> {/* "alert" content will be read out */ }
        {
          fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('establishments_unknown_error') } />
        }
      </div>

      {
        fetchStatus === FetchStatus.pending && <LoadingIndicator />
      }

      {
        fetchStatus === FetchStatus.success &&
        <div className="content-wrapper">
          <EstablishmentsPageContent
            establishments={ fetchResult! }
            enterprise={ user.enterprise! }
            onRefreshEstablishmentsRequested={ executeFetch }
          />
        </div>
      }
    </Layout>
  </ScopeGate>;
}


interface EstablishmentsPageContentProps {
  enterprise: Enterprise,
  establishments: Establishment[],
  onRefreshEstablishmentsRequested?: () => void,
}

function EstablishmentsPageContent(props: EstablishmentsPageContentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firstEstablishment = props.establishments.length > 0 ? props.establishments[0] : null;
  const [selectedEstablishment, setSelectedEstablishment] = useState<Establishment | null>(firstEstablishment);

  const onAddClicked = () => {
    navigate(routes.ESTABLISHMENTS_ADD);
  };

  const optionToEstablishment = (option: EstablishmentOption): Establishment => {
    return option.establishment as Establishment;
  }

  return <>
    <div className={ styles.establishmentNav }>
      {
        props.establishments.length === 0
          ? <div></div>
          : <div className={ styles.establishmentNavSelectBlock }>
            <EstablishmentDropdown
              value={ establishmentToOption(selectedEstablishment!) }
              options={ props.establishments.map(establishment => establishmentToOption(establishment)) }
              disabledOptions={ [] }
              onValueChanged={ (option) => setSelectedEstablishment(optionToEstablishment(option)) }
            />
          </div>
      }
      <div className={ styles.establishmentNavActionsBlock }>
        <button type="button" className="btn-secondary btn-secondary--add" onClick={ onAddClicked }>
          <span>{ t('establishments_add_establishment') }</span>
        </button>
      </div>
    </div>

    {
      props.establishments.length === 0
        ? <MessageBox message={ t('establishments_empty') } />
        :
        <div className={ styles.establishment }>

          <div className={ styles.establishmentHeader }>
            <h2 className={ styles.establishmentTitle }>{ selectedEstablishment!.name }</h2>
            <DeleteEstablishmentButton
              enterprise={ props.enterprise }
              establishment={ selectedEstablishment! }
              onDeleteSuccess={ props.onRefreshEstablishmentsRequested }
            />
          </div>

          <div className={ styles.establishmentData }>
            <div className={ styles.establishmentDataMain }>
              <EstablishmentTile establishment={ selectedEstablishment! } />
            </div>
            <div className={ styles.establishmentDataAdditional }>
              <EstablishmentInfo establishment={ selectedEstablishment! } />
            </div>
          </div>

        </div>
    }
  </>;
}


interface DeleteEstablishmentButtonProps {
  enterprise: Enterprise,
  establishment: Establishment,
  onDeleteSuccess?: () => any,
}

function DeleteEstablishmentButton(props: DeleteEstablishmentButtonProps) {
  const { t } = useTranslation();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const deleteEstablishment = useCallback(async () => {
    await enterpriseServices.deleteEstablishment(props.enterprise.cbeNumber, props.establishment.id);
    if (props.onDeleteSuccess) {
      props.onDeleteSuccess();
    }
  }, [props]);

  const { executeFetch, fetchStatus } = useFetch<void>(deleteEstablishment, false);

  const onDeleteClicked = () => {
    setConfirmModalVisible(true);
  };

  const onAccept = async () => {
    setConfirmModalVisible(false)
    await executeFetch();
  };

  return <>
    <ConfirmDeleteModal
      isOpen={ confirmModalVisible }
      onAccept={ onAccept }
      onCancel={ () => setConfirmModalVisible(false) }
    />
    {
      fetchStatus === FetchStatus.unknownError &&
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    }
    {
      fetchStatus === FetchStatus.pending
        ? <LoadingIndicator />
        : <button className={ styles.establishmentRemoveButton } onClick={ onDeleteClicked }>{ t('establishments_delete_establishment') }</button>
    }
  </>;
}


interface EstablishmentTileProps {
  establishment: Establishment,
}

function EstablishmentTile(props: EstablishmentTileProps) {
  const { t } = useTranslation();

  return <div className={ styles.tile }>

    <div className={ styles.tileInner }>
      <div className={ styles.tileInnerIcon }>
        <IconLocation />
      </div>
      <div className={ styles.tileInnerContent }>
        <address className={ styles.tileAddress }>
          <span className={ styles.tileAddressName }>{ props.establishment.name ?? t('establishment_name_unknown') }</span>
          <span className={ styles.tileAddressLine }>{ formatAddressLine1(props.establishment.address) }</span>
          <span className={ styles.tileAddressLine }>{ formatAddressLine2(props.establishment.address) }</span>
        </address>
      </div>
    </div>

    <div className={ styles.tileOuter }>
      <div className={ styles.tileOuterItem }>
        <span className={ styles.tileOuterItemLabel }>{ t('establishments_activity') }</span>
        {
          props.establishment.activity != null
            ? props.establishment.activity
            : t('establishments_activity_unknown')
        }
      </div>
    </div>

  </div>;
}


interface EstablishmentInfoProps {
  establishment: Establishment,
}

function EstablishmentInfo(props: EstablishmentInfoProps) {
  const { t } = useTranslation();

  return <div className={ styles.establishmentInfo }>
    <div className={ styles.establishmentInfoItem }>
      <div className={ styles.establishmentInfoItemLabel }>
        { t('establishments_cbe_number') }
      </div>
      <div className={ styles.establishmentInfoItemValue }>
        { props.establishment.cbeNumber }
      </div>
    </div>
  </div>;
}
