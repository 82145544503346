import { PendingEnterpriseDTO, PendingEstablishmentDTO, PendingRegistrationDTO, PendingUserDTO, RegisteredEnterpriseDTO, ProvinceUserDTO, CreateProvinceUserResponse, DeleteProvinceUserResponse, ProvinceMobiscanDTO } from "features/province/data/models";


export function mapPendingRegistrationListFromJsonList(jsonList: any[]): PendingRegistrationDTO[] {
  return jsonList.map((json) => mapPendingRegistrationFromJson(json));
}

function mapPendingRegistrationFromJson(json: any): PendingRegistrationDTO {
  return {
    date: json.date,
    enterprise: mapPendingEnterpriseFromJson(json.enterprise),
    user: mapPendingUserFromJson(json.user),
    establishments: mapPendingEstablishmentListFromJsonList(json.establishments),
  }
}

function mapPendingEnterpriseFromJson(json: any): PendingEnterpriseDTO {
  return {
    name: json.name,
    cbeNumber: json.cbeNumber,
  }
}

function mapPendingUserFromJson(json: any): PendingUserDTO {
  return {
    firstName: json.firstName,
    lastName: json.lastName,
    phone: json.phone,
    email: json.email,
  }
}

function mapPendingEstablishmentListFromJsonList(jsonList: any[]): PendingEstablishmentDTO[] {
  return jsonList.map(mapPendingEstablishmentFromJson);
}

function mapPendingEstablishmentFromJson(json: any): PendingEstablishmentDTO {
  return {
    name: json.name,
    cbeNumber: json.cbeNumber,
  }
}


export function mapRegisteredEnterpriseListFromJsonList(jsonList: any[]): RegisteredEnterpriseDTO[] {
  return jsonList.map(mapRegisteredEnterpriseFromJson);
}

function mapRegisteredEnterpriseFromJson(json: any): RegisteredEnterpriseDTO {
  return {
    name: json.name,
    cbeNumber: json.cbeNumber,
    registrationDate: json.registrationDate,
    mobiscans: json.mobiscans,
  }
}


export function mapProvinceMobiscanListFromJsonList(jsonList: any[]): ProvinceMobiscanDTO[] {
  return jsonList.map((json) => mapProvinceMobiscanFromJson(json));
}

function mapProvinceMobiscanFromJson(json: any): ProvinceMobiscanDTO {
  return {
    id: json.id,
    enterprise: json.enterprise,
    establishment: json.establishment,
    startedBy: json.startedBy,
    startedDateTime: json.startedDateTime,
    requestedBy: json.requestedBy,
    requestedDateTime: json.requestedDateTime,
    status: json.status
  }
}


export function mapProvinceUserListFromJsonList(jsonList: any[]): ProvinceUserDTO[] {
  return jsonList.map((json) => mapProvinceUserFromJson(json));
}

function mapProvinceUserFromJson(json: any): ProvinceUserDTO {
  return {
    id: json.id,
    firstName: json.firstName,
    lastName: json.lastName,
    jobTitle: json.jobTitle,
    phone: json.phone,
    email: json.email,
  }
}


export function mapCreateProvinceUserSuccessResponse(): CreateProvinceUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapCreateProvinceUserValidationErrors(json: any): CreateProvinceUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}


export function mapDeleteProvinceUserSuccessResponse(): DeleteProvinceUserResponse {
  return {
    success: true,
    validationCodes: [],
  }
}

export function mapDeleteProvinceUserValidationErrors(json: any): DeleteProvinceUserResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}