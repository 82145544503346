import { mobiscanDraftApi } from "features/common/data/repositories";
import { Establishment } from "features/enterprise/domain/models";
import { mapQuestionnaireFieldsToRequestDto, mapQuestionnairePageDataFromDTO, mapSectorToActivityDto } from "features/mobiscan/draft/domain/mappers";
import { PersonnelParseErrorCorrection, QuestionnaireField, QuestionnairePageData } from "features/mobiscan/draft/domain/models";
import { mapDraft, mapGetPersonnelResponse, mapUploadPersonnelResponse } from "features/mobiscan/draft/domain/mappers";
import { Draft, Personnel, UploadPersonnelResult } from "features/mobiscan/draft/domain/models";
import { mapPersonnelCorrectionToDto } from "features/mobiscan/draft/data/mappers";
import { Activity } from "features/mobiscan/general/domain/models";

export async function createDraft(establishment: Establishment): Promise<Draft> {
  const result = await mobiscanDraftApi.createDraft(establishment);
  return mapDraft(result);
}

export async function submitDraft(draftId: string): Promise<void> {
  await mobiscanDraftApi.submitDraft(draftId);
}

export async function deleteDraft(draftId: string): Promise<void> {
  await mobiscanDraftApi.deleteDraft(draftId);
}

export async function uploadPersonnelFile(draftId: string, file: File): Promise<UploadPersonnelResult> {
  const result = await mobiscanDraftApi.uploadPersonnelFile(draftId, file);
  return mapUploadPersonnelResponse(result);
}

export async function getQuestionnaireData(draftId: string, pageName: string): Promise<QuestionnairePageData> {
  const dto = await mobiscanDraftApi.fetchQuestionnairePage(draftId, pageName);

  return mapQuestionnairePageDataFromDTO(dto);
}

export async function updateQuestionnaireAnswers(draftId: string, page: string, section: string, fields: QuestionnaireField[]): Promise<void> {
  const requestDto = mapQuestionnaireFieldsToRequestDto(fields);
  await mobiscanDraftApi.updateQuestionnaireAnswers(draftId, page, section, requestDto);
}

export async function getPersonnel(draftId: string): Promise<Personnel> {
  const result = await mobiscanDraftApi.getPersonnel(draftId);
  return mapGetPersonnelResponse(result);
}

export async function getDraft(draftId: string): Promise<Draft> {
  const result = await mobiscanDraftApi.getDraft(draftId);
  return mapDraft(result);
}

export async function saveSituation(draftId: string, situation: string): Promise<void> {
  return await mobiscanDraftApi.saveSituation(draftId, situation);
}

export async function saveTimetableCorrections(draftId: string, corrections: PersonnelParseErrorCorrection[]): Promise<void> {
  const payload = mapPersonnelCorrectionToDto(corrections);
  return await mobiscanDraftApi.saveTimetableCorrections(draftId, payload);
}

export async function saveTransportationCorrections(draftId: string, corrections: PersonnelParseErrorCorrection[]): Promise<void> {
  const payload = mapPersonnelCorrectionToDto(corrections);
  return await mobiscanDraftApi.saveTransportationCorrections(draftId, payload);
}

export async function updateBenchmarkSector(draftId: string, activity: Activity): Promise<void> {
  const payload = mapSectorToActivityDto(activity);
  return await mobiscanDraftApi.updateBenchmarkSector(draftId, payload);
}
