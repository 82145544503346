export interface AuthStorage {
  getToken(): Promise<string | undefined>;
  persistToken(token: string): Promise<void>;
  deleteToken(): Promise<void>;
}

export class AuthStorageImpl implements AuthStorage {

  private static readonly KEY_AUTH_TOKEN = 'MOBIS_AUTH_TOKEN';

  async getToken(): Promise<string | undefined> {
    return localStorage.getItem(AuthStorageImpl.KEY_AUTH_TOKEN) ?? undefined;
  }

  async persistToken(token: string): Promise<void> {
    localStorage.setItem(AuthStorageImpl.KEY_AUTH_TOKEN, token);
  }

  async deleteToken(): Promise<void> {
    localStorage.removeItem(AuthStorageImpl.KEY_AUTH_TOKEN);
  }

}