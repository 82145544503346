import { ModalButton } from "features/common/ui/ModalButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconUser } from 'assets/inline-svg/icons/icon__user.svg';
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import styles from "./SummaryTab.module.scss";
import stylesIntro from "./SummaryTab.intro.module.scss";
import stylesPotential from "./SummaryTab.potential.module.scss";
import { Mobiscan, Summary, SummaryPotential } from "features/mobiscan/report/domain/models";
import { useCallback } from "react";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import { roundNumber } from "features/common/util/math";

interface SummaryTabProps {
  onGoToMeasuresClicked: () => void,
  mobiscan: Mobiscan
}

export function SummaryTab(props: SummaryTabProps) {
  const { t } = useTranslation();

  const fetchSummary = useCallback(async () => {
    return await reportServices.getSummary(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchResult, fetchStatus } = useFetch<Summary>(fetchSummary);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const summary = fetchResult!;

    return <>

      <Intro summary={ summary } mobiscan={ props.mobiscan } />
      <InfoBox />

      <PotentialContainer summary={ summary } />

      <section className={ styles.transporationActions }>
        <div className={ styles.transporationActionsInner }>
          <button className="btn-primary" onClick={ () => props.onGoToMeasuresClicked() }>{ t('report_tab_summary_go_to_measures_cta') }</button>
        </div>
      </section>

      <ProvinceComparisonsContainer summary={ summary } mobiscan={ props.mobiscan } />
    </>;
  }

  return <LoadingIndicator />;
}

interface IntroProps {
  summary: Summary,
  mobiscan: Mobiscan,
}

function Intro(props: IntroProps) {
  const { t } = useTranslation();

  const establishmentName = props.mobiscan.establishment.name;
  const personnelCount = props.summary.totalEmployees;
  const currentSustainable = roundNumber(props.summary.durable.current.percentage, 0);
  const potentialSustainable = roundNumber(props.summary.durable.potential.percentage, 0);

  return <>
    <section className={ stylesIntro.intro }>
      <div className={ stylesIntro.introInner }>

        <div className={ stylesIntro.textBlock }>
          <h2 className={ stylesIntro.title }>
            { t('report_tab_summary_title') }
          </h2>
          <div className={ stylesIntro.text }
            dangerouslySetInnerHTML={ { __html: t('report_tab_summary_summary', { establishmentName, personnelCount, currentSustainable, potentialSustainable }) } }>
          </div>
        </div>

        <div className={ stylesIntro.graphicBlock }>
          <PotentialGrowthVisualisation
            potential={ props.summary.durable }
          />
        </div>

      </div>
    </section>
  </>;
}

function InfoBox() {
  const { t } = useTranslation();
  return <>
    <section className={ styles.infoBox }>
      <div className={ styles.infoBoxInner }>
        <div className={ styles.infoBoxContainer }>
          <div className={ styles.infoBoxIcon }>
            <IconInfo />
          </div>
          <div className={ styles.infoBoxContent }>
            <p dangerouslySetInnerHTML={ { __html: t('report_tab_summary_info') } }></p>
            <ModalButton targetUrl={ TreffikUrls.potentialCalculationInfoUrl }>
              { t('report_tab_summary_info_more_info') }
            </ModalButton>
          </div>
        </div>
      </div>
    </section>
  </>;
}

interface PotentialGrowthVisualisationProps {
  potential: SummaryPotential,
}

function PotentialGrowthVisualisation(props: PotentialGrowthVisualisationProps) {
  const { t } = useTranslation();

  const currentPercent = roundNumber(props.potential.current.percentage, 0);
  const currentPotentialCount = props.potential.current.count;

  const potentialPercent = roundNumber(props.potential.potential.percentage, 0);
  const potentialCount = props.potential.potential.count;

  return <div>
    <div className={ stylesIntro.itemSummary }>
      <div className={ stylesIntro.itemSummaryBlock }>
        <div className={ stylesIntro.itemSummaryCircle } data-type="current">
          <div className={ stylesIntro.itemSummaryCircleBackground }></div>
          <div className={ stylesIntro.itemSummaryCircleContent }>
            <div className={ stylesIntro.itemSummaryCircleValue }>{ currentPercent }<span>%</span></div>
            <div className={ stylesIntro.itemSummaryCircleDescription }><span>{ currentPotentialCount }</span><IconUser /></div>
          </div>
        </div>
        <div className={ stylesIntro.itemSummaryContent }>{ t('report_tab_summary_current_sustainable') }</div>
      </div>
      <div className={ stylesIntro.itemSummaryBlock }>
        <div className={ stylesIntro.itemSummaryCircle } data-type="potential">
          <div className={ stylesIntro.itemSummaryCircleBackground }></div>
          <div className={ stylesIntro.itemSummaryCircleArrow }><IconArrow /></div>
          <div className={ stylesIntro.itemSummaryCircleContent }>
            <div className={ stylesIntro.itemSummaryCircleValue }>{ potentialPercent }<span>%</span></div>
            <div className={ stylesIntro.itemSummaryCircleDescription }><span>{ potentialCount }</span><IconUser /></div>
          </div>
        </div>
        <div className={ stylesIntro.itemSummaryContent }>{ t('report_tab_summary_potential_sustainable') }</div>
      </div>
    </div>
  </div>;
}


interface TransportPotentialProps {
  imageUrl: string,
  imageWidth: number,
  imageHeight: number,
  transportName: string,
  potential: SummaryPotential,
  isVertical: boolean,
  label: string,
}

function TransportPotential(props: TransportPotentialProps) {

  return <article className={ stylesPotential.potentialBlock } data-vertical={ props.isVertical }>
    <div className={ stylesPotential.potentialBlockInner }>
      <div className={ stylesPotential.potentialBlockGraphic }>
        <div className={ stylesPotential.potentialBlockGraphicImageHolder }>
          <img src={ props.imageUrl } alt={ props.transportName } width={ props.imageWidth } height={ props.imageHeight } />
        </div>
      </div>
      <div className={ stylesPotential.transportationContent }>
        <h3 className={ stylesPotential.transportationTitle }>{ props.transportName }</h3>
        <div className={ stylesPotential.transportationText } dangerouslySetInnerHTML={ { __html: props.label } }></div>
      </div>
      <div className={ stylesPotential.transportationVisualisationHolder }>
        <TransportPotentialVisualisation
          isVertical={ props.isVertical }
          potential={ props.potential }
        />
      </div>
    </div>
  </article>;
}


interface TransportPotentialVisualisationProps {
  isVertical: boolean,
  potential: SummaryPotential,
}

function TransportPotentialVisualisation(props: TransportPotentialVisualisationProps) {

  const currentPercent = roundNumber(props.potential.current.percentage, 0);
  const currentPotentialCount = props.potential.current.count;

  const potentialPercent = roundNumber(props.potential.potential.percentage, 0);
  const potentialCount = props.potential.potential.count;

  return <div className={ props.isVertical ? stylesPotential.summaryVertical : stylesPotential.summaryHorizontal }>
    <div className={ props.isVertical ? stylesPotential.summaryVerticalBlock : stylesPotential.summaryHorizontalBlock }>
      <div className={ props.isVertical ? stylesPotential.summaryVerticalCircle : stylesPotential.summaryHorizontalCircle } data-type="current">
        <div className={ props.isVertical ? stylesPotential.summaryVerticalCircleBackground : stylesPotential.summaryHorizontalCircleBackground }></div>
        <div className={ props.isVertical ? stylesPotential.summaryVerticalCircleContent : stylesPotential.summaryHorizontalCircleContent }>
          <p className={ props.isVertical ? stylesPotential.summaryVerticalCircleValue : stylesPotential.summaryHorizontalCircleValue }>{ currentPercent }<span>%</span></p>
          <p className={ props.isVertical ? stylesPotential.summaryVerticalCircleDescription : stylesPotential.summaryHorizontalCircleDescription }><span>{ currentPotentialCount }</span><IconUser /></p>
        </div>
      </div>
    </div>
    <div className={ props.isVertical ? stylesPotential.summaryVerticalBlock : stylesPotential.summaryHorizontalBlock }>
      <div className={ props.isVertical ? stylesPotential.summaryVerticalCircle : stylesPotential.summaryHorizontalCircle } data-type="potential">
        <div className={ props.isVertical ? stylesPotential.summaryVerticalCircleBackground : stylesPotential.summaryHorizontalCircleBackground }></div>
        <div className={ props.isVertical ? stylesPotential.summaryVerticalCircleContent : stylesPotential.summaryHorizontalCircleContent }>
          <p className={ props.isVertical ? stylesPotential.summaryVerticalCircleValue : stylesPotential.summaryHorizontalCircleValue }>{ potentialPercent }<span>%</span></p>
          <p className={ props.isVertical ? stylesPotential.summaryVerticalCircleDescription : stylesPotential.summaryHorizontalCircleDescription }><span>{ potentialCount }</span><IconUser /></p>
        </div>
      </div>
    </div>
  </div>;
}


interface PotentialContainerProps {
  summary: Summary,
}

function PotentialContainer(props: PotentialContainerProps) {
  const { t } = useTranslation();

  const createGrowthLabel = (summaryPotential: SummaryPotential, transportName: string) => {
    const currentAmount = summaryPotential.current.count;
    const potentialAmount = summaryPotential.potential.count;

    if (potentialAmount > currentAmount) {
      return t('report_tab_summary_transport_growth_potential', { currentAmount, potentialAmount, transportName: transportName.toLowerCase() });
    }
    return t('report_tab_summary_transport_growth_no_potential', { transportName: transportName.toLowerCase() });
  };

  const createDeclineLabel = (summaryPotential: SummaryPotential, transportName: string) => {
    const currentAmount = summaryPotential.current.count;
    const potentialAmount = summaryPotential.potential.count;

    if (potentialAmount < currentAmount) {
      return t('report_tab_summary_transport_decline_potential', { currentAmount, potentialAmount, transportName: transportName.toLowerCase() });
    }
    return t('report_tab_summary_transport_decline_no_potential', { transportName: transportName.toLowerCase() });
  };

  const activeTransportLabel = createGrowthLabel(props.summary.active, t('report_tab_summary_transport_active'));
  const collectiveTransportLabel = createGrowthLabel(props.summary.collective, t('report_tab_summary_transport_collective'));
  const carpoolTransportLabel = createGrowthLabel(props.summary.carpool, t('report_tab_summary_transport_carpool'));
  const motorizedTransportLabel = createDeclineLabel(props.summary.motorizedTransport, t('report_tab_summary_transport_motorized'));

  return <>
    <section className={ styles.transportation }>
      <div className={ styles.transportationInner }>
        <div className={ styles.transportationTypes }>
          <div className={ styles.transportationType }>
            <TransportPotential
              imageUrl="/images/icons/icon__active-transport.svg"
              imageWidth={ 96 }
              imageHeight={ 60 }
              transportName={ t('report_tab_summary_transport_active') }
              potential={ props.summary.active }
              isVertical={ true }
              label={ activeTransportLabel }
            />
          </div>
          <div className={ styles.transportationType }>
            <TransportPotential
              imageUrl="/images/icons/icon__collective-transport.svg"
              imageWidth={ 100 }
              imageHeight={ 60 }
              transportName={ t('report_tab_summary_transport_collective') }
              potential={ props.summary.collective }
              isVertical={ true }
              label={ collectiveTransportLabel }
            />
          </div>
          <div className={ styles.transportationType }>
            <TransportPotential
              imageUrl="/images/icons/icon__carpool.svg"
              imageWidth={ 100 }
              imageHeight={ 60 }
              transportName={ t('report_tab_summary_transport_carpool') }
              potential={ props.summary.carpool }
              isVertical={ true }
              label={ carpoolTransportLabel }
            />
          </div>
        </div>
      </div>
    </section>

    <section className={ styles.transportationTypeMotorized }>
      <div className={ styles.transportationTypeMotorizedInner }>
        <TransportPotential
          imageUrl="/images/icons/icon__motorized-transport.svg"
          imageWidth={ 220 }
          imageHeight={ 90 }
          transportName={ t('report_tab_summary_transport_motorized') }
          potential={ props.summary.motorizedTransport }
          isVertical={ false }
          label={ motorizedTransportLabel }
        />
      </div>
    </section>
  </>;
}

interface ProvinceComparisonsContainerProps {
  summary: Summary,
  mobiscan: Mobiscan,
}

function ProvinceComparisonsContainer(props: ProvinceComparisonsContainerProps) {
  const { t } = useTranslation();
  const establishmentName = props.mobiscan.establishment.name;
  //TODO: this looks messy; maybe pass a domain model with all the data that's needed for <ProvinceComparison/>
  // Then make an array of these objects and map them to <ProvinceComparison/>
  return <div className={ styles.compareList }>
    <div className={ styles.compareListInner }>
      <div className={ styles.compareListItems }>
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_commuting') }
          info={ t('report_tab_summary_comparison_commuting_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.commuteDistance.maxValue }
          establishmentBarCurrentValue={ props.summary.commuteDistance.actual }
          provinceBarCurrentValue={ props.summary.commuteDistance.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_km', { value }) }
        />
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_travel_time') }
          info={ t('report_tab_summary_comparison_travel_time_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.commuteDuration.maxValue }
          establishmentBarCurrentValue={ props.summary.commuteDuration.actual }
          provinceBarCurrentValue={ props.summary.commuteDuration.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_min', { value }) }
        />
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_sustainability') }
          info={ t('report_tab_summary_comparison_sustainability_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.percentageDurable.maxValue }
          establishmentBarCurrentValue={ props.summary.percentageDurable.actual }
          provinceBarCurrentValue={ props.summary.percentageDurable.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_percentage', { value }) }
        />
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_emissions') }
          info={ t('report_tab_summary_comparison_emissions_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.carbonDioxide.maxValue }
          establishmentBarCurrentValue={ props.summary.carbonDioxide.actual }
          provinceBarCurrentValue={ props.summary.carbonDioxide.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_g', { value }) }
        />
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_bike_spots') }
          info={ t('report_tab_summary_comparison_bike_spots_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.bicycleParkingSpotsPercentage.maxValue }
          establishmentBarCurrentValue={ props.summary.bicycleParkingSpotsPercentage.actual }
          provinceBarCurrentValue={ props.summary.bicycleParkingSpotsPercentage.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_percentage', { value }) }
        />
        <ProvinceComparison
          title={ t('report_tab_summary_comparison_car_spots') }
          info={ t('report_tab_summary_comparison_car_spots_info') }
          establishmentName={ establishmentName }
          provinceName={ t('province_west_flanders') }
          maxValue={ props.summary.carParkingSpotsPercentage.maxValue }
          establishmentBarCurrentValue={ props.summary.carParkingSpotsPercentage.actual }
          provinceBarCurrentValue={ props.summary.carParkingSpotsPercentage.benchmark }
          numberFormatter={ (value) => t('report_tab_summary_comparison_unit_percentage', { value }) }
        />
      </div>
    </div>
  </div>;
}

interface ProvinceComparisonProps {
  title: string,
  info: string,
  establishmentName: string,
  provinceName: string,

  maxValue: number,
  establishmentBarCurrentValue: number,
  provinceBarCurrentValue: number,
  numberFormatter: (number: number) => string, // Used to add the unit to the number. 5 => 5km
}

function ProvinceComparison(props: ProvinceComparisonProps) {

  return <div className={ styles.compareItem }>
    <div className={ styles.compareItemInner }>

      <h3 className={ styles.compareItemTitle } dangerouslySetInnerHTML={ { __html: props.title } }></h3>
      <div className={ styles.compareItemContent } dangerouslySetInnerHTML={ { __html: props.info } }></div>

      <div className={ styles.compareItemRanges }>
        <div className={ styles.compareItemRange }>
          <div className={ styles.compareItemRangeTitle }>{ props.establishmentName }</div>
          <ComparisonBar
            maxValue={ props.maxValue }
            currentValue={ props.establishmentBarCurrentValue }
            numberFormatter={ props.numberFormatter }
          />
        </div>
        <div className={ styles.compareItemRange }>
          <div className={ styles.compareItemRangeTitle }>{ props.provinceName }</div>
          <ComparisonBar
            maxValue={ props.maxValue }
            currentValue={ props.provinceBarCurrentValue }
            numberFormatter={ props.numberFormatter }
          />
        </div>
      </div>

    </div>
  </div>;
}

interface ComparisonBarProps {
  maxValue: number,
  currentValue: number,
  numberFormatter: (number: number) => string, // Used to add the unit to the number. 5 => 5km
}

function ComparisonBar(props: ComparisonBarProps) {
  const { t } = useTranslation();
  const numberFormatter = props.numberFormatter;

  const percentage = (100 / props.maxValue * props.currentValue) + '%';

  const position = {
    left: percentage,
  };

  const current = {
    width: percentage,
  };

  return <div className={ styles.rangeItem }>
    <div className={ styles.rangeItemMin }>
      { numberFormatter(0) }
      <span className="visually-hidden">{ t('province_range_minimum') }</span>
    </div>
    <div className={ styles.rangeItemCurrent }>
      <div className={ styles.rangeValue } style={ position }>
        { numberFormatter(props.currentValue) }
        <span className="visually-hidden">{ t('province_range_current') }</span>
      </div>
      <div className={ styles.rangeCurrent } style={ current }>
      </div>
      <div className={ styles.rangeMax }></div>
    </div>
    <div className={ styles.rangeItemMax }>
      { numberFormatter(props.maxValue) }
      <span className="visually-hidden">{ t('province_range_maximum') }</span>
    </div>
  </div>;
}
