import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapActivityDTOFromJson, mapScheduleDTOFromJson, mapSituationDTOFromJson, mapTransportModeDTOFromJson } from "features/mobiscan/general/data/mappers";
import { ActivityDTO, ScheduleDTO, SituationDTO, TransportModeDTO } from "features/mobiscan/general/data/models";


export interface MobiscanGeneralApi {
  fetchActivities(): Promise<ActivityDTO[]>;
  fetchSchedules(): Promise<ScheduleDTO[]>;
  fetchTransportModes(): Promise<TransportModeDTO[]>;
  fetchSituations(): Promise<SituationDTO[]>;
}

export class MobiscanGeneralApiImpl implements MobiscanGeneralApi {

  async fetchActivities(): Promise<ActivityDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan/activities';
    const response = await httpClient.shareTokenOrAuthorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return json.activities.map(mapActivityDTOFromJson);
    }

    throw new ApiException(response.status);
  }

  async fetchSchedules(): Promise<ScheduleDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan/schedules';
    const response = await httpClient.shareTokenOrAuthorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return json.schedules.map(mapScheduleDTOFromJson);
    }

    throw new ApiException(response.status);
  }

  async fetchTransportModes(): Promise<TransportModeDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan/transportmodes';
    const response = await httpClient.shareTokenOrAuthorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return json.transportModes.map(mapTransportModeDTOFromJson);
    }

    throw new ApiException(response.status);
  }

  async fetchSituations(): Promise<SituationDTO[]> {
    const url = AppConfig.API_URL + '/mobiscan/situations';
    const response = await httpClient.shareTokenOrAuthorizedFetch(url);

    if (response.status === 200) {
      const json = await response.json();
      return json.situations.map(mapSituationDTOFromJson);
    }

    throw new ApiException(response.status);
  }

}
