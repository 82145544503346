import { CorrectionTable } from "features/mobiscan/draft/ui/personnel/CorrectionTable";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import styles from "./MainTransport.module.scss";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { PersonnelParseError, PersonnelParseErrorCorrection } from "features/mobiscan/draft/domain/models";
import { useCallback, useEffect, useState } from "react";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { TransportMode } from "features/mobiscan/general/domain/models";


interface MainTransportTabProps {
  draftId: string,
  onPreviousPressed: () => void,
  onSaveFinished: () => void,
  parseErrors: PersonnelParseError[],
}

export function MainTransportTab(props: MainTransportTabProps) {
  const { t } = useTranslation();

  const fetchTransportModes = useCallback(async () => {
    return await mobiscanGeneralServices.getTransportModes();
  }, []);

  const { fetchStatus, fetchResult } = useFetch<TransportMode[]>(fetchTransportModes);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const transportModes = fetchResult!;

    return <MainTransportTabContent
      draftId={ props.draftId }
      onPreviousPressed={ props.onPreviousPressed }
      onSaveFinished={ props.onSaveFinished }
      parseErrors={ props.parseErrors }
      transportModes={ transportModes } />;
  }

  // pending
  return <LoadingIndicator />;
}


interface MainTransportTabContentProps {
  draftId: string,
  onPreviousPressed: () => void,
  onSaveFinished: () => void,
  parseErrors: PersonnelParseError[],
  transportModes: TransportMode[],
}

function MainTransportTabContent(props: MainTransportTabContentProps) {
  const { t } = useTranslation();

  const dropdownOptions = props.transportModes.map(transportMode => ({
    value: transportMode.id,
    text: t('transport_mode_' + transportMode.id.toLowerCase()),
  }));

  const [corrections, setCorrections] = useState(createDefaultCorrections(props.parseErrors, props.transportModes[0].id));

  useEffect(() => {
    const newCorrections = createDefaultCorrections(props.parseErrors, props.transportModes[0].id);
    setCorrections(newCorrections);
  }, [props.parseErrors, props.transportModes]);

  const saveCorrections = useCallback(async () => {
    await mobiscanDraftServices.saveTransportationCorrections(props.draftId, corrections);
    props.onSaveFinished();
  }, [corrections, props]);

  const { executeFetch, fetchStatus } = useFetch<void>(saveCorrections, false);

  const onCorrectionValueChanged = (changedCorrection: PersonnelParseErrorCorrection) => {
    const index = corrections.findIndex(correction => correction.parseError.id === changedCorrection.parseError.id);
    const newCorrections = [...corrections];
    newCorrections[index] = changedCorrection;
    setCorrections(newCorrections);
  };

  const infoBoxText = props.parseErrors && props.parseErrors.length > 0
    ? t('edit_personnel_tab_transport_info')
    : t('edit_personnel_tab_transport_success');

  return <div>
    <h2 className={ styles.maintransportTitle }>{ t('edit_personnel_tab_transport_title') }</h2>

    <div className={ styles.maintransportContent }>
      <IconInfo />
      <span>{ infoBoxText }</span>
    </div>

    <div className={ styles.maintransportOptions }>
      {
        props.parseErrors && props.parseErrors.length > 0 &&
        <CorrectionTable
          dropdownOptions={ dropdownOptions }
          corrections={ corrections }
          title={ t('edit_personnel_tab_transport_title') }
          onCorrectionValueChanged={ onCorrectionValueChanged }
        />
      }
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }
      {
        fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('general_unknown_error') } />
      }
    </div>

    <div className={ styles.maintransportActions }>
      {
        fetchStatus === FetchStatus.pending
          ? <LoadingIndicator />
          : <>
            <BackButton label={ t('edit_personnel_tab_transport_back') } targetUrl="#" onClick={ props.onPreviousPressed } displayType={ BackButtonType.inline } />
            <button className="btn-primary" onClick={ executeFetch }>{ t('edit_personnel_tab_transport_submit') }</button>
          </>
      }
    </div>
  </div >;
}


function createDefaultCorrections(parseErrors: PersonnelParseError[], defaultCorrectionValue: string): PersonnelParseErrorCorrection[] {
  return parseErrors.map(parseError => ({
    parseError: parseError,
    correctionValue: defaultCorrectionValue,
  }));
}
