import moment from 'moment';
import 'moment/locale/nl-be';

export function mapIso8601StringToDate(isoString: string): Date {
  return new Date(isoString);
}

export function formatDateToSlashString(date: Date): string {
  const dateTime = moment(date);

  return dateTime.format('DD/MM/YYYY');
}

export function formatDateToExpandedString(date: Date): string {
  const dateTime = moment(date);
  dateTime.locale('nl');

  return dateTime.format('DD MMMM ‘YY');
}
