import { ModalButton } from "features/common/ui/ModalButton";
import { RadioButton } from "features/common/ui/forms/RadioButton";
import { EstablishmentsSelector } from "features/enterprise/ui/EstablishmentsSelector";
import { setEnterpriseRegistrationInfo, setRegistrationUserInput } from "features/register/domain/redux";
import { EnterpriseTile } from "features/register/ui/EnterpriseTile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch, useAppSelector } from "redux/store";
import { MagdaEnterprise, MagdaEstablishment } from "features/magda/domain/models";
import styles from "./ConfirmEstablishment.module.scss";
import { register } from "features/register/domain/registrationOperations";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router";
import { cleanCbeNumber } from "features/magda/domain/util";
import { EstablishmentOption, magdaEstablishmentToOption } from "features/enterprise/ui/EstablishmentDropdown";
import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { RegisterForm } from "features/register/ui/cases/RegisterForm";
import * as validation from "features/register/domain/validation";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { FetchStatus } from "features/common/hooks/UseFetch";
import * as routes from "routes";
import { RegisterResult } from "features/register/domain/models";
import { TreffikUrls } from "features/i18n/TreffikUrls";

interface ConfirmEstablishmentProps {
  enterprise: MagdaEnterprise,
}

export function ConfirmEstablishment(props: ConfirmEstablishmentProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInput = useAppSelector((state: RootState) => state.registration.registrationUserInput);

  const resetEnterpriseInfo = () => {
    dispatch(setEnterpriseRegistrationInfo(undefined));
  };

  const executeRegisterCall = async () => {
    // Client side validate
    const validationResult = validation.validateRegisterForm(userInput, t);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const registerAction = await dispatch(register());
    const result = unwrapResult(registerAction);

    if (result.success) {
      navigate(routes.REGISTER_FINISHED);
      return {
        result,
      };
    } else {
      return {
        validationResult: result.validationResult,
      };
    }
  };

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<RegisterResult>(executeRegisterCall, false);


  return <>
    <div className="content-wrapper">
      <div className="inner-wrapper">

        <BackButton label={ t('register_confirm_back') } targetUrl={ routes.REGISTER } onClick={ resetEnterpriseInfo } displayType={ BackButtonType.text } />

        <div className="form form--confirm-establishment">
          <div className={ styles.content }>
            <Title establishments={ props.enterprise.establishments } />
            <EnterpriseTile enterpriseName={ props.enterprise.name } enterpriseCbeNumber={ props.enterprise.cbeNumber } />
            {
              props.enterprise.establishments.length > 1 && <>
                <EstablishmentsSelectorContainer establishments={ props.enterprise.establishments } />
              </>
            }
          </div>


          {
            props.enterprise.establishments.length > 1 && <>
              <div className={ styles.cluster }>
                <MessageBox
                  icon={ MessageBoxIcon.info }
                  message={ <div>
                    <ul>
                      <li>{ t('register_confirm_multiple_establishments_cluster_info_1') }</li>
                      <li>{ t('register_confirm_multiple_establishments_cluster_info_2') }</li>
                    </ul>
                  </div> }
                  actions={
                    <ModalButton
                      targetUrl={ TreffikUrls.contactUrl }
                      iframeTitle={ t('register_confirm_multiple_establishments_cluster_a11y_iframe_title') }
                      modalContentLabel={ t('register_confirm_multiple_establishments_cluster_a11y_modal_content_label') }>
                      { t('register_confirm_multiple_establishments_cluster_button') }
                    </ModalButton>
                  } />
              </div>
            </>
          }

          <RegisterForm
            submitLabel={ t('register_confirm_confirm_button') }
            onSubmit={ executeFetch }
            isLoading={ fetchStatus === FetchStatus.pending }
            hasUnknownError={ fetchStatus === FetchStatus.unknownError }
            validationResult={ validationResult }
          />
        </div>
      </div>
    </div>
  </>;
}


interface TitleProps {
  establishments: MagdaEstablishment[],
}

function Title(props: TitleProps) {
  const { t } = useTranslation();

  const title = props.establishments.length > 1
    ? t('register_confirm_multiple_establishments_title')
    : t('register_confirm_one_establishment_title');

  return <h2 className={ styles.title }>{ title }</h2>;
}


interface EstablishmentsSelectorContainerProps {
  establishments: MagdaEstablishment[],
}

function EstablishmentsSelectorContainer(props: EstablishmentsSelectorContainerProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userInput = useAppSelector((state: RootState) => state.registration.registrationUserInput);

  const [addAllSelected, setAddAllSelected] = useState(true);

  // Check if the component is loaded with 1 selected establishment
  useEffect(() => {
    if (userInput.selectedEstablishments.length === 1) {
      setAddAllSelected(false);
    }
  }, [userInput]);

  const onRadioChange = () => {
    const newIsAddAllSelected = !addAllSelected;
    setAddAllSelected(newIsAddAllSelected);

    if (newIsAddAllSelected === true) {
      selectAllEstablishments();
    } else {
      selectOneEstablishment();
    }
  };

  const selectAllEstablishments = () => {
    setSelectedEstablishments(props.establishments);
  };

  const selectOneEstablishment = () => {
    // If the user has entered an establishment number; select that one
    const inputNumber = cleanCbeNumber(userInput.cbeNumber);
    const establishmentInput = props.establishments.find(establishment => establishment.cbeNumber === inputNumber);
    const establishmentToSelect = establishmentInput ?? props.establishments[0];
    setSelectedEstablishments([establishmentToSelect]);
  };

  const setSelectedEstablishments = (selectedEstablishments: MagdaEstablishment[]) => {
    dispatch(setRegistrationUserInput({ ...userInput, selectedEstablishments, }));
  }

  const optionsToMagdaEstablishments = (options: EstablishmentOption[]): MagdaEstablishment[] => {
    return options.map(o => o.establishment as MagdaEstablishment)
  };

  return <>
    <div className={ styles.establishment }>{ t('register_confirm_multiple_establishments_content') }</div>
    <div className={ styles.options }>
      <div className="form-row">
        <RadioButton identifier="all-establishments" checked={ addAllSelected } onChange={ onRadioChange }>
          { t('register_confirm_multiple_establishments_radio_add_all') }
        </RadioButton>
      </div>
      <div className="form-row">
        <RadioButton identifier="some-establishments" checked={ !addAllSelected } onChange={ onRadioChange } >
          { t('register_confirm_multiple_establishments_radio_add_some') }
        </RadioButton>
      </div>
      {
        !addAllSelected &&
        <div className={ styles.organizations }>
          <EstablishmentsSelector
            options={ props.establishments.map(establishment => magdaEstablishmentToOption(establishment)) }
            selectedOptions={ userInput.selectedEstablishments.map(establishment => magdaEstablishmentToOption(establishment)) }
            selectedEstablishmentsChanged={ (options) => setSelectedEstablishments(optionsToMagdaEstablishments(options)) }
            enableAddButton={ true }
            addButtonLabel={ t('register_confirm_multiple_establishments_add_button') }
            mustHaveAtLeastOneSelected={ true }
          />
        </div>
      }
    </div>
  </>;
}
