import ReactModal from 'react-modal';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconClose } from 'assets/inline-svg/icons/icon__close.svg';
import styles from "./ConfirmModal.module.scss";

interface ConfirmDeleteModalProps {
  isOpen: boolean,
  message?: string,
  onAccept: () => void,
  onCancel: () => void,
  contentLabel?: string,  // Accessibility of modal
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const { t } = useTranslation();

  return <ReactModal
    isOpen={ props.isOpen }
    contentLabel={ props.contentLabel }
    onRequestClose={ props.onCancel }
    shouldCloseOnOverlayClick={ true }
    ariaHideApp={ true }
    className={ styles.modal }
    overlayClassName={ {
      base: styles.modalOverlay,
      afterOpen: styles.modalOverlayAfterOpen,
      beforeClose: styles.modalOverlayBeforeClose,
    } }
  >

    <div className={ styles.modalContent }>
      <div className={ styles.modalHeader }>
        <button type="button" className={ styles.modalCloseButton } onClick={ props.onCancel } >
          <span className="visually-hidden">{ t('label_close_modal') }</span>
          <IconClose />
        </button>
      </div>

      <div className={ styles.modalMain }>
        <h2 className={ styles.modalTitle }>
          { props.message ?? t('confirm_modal_message') }
        </h2>
        <div className={ styles.modalActions }>
          <button type="button" className={ styles.modalActionReject } onClick={ props.onAccept }>{ t('confirm_modal_accept') }</button>
          <button type="button" className={ styles.modalActionApprove } onClick={ props.onCancel }>{ t('confirm_modal_cancel') }</button>
        </div>
      </div>
    </div>

  </ReactModal >;
}

