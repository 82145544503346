import { CustomEstablishmentInput } from "features/enterprise/domain/models";
import * as helpers from "features/validation/domain/helpers";
import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import { TFunction } from "i18next";

export function validateCustomEstablishment(input: CustomEstablishmentInput, t: TFunction): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(input.name)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_field_name')));
  }

  if (helpers.isNullOrWhitespace(input.address.city)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_fields_city')));
  }

  if (helpers.isNullOrWhitespace(input.address.country)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_fields_country')));
  }

  if (helpers.isNullOrWhitespace(input.address.houseNumber)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_fields_house_number')));
  }

  if (helpers.isNullOrWhitespace(input.address.postalCode)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_fields_postal_code')));
  }

  if (helpers.isNullOrWhitespace(input.address.street)) {
    validationMessages.push(helpers.validationMessageFromString(t('add_establishment_validation_missing_fields_street')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}

export function validateEnterpriseUserAddForm(firstName: string, lastName: string, jobTitle: string, phone: string, email: string, t: TFunction): ValidationResult {
  const validationMessages: ValidationMessage[] = [];

  if (helpers.isNullOrWhitespace(firstName)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_first_name_empty')));
  }

  if (helpers.isNullOrWhitespace(lastName)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_last_name_empty')));
  }

  if (helpers.isNullOrWhitespace(jobTitle)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_job_title_empty')));
  }

  if (helpers.isNullOrWhitespace(phone)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_phone_empty')));
  }

  if (helpers.isNullOrWhitespace(email)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_email_empty')));
  } else if (!helpers.isEmail(email)) {
    validationMessages.push(helpers.validationMessageFromString(t('mobiscan_user_validation_email_incorrect')));
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
  };
}