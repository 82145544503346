import { Establishment } from "features/enterprise/domain/models";
import { basicRadiusesLayerKey, Coordinates, MapLayerCollectionKey, nmbsStationLayerKey } from "features/map/domain/models";
import { Schedule, TransportMode } from "features/mobiscan/general/domain/models";
import { Employee, IsolineCollection } from "features/mobiscan/report/domain/models";
import { useTranslation } from "react-i18next";
import MapComponent from "features/map/ui/MapComponent";
import { ProvinceBorderLayer } from "features/mobiscan/report/ui/tabs/maps/layers/ProvinceBorderLayer";
import { EstablishmentLayer } from "features/mobiscan/report/ui/tabs/maps/layers/EstablishmentLayer";
import { MapFilterLegend } from "features/map/ui/MapFilterLegend";
import { useState } from "react";
import { EmployeeLayer } from "features/mobiscan/report/ui/tabs/maps/layers/EmployeeLayer";
import { CircleMapIcon } from "features/map/ui/CircleMapIcon";
import { LayerCollectionRenderer } from "features/map/ui/LayerCollectionRenderer";
import styles from "./EmployeeResidenceMap.module.scss";

interface EmployeeResidenceMapProps {
  transportModes: TransportMode[],
  schedules: Schedule[],
  employees: Employee[],
  establishment: Establishment,
  establishmentCoordinates: Coordinates,
  isolineCollections: IsolineCollection[],
}

export function EmployeeResidenceMap(props: EmployeeResidenceMapProps) {
  const { t } = useTranslation();

  // Radius, nmbs and the isoline collections received from API 
  const availableLayerCollections = [
    nmbsStationLayerKey,
    basicRadiusesLayerKey,
    ...props.isolineCollections.map((collection) => collection.name),
  ];

  const [selectedLayers, setSelectedLayers] = useState<MapLayerCollectionKey[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>(props.employees);

  const getToolTipContent = (employee: Employee) => {
    return <ToolTipContent employee={ employee } />;
  };

  const employeeToIcon = (employee: Employee) => {
    return <CircleMapIcon dataType={ employee.transportMode.id.toLowerCase() } label={ t('transport_mode_' + employee.transportMode.id.toLowerCase()) } />
  };

  const onEmployeeFiltersUpdated = (selectedTransportModes: TransportMode[], showEmployeesWithoutPotential: boolean, selectedSchedule?: Schedule) => {
    const filteredEmployees = props.employees
      .filter((employee) => {
        const matchesWorkSchedule = selectedSchedule === undefined || employee.schedule.id === selectedSchedule.id;
        const matchesTransportMode = selectedTransportModes.map(mode => mode.id).indexOf(employee.transportMode.id) >= 0;
        return matchesTransportMode && matchesWorkSchedule;
      });

    setFilteredEmployees(filteredEmployees);
  };


  return <div className={ styles.container }>
    <div className={ styles.mapBlock }>
      <MapComponent center={ props.establishmentCoordinates }>

        <ProvinceBorderLayer />

        <EstablishmentLayer
          establishment={ props.establishment }
          establishmentLocation={ props.establishmentCoordinates }
        />

        <LayerCollectionRenderer
          layersToRender={ selectedLayers }
          isolineCollections={ props.isolineCollections }
          establishmentCoordinates={ props.establishmentCoordinates }
        />

        <EmployeeLayer
          employees={ filteredEmployees }
          getTooltipContent={ getToolTipContent }
          getIcon={ employeeToIcon }
        />
      </MapComponent>
    </div>
    <div className={ styles.legendBlock }>
      <MapFilterLegend
        establishment={ props.establishment }
        transportModes={ props.transportModes }
        schedules={ props.schedules }
        onEmployeeFiltersUpdated={ onEmployeeFiltersUpdated }
        showPotentialCheckbox={ false }
        onSelectedLayersUpdated={ setSelectedLayers }
        availableLayerCollections={ availableLayerCollections }
      />
    </div>
  </div>;
}


interface ToolTipContentProps {
  employee: Employee,
}

function ToolTipContent(props: ToolTipContentProps) {
  const { t } = useTranslation();

  const title = t('employee_residence_map_tooltip_title', { employeeId: props.employee.id });
  const transportModeLabel = t('transport_mode_' + props.employee.transportMode.id.toLowerCase());
  const travelTime = props.employee.travelSeconds / 60; // in minutes
  const description = `${transportModeLabel} ${parseInt(`${travelTime}`)} min`;

  return <>
    <div><strong>{ title }</strong></div>
    <div>{ description }</div>
  </>;
}
