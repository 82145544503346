import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { ModalButton } from "features/common/ui/ModalButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconExternal } from 'assets/inline-svg/icons/icon__external.svg';
import styles from "./NoEstablishments.module.scss";
import { TreffikUrls } from "features/i18n/TreffikUrls";

export function NoEstablishments() {
  const { t } = useTranslation();

  return <>
    <div className="content-wrapper">
      <div className="inner-wrapper">

        <MessageBox icon={ MessageBoxIcon.info } message={ t('register_no_establishments_title') } />

        <div className={ styles.info }>
          <h2 className={ styles.subtitle }>
            { t('register_no_establishments_provinces_subtitle') }
          </h2>
          <div className="text-content">
            <p>{ t('register_no_establishments_provinces_content') }</p>
            <ul>
              <li>
                <a className="text-content-external-link" href="https://www.provincieantwerpen.be/aanbod/drem/dienst-mobiliteit/bedrijfsadvies/bedrijfsadvies.html" target="_blank" rel="noopener noreferrer">
                  { t('register_no_establishments_provinces_antwerp') }
                  <IconExternal />
                  <span className='visually-hidden'> - { t('general_target_blank_link') }</span>
                </a>
              </li>
              <li>
                <a className="text-content-external-link" href="https://oost-vlaanderen.be/wonen-en-leven/mobiliteit/Bedrijfsmobiliteit.html" target="_blank" rel="noopener noreferrer">
                  { t('register_no_establishments_provinces_ovl') }
                  <IconExternal />
                  <span className='visually-hidden'> - { t('general_target_blank_link') }</span>
                </a>
              </li>
              <li>
                <a className="text-content-external-link" href="https://www.vlaamsbrabant.be/nl/mobiliteit-en-verkeer/provinciaal-mobiliteitspunt" target="_blank" rel="noopener noreferrer">
                  { t('register_no_establishments_provinces_vb') }
                  <IconExternal />
                  <span className='visually-hidden'> - { t('general_target_blank_link') }</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={ styles.contact }>
          <h2 className={ styles.subtitle }>
            { t('register_no_establishments_contact_subtitle') }
          </h2>
          <div className="text-content">
            <p>{ t('register_no_establishments_contact_content') }</p>
          </div>
          <div className={ styles.contactActions }>
            <ModalButton
              className="btn-secondary"
              targetUrl={ TreffikUrls.contactUrl }
              iframeTitle={ t('register_no_establishments_contact_a11y_iframe_title') }
              modalContentLabel={ t('register_no_establishments_contact_a11y_modal_content_label') }>
              { t('register_no_establishments_button') }
            </ModalButton>
          </div>
        </div>

      </div>
    </div>
  </>;
}
