import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import * as chartServices from 'features/chart/domain/chartServices';
import { ChartSettings } from 'features/chart/domain/models';
import { roundNumber } from 'features/common/util/math';
import { Establishment } from 'features/enterprise/domain/models';
import { CommuteDistances, DistanceSegment } from 'features/mobiscan/report/domain/models';
import { TFunction } from 'i18next';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface CommuteGeneralChartProps {
  commuteDistances: CommuteDistances,
  establishment: Establishment,
}

export function CommuteGeneralChart(props: CommuteGeneralChartProps) {
  const { t } = useTranslation();

  const extraChartSettings: ChartSettings = {
    yAxisLabelText: t('report_tab_mobility_tab_commuting_tab_general_chart_y'),
    yAxisLabelFont: {
      size: 16
    },
    displayLegend: false,
    getPopupTitleCallback,
    getPopupBodyCallback,
    getPopupFooterCallback: (context) => getPopupFooterCallback(context, t),
    showVerticalGrid: false,
  };

  const options = chartServices.createChartOptions(extraChartSettings);
  const data = createData(props.commuteDistances, props.establishment);

  return <div className='chart-scroll-outer'>
    <div className='chart-scroll-inner'>
      <Bar options={ options } data={ data } role="img" aria-label={ t('report_tab_mobility_tab_commuting_tab_general_chart_label') } />
    </div>
  </div>;
}


const createData = (commuteDistances: CommuteDistances, establishment: Establishment) => {
  const segments = commuteDistances.distanceSegments;
  const labels = segments.map((segment) => createXLabel(segment));

  return {
    labels,
    datasets: [
      {
        label: establishment.name,
        data: segments.map((segment) => {
          return {
            count: segment.count,
            percentage: roundNumber((segment.count / commuteDistances.totalCount) * 100),
            label: createXLabel(segment),
          };
        }),
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'percentage'
        },
        backgroundColor: 'rgba(32, 176, 228, 1)',
        hoverBackgroundColor: 'rgba(32, 176, 228, .7)',
        borderRadius: 5,
        // barThickness: 20,
        // maxBarThickness: 30,
        // // barPercentage: 1, // doesn't work in combination with thickness values
        // barPercentage: 0.8,
      },
    ],
  };
};

const createXLabel = (segment: DistanceSegment) => {
  return segment.kmMax != null
    ? `${segment.kmMin}-${segment.kmMax} km`
    : `>${segment.kmMin}km`;
};

const getPopupTitleCallback = (context: any) => {
  return context[0].dataset.label;
};

const getPopupBodyCallback = (context: any) => {
  return '';
};

const getPopupFooterCallback = (context: any, t: TFunction) => {
  const count = context[0].raw.count;
  const percentage = context[0].raw.percentage;

  const label = t('report_tab_mobility_tab_commuting_tab_general_chart_tooltip_label', { percentage, count });
  return label;
};
