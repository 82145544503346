import { Establishment } from "features/enterprise/domain/models";
import { MobiscanDraftApi } from "features/mobiscan/draft/data/MobiscanDraftApi";
import { ActivityDTO, PersonnelCorrectionDto, QuestionnairePageDataDTO } from "features/mobiscan/draft/data/models";
import { DraftDTO, PersonnelResponse, UploadPersonnelResponse } from "features/mobiscan/draft/data/models";
import { UpdateQuestionnaireFieldsDto } from "features/mobiscan/draft/domain/models";

export class MobiscanDraftMockApi implements MobiscanDraftApi {
  async submitDraft(draftId: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async deleteDraft(draftId: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async saveTimetableCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async saveTransportationCorrections(draftId: string, corrections: PersonnelCorrectionDto): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async updateBenchmarkSector(draftId: string, activityDto: ActivityDTO): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async saveSituation(draftId: string, situation: string): Promise<void> {
    await new Promise(r => setTimeout(r, 1000));
  }

  async getDraft(draftId: string): Promise<DraftDTO> {
    await new Promise(r => setTimeout(r, 1000));
    return {
      id: "1",
      enterpriseNumber: "1234",
      establishment: {
        id: 1,
        cbeNumber: 'est_1',
        name: 'De paddenhoek',
        address: {
          street: 'Straatnaam',
          houseNumber: '1',
          box: 'A',
          postalCode: '8000',
          city: 'Hoofdgemeente',
          country: 'BE',
        },
      },
      createdBy: {
        userId: '2',
        email: "rita.gesmorov@duo-brugge.be",
        firstName: "Rita",
        lastName: "de Preud'homme d'Hailly de Nieuport",
      },
      geocoded: true,
      benchmarkSector: undefined,
      mobiscanSituation: undefined,
      creationDateTime: "2022-07-12T17:15:52.097",
      modificationDateTime: "2022-07-12T17:15:52.097",
      version: 1,
      bicycleInfrastructure: {
        confirmed: false,
      },
      carInfrastructure: {
        confirmed: false,
      },
      bicyclePolicy: {
        confirmed: false,
      },
      publicTransitPolicy: {
        confirmed: false,
      },
      carpoolPolicy: {
        confirmed: false,
      },
      carPolicy: {
        confirmed: false,
      },
      communicationPolicy: {
        confirmed: false,
      },
      policyExtras: {
        confirmed: false,
      },
      personnel: {
        confirmed: false,
      },
    };
  }

  async getPersonnel(draftId: string): Promise<PersonnelResponse> {
    await new Promise(r => setTimeout(r, 1000));
    return {
      finishedResponse: {
        mobiscanDraftId: "1",
        processingErrors: [
          {
            id: 1,
            row: 1,
            address: {
              street: 'Straatnaam',
              houseNumber: '1',
              box: 'B',
              postalCode: '8200',
              city: 'Brugge',
              country: 'BE',
            },
            wrongValue: "huh",
            error: "ERROR_SCHEDULE",
          },
          {
            id: 2,
            row: 2,
            address: {
              street: 'Straatnaam',
              houseNumber: '1',
              box: 'B',
              postalCode: '8200',
              city: 'Brugge',
              country: 'BE',
            },
            wrongValue: "wat",
            error: "ERROR_SCHEDULE",
          },
          {
            id: 3,
            row: 3,
            address: {
              street: 'Straatnaam',
              houseNumber: '1',
              box: 'B',
              postalCode: '8200',
              city: 'Brugge',
              country: 'BE',
            },
            wrongValue: "huh",
            error: "ERROR_TRANSPORTATION",
          },
          {
            id: 4,
            row: 4,
            address: {
              street: 'Straatnaam',
              houseNumber: '1',
              box: 'B',
              postalCode: '8200',
              city: 'Brugge',
              country: 'BE',
            },
            wrongValue: "wat",
            error: "ERROR_TRANSPORTATION",
          }
        ],
      },
    };
  }

  async createDraft(establishment: Establishment): Promise<DraftDTO> {
    await new Promise(r => setTimeout(r, 1000));
    return {
      id: "1",
      enterpriseNumber: "1234",
      establishment: {
        id: 1,
        cbeNumber: 'est_1',
        name: 'De paddenhoek',
        address: {
          street: 'Straatnaam',
          houseNumber: '1',
          box: 'A',
          postalCode: '8000',
          city: 'Hoofdgemeente',
          country: 'BE',
        },
      },
      createdBy: {
        userId: '2',
        email: "rita.gesmorov@duo-brugge.be",
        firstName: "Rita",
        lastName: "de Preud'homme d'Hailly de Nieuport",
      },
      geocoded: true,
      benchmarkSector: undefined,
      mobiscanSituation: undefined,
      creationDateTime: "2022-07-12T17:15:52.097",
      modificationDateTime: "2022-07-12T17:15:52.097",
      version: 1,
      bicycleInfrastructure: {
        confirmed: false,
      },
      carInfrastructure: {
        confirmed: false,
      },
      bicyclePolicy: {
        confirmed: false,
      },
      publicTransitPolicy: {
        confirmed: false,
      },
      carpoolPolicy: {
        confirmed: false,
      },
      carPolicy: {
        confirmed: false,
      },
      communicationPolicy: {
        confirmed: false,
      },
      policyExtras: {
        confirmed: false,
      },
      personnel: {
        confirmed: false,
      },
    };
  }

  async uploadPersonnelFile(draftId: string, file: File): Promise<UploadPersonnelResponse> {
    await new Promise(r => setTimeout(r, 1000));
    throw new Error("Method not implemented.");
  }

  //Not a really valid mock response, but too much work...
  async fetchQuestionnairePage(draftId: string, page: string): Promise<QuestionnairePageDataDTO> {
    await new Promise(r => setTimeout(r, 1000));
    return {
      "draftId": "dd674a8a-c6e0-4c69-aa65-d4c0a470d1b0",
      "name": "infrastructure",
      "tabs": []
    };
  }

  async updateQuestionnaireAnswers(draftId: string, page: string, section: string, requestDto: UpdateQuestionnaireFieldsDto): Promise<void> {
    throw new Error("Method not implemented.");
  }

}
