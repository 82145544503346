import { Establishment } from "features/enterprise/domain/models";
import { Coordinates } from "features/map/domain/models";
import MapComponent from "features/map/ui/MapComponent";
import { Schedule, TransportMode } from "features/mobiscan/general/domain/models";
import { EmployeesOfCity } from "features/mobiscan/report/domain/models";
import { EstablishmentLayer } from "features/mobiscan/report/ui/tabs/maps/layers/EstablishmentLayer";
import { ProvinceBorderLayer } from "features/mobiscan/report/ui/tabs/maps/layers/ProvinceBorderLayer";
import { TownBorderLayer } from "features/mobiscan/report/ui/tabs/maps/layers/TownBorderLayer";
import { useTranslation } from "react-i18next";
import styles from "./EmployeeCommuneMap.module.scss";

interface EmployeeCommuneMapProps {
  employeesByCity: EmployeesOfCity[],
  transportModes: TransportMode[],
  schedules: Schedule[],
  establishment: Establishment,
  establishmentCoordinates: Coordinates,
}

export function EmployeeCommuneMap(props: EmployeeCommuneMapProps) {
  const getPopup = (clickedCityName: string) => {
    const clickedCityValues = props.employeesByCity.find((city) => city.cityName.trim().toLowerCase() === clickedCityName.trim().toLowerCase());
    const employeeCount = clickedCityValues?.count ?? 0;
    return employeeCount > 0
      ? <ToolTipContent cityName={ clickedCityName } employeesOfCity={ clickedCityValues } />
      : undefined;
  };

  const styleCommunePolygon = (cityName: string) => {
    const valuesForCity = props.employeesByCity.find((city) => city.cityName.trim().toLowerCase() === cityName.trim().toLowerCase());
    const employeeCount = valuesForCity?.count ?? 0;
    const color = employeeCountToColor(employeeCount);

    const fill = employeeCount > 0 ? true : undefined;
    return {
      stroke: employeeCount > 0,
      color: '#fff',
      weight: 2,
      opacity: 1,

      fill,
      fillColor: color,
      fillOpacity: color == null ? 0 : 0.8,
    };
  };

  return <div className={ styles.container }>
    <div className={ styles.mapBlock }>

      <MapComponent center={ props.establishmentCoordinates }>

        <TownBorderLayer
          townBorderStyle={ (feature: any) => styleCommunePolygon(feature.properties.NAME) }
          getPopup={ getPopup }
        />

        <ProvinceBorderLayer />

        <EstablishmentLayer
          establishment={ props.establishment }
          establishmentLocation={ props.establishmentCoordinates }
        />

      </MapComponent>

    </div>
    <div className={ styles.legendBlock }>

      <Legend establishment={ props.establishment } />

    </div>
  </div>;
}


function employeeCountToColor(value: number): string | undefined {
  if (value >= 50) {
    return '#14315C';
  } else if (value >= 30) {
    return '#416083';
  } else if (value >= 20) {
    return '#6F8EAA';
  } else if (value >= 10) {
    return '#9CBDD0';
  } else if (value >= 1) {
    return '#C9EBF7';
  }
  return undefined;
}


interface ToolTipContentProps {
  employeesOfCity?: EmployeesOfCity,
  cityName: string,
}

function ToolTipContent(props: ToolTipContentProps) {
  const { t } = useTranslation();

  const title = props.employeesOfCity?.cityName ?? props.cityName;
  const employeeCount = props.employeesOfCity?.count ?? 0;
  const description = t('employee_commune_map_tooltip_title', { employeeCount, });

  return <>
    <div><strong>{ title }</strong></div>
    <div>{ description }</div>
  </>;
}


interface LegendProps {
  establishment: Establishment,
}

function Legend(props: LegendProps) {
  const { t } = useTranslation();

  return <div className={ styles.EmployeeCommuneMapLegend }>
    <div className={ styles.EmployeeCommuneMapMarker }></div>
    <h3 className={ styles.EmployeeCommuneMapLegendTitle }>{ props.establishment.name }</h3>

    <p className={ styles.EmployeeCommuneMapLegendSubtitle }>{ t('employee_commune_map_legend_title') }</p>
    <ul className={ styles.EmployeeCommuneMapLegendList }>
      <li className={ styles.EmployeeCommuneMapLegendListitem } data-type="50">{ t('employee_commune_map_legend_50') }</li>
      <li className={ styles.EmployeeCommuneMapLegendListitem } data-type="30">{ t('employee_commune_map_legend_30') }</li>
      <li className={ styles.EmployeeCommuneMapLegendListitem } data-type="20">{ t('employee_commune_map_legend_20') }</li>
      <li className={ styles.EmployeeCommuneMapLegendListitem } data-type="10">{ t('employee_commune_map_legend_10') }</li>
      <li className={ styles.EmployeeCommuneMapLegendListitem } data-type="1">{ t('employee_commune_map_legend_1') }</li>
    </ul>
  </div>;
}
