import { useTranslation } from "react-i18next";
import styles from "./LoadingIndicator.module.scss";

export enum LoadingIndicatorDisplayType {
  default = 'default',
  minimal = 'minimal'
}

interface LoadingIndicatorProps {
  message?: string,
  displayType?: LoadingIndicatorDisplayType
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { t } = useTranslation();
  const message = props.message ?? t('label_loading');

  return <div className={ styles.loadingIndicator } data-displaytype={ props.displayType }>
    <div className={ styles.dot }>
      <div className={ styles.dotItem }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem2 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem3 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem4 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem5 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem6 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem7 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem8 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem9 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem10 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem11 }></div>
      <div className={ styles.dotItem + ' ' + styles.dotItem12 }></div>
    </div>
    <div className={ styles.text }>{ message }</div>
  </div>
}
