import { MagdaEstablishment } from "features/magda/domain/models";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from 'react-select';
import { ReactComponent as IconSelect } from 'assets/inline-svg/icons/icon__select.svg';
import { Establishment } from "features/enterprise/domain/models";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";

export interface EstablishmentOption {
  establishment: Establishment | MagdaEstablishment,
  identifier: string, // id for Establishment, cbeNumber for MagdaEstablishment
}

export function establishmentToOption(establishment: Establishment): EstablishmentOption {
  return {
    establishment,
    identifier: establishment.id.toString(),
  };
}

export function magdaEstablishmentToOption(establishment: MagdaEstablishment): EstablishmentOption {
  return {
    establishment,
    identifier: establishment.cbeNumber,
  };
}


interface EstablishmentDropdownProps {
  value: EstablishmentOption | null,
  options: EstablishmentOption[],
  disabledOptions: EstablishmentOption[],
  onValueChanged: (newValue: EstablishmentOption) => void,
}

export function EstablishmentDropdown(props: EstablishmentDropdownProps) {
  const { t } = useTranslation();

  const onChange = (e: SingleValue<EstablishmentOption>) => {
    if (e != null) {
      props.onValueChanged(e);
    }
  };

  const formatOption = (option: EstablishmentOption): ReactNode => {
    return <>
      <div className="react-select__organization">
        <span className="react-select__organization__info">
          <span className="react-select__organization__name">
            { option.establishment.name ?? t('establishment_name_unknown') }
          </span>
          <span className="react-select__organization__address">
            { formatAddressLine1(option.establishment.address) }, { formatAddressLine2(option.establishment.address) }
          </span>
        </span>
        {
          option.establishment.cbeNumber &&
          <span className="react-select__organization__number">{ t('establishment_selector_cbe_number', { cbeNumber: option.establishment.cbeNumber }) }</span>
        }
      </div>
    </>;
  };

  const disabledIdentifiers = props.disabledOptions.map(option => option.identifier);

  return <div className="react-select-container">
    <Select
      classNamePrefix="react-select"
      aria-label={ t('establishment_selector_label') }
      value={ props.value }
      onChange={ onChange }
      options={ props.options }
      getOptionLabel={ (option) => option.establishment.name ?? t('establishment_name_unknown') }
      getOptionValue={ (option) => option.identifier }
      isOptionDisabled={ (option) => disabledIdentifiers.includes(option.identifier) }
      formatOptionLabel={ (option) => formatOption(option) }
      isSearchable={ true }
      placeholder={ <NoEstablishmentPlaceholder /> }
    />
    <div className="react-select-icon">
      <IconSelect />
    </div>
  </div>;
}

function NoEstablishmentPlaceholder() {
  const { t } = useTranslation();

  return <div className='react-select-container-placeholder'>
    <span>{ t('establishment_selector_placeholder') }</span>
  </div>;
}
