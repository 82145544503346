import { JoinEnterpriseResponse, RegisterResponse } from "features/register/data/models";

export function mapRegisterSuccessResponse(): RegisterResponse {
  return {
    success: true,
    validationCodes: [],
  };
}

export function mapRegisterFailedResponse(json: any): RegisterResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
  };
}

export function mapJoinEnterpriseSuccessResponse(): JoinEnterpriseResponse {
  return {
    success: true,
  };
}

export function mapJoinEnterpriseFailedResponse(): JoinEnterpriseResponse {
  return {
    success: false,
  };
}
