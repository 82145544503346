import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { Checkbox } from "features/common/ui/forms/Checkbox";
import Dropdown, { DropdownOption } from "features/common/ui/forms/Dropdown";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconArrow } from 'assets/inline-svg/icons/icon__arrow.svg';
import { ReactComponent as IconExternal } from 'assets/inline-svg/icons/icon__external.svg';
import { ReactComponent as IconInfo } from 'assets/inline-svg/icons/icon__info.svg';
import styles from "./EditDraftPage.module.scss";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import * as routes from "routes";
import * as mobiscanDraftServices from "features/mobiscan/draft/domain/mobiscanDraftServices";
import { Draft } from "features/mobiscan/draft/domain/models";
import { formatAddressLine1, formatAddressLine2 } from "features/common/util/enterprise";
import { formatDateToSlashString } from "features/common/util/date";
import FullPageLoadingIndicator from "features/common/ui/FullPageLoadingIndicator";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ValidationResult } from "features/validation/domain/models";
import { validateSubmitForm } from "features/mobiscan/draft/domain/validation";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import * as mobiscanGeneralServices from "features/mobiscan/general/domain/mobiscanGeneralServices";
import { Activity } from "features/mobiscan/general/domain/models";
import { TreffikUrls } from "features/i18n/TreffikUrls";

export default function EditDraftPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(routes.HOME);
  }

  const fetchMobiscan = useCallback(async () => {
    const result = await mobiscanDraftServices.getDraft(id!);
    return result;
  }, [id]);

  const fetchActivities = useCallback(async () => {
    const result = await mobiscanGeneralServices.getActivities();
    return result;
  }, []);

  const draftFetch = useFetch<Draft>(fetchMobiscan);
  const activitiesFetch = useFetch<Activity[]>(fetchActivities);

  if (draftFetch.fetchStatus === FetchStatus.unknownError || activitiesFetch.fetchStatus === FetchStatus.unknownError) {
    return <div role="alert"> {/* "alert" content will be read out */ }
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    </div>;
  }

  if (draftFetch.fetchStatus === FetchStatus.success && activitiesFetch.fetchStatus === FetchStatus.success) {
    const draft = draftFetch.fetchResult!;
    const activities = activitiesFetch.fetchResult!;

    // TODO: Edge case: when a user sends the link of the edit-draft-page to someone else without permission to edit this specific draft
    // The receiving user will get a general error
    // A <EstablishmentGate> would be nice but we don't know the establishment yet (the establishment comes from /draft/{id} but that endpoint will throw a 'forbidden')
    // Another option is to catch the specific 'forbidden' API response and show a proper message when it happens
    return <Layout>
      <div>
        <PageIntro>
          <PageIntroItem position={ IntroPosition.left }>
            <BackButton label={ t('edit_draft_header_back') } targetUrl={ routes.HOME } displayType={ BackButtonType.box } />
            <h1 className="page-title">{ t('edit_draft_header_title', { establishmentName: draft.establishment.name }) }</h1>
          </PageIntroItem>
        </PageIntro>

        <DraftInfo draft={ draft } />
        <div className={ styles.contentLayout }>
          <div className={ styles.contentLayoutInner }>
            <div className={ styles.contentLayoutMain }>

              <ol className={ styles.sectionList }>
                <li className={ styles.sectionListItem }>
                  <MobiscanSection isCompleted={ draft.infrastructureTabFinished } sectionEditUrl={ routes.generateEditQuestionnaireRoute(id!, 'infrastructure') } title={ t('edit_draft_section_infrastructure_title') } content={ t('edit_draft_section_infrastructure_content') } />
                </li>
                <li className={ styles.sectionListItem }>
                  <MobiscanSection isCompleted={ draft.policyTabFinished } sectionEditUrl={ routes.generateEditQuestionnaireRoute(id!, 'policy') } title={ t('edit_draft_section_policy_title') } content={ t('edit_draft_section_policy_content') } />
                </li>
                <li className={ styles.sectionListItem }>
                  <MobiscanSection isCompleted={ draft.personnelTabFinished } sectionEditUrl={ routes.generateEditPersonnelRoute(id!) } title={ t('edit_draft_section_personnel_title') } content={ t('edit_draft_section_personnel_content') } />
                </li>
              </ol>

            </div>
            <div className={ styles.contentLayoutSide }>
              <ExtraInfo />
            </div>
          </div>
        </div>
        <CreateMobiscanForm
          isDraftReady={ draft.readyToSubmit }
          activities={ activities }
          draft={ draft }
        />
      </div>
    </Layout>;
  }

  // Pending
  return <FullPageLoadingIndicator />;
}

interface DraftInfoProps {
  draft: Draft,
}

function DraftInfo(props: DraftInfoProps) {
  const { t } = useTranslation();
  const establishment = props.draft.establishment;

  return <article className={ styles.info }>
    <div className={ styles.infoInner }>
      <div className={ styles.infoMainBlock }>
        <h2 className={ styles.subtitle }>{ establishment.name }</h2>
        <address className={ styles.address }>
          <div>{ formatAddressLine1(establishment.address) }</div>
          <div>{ formatAddressLine2(establishment.address) }</div>
          {
            props.draft.establishment.cbeNumber &&
            <div>{ t('edit_draft_cbe_number', { cbeNumber: props.draft.establishment.cbeNumber }) }</div>
          }
        </address>
      </div>
      <div className={ styles.infoMetaBlock }>
        <div className={ styles.infoButtonHolder }>
          <ModalButton targetUrl={ TreffikUrls.draftInfoUrl } className={ styles.infoButton }>
            <IconInfo />
            <span>{ t('edit_draft_more_info') }</span>
          </ModalButton>
        </div>
        <div className={ styles.metaBlock }>
          <div className={ styles.itemMetaBlock }>
            <span className={ styles.labelMetaBlock }>{ t('edit_draft_requested_by') }</span>
            <span>{ props.draft.createdBy.firstName } { props.draft.createdBy.lastName }</span>
          </div>
          <div className={ styles.itemMetaBlock }>
            <span className={ styles.labelMetaBlock }>{ t('edit_draft_last_edited') }</span>
            <span>{ formatDateToSlashString(props.draft.modificationDateTime) }</span>
          </div>
        </div>
      </div>
    </div>
  </article>;
}


interface MobiscanSectionProps {
  isCompleted: boolean,
  sectionEditUrl: string,
  title: string,
  content: string,
}

function MobiscanSection(props: MobiscanSectionProps) {
  const { t } = useTranslation();

  const statusText = props.isCompleted ? t('edit_draft_completed') : t('edit_draft_not_completed');
  const buttonText = props.isCompleted ? t('edit_draft_section_edit_cta_completed') : t('edit_draft_section_edit_cta');

  return <article className={ styles.sectionBlock } data-status={ (props.isCompleted ? 'done' : 'open') } >
    <div className={ styles.sectionBlockMain }>
      <div className={ styles.sectionBlockHeader }>
        <h3 className={ styles.sectionBlockTitle }>
          { props.title }
        </h3>
      </div>
      <div className={ styles.sectionBlockContent }>
        <p>{ props.content }</p>
      </div>
    </div>
    <div className={ styles.sectionBlockFooter }>
      <div className={ styles.sectionBlockFooterInner }>
        <div className={ styles.sectionBlockStatus }>
          <div className={ styles.sectionBlockStatusIcon }>
          </div>
          <div className={ styles.sectionBlockStatusText }>
            { statusText }
          </div>
        </div>
        <div className={ styles.sectionBlockActions }>
          <Link to={ props.sectionEditUrl }>
            { buttonText } <span className="visually-hidden">{ props.title }</span>
          </Link>
        </div>
      </div>
    </div>
  </article>;
}

function ExtraInfo() {
  const { t } = useTranslation();

  return <div className={ styles.infoMobiscanBlock }>
    <h3 className={ styles.infoMobiscanBlockTitle }>
      { t('edit_draft_info_title') }
    </h3>
    <ul className={ styles.infoMobiscanBlockList }>
      <li>
        <IconArrow />
        <span dangerouslySetInnerHTML={ { __html: t('edit_draft_info_1') } }></span>
      </li>
      <li>
        <IconArrow />
        <span dangerouslySetInnerHTML={ { __html: t('edit_draft_info_2') } }></span>
      </li>
      <li>
        <IconArrow />
        <span dangerouslySetInnerHTML={ { __html: t('edit_draft_info_3') } }></span>
      </li>
      <li>
        <IconArrow />
        <span dangerouslySetInnerHTML={ { __html: t('edit_draft_info_4') } }></span>
      </li>
      <li>
        <IconArrow />
        <span dangerouslySetInnerHTML={ { __html: t('edit_draft_info_5') } }></span>
      </li>
    </ul>
    <p dangerouslySetInnerHTML={ { __html: t('edit_draft_info_end') } }></p>
  </div>
}


interface CreateMobiscanFormProps {
  draft: Draft,
  isDraftReady: boolean,
  activities: Activity[],
}

function CreateMobiscanForm(props: CreateMobiscanFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedActivity, setSelectedActivity] = useState<Activity | undefined>();
  const [legalChecked, setLegalChecked] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const submitDraft = useCallback(async () => {
    // Client side validate
    setValidationResult(undefined);
    const validationResult = validateSubmitForm(t, legalChecked, selectedActivity);
    if (!validationResult.isValid) {
      setValidationResult(validationResult);
      return;
    }

    await mobiscanDraftServices.updateBenchmarkSector(props.draft.id, selectedActivity!);
    await mobiscanDraftServices.submitDraft(props.draft.id);

    navigate(routes.HOME);
  }, [legalChecked, navigate, props.draft.id, selectedActivity, t]);

  const { executeFetch, fetchStatus } = useFetch<void>(submitDraft, false);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (props.isDraftReady) {
      executeFetch();
    }
  };

  const activityOptions = props.activities
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(activity => mapActivityToDropdownOption(activity));

  const selectedOption = selectedActivity
    ? mapActivityToDropdownOption(selectedActivity)
    : undefined;

  return <form onSubmit={ onSubmit } className={ styles.createform }>
    <div className={ styles.createformInner }>
      <div className={ styles.sectorForm }>
        <Dropdown
          identifier="section"
          options={ activityOptions }
          selectedOption={ selectedOption }
          label={ t('edit_draft_form_sector') }
          onChange={ (value) => setSelectedActivity(props.activities.find(activity => activity.label === value)!) }
          placeholderText={ t('edit_draft_form_sector_placeholder') }
        />
      </div>
      <div className={ styles.legalForm }>
        <LegalCheckbox checked={ legalChecked } onCheckedChanged={ setLegalChecked } />
      </div>
      {
        fetchStatus === FetchStatus.unknownError &&
        <ErrorBox errorMessage={ t('general_unknown_error') } />
      }
      {
        validationResult && !validationResult.isValid &&
        <ValidationErrorBox messages={ validationResult.messages } />
      }
      {
        fetchStatus === FetchStatus.pending
          ? <LoadingIndicator />
          : <button className="btn-primary" type="submit" disabled={ !props.isDraftReady }>{ t('edit_draft_form_submit') }</button>
      }
      {
        !props.isDraftReady && <DraftNotReadyInfo />
      }
    </div>
  </form>;
}

const mapActivityToDropdownOption = (activity: Activity): DropdownOption => {
  return {
    value: activity.label,
    text: activity.label,
  };
};


interface LegalCheckboxProps {
  checked: boolean,
  onCheckedChanged: (value: boolean) => void,
}

function LegalCheckbox(props: LegalCheckboxProps) {
  const { t } = useTranslation();

  return <>
    <div className={ styles.legal }>
      <Checkbox identifier="legal" checked={ props.checked } onChange={ props.onCheckedChanged }>
        { t('edit_draft_form_legal') } *
      </Checkbox>
      <div className={ styles.legalItems }>
        <div className={ styles.legalItem }>
          <ModalButton
            className="link-text"
            targetUrl={ TreffikUrls.termsConditionsUrl }
            iframeTitle={ t('edit_draft_form_legal_terms') }
            modalContentLabel={ t('edit_draft_form_legal_terms') }>
            <span>{ t('edit_draft_form_legal_terms') }</span>
            <IconExternal />
          </ModalButton>
        </div>
      </div>
    </div>
  </>;
}


function DraftNotReadyInfo() {
  const { t } = useTranslation();

  return <div className={ styles.informationMessage } >
    <IconInfo />
    <div><span>{ t('edit_draft_form_not_ready') }</span></div>
  </div>;
}
