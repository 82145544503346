import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NumericField.module.scss";

interface NumericFieldProps {
  identifier: string, //To use as html#id
  value: number,
  label?: string,
  disabled?: boolean,
  required?: boolean,
  onChange: (value: number) => void,
  step?: number,
  min?: number,
  max?: number,
  allowDecimalPoint?: boolean,
  decimalPlaces?: number,
}

export function NumericField(props: NumericFieldProps) {
  const { t } = useTranslation();
  const [number, setNumber] = useState(props.value);
  const [input, setInput] = useState(number.toString());

  useEffect(() => {
    setNumber(props.value);
    setInput(props.value.toString());
  }, [props.value]);

  const validate = (newNumber: number) => {
    const decimalValid = !(!props.allowDecimalPoint && containsDecimalPoint(newNumber));
    const minValid = props.min != null ? newNumber >= props.min : true;
    const maxValid = props.max != null ? newNumber <= props.max : true;
    return !isNaN(newNumber) && minValid && maxValid && decimalValid;
  };

  const updateNumber = (newNumber: number) => {
    const valid = validate(newNumber);
    setInput(newNumber.toString());
    if (valid && newNumber !== number) {
      setNumber(newNumber);
      props.onChange(newNumber);
    }
  }

  const onTextInput = (newInput: string) => {
    setInput(newInput);
    if (!newInput.length) return;
    const newNumber = parseInt(newInput);
    if (isNaN(newNumber)) return;
    updateNumber(newNumber);
  };

  const onLoseFocus = () => {
    // if input was empty, set the previous amount
    setInput(number.toString());
  };

  const addStep = (value: number) => {
    const currentValue = new BigNumber(props.value);
    const stepValue = new BigNumber(value);
    const newNumber = currentValue.plus(stepValue).toNumber();
    const valid = validate(newNumber);
    if (valid) {
      props.onChange(newNumber);
    }
  }

  return <div className={ styles.numeric }>
    {
      props.label && (<label htmlFor={ props.identifier }>{ props.label }</label >)
    }
    <div className={ styles.numericInner }>
      <input
        type="number"
        pattern="[0-9]*"
        className="form-input"
        id={ props.identifier }
        name={ props.identifier }
        value={ input }
        min={ props.min }
        max={ props.max }
        onChange={ (e) => onTextInput(e.target.value) }
        onBlur={ onLoseFocus }
        disabled={ props.disabled }
        required={ props.required }
      />
      <button className={ styles.numericDecrease } onClick={ () => addStep(-(props.step ?? 1)) } disabled={ props.disabled }>{ t('switch_decrease') }</button>
      <button className={ styles.numericIncrease } onClick={ () => addStep(props.step ?? 1) } disabled={ props.disabled }>{ t('switch_increase') }</button>
    </div>
  </div>;
}

function containsDecimalPoint(n: number) {
  return (n - Math.floor(n)) !== 0;
}