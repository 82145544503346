import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapJoinEnterpriseFailedResponse, mapJoinEnterpriseSuccessResponse, mapRegisterFailedResponse, mapRegisterSuccessResponse } from "features/register/data/mappers";
import { JoinEnterpriseRequest, JoinEnterpriseResponse, RegisterRequest, RegisterResponse } from "features/register/data/models";

export interface RegisterApi {
  register(registerRequest: RegisterRequest): Promise<RegisterResponse>,
  requestJoinEnterprise(joinEnterpriseRequest: JoinEnterpriseRequest): Promise<JoinEnterpriseResponse>,
}

export class RegisterApiImpl implements RegisterApi {

  async register(registerRequest: RegisterRequest): Promise<RegisterResponse> {
    const url = AppConfig.API_URL + '/register';
    const payload = JSON.stringify(registerRequest);
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.unauthorizedFetch(url, options);


    if (response.status === 200) {
      return mapRegisterSuccessResponse();
    } else if (response.status === 400) {
      const json = await response.json();
      return mapRegisterFailedResponse(json);
    }

    throw new ApiException(response.status);
  }

  async requestJoinEnterprise(joinEnterpriseRequest: JoinEnterpriseRequest): Promise<JoinEnterpriseResponse> {
    const url = AppConfig.API_URL + '/join';
    const payload = JSON.stringify(joinEnterpriseRequest);
    const options = { method: 'POST', body: payload, };
    const response = await httpClient.unauthorizedFetch(url, options);

    if (response.status === 200) {
      return mapJoinEnterpriseSuccessResponse();
    } else if (response.status === 400) {
      return mapJoinEnterpriseFailedResponse();
    }

    throw new ApiException(response.status);
  }

}