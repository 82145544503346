import { QuestionnairePageDataDTO, ActivityDTO, QuestionnaireFieldDTO } from "features/mobiscan/draft/data/models";
import { QuestionnaireField, QuestionnaireFieldType, QuestionnairePageData, UpdateQuestionnaireFieldsDto } from "features/mobiscan/draft/domain/models";
import { DraftDTO, PersonnelResponse, UploadPersonnelResponse, UploadPersonnelValidationCode } from "features/mobiscan/draft/data/models";
import { Draft, FileProcessingStatus, Personnel, UploadPersonnelResult } from "features/mobiscan/draft/domain/models";
import { ValidationMessage, ValidationResult } from "features/validation/domain/models";
import { t } from "i18next";
import { mapIso8601StringToDate } from "features/common/util/date";
import { Activity } from "features/mobiscan/general/domain/models";

const VALIDATION_CODE_MISSING_ADDRESS_DATA = 'MISSING_ADDRESS_DATA';
const ERROR_KEY_TRANSPORTATION = 'ERROR_TRANSPORTATION';
const ERROR_KEY_TIMETABLE = 'ERROR_SCHEDULE';

export function mapQuestionnairePageDataFromDTO(pageDataDTO: QuestionnairePageDataDTO): QuestionnairePageData {
  return {
    ...pageDataDTO,
    tabs: pageDataDTO.tabs.map((tabDto) => {
      return {
        ...tabDto,
        fields: tabDto.fields.map((fieldDto) => mapQuestionnaireFieldFromDTO(fieldDto))
      }
    })
  };
}

function mapQuestionnaireFieldFromDTO(fieldDto: QuestionnaireFieldDTO): QuestionnaireField {
  const type = fieldDto.type as QuestionnaireFieldType;
  let value = fieldDto.value;

  // If type is string: set empty string as default value
  // Because there's no difference in an <input> for the value 'null' or ''
  if (type === QuestionnaireFieldType.STRING && fieldDto.value == null) {
    value = '';
  }

  // If type is number: set 0 as default value
  // Because react doesn't like when an <input>'s value switches from 'undefined' to a real numeric value
  // https://reactjs.org/docs/forms.html#controlled-components
  if ((type === QuestionnaireFieldType.BIG_DECIMAL || type === QuestionnaireFieldType.INTEGER) && fieldDto.value == null) {
    value = '0';
  }

  return {
    ...fieldDto,
    type,
    value,
  };
}

export function mapQuestionnaireLongTabNameToShort(longTabName: string): string {
  switch (longTabName) {
    case 'carInfrastructure':
      return 'car';
    case 'bicycleInfrastructure':
      return 'bicycle';
    case 'bicyclePolicy':
      return 'bicycle';
    case 'publicTransitPolicy':
      return 'publictransit';
    case 'carpoolPolicy':
      return 'carpool';
    case 'carPolicy':
      return 'car';
    case 'communicationPolicy':
      return 'communication';
    case 'policyExtras':
      return 'extras';
    default:
      return 'invalid';
  }
}


export function mapUploadPersonnelResponse(response: UploadPersonnelResponse): UploadPersonnelResult {
  const validationResult: ValidationResult = {
    isValid: true,
    messages: [],
  };

  if (response.unprocessableResponse) {
    validationResult.isValid = false;
    validationResult.messages = [{ message: t('edit_personnel_tab_template_validation_structure_changed') }];
  } else if (response.validationResponse) {
    validationResult.isValid = false;
    validationResult.messages = mapValidationCodes(response.validationResponse.validationCodes);
  }


  return {
    validationResult,
  };
}

function mapValidationCodes(codes: UploadPersonnelValidationCode[]): ValidationMessage[] {
  let messages: ValidationMessage[] = [];

  const missingAddressDataCodes = codes.filter(code => code.code === VALIDATION_CODE_MISSING_ADDRESS_DATA);
  if (missingAddressDataCodes.length > 0) {
    const locationsString = codes.map(code => code.location).join(', ');
    const message = t('edit_personnel_tab_template_validation_missing_address', { locations: locationsString, });
    messages.push({ message, });
  }

  const unknownCodes = codes.filter(code => code.code !== VALIDATION_CODE_MISSING_ADDRESS_DATA);
  const unknownCodesMessages = unknownCodes.map(code => ({ message: code.code, }));

  messages = [...messages, ...unknownCodesMessages];
  return messages;
}

export function mapGetPersonnelResponse(response: PersonnelResponse): Personnel {
  const processingErrors = response.finishedResponse?.processingErrors;
  const transportErrors = processingErrors?.filter(dto => dto.error === ERROR_KEY_TRANSPORTATION);
  const timeTableErrors = processingErrors?.filter(error => error.error === ERROR_KEY_TIMETABLE);

  return {
    fileProcessingStatus: responseToFileProcessingStatus(response),
    transportErrors,
    timeTableErrors,
  };
}

const responseToFileProcessingStatus = (response: PersonnelResponse): FileProcessingStatus => {
  if (response.finishedResponse) {
    return FileProcessingStatus.finished;
  }
  if (response.pendingResponse) {
    return FileProcessingStatus.pending;
  }
  if (response.vacantResponse) {
    return FileProcessingStatus.vacant;
  }
  throw new Error('Unknown FileProcessingStatus');
}

export function mapDraft(dto: DraftDTO): Draft {
  const infrastructureFinished = isInfrastructureTabFinished(dto);
  const policyFinished = isPolicyTabFinished(dto);
  const personnelFinished = dto.personnel.confirmed && dto.mobiscanSituation != null;

  const readyToSubmit = infrastructureFinished
    && policyFinished
    && personnelFinished
    && dto.mobiscanSituation != null;

  return {
    ...dto,
    modificationDateTime: mapIso8601StringToDate(dto.modificationDateTime),
    infrastructureTabFinished: infrastructureFinished,
    policyTabFinished: policyFinished,
    personnelTabFinished: personnelFinished,
    readyToSubmit,
  };
}

function isInfrastructureTabFinished(dto: DraftDTO): boolean {
  return dto.carInfrastructure.confirmed && dto.bicycleInfrastructure.confirmed;
}

function isPolicyTabFinished(dto: DraftDTO): boolean {
  return dto.bicyclePolicy.confirmed
    && dto.publicTransitPolicy.confirmed
    && dto.carpoolPolicy.confirmed
    && dto.carPolicy.confirmed
    && dto.communicationPolicy.confirmed
    && dto.policyExtras.confirmed;
}

export function mapQuestionnaireFieldsToRequestDto(fields: QuestionnaireField[]): UpdateQuestionnaireFieldsDto {
  const initialValue: UpdateQuestionnaireFieldsDto = {};

  return fields.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, initialValue);
}

export function mapSectorToActivityDto(activity: Activity): ActivityDTO {
  return {
    sector: activity.label,
  };
}
