import { unwrapResult } from "@reduxjs/toolkit";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { MagdaEnterprise } from "features/magda/domain/models";
import { setEnterpriseRegistrationInfo } from "features/register/domain/redux";
import { requestJoinEnterprise } from "features/register/domain/registrationOperations";
import * as validation from "features/register/domain/validation";
import { RegisterForm } from "features/register/ui/cases/RegisterForm";
import { EnterpriseTile } from "features/register/ui/EnterpriseTile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { MessageBox, MessageBoxIcon } from "features/common/ui/boxes/MessageBox";
import { RootState, useAppDispatch, useAppSelector } from "redux/store";
import styles from "./AlreadyRegistered.module.scss";
import * as routes from "routes";


interface AlreadyRegisteredProps {
  enterprise: MagdaEnterprise,
}

export function AlreadyRegistered(props: AlreadyRegisteredProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInput = useAppSelector((state: RootState) => state.registration.registrationUserInput);

  const executeRegisterCall = async () => {
    // Client side validate
    const validationResult = validation.validateRegisterForm(userInput, t, false);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const joinEnterpriseAction = await dispatch(requestJoinEnterprise());
    const result = unwrapResult(joinEnterpriseAction);
    if (result.success) {
      navigate(routes.REGISTER_FINISHED);
    }

    throw new Error('Unknown state'); // Shouldn't be possible
  };

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<void>(executeRegisterCall, false);

  const resetEnterpriseInfo = () => {
    dispatch(setEnterpriseRegistrationInfo(undefined));
  };

  return <>
    <div className="content-wrapper">
      <div className="inner-wrapper">

        <BackButton label={ t('register_already_registered_back') } targetUrl={ routes.REGISTER } onClick={ resetEnterpriseInfo } displayType={ BackButtonType.text } />
        <EnterpriseTile enterpriseName={ props.enterprise.name } enterpriseCbeNumber={ props.enterprise.cbeNumber } />

        <div className={ styles.askAccessMessage }>
          <MessageBox
            icon={ MessageBoxIcon.info }
            title={ t('register_already_registered_title') }
            message={ t('register_already_registered_content') }
          />
        </div>

        <RegisterForm
          submitLabel={ t('register_already_registered_button') }
          onSubmit={ executeFetch }
          isLoading={ fetchStatus === FetchStatus.pending }
          hasUnknownError={ fetchStatus === FetchStatus.unknownError }
          validationResult={ validationResult }
          hidePasswordField={ true }
        />
      </div>
    </div>
  </>;
}
