import { mapAddressFromJson } from "features/enterprise/data/mappers";
import { CrossroadsResponse, MagdaEnterpriseDTO, MagdaEstablishmentDTO, MagdaEstablishmentsDTO } from "features/magda/data/models";

export function mapCrossroadsResponse(json: any): CrossroadsResponse {
  return {
    success: true,
    validationCodes: [],
    registered: json.registered,
    enterprise: mapEnterpriseJson(json.enterprise),
  }
}

export function mapCrossroadsValidationErrors(json: any): CrossroadsResponse {
  const reasons = json.reasons as any[];
  const errorCodes = reasons.map((reason) => reason.code);

  return {
    success: false,
    validationCodes: errorCodes,
    registered: undefined,
    enterprise: undefined,
  };
}

function mapEnterpriseJson(json: any): MagdaEnterpriseDTO {
  return {
    name: json.name,
    cbeNumber: json.cbeNumber,
    establishments: mapEstablishmentsJson(json.establishments),
  };
}

function mapEstablishmentsJson(json: any): MagdaEstablishmentsDTO {
  const wvl = 'WVL' in json
    ? json.WVL as any[]
    : [];
  return {
    WVL: wvl.map((json) => mapEstablishmentJson(json)),
  };
}

function mapEstablishmentJson(json: any): MagdaEstablishmentDTO {
  return {
    name: json.name,
    cbeNumber: json.cbeNumber,
    address: mapAddressFromJson(json.address),
  };
}