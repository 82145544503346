import { mapEstablishmentFromJson } from "features/enterprise/data/mappers";
import { mapCoordinatesDTOFromJson } from "features/map/data/mappers";
import { mapCoordinatesFromDto } from "features/map/domain/mappers";
import { mapScheduleDTOFromJson, mapTransportModeDTOFromJson } from "features/mobiscan/general/data/mappers";
import { mapGeneralPotentialDTOFromJson, mapMobiscanUserDTOFromJson } from "features/mobiscan/overview/data/mappers";
import { BenchmarkValuesDTO, CommuteDistancesDTO, EmployeeByScheduleValuesDTO, EmployeeDTO, EmployeesByCityDTO, EmployeesByCityValuesDTO, EmployeesByScheduleDTO, InspirationDTO, IsolinesCollectionDTO, IsolinesDTO, MeasureDTO, MeasuresAnsInspirationsDTO, MobiscanDTO, ModalSplitBenchmarkDTO, ModalSplitValueDTO, PolygonDTO, PotentialTransportModeDTO, ReportInfrastructureDTO, ReportPolicyDTO, ShareTokenDTO, SummaryDTO, SummaryPotentialDTO } from "features/mobiscan/report/data/models";

export function mapMobiscanDtoFromJson(json: any): MobiscanDTO {
  return {
    id: json.id,
    enterpriseNumber: json.enterpriseNumber,
    employeeCount: json.employeeCount,
    establishment: mapEstablishmentFromJson(json.establishment),
    startedBy: mapMobiscanUserDTOFromJson(json.startedBy),
    startedDateTime: json.startedDateTime,
    requestedBy: mapMobiscanUserDTOFromJson(json.requestedBy),
    requestedDateTime: json.requestedDateTime,
    modificationDateTime: json.modificationDateTime,
    status: json.status,
    potential: mapGeneralPotentialDTOFromJson(json.potential),
    latitudeLongitude: mapCoordinatesDTOFromJson(json.latitudeLongitude),
  };
}

export function mapSummaryDTOFromJson(json: any): SummaryDTO {
  return {
    mobiscanId: json.mobiscanId,
    totalEmployees: json.totalEmployees,
    durable: mapSummaryPotentialDTOFromJson(json.durable),
    active: mapSummaryPotentialDTOFromJson(json.active),
    collective: mapSummaryPotentialDTOFromJson(json.collective),
    carpool: mapSummaryPotentialDTOFromJson(json.carpool),
    motorizedTransport: mapSummaryPotentialDTOFromJson(json.motorizedTransport),
    commuteDistance: mapBenchmarkValuesDTOFromJson(json.commuteDistance),
    commuteDuration: mapBenchmarkValuesDTOFromJson(json.commuteDuration),
    carbonDioxide: mapBenchmarkValuesDTOFromJson(json.carbonDioxide),
    percentageDurable: mapBenchmarkValuesDTOFromJson(json.percentageDurable),
    bicycleParkingSpotsPercentage: mapBenchmarkValuesDTOFromJson(json.bicycleParkingSpotsPercentage),
    carParkingSpotsPercentage: mapBenchmarkValuesDTOFromJson(json.carParkingSpotsPercentage),
  };
}

function mapSummaryPotentialDTOFromJson(json: any): SummaryPotentialDTO {
  return {
    current: json.current,
    potential: json.potential,
  };
}

function mapBenchmarkValuesDTOFromJson(json: any): BenchmarkValuesDTO {
  return {
    actual: json.actual,
    benchmark: json.benchmark,
  };
}

export function mapCommuteDistancesDTOFromJson(json: any): CommuteDistancesDTO {
  return {
    totalCount: json.totalCount,
    modalSplit: mapModalSplit(json.modalSplit),
    distanceSegments: json.distanceSegments.map((segment: any) => ({
      count: segment.count,
      kmMin: segment.kmMin,
      kmMax: segment.kmMax,
      modalSplit: mapModalSplit(segment.modalSplit),
    })),
    modalSplitBenchmarks: json.modalSplitBenchmarks.map(mapBenchmarkModalSplit),
  };
}

function mapModalSplit(json: any) {
  const result: { [key: string]: ModalSplitValueDTO } = {};

  Object.keys(json).forEach((transportKey) => {
    result[transportKey] = {
      count: json[transportKey].count,
      percentage: json[transportKey].percentage,
    };
  });

  return result;
}

function mapBenchmarkModalSplit(json: any): ModalSplitBenchmarkDTO {
  const modalSplit: { [key: string]: number } = {};

  Object.keys(json.modalSplit).forEach((transportKey) => {
    modalSplit[transportKey] = json.modalSplit[transportKey];
  });

  return {
    name: json.name,
    modalSplit,
  };
}

export function mapEmployeesByCityDTOFromJson(json: any): EmployeesByCityDTO {
  const counts = json.counts;
  const result: { [key: string]: EmployeesByCityValuesDTO } = {};

  Object.keys(counts).forEach((cityKey) => {
    result[cityKey] = {
      count: counts[cityKey].count,
      percentage: counts[cityKey].percentage,
    };
  });

  return {
    counts: result,
  };
}

export function mapEmployeesDTOsFromJson(json: any): EmployeeDTO[] {
  return json.employees.map((employeeJson: any) => ({
    id: employeeJson.id,
    latitudeLongitude: mapCoordinatesDTOFromJson(employeeJson.latitudeLongitude),
    transportMode: mapTransportModeDTOFromJson(employeeJson.transportMode),
    schedule: mapScheduleDTOFromJson(employeeJson.schedule),
    travelMeters: employeeJson.travelMeters,
    travelSeconds: employeeJson.travelSeconds,
    potentialTransportModes: employeeJson.potentialTransportModes.map(mapPotentialTransportModeDTOFromJson),
  }));
}

function mapPotentialTransportModeDTOFromJson(json: any): PotentialTransportModeDTO {
  return {
    transportMode: json.transportMode,
    meters: json.meters,
    seconds: json.seconds,
  };
}

export function mapEmployeesByScheduleDTOFromJson(json: any): EmployeesByScheduleDTO {
  const counts = json.counts;
  const result: { [key: string]: EmployeeByScheduleValuesDTO } = {};

  Object.keys(counts).forEach((cityKey) => {
    result[cityKey] = {
      count: counts[cityKey].count,
      percentage: counts[cityKey].percentage,
    };
  });

  return {
    counts: result,
  };
}

export function mapReportPolicyDTOFromJson(json: any): ReportPolicyDTO {
  return {
    mobiscanId: json.mobiscanId,
    pageName: json.pageName,
    tabs: json.tabs.map((tab: any) => ({
      tabName: tab.tabName,
      fields: tab.fields.map((field: any) => ({
        fieldName: field.fieldName,
        label: field.label,
        type: field.type,
        value: field.value,
      })),
    })),
  };
}

export function mapReportInfrastructureDTOFromJson(json: any): ReportInfrastructureDTO {
  return {
    mobiscanId: json.mobiscanId,
    pageName: json.pageName,
    tabs: json.tabs.map((tab: any) => ({
      tabName: tab.tabName,
      fields: tab.fields.map((field: any) => ({
        fieldName: field.fieldName,
        label: field.label,
        type: field.type,
        value: field.value,
      })),
    })),
  };
}

export function mapMeasuresAndInspirationsDTOFromJson(json: any): MeasuresAnsInspirationsDTO {
  return {
    measures: json.measures.map(mapMeasureDTOFromJson),
    inspirations: json.inspirations.map(mapInspirationDTOFromJson),
  };
}

export function mapMeasureDTOFromJson(json: any): MeasureDTO {
  return {
    tag: json.tag,
    tagColor: json.tagColor,
    effect: json.effect,
    effort: json.effort,
    imageUrl: json.imageUrl,
    detailUrl: json.detailUrl,
    title: json.title,
    effectChart: json.effectChart,
    effortChart: json.effortChart,
  };
}

export function mapInspirationDTOFromJson(json: any): InspirationDTO {
  return {
    tag: json.tag,
    tagColor: json.tagColor,
    imageUrl: json.imageUrl,
    detailUrl: json.detailUrl,
    title: json.title,
  };
}

export function mapIsolinesCollectionDTOFromJson(json: any): IsolinesCollectionDTO {
  const result: IsolinesCollectionDTO = {};

  Object.keys(json).forEach((key) => {
    result[key] = mapIsolinesDTO(json[key]);
  });

  return result;
}

function mapIsolinesDTO(json: any): IsolinesDTO {
  const result: IsolinesDTO = {};

  Object.keys(json).forEach((key) => {
    result[key] = {
      polygons: json[key].polygons.map(mapPolygonFromJson),
      connections: json[key].connections,
    };
  });

  return result;
}

function mapPolygonFromJson(json: any): PolygonDTO {
  return {
    outer: json.outer.map((outer: any) => mapCoordinatesFromDto(outer)),
    inner: json.inner.map((inner: any) => mapCoordinatesFromDto(inner)),
  };
}

export function mapShareTokenFromJson(json: any): ShareTokenDTO {
  return {
    mobiscanId: json.mobiscanId,
    value: json.value,
  };
}