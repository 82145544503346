import { UserRole } from "features/auth/domain/models";
import { Establishment } from "features/enterprise/domain/models";
import { establishmentPermissions, EstablishmentScope } from "features/mobiscan/permissions/domain/definitions";
import { useAppSelector } from "redux/store";

interface EstablishmentGateProps {
  establishment: Establishment,
  requiredScope: EstablishmentScope,
  children?: React.ReactNode,
}

export default function EstablishmentGate(props: EstablishmentGateProps) {
  const user = useAppSelector((state) => state.auth.user!);
  const masterRoles = [UserRole.ADMIN, UserRole.PROVINCE, UserRole.ORG_ADMIN];

  let isAllowed = false;

  if (masterRoles.indexOf(user.role) >= 0) {
    isAllowed = true;
  } else if (user.establishmentPermissions) {
    for (let permission of user.establishmentPermissions) {
      const scopesForEstablishment = establishmentPermissions[permission.permission];
      if (permission.establishment.id === props.establishment.id && scopesForEstablishment.indexOf(props.requiredScope) >= 0) {
        isAllowed = true;
        break;
      }
    }
  }

  if (isAllowed) {
    return <>{ props.children }</>;
  } else {
    return <></>;

  }

}