import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { EmployeesBySchedule, Mobiscan } from "features/mobiscan/report/domain/models";
import * as reportServices from "features/mobiscan/report/domain/reportServices";
import { TimetableChart } from "features/mobiscan/report/ui/tabs/charts/TimetableChart";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TimetableTab.module.scss";

interface TimetableTabProps {
  mobiscan: Mobiscan,
}

export function TimetableTab(props: TimetableTabProps) {
  const { t } = useTranslation();

  const fetchEmployeesBySchedule = useCallback(async () => {
    return await reportServices.getEmployeesBySchedule(props.mobiscan.id);
  }, [props.mobiscan.id]);

  const { fetchStatus, fetchResult } = useFetch<EmployeesBySchedule[]>(fetchEmployeesBySchedule);

  if (fetchStatus === FetchStatus.unknownError) {
    return <ErrorBox errorMessage={ t('general_unknown_error') } />;
  }

  if (fetchStatus === FetchStatus.success) {
    const employeesBySchedule = fetchResult!;

    return <article className={ styles.container }>
      <header className={ styles.header }>
        <div className={ styles.headerInner }>
          <h2 className={ styles.headerTitle }>
            { t('report_tab_mobility_tab_timetable_title') }
          </h2>
        </div>
      </header>

      <div className={ styles.content }>
        <div className={ styles.contentInner }>
          <TimetableChart
            establishment={ props.mobiscan.establishment }
            employeesBySchedule={ employeesBySchedule }
          />
        </div>
      </div>
    </article>;
  }

  return <LoadingIndicator />;
}
