import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Report } from "features/mobiscan/report/ui/Report";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";

export default function ReportPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  if (id != null) {
    return <>
      <Report
        mobiscanId={ id }
      />
    </>;
  }

  return <Layout>
    <PageIntro>
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    </PageIntro>
  </Layout >;
}
