import L from "leaflet";
import { renderToString } from "react-dom/server";
import styles from './MapIconHelper.module.scss';

export const createLeafletMapIcon = (icon: JSX.Element) => {
  return L.divIcon({
    className: styles.transparent,
    html: renderToString(icon),
  });
}
