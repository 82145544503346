import ReactModal from 'react-modal';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconClose } from 'assets/inline-svg/icons/icon__close.svg';
import styles from './ShareModal.module.scss';
import { TextField } from 'features/common/ui/forms/TextField';

interface ShareModalProps {
  isOpen: boolean,
  onCloseRequest: () => void,
  shareUrl: string,
  contentLabel?: string,  // Accessibility of modal
}

export function ShareModal(props: ShareModalProps) {
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.shareUrl);
  };

  return <ReactModal
    isOpen={ props.isOpen }
    contentLabel={ props.contentLabel }
    onRequestClose={ props.onCloseRequest }
    shouldCloseOnOverlayClick={ true }
    ariaHideApp={ true }
    className={ styles.modal }
    overlayClassName={ {
      base: styles.modalOverlay,
      afterOpen: styles.modalOverlayAfterOpen,
      beforeClose: styles.modalOverlayBeforeClose,
    } }
  >

    <div className={ styles.modalContent }>
      <div className={ styles.modalHeader }>
        <button type="button" className={ styles.modalCloseButton } onClick={ props.onCloseRequest } >
          <span className="visually-hidden">{ t('label_close_modal') }</span>
          <IconClose />
        </button>
      </div>

      <div className={ styles.modalMain }>
        <h2 className={ styles.modalTitle }>
          { t('share_modal_title') }
        </h2>
        <div className={ styles.modalText }>
          { t('share_modal_text') }
        </div>
        <div className={ styles.shareControl }>
          <div className={ styles.shareControlInputBlock }>
            <TextField
              identifier='share-link'
              value={ props.shareUrl }
              readonly={ true }
            />
          </div>
          <div className={ styles.shareControlActionsBlock }>
            <button type="button" onClick={ copyToClipboard } className={ styles.shareControlActionButton }>
              { t('share_modal_copy') }
            </button>
          </div>
        </div>

      </div>
    </div>

  </ReactModal>;
}
