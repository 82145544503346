import { TextField } from 'features/common/ui/forms/TextField';
import { ReactComponent as IconEyeVisible } from 'assets/inline-svg/icons/icon__eye--visible.svg';
import { ReactComponent as IconEye } from 'assets/inline-svg/icons/icon__eye.svg';
import styles from './PasswordTextField.module.scss';
import { useState } from 'react';

interface PasswordTextFieldProps {
  identifier: string; //To use as html#id
  value: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

export function PasswordTextField(props: PasswordTextFieldProps) {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <div className={ styles.passwordTextFieldWrapper }>
      <TextField type={ hidePassword ? 'password' : 'text' } identifier={ props.identifier } value={ props.value } onChange={ props.onChange } label={ props.label } disabled={ props.disabled } required={ props.required } />
      <button className={ styles.password_show_hide } aria-checked={ hidePassword } role="checkbox" type="button" onClick={ () => setHidePassword((previous) => !previous) }>
        {
          hidePassword
            ? <IconEye />
            : <IconEyeVisible />
        }
      </button>
    </div>
  );
}
