import { AppConfig } from "config/AppConfig";
import { AuthApi, AuthApiImpl } from "features/auth/data/AuthApi";
import { AuthMockApi } from "features/auth/data/AuthMockApi";
import { AuthStorage, AuthStorageImpl } from "features/auth/data/AuthStorage";
import { HttpClient } from "features/common/data/HttpClient";
import { RegisterApi, RegisterApiImpl } from "features/register/data/RegisterApi";
import { RegisterMockApi } from "features/register/data/RegisterMockApi";
import { MobiscanApi, MobiscanApiImpl } from "features/mobiscan/overview/data/MobiscanApi";
import { MobiscanMockApi } from "features/mobiscan/overview/data/MobiscanMockApi";
import { UserApi, UserApiImpl } from "features/user/data/UserApi";
import { UserMockApi } from "features/user/data/UserMockApi";
import { MagdaApi, MagdaApiImpl } from "features/magda/data/MagdaApi";
import { MagdaMockApi } from "features/magda/data/MagdaMockApi";
import { EnterpriseApi, EnterpriseApiImpl } from "features/enterprise/data/EnterpriseApi";
import { EnterpriseMockApi } from "features/enterprise/data/EnterpriseMockApi";
import { ProvinceApi, ProvinceApiImpl } from "features/province/data/ProvinceApi";
import { ProvinceMockApi } from "features/province/data/ProvinceMockApi";
import { MobiscanDraftApi, MobiscanDraftApiImpl } from "features/mobiscan/draft/data/MobiscanDraftApi";
import { MobiscanDraftMockApi } from "features/mobiscan/draft/data/MobiscanDraftMockApi";
import { MobiscanReportApi, MobiscanReportApiImpl } from "features/mobiscan/report/data/MobiscanReportApi";
import { MobiscanReportMockApi } from "features/mobiscan/report/data/MobiscanReportMockApi";
import { MobiscanGeneralApi, MobiscanGeneralApiImpl } from "features/mobiscan/general/data/MobiscanGeneralApi";
import { MobiscanGeneralMockApi } from "features/mobiscan/general/data/MobiscanGeneralMockApi";
import { ProvinceStorage, ProvinceStorageImpl } from "features/province/data/ProvinceStorage";

export const httpClient: HttpClient = new HttpClient();

export const authApi: AuthApi = AppConfig.MOCK_API === true ? new AuthMockApi() : new AuthApiImpl();
export const authStorage: AuthStorage = new AuthStorageImpl();

export const registerApi: RegisterApi = AppConfig.MOCK_API === true ? new RegisterMockApi() : new RegisterApiImpl();
export const magdaApi: MagdaApi = AppConfig.MOCK_API === true ? new MagdaMockApi() : new MagdaApiImpl();
export const mobiscanApi: MobiscanApi = AppConfig.MOCK_API ? new MobiscanMockApi() : new MobiscanApiImpl();
export const mobiscanGeneralApi: MobiscanGeneralApi = AppConfig.MOCK_API ? new MobiscanGeneralMockApi() : new MobiscanGeneralApiImpl();
export const mobiscanDraftApi: MobiscanDraftApi = AppConfig.MOCK_API ? new MobiscanDraftMockApi() : new MobiscanDraftApiImpl();
export const mobiscanReportApi: MobiscanReportApi = AppConfig.MOCK_API ? new MobiscanReportMockApi() : new MobiscanReportApiImpl();
export const userApi: UserApi = AppConfig.MOCK_API ? new UserMockApi() : new UserApiImpl();
export const enterpriseApi: EnterpriseApi = AppConfig.MOCK_API ? new EnterpriseMockApi() : new EnterpriseApiImpl();
export const provinceApi: ProvinceApi = AppConfig.MOCK_API ? new ProvinceMockApi() : new ProvinceApiImpl();
export const provinceStorage: ProvinceStorage = new ProvinceStorageImpl();