import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { MessageBox, MessageBoxType, MessageBoxDisplayType } from "features/common/ui/boxes/MessageBox";
import { Layout } from "features/common/ui/layout/Layout";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { ModalButton } from "features/common/ui/ModalButton";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { AddEstablishmentUserInput, CustomEstablishmentInput, Establishment } from "features/enterprise/domain/models";
import { EnterpriseTile } from "features/enterprise/ui/EnterpriseTile";
import { CustomEstablishmentsInput } from "features/enterprise/ui/CustomEstablishmentsInput";
import { EstablishmentOption, magdaEstablishmentToOption } from "features/enterprise/ui/EstablishmentDropdown";
import { EstablishmentsSelector } from "features/enterprise/ui/EstablishmentsSelector";
import * as magdaServices from "features/magda/domain/magdaServices";
import { EnterpriseInfo, MagdaEnterprise, MagdaEstablishment } from "features/magda/domain/models";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAppSelector } from "redux/store";
import styles from "./AddEstablishmentPage.module.scss";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";
import { validateCustomEstablishment } from "features/enterprise/domain/validation";
import { TreffikUrls } from "features/i18n/TreffikUrls";
import { Link } from "react-router-dom";

const initialUserInput: AddEstablishmentUserInput = {
  selectedEstablishments: [],
  customEstablishments: [],
};

export function AddEstablishmentPage() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);
  const navigate = useNavigate();

  const fetchMagdaEnterprise = useCallback(async () => {
    const result = await magdaServices.getEnterpriseRegistrationInfo(user.enterprise!.cbeNumber);
    if (result.success) {
      return result.enterpriseInfo!;
    } else {
      throw new Error('Error in crossroads API'); // Should never happen, but you never know
    }
  }, [user.enterprise]);

  const fetchEstablishments = useCallback(async () => {
    const establishments = await enterpriseServices.getEstablishments(user.enterprise!.cbeNumber);
    return establishments;
  }, [user.enterprise]);

  const enterpriseFetch = useFetch<EnterpriseInfo>(fetchMagdaEnterprise);
  const establishmentFetch = useFetch<Establishment[]>(fetchEstablishments);

  useEffect(() => {
    if (user.enterprise == null) {
      navigate(routes.HOME);
    }
  }, [navigate, user.enterprise]);


  return <ScopeGate requiredScope={ Scope.MANAGE_ESTABLISHMENTS }>
    <Layout>
      <PageIntro>

        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('add_establishment_header_back') } targetUrl={ routes.ESTABLISHMENTS } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('add_establishment_header_title') }</h1>
        </PageIntroItem>

        <PageIntroItem position={ IntroPosition.right }>
          <EnterpriseTile enterprise={ user.enterprise! } />
        </PageIntroItem>

      </PageIntro>

      <div className="content-wrapper">
        <div className="inner-wrapper">
          {
            (enterpriseFetch.fetchStatus === FetchStatus.unknownError || establishmentFetch.fetchStatus === FetchStatus.unknownError) &&
            <ErrorBox errorMessage={ t('add_establishment_unknown_error') } />
          }
          {
            (enterpriseFetch.fetchStatus === FetchStatus.success && establishmentFetch.fetchStatus === FetchStatus.success) &&
            <AddEstablishmentPageContent establishmentsAlreadyAdded={ establishmentFetch.fetchResult! } magdaEnterprise={ enterpriseFetch.fetchResult!.enterprise! } />
          }
          {
            (enterpriseFetch.fetchStatus === FetchStatus.pending || establishmentFetch.fetchStatus === FetchStatus.pending) &&
            <LoadingIndicator />
          }
        </div>
      </div>
    </Layout>
  </ScopeGate >;
}


interface AddEstablishmentPageContentProps {
  establishmentsAlreadyAdded: Establishment[],
  magdaEnterprise: MagdaEnterprise,
}

function AddEstablishmentPageContent(props: AddEstablishmentPageContentProps) {
  const { t } = useTranslation();

  const [userInput, setUserInput] = useState<AddEstablishmentUserInput>(initialUserInput);

  const optionToEstablishment = (option: EstablishmentOption): MagdaEstablishment => {
    return option.establishment as MagdaEstablishment;
  };

  const selectedEstablishments = userInput.selectedEstablishments;
  const alreadyAddedCbeNumbers = props.establishmentsAlreadyAdded.map(e => e.cbeNumber);
  const availableEstablishments = props.magdaEnterprise.establishments.filter((establishment) => !alreadyAddedCbeNumbers.includes(establishment.cbeNumber));

  const setSelectedEstablishments = useCallback((establishments: MagdaEstablishment[]) => {
    const newUserInput = { ...userInput, selectedEstablishments: establishments, };
    setUserInput(newUserInput);
  }, [userInput]);

  const setCustomEstablishments = useCallback((customEstablishments: CustomEstablishmentInput[]) => {
    const newUserInput = { ...userInput, customEstablishments, };
    setUserInput(newUserInput);
  }, [userInput]);

  const clientSideValidateCustomEstablishments = useCallback(() => {
    let isValid = true;
    for (const customEstablishment of userInput.customEstablishments) {
      const validationResult = validateCustomEstablishment(customEstablishment, t);
      customEstablishment.validationResult = validateCustomEstablishment(customEstablishment, t);
      if (!validationResult.isValid) {
        isValid = false;
      }
    }
    setCustomEstablishments([...userInput.customEstablishments]);
    return isValid;
  }, [setCustomEstablishments, t, userInput.customEstablishments]);

  const addCbeEstablishments = useCallback(async (): Promise<AddEstablishmentsResult> => {
    // CBE Establishments
    const failedCbeEstablishments = [];
    for (const selectedEstablishment of userInput.selectedEstablishments) {
      try {
        await enterpriseServices.addEstablishment(props.magdaEnterprise.cbeNumber, selectedEstablishment.cbeNumber);
      } catch (e) {
        failedCbeEstablishments.push(selectedEstablishment);
      }
    }

    setSelectedEstablishments(failedCbeEstablishments);
    return {
      amountOfFailedEstablishments: failedCbeEstablishments.length,
    }
  }, [props.magdaEnterprise.cbeNumber, setSelectedEstablishments, userInput.selectedEstablishments]);


  const addCustomEstablishments = useCallback(async (): Promise<AddEstablishmentsResult> => {
    // Custom establishments
    const totalEstablishmentsToAdd = userInput.customEstablishments.length;
    const failedCustomEstablishments = [];
    for (let i = 0; i < totalEstablishmentsToAdd; i++) {
      const customEstablishment = userInput.customEstablishments[i];
      const result = await enterpriseServices.addCustomEstablishment(props.magdaEnterprise.cbeNumber, customEstablishment);
      if (result.success === false) {
        customEstablishment.validationResult = result.validationResult;
        failedCustomEstablishments.push(customEstablishment);
      }
    }

    setCustomEstablishments(failedCustomEstablishments);
    return {
      amountOfFailedEstablishments: failedCustomEstablishments.length,
    }
  }, [props.magdaEnterprise.cbeNumber, setCustomEstablishments, userInput.customEstablishments]);

  const addCbeEstablishmentsFetch = useFetch<AddEstablishmentsResult>(addCbeEstablishments, false);
  const addCustomEstablishmentsFetch = useFetch<AddEstablishmentsResult>(addCustomEstablishments, false);

  const onAddClicked = () => {
    // Client side validation
    const isValid = clientSideValidateCustomEstablishments();
    console.log(isValid);
    if (isValid) {
      addCbeEstablishmentsFetch.executeFetch();
      addCustomEstablishmentsFetch.executeFetch();
    }
  };

  const fetchesExecuted = addCustomEstablishmentsFetch.fetchStatus === FetchStatus.success && addCbeEstablishmentsFetch.fetchStatus === FetchStatus.success;


  return <section>
    <BackButton label={ t('add_establishment_header_back') } targetUrl={ routes.ESTABLISHMENTS } displayType={ BackButtonType.text } />

    <header className={ styles.pageHeader }>
      <h2 className={ styles.pageTitle }>
        { t('add_establishment_title') }
      </h2>
    </header>

    <div className={ styles.pageContent }>

      {
        availableEstablishments.length > 0 && <>
          <SubTitle label={ t('establishment_selector_subtitle_official') } />
          <EstablishmentsSelector
            options={ availableEstablishments.map(establishment => magdaEstablishmentToOption(establishment)) }
            selectedOptions={ selectedEstablishments.map(establishment => magdaEstablishmentToOption(establishment)) }
            selectedEstablishmentsChanged={ (newSelected) => setSelectedEstablishments(newSelected.map(option => optionToEstablishment(option))) }
            enableAddButton={ true }
            addButtonLabel={ t('add_establishment_button_add_official') }
          />
        </>
      }
      {
        addCbeEstablishmentsFetch.fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('add_establishment_cbe_unknown_error') } />
      }

      <SubTitle label={ t('establishment_selector_subtitle_manual') } />
      <div className={ styles.manualInfoHolder }>
        <MessageBox
          displayType={ MessageBoxDisplayType.minimal }
          message={ <div>
            <p>{ t('add_establishment_cluster_info') }</p>
            <ul>
              <li>{ t('add_establishment_cluster_options_1') }</li>
              <li>{ t('add_establishment_cluster_options_2') }</li>
            </ul>
            <p>{ t('add_establishment_cluster_options_final') }</p>
          </div> }
          actions={
            <ModalButton
              targetUrl={ TreffikUrls.contactUrl }
              iframeTitle={ t('add_establishment_cluster_a11y_iframe_title') }
              modalContentLabel={ t('add_establishment_cluster_a11y_modal_content_label') }>
              { t('add_establishment_cluster_button') }
            </ModalButton>
          } />
      </div>
      <CustomEstablishmentsInput
        enableAddButton={ true }
        addButtonLabel={ t('add_establishment_button_add_manual') }
        customEstablishments={ userInput.customEstablishments }
        onCustomEstablishmentChanged={ setCustomEstablishments }
      />
      {
        addCustomEstablishmentsFetch.fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('add_establishment_custom_unknown_error') } />
      }

      <div className={ styles.pageActions }>
        {
          (fetchesExecuted) &&
          <>
            {
              /**
               * This block is executed in case both the custom & cbe API-calls worked
               * However, the API might have been able to process some addresses, but returned validation errors for others
               * In this case, we don't show the successfully processed establishments anymore, and still show the forms for the failed addresses so users can fix it. 
               * To let the users know this (edge) case clearly, the AddEstablishmentsResult model with the conditional rendering below was created
               */
            }
            {
              (addCustomEstablishmentsFetch.fetchResult!.amountOfFailedEstablishments + addCbeEstablishmentsFetch.fetchResult!.amountOfFailedEstablishments) > 0
                ? <ErrorBox errorMessage={ t('add_establishment_incomplete_error') } />
                : <>
                  <MessageBox type={ MessageBoxType.success } message={ t('add_establishment_success') } />
                  <Link to={ routes.CREATE_DRAFT } className={ `${styles.createMobiscan} btn-secondary btn-secondary--add` }>
                    <span>{ t('add_establishment_mobiscan_button') }</span>
                  </Link>
                </>
            }
          </>
        }
        {
          (addCbeEstablishmentsFetch.fetchStatus === FetchStatus.pending || addCustomEstablishmentsFetch.fetchStatus === FetchStatus.pending)
            ? <LoadingIndicator />
            : <button
              className="btn-primary btn-primary--large"
              onClick={ onAddClicked }
              disabled={ userInput.customEstablishments.length === 0 && userInput.selectedEstablishments.length === 0 }
            >
              { t('add_establishment_add_button') }
            </button>
        }
      </div>

    </div>
  </section>;
}

interface SubTitleProps {
  label: string,
}

function SubTitle(props: SubTitleProps) {
  return <div className={ styles.addOrganizationSubtitleHolder }>
    <h3 className={ styles.addOrganizationSubtitle }>
      { props.label }
    </h3>
  </div>;
}


interface AddEstablishmentsResult {
  amountOfFailedEstablishments: number,
}