
interface AppConfiguration {
  API_URL: string,
  FRONT_URL: string,
  ENVIRONMENT_NAME: string,
  MOCK_API: boolean,
}

export const AppConfig: AppConfiguration = {
  API_URL: process.env.REACT_APP_API_URL!,
  FRONT_URL: process.env.REACT_APP_FRONT_URL!,
  ENVIRONMENT_NAME: process.env.REACT_APP_ENVIRONMENT_NAME!,
  MOCK_API: process.env.REACT_APP_MOCK_API ? process.env.REACT_APP_MOCK_API === 'TRUE' : false,
}