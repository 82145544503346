import React from 'react';
import styles from "./CheckboxSmall.module.scss";

interface CheckboxProps {
  identifier: string; //To use as html#id
  checked: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export function Checkbox(props: CheckboxProps) {
  return (
    <div className={ styles.checkbox }>
      <input type="checkbox" className="form-checkbox" id={ props.identifier } name={ props.identifier } checked={ props.checked } onChange={ (e) => props.onChange(e.target.checked) } disabled={ props.disabled } />
      {
        props.children && (<label htmlFor={ props.identifier }>{ props.children }</label >)
      }
    </div>
  );
}
