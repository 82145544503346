import { roundNumber } from "features/common/util/math";
import { Establishment } from "features/enterprise/domain/models";
import { basicRadiusesLayerKey, Coordinates, MapLayerCollectionKey, nmbsStationLayerKey } from "features/map/domain/models";
import { CircleMapIcon } from "features/map/ui/CircleMapIcon";
import { LayerCollectionRenderer } from "features/map/ui/LayerCollectionRenderer";
import MapComponent from "features/map/ui/MapComponent";
import { MapFilterLegend } from "features/map/ui/MapFilterLegend";
import { Schedule, TransportCategory, TransportMode } from "features/mobiscan/general/domain/models";
import { getTransportModesByCategory } from "features/mobiscan/general/domain/transportModeUtil";
import { Employee, IsolineCollection } from "features/mobiscan/report/domain/models";
import { EmployeeLayer } from "features/mobiscan/report/ui/tabs/maps/layers/EmployeeLayer";
import { EstablishmentLayer } from "features/mobiscan/report/ui/tabs/maps/layers/EstablishmentLayer";
import { ProvinceBorderLayer } from "features/mobiscan/report/ui/tabs/maps/layers/ProvinceBorderLayer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PotentialShiftMap.module.scss";

interface PotentialShiftMapProps {
  transportCategory: TransportCategory,
  schedules: Schedule[],
  employees: Employee[],
  establishment: Establishment,
  establishmentCoordinates: Coordinates,
  isolineCollections: IsolineCollection[],
  showPotentialLabelKey: string,
  initialSelectedLayers?: MapLayerCollectionKey[],
}

export function PotentialShiftMap(props: PotentialShiftMapProps) {
  const { t } = useTranslation();

  // Radius, nmbs and the isoline collections received from API 
  const availableLayerCollections = [
    nmbsStationLayerKey,
    basicRadiusesLayerKey,
    ...props.isolineCollections.map((collection) => collection.name),
  ];

  const employeesWithPotentialShift = props.employees.filter((employee) => employee.potentialTransportModes.find((potential) => potential.transportMode.category === props.transportCategory) != null);
  const employeesWithoutPotentialShift = props.employees.filter((employee) => employee.potentialTransportModes.length === 0 && employee.transportMode.category === props.transportCategory);

  const employeesToVisualize = [
    ...employeesWithPotentialShift,
    ...employeesWithoutPotentialShift,
  ].filter((value, index, self) => self.indexOf(value) === index); // Distinct


  const [selectedLayers, setSelectedLayers] = useState<MapLayerCollectionKey[]>(props.initialSelectedLayers ?? []);
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>(employeesToVisualize);


  const onEmployeeFiltersUpdated = (selectedTransportModes: TransportMode[], showEmployeesWithoutPotential: boolean, selectedSchedule?: Schedule) => {
    const filteredTransportModeIds = selectedTransportModes.map(mode => mode.id);

    const employeesWithPotential = employeesWithPotentialShift
      .filter((employee) => {
        const matchesWorkSchedule = selectedSchedule === undefined || employee.schedule.id === selectedSchedule.id;
        const matchesCurrentTransportMode = filteredTransportModeIds.includes(employee.transportMode.id);
        const matchesPotentialTransportMode = employee.potentialTransportModes.map((potential) => potential.transportMode.id).find(transportModeId => filteredTransportModeIds.includes(transportModeId)) != null;//employee.potentialTransportMode != null && filteredTransportModeIds.includes(employee.potentialTransportMode.id);
        return (matchesCurrentTransportMode || matchesPotentialTransportMode) && matchesWorkSchedule;
      });

    const employeesWithoutPotential = showEmployeesWithoutPotential
      ? employeesWithoutPotentialShift
      : [];

    const filteredEmployees = [
      ...employeesWithPotential,
      ...employeesWithoutPotential,
    ];
    setFilteredEmployees(filteredEmployees);
  };

  const employeeToIcon = (employee: Employee) => {
    const potential = employee.potentialTransportModes.find((potential) => potential.transportMode.category === props.transportCategory);
    if (potential) {
      return <CircleMapIcon dataType={ potential.transportMode.id.toLowerCase() } label={ t('transport_mode_' + potential.transportMode.id.toLowerCase()) } />;
    }

    if (employee.transportMode.category === props.transportCategory) {
      return <CircleMapIcon dataType={ 'no_potential' } label={ t('map_marker_no_potential') } />;
    }

    return <></>;
  };

  const getToolTipContent = (employee: Employee) => {
    return <ToolTipContent employee={ employee } transportCategory={ props.transportCategory } />;
  };

  return employeesToVisualize.length === 0
    ? <div >
      { t('potential_shift_map_no_data') }
    </div >
    : <div className={ styles.mapContainer }>
      <div className={ styles.mapContainerMap }>
        <MapComponent center={ props.establishmentCoordinates }>

          <ProvinceBorderLayer />

          <EstablishmentLayer
            establishment={ props.establishment }
            establishmentLocation={ props.establishmentCoordinates }
          />

          <LayerCollectionRenderer
            layersToRender={ selectedLayers }
            isolineCollections={ props.isolineCollections }
            establishmentCoordinates={ props.establishmentCoordinates }
          />

          <EmployeeLayer
            employees={ filteredEmployees }
            getTooltipContent={ getToolTipContent }
            getIcon={ employeeToIcon }
          />

        </MapComponent>
      </div>
      <div className={ styles.mapContainerLegend }>
        <MapFilterLegend
          establishment={ props.establishment }
          transportModes={ getTransportModesByCategory(props.transportCategory) }
          schedules={ props.schedules }
          onEmployeeFiltersUpdated={ onEmployeeFiltersUpdated }
          showPotentialCheckbox={ true }
          showPotentialLabelKey={ props.showPotentialLabelKey }
          initialSelectedLayers={ props.initialSelectedLayers }
          onSelectedLayersUpdated={ setSelectedLayers }
          availableLayerCollections={ availableLayerCollections }
        />
      </div>
    </div>;
}

interface ToolTipContentProps {
  employee: Employee,
  transportCategory: TransportCategory,
}

function ToolTipContent(props: ToolTipContentProps) {
  const { t } = useTranslation();

  const title = t('potential_shift_map_tooltip_title', { employeeId: props.employee.id });
  const currentTravelTime = roundNumber(props.employee.travelSeconds / 60); // in minutes
  const currentTravelDistance = roundNumber(props.employee.travelMeters / 1000, 1); // in km
  const currentTransportModeName = t('transport_mode_' + props.employee.transportMode.id.toLowerCase());

  const potential = props.employee.potentialTransportModes.find((potential) => potential.transportMode.category === props.transportCategory);

  return <>
    <div><b>{ title }</b></div>
    <table className={ styles.PotentialShiftMapTable }>
      <thead className="visually-hidden">
        <tr>
          <th>{ t('potential_shift_map_tooltip_table_header_transport_mode') }</th>
          <th>{ t('potential_shift_map_tooltip_table_header_transport_travel_time') }</th>
          <th>{ t('potential_shift_map_tooltip_table_header_transport_travel_distance') }</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{ currentTransportModeName }</th>
          <td>{ `${currentTravelTime} min` }</td>
          <td>{ `${currentTravelDistance} km` }</td>
        </tr>
        {
          potential &&
          <tr>
            <th>{ t('transport_mode_' + potential.transportMode.id.toLowerCase()) }</th>
            <td>{ `${roundNumber(potential.seconds! / 60)} min` }</td> {/* in minutes /*/ }
            <td>{ `${roundNumber(potential.meters! / 1000, 1)} km` }</td> {/* in km /*/ }
          </tr>
        }
      </tbody>
    </table>
  </>;
}
