
import { mapIso8601StringToDate } from "features/common/util/date";
import { MobiscanOverviewItemDTO } from "features/mobiscan/overview/data/models";
import { CompletedMobiscanOverviewItem, DraftMobiscanOverviewItem, InProgressMobiscanOverviewItem, MobiscanOverviewItem, MobiscanStatus } from "features/mobiscan/overview/domain/models";

export function mapMobiscanOverviewItemsFromDTOs(dtos: MobiscanOverviewItemDTO[]): MobiscanOverviewItem[] {
  return dtos.map((dto) => mapMobiscanOverviewItemFromDTO(dto));
}

export function mapMobiscanOverviewItemFromDTO(dto: MobiscanOverviewItemDTO): MobiscanOverviewItem {
  if (dto.status === MobiscanStatus.DRAFT) {
    return mapDraftMobiscanOverviewItemFromDTO(dto);
  } else if (dto.status === MobiscanStatus.IN_PROGRESS) {
    return mapInProgressMobiscanOverviewItemFromDTO(dto);
  } else if (dto.status === MobiscanStatus.COMPLETE) {
    return mapCompletedMobiscanOverviewItemFromDTO(dto);
  } else {
    throw Error('invalid mobiscan status');
  }
}

function mapDraftMobiscanOverviewItemFromDTO(dto: MobiscanOverviewItemDTO): DraftMobiscanOverviewItem {
  return {
    id: dto.id,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDate: mapIso8601StringToDate(dto.startedDateTime),
    modifiedDate: mapIso8601StringToDate(dto.modificationDateTime),
    status: MobiscanStatus.DRAFT
  };
}

function mapInProgressMobiscanOverviewItemFromDTO(dto: MobiscanOverviewItemDTO): InProgressMobiscanOverviewItem {
  return {
    id: dto.id,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDate: mapIso8601StringToDate(dto.startedDateTime),
    requestedBy: dto.requestedBy!,
    requestedDate: mapIso8601StringToDate(dto.requestedDateTime!),
    modifiedDate: mapIso8601StringToDate(dto.modificationDateTime),
    status: MobiscanStatus.IN_PROGRESS
  };
}

function mapCompletedMobiscanOverviewItemFromDTO(dto: MobiscanOverviewItemDTO): CompletedMobiscanOverviewItem {
  return {
    id: dto.id,
    establishment: dto.establishment,
    startedBy: dto.startedBy,
    startedDate: mapIso8601StringToDate(dto.startedDateTime),
    requestedBy: dto.requestedBy!,
    requestedDate: mapIso8601StringToDate(dto.requestedDateTime!),
    modifiedDate: mapIso8601StringToDate(dto.modificationDateTime),
    status: MobiscanStatus.COMPLETE,
    durableActualPercentage: dto.potential!.durableActualPercentage,
    durablePotentialPercentage: dto.potential!.durablePotentialPercentage
  };
}
