import Tooltip from "rc-tooltip";
import styles from "./HorizontalStackedBarChart.module.scss";

export interface LegendLabel {
  label: string,
  dataType: string,
  className?: string,
}

export interface StackedBar {
  label: string,
  count: number,
  segments: StackedBarSegment[],
  className?: string,
}

export interface StackedBarSegment {
  count: number,
  percentage: number,
  tooltipTitle: string,
  tooltipContent: string,
  dataType: string,
}

interface HorizontalStackedBarChartProps {
  legendLabels: LegendLabel[],
  bars: StackedBar[],
  a11yLabelGroupName?: string,
  a11yLabelCount?: string,
  a11yLabelComposition?: string,
}

export function HorizontalStackedBarChart(props: HorizontalStackedBarChartProps) {
  return <>
    <div className={ styles.distanceChart }>
      <figure className={ styles.figure }>
        <div className={ styles.figureInner }>
          <div className={ styles.figureGraphicBlock }>

            <div className={ styles.figureTableWrapper }>
              <table className={ styles.figureTable }>
                <thead className="visually-hidden">
                  <tr>
                    <th>{ props.a11yLabelGroupName }</th>
                    <th>{ props.a11yLabelCount }</th>
                    <th>{ props.a11yLabelComposition }</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.bars.map((bar, i) => {
                      return <Bar key={ `row-${i}` } bar={ bar } />;
                    })
                  }
                </tbody>
                <tfoot>
                  <PercentageBar />
                </tfoot>
              </table>
            </div>

          </div>
          <Legend labels={ props.legendLabels } />
        </div>
      </figure>
    </div>
  </>;
}


interface BarProps {
  bar: StackedBar,
}

function Bar(props: BarProps) {

  return <tr>
    <th>{ props.bar.label }</th>
    <td>
      <span className={ styles.figureNumberCircle }>
        { props.bar.count }
      </span>
    </td>
    <td>

      <ul className={ styles.figureBar }>
        {
          props.bar.segments.map((segment) => {
            return <>
              {
                segment.percentage > 0 &&
                <BarSegment
                  segment={ segment }
                  key={ `fig-${segment.dataType}` }
                  className={ props.bar.className }
                />
              }
            </>
          })
        }
      </ul>

    </td>
  </tr>
}


interface BarSegmentProps {
  segment: StackedBarSegment,
  className?: string,
}

function BarSegment(props: BarSegmentProps) {

  return <Tooltip
    placement="top"
    overlay={ <TooltipContent title={ props.segment.tooltipTitle } content={ props.segment.tooltipContent } /> }
  >
    <li className={ `${styles.figureBarItem} ${props.className}` } data-type={ props.segment.dataType } style={ { width: `${props.segment.percentage}%` } } >
      <span className={ styles.figureBarItemLabel }>{ props.segment.tooltipTitle }</span>
      <span className={ styles.figureBarItemNumber }>{ props.segment.count }</span>
    </li>
  </Tooltip>
}


interface TooltipContentProps {
  title: string,
  content: string,
}

function TooltipContent(props: TooltipContentProps) {
  return <>
    <div><strong>{ props.title }</strong></div>
    <div>{ props.content }</div>
  </>;
}

function PercentageBar() {
  return <tr>
    <td></td>
    <td></td>
    <td>
      <div className={ styles.percentageBar }>
        <div className={ styles.percentageBarItem }>
          <span className={ styles.percentageBarItemPercentage }>0%</span>
        </div>
        <div className={ styles.percentageBarItem }>
          <span className={ styles.percentageBarItemPercentage }>20%</span>
        </div>
        <div className={ styles.percentageBarItem }>
          <span className={ styles.percentageBarItemPercentage }>40%</span>
        </div>
        <div className={ styles.percentageBarItem }>
          <span className={ styles.percentageBarItemPercentage }>60%</span>
        </div>
        <div className={ styles.percentageBarItem }>
          <span className={ styles.percentageBarItemPercentage }>80%</span>
        </div>
        <div className={ `${styles.percentageBarItem} ${styles.percentageBarItemFinal}` }>
          <span className={ styles.percentageBarItemPercentage }>100%</span>
        </div>
      </div>
    </td>
  </tr>;
}

interface LegendProps {
  labels: LegendLabel[],
}

function Legend(props: LegendProps) {
  return <div className={ styles.figureLegendBlock }>
    <ul className={ styles.figureLegend }>
      {
        props.labels.map((label) => {
          return <li className={ `${styles.figureLegendItem} ${label.className}` } data-type={ label.dataType } key={ `legend-${label.dataType}` }>
            <span className={ styles.figureLegendItemLabel }>{ label.label }</span>
          </li>;
        })
      }
    </ul>
  </div>;
}
