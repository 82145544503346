import React from 'react';
import styles from "./RadioButton.module.scss";

interface RadioButtonProps {
  identifier: string; //To use as html#id
  checked: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export function RadioButton(props: RadioButtonProps) {
  return (
    <div className={ styles.radioButton }>
      <input type="radio" id={ props.identifier } name={ props.identifier } checked={ props.checked } onChange={ (e) => props.onChange(e.target.checked) } disabled={ props.disabled } />
      {
        props.children && (<label htmlFor={ props.identifier }>{ props.children }</label >)
      }
    </div>
  );
}
