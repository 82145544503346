import { ApiException } from 'features/common/exceptions/ApiException';
import { EnterpriseApi } from 'features/enterprise/data/EnterpriseApi';
import { AddCustomEstablishmentResponse, CreateEnterpriseUserResponse, CustomEstablishmentInputDTO, DeleteEnterpriseUserResponse, EnterpriseUserDTO, EnterpriseUserRequest, EnterpriseUsersRequest, EstablishmentsRequest, EstablishmentsResponse, UpdateEnterpriseUserResponse } from 'features/enterprise/data/models';
import { EnterpriseUser } from 'features/enterprise/domain/models';

export class EnterpriseMockApi implements EnterpriseApi {

  async fetchEnterpriseUsers(request: EnterpriseUsersRequest): Promise<EnterpriseUserDTO[]> {
    await new Promise(r => setTimeout(r, 2000));

    return [];
  }

  async getEstablishments(request: EstablishmentsRequest): Promise<EstablishmentsResponse> {
    await new Promise(r => setTimeout(r, 2000));
    if (request.cbeNumber === 'mock-error') {
      throw new ApiException(500);
    }
    return {
      cbeNumber: '0123456789',
      establishments: [
        {
          id: 0,
          name: 'De paddenhoek',
          cbeNumber: 'est_1',
          activity: 'Openbare instelling',
          address: {
            street: 'Straatnaam',
            houseNumber: '1',
            box: 'B',
            postalCode: '8200',
            city: 'Brugge',
            country: 'BE',
          },
        },
        {
          id: 1,
          name: 'De Kikkerhoek',
          cbeNumber: 'est_2',
          activity: 'Openbare instelling',
          address: {
            street: 'Burgemeester Charles Rotsaert de Hertainglaan',
            houseNumber: '263',
            box: 'B',
            postalCode: '8000',
            city: 'Brugge',
            country: 'BE',
          },
        },
        {
          id: 2,
          name: 'De paardenhoek',
          cbeNumber: 'est_3',
          activity: 'Openbare instelling',
          address: {
            street: 'Dorpstraat',
            houseNumber: '1',
            box: '',
            postalCode: '8000',
            city: 'Brugge',
            country: 'BE',
          },
        },
        {
          id: 3,
          name: 'De Kattenhoek',
          cbeNumber: 'est_4',
          activity: 'Openbare instelling',
          address: {
            street: 'Noordhollandschkanaaldijk',
            houseNumber: '43',
            box: 'C',
            postalCode: '8800',
            city: 'Roeselare',
            country: 'BE',
          },
        },
      ],
    };
  }

  async createEnterpriseUser(provinceUser: EnterpriseUser): Promise<CreateEnterpriseUserResponse> {
    await new Promise(r => setTimeout(r, 1000));

    if (provinceUser.firstName === '400') {
      throw new Error('mock error');
    }

    return {
      success: true,
      validationCodes: [],
    };
  }

  async deleteEnterpriseUser(id: number): Promise<DeleteEnterpriseUserResponse> {
    await new Promise(r => setTimeout(r, 2000));

    return {
      success: true,
      validationCodes: [],
    };
  }

  async updateEnterpriseUser(request: EnterpriseUserRequest): Promise<UpdateEnterpriseUserResponse> {
    await new Promise(r => setTimeout(r, 2000));

    return {
      success: true,
      validationCodes: [],
    };
  }

  async requestDeleteEnterprise(cbeNumber: string): Promise<void> {
    await new Promise(r => setTimeout(r, 2000));
  }

  async deleteEstablishment(cbeNumber: string, establishmentId: number): Promise<void> {
    await new Promise(r => setTimeout(r, 2000));
  }

  async addEstablishment(cbeNumber: string, establishmentCbe: string): Promise<void> {
    await new Promise(r => setTimeout(r, 2000));
  }

  async addCustomEstablishment(cbeNumber: string, customEstablishment: CustomEstablishmentInputDTO): Promise<AddCustomEstablishmentResponse> {
    await new Promise(r => setTimeout(r, 2000));
    return {
      success: true,
      validationCodes: [],
    }
  }

}
