import { EstablishmentPermission, UserRole } from "features/auth/domain/models";
import { FetchStatus, useFetch } from "features/common/hooks/UseFetch";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { EmailTextField } from "features/common/ui/forms/EmailTextField";
import { TextField } from "features/common/ui/forms/TextField";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { EnterpriseUser, Establishment } from "features/enterprise/domain/models";
import { validateEnterpriseUserAddForm } from "features/enterprise/domain/validation";
import { Scope } from "features/mobiscan/permissions/domain/definitions";
import ScopeGate from "features/mobiscan/permissions/ui/ScopeGate";
import { ValidationResult } from "features/validation/domain/models";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as routes from "routes";
import * as enterpriseServices from "features/enterprise/domain/enterpriseServices";
import { RoleEditor } from "features/mobiscan/permissions/ui/RoleEditor";
import { useAppSelector } from "redux/store";

export function EnterpriseUserAdd() {
  const { t } = useTranslation();
  const enterpriseUser = {
    id: 0,
    firstName: '',
    lastName: '',
    phone: '',
    jobTitle: '',
    email: '',
    role: UserRole.ORG_ADMIN,
    establishmentPermissions: []
  }

  return <ScopeGate requiredScope={ Scope.MANAGE_USERS }>
    <Layout>
      <PageIntro>

        <PageIntroItem position={ IntroPosition.left }>
          <BackButton label={ t('mobiscan_users_header_back_to_overview') } targetUrl={ routes.ENTERPRISE_USERS } displayType={ BackButtonType.box } />
          <h1 className="page-title">{ t('mobiscan_users_header_title') }</h1>
        </PageIntroItem>

      </PageIntro>

      <div className="content-wrapper">
        <div className="inner-wrapper">
          { <EnterpriseUserDataForm initialValues={ enterpriseUser } /> }
        </div>
      </div>

    </Layout>
  </ScopeGate>;
}

interface EnterpriseUserDataFormProps {
  initialValues: EnterpriseUser
}

function EnterpriseUserDataForm(props: EnterpriseUserDataFormProps) {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user!);
  const navigate = useNavigate();

  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const fetchEstablishments = useCallback(async () => {
    return enterpriseServices.getEstablishments(user.enterprise!.cbeNumber);
  }, [user.enterprise]);

  const establishmentsFetch = useFetch<Establishment[]>(fetchEstablishments);

  const sendAddRequest = async () => {
    setValidationResult(undefined);

    // Client side validate
    const validationResult = validateEnterpriseUserAddForm(firstName, lastName, jobTitle, phone, email, t);
    if (!validationResult.isValid) {
      setValidationResult(validationResult);
      return;
    }

    const result = await enterpriseServices.createEnterpriseUser({
      id: 0,
      firstName: firstName,
      lastName: lastName,
      jobTitle: jobTitle,
      phone: phone,
      email: email,
      role: role,
      establishmentPermissions: role === UserRole.ORG_USER ? establishmentPermissions : []
    });

    if (!result.success) {
      setValidationResult(result.validationResult);
      return;
    }

    navigate(routes.ENTERPRISE_USERS);
  }

  const { executeFetch, fetchStatus } = useFetch<void>(sendAddRequest, false);

  const [firstName, setFirstName] = useState(props.initialValues.firstName);
  const [lastName, setLastName] = useState(props.initialValues.lastName);
  const [jobTitle, setJobTitle] = useState(props.initialValues.jobTitle);
  const [phone, setPhone] = useState(props.initialValues.phone);
  const [email, setEmail] = useState(props.initialValues.email);

  const [role, setRole] = useState(props.initialValues.role);
  const [establishmentPermissions, setEstablishmentPermissions] = useState(props.initialValues.establishmentPermissions);

  const setEnterpriseUserRole = (enterpriseUser: EnterpriseUser, role: UserRole) => {
    setRole(role);
  }

  const setEnterpriseUserEstablishmentPermissions = (enterpriseUser: EnterpriseUser, establishmentPermissions: EstablishmentPermission[]) => {
    setEstablishmentPermissions(establishmentPermissions);
  }

  const hasNoEstablishmentPermissions = role === UserRole.ORG_USER && establishmentPermissions?.length === 0;

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    executeFetch();
  };

  return <form className="form form--user-info" onSubmit={ onSubmit } noValidate={ true }>
    <div className="form-row">
      <TextField identifier='first-name' value={ firstName } onChange={ setFirstName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('mobiscan_users_first_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='last-name' value={ lastName } onChange={ setLastName } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('mobiscan_users_last_name') } />
    </div>
    <div className="form-row">
      <TextField identifier='job-title' value={ jobTitle } onChange={ setJobTitle } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('mobiscan_users_job_title') } />
    </div>
    <div className="form-row">
      <TextField identifier='phone' value={ phone } onChange={ setPhone } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('user_page_form_phone') } />
    </div>
    <div className="form-row">
      <EmailTextField identifier='email' value={ email } onChange={ setEmail } disabled={ fetchStatus === FetchStatus.pending } required={ true } label={ t('mobiscan_users_email') } />
    </div>

    <div>
      <RoleEditor radioKey={ 0 } enterpriseUser={ props.initialValues } role={ UserRole.ORG_ADMIN } establishments={ establishmentsFetch.fetchResult! } establishmentPermissions={ [] } setEnterpriseUserRole={ setEnterpriseUserRole } setEnterpriseUserEstablishmentPermissions={ setEnterpriseUserEstablishmentPermissions } />
    </div>

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('mobiscan_users_add_unknown_error') } />
      }

      {
        validationResult
        && !validationResult.isValid
        && <ValidationErrorBox mainMessage={ t('user_validation_title') } messages={ validationResult.messages } />
      }

    </div>

    <div className="form-actions">
      <div className="form-action form-action--large">

        {
          fetchStatus === FetchStatus.pending &&
          <button
            className="form-button"
            type='submit'
            disabled
          >
            { t('mobiscan_users_add_is_saving') }
          </button>
        }

        {
          fetchStatus !== FetchStatus.pending &&
          <button
            className="form-button"
            type='submit'
            disabled={ hasNoEstablishmentPermissions }
          >
            { t('mobiscan_users_add') }
          </button>
        }

      </div>
    </div>

  </form>
}