import { httpClient } from "features/common/data/repositories";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { Report } from "features/mobiscan/report/ui/Report";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export function SharedReportPage() {
  const { shareToken } = useParams();
  const { t } = useTranslation();
  const { id } = useParams();

  if (shareToken != null && id != null) {
    httpClient.setShareToken(shareToken);
    return <>
      <Report
        mobiscanId={ id }
        hideActions={ true }
        hideBackButton={ true }
      />
    </>;
  }

  return <Layout>
    <PageIntro>
      <ErrorBox errorMessage={ t('general_unknown_error') } />
    </PageIntro>
  </Layout >;
}
