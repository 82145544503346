import { AppConfig } from "config/AppConfig";
import { httpClient } from "features/common/data/repositories";
import { ApiException } from "features/common/exceptions/ApiException";
import { mapCommuteDistancesDTOFromJson, mapEmployeesByCityDTOFromJson, mapEmployeesByScheduleDTOFromJson, mapEmployeesDTOsFromJson, mapIsolinesCollectionDTOFromJson, mapMeasuresAndInspirationsDTOFromJson, mapMobiscanDtoFromJson, mapReportInfrastructureDTOFromJson, mapReportPolicyDTOFromJson, mapShareTokenFromJson, mapSummaryDTOFromJson } from "features/mobiscan/report/data/mappers";
import { CommuteDistancesDTO, EmployeeDTO, EmployeesByCityDTO, EmployeesByScheduleDTO, IsolinesCollectionDTO, MeasuresAnsInspirationsDTO, MobiscanDTO, ReportInfrastructureDTO, ReportPolicyDTO, ShareTokenDTO, SummaryDTO } from "features/mobiscan/report/data/models";

export interface MobiscanReportApi {
  getMobiscan(mobiscanId: string): Promise<MobiscanDTO>,

  getSummary(mobiscanId: string): Promise<SummaryDTO>,

  getCommuteDistances(mobiscanId: string): Promise<CommuteDistancesDTO>,
  getEmployees(mobiscanId: string): Promise<EmployeeDTO[]>,
  getEmployeesByCity(mobiscanId: string): Promise<EmployeesByCityDTO>,
  getEmployeesBySchedule(mobiscanId: string): Promise<EmployeesByScheduleDTO>,

  getIsolines(mobiscanId: string): Promise<IsolinesCollectionDTO>,

  getPolicy(mobiscanId: string): Promise<ReportPolicyDTO>;
  getInfrastructure(mobiscanId: string): Promise<ReportInfrastructureDTO>;

  getMeasuresAndInspirations(mobiscanId: string): Promise<MeasuresAnsInspirationsDTO>;

  getPotentialExportBlob(mobiscanId: string): Promise<Blob>;

  getShareToken(mobiscanId: string): Promise<ShareTokenDTO>;
}

export class MobiscanReportApiImpl implements MobiscanReportApi {
  async getMobiscan(mobiscanId: string): Promise<MobiscanDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapMobiscanDtoFromJson(json.mobiscan);
    }

    throw new ApiException(response.status);
  }

  async getSummary(mobiscanId: string): Promise<SummaryDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/summary`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapSummaryDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getCommuteDistances(mobiscanId: string): Promise<CommuteDistancesDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/commute`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapCommuteDistancesDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getEmployees(mobiscanId: string): Promise<EmployeeDTO[]> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/employees`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapEmployeesDTOsFromJson(json);
    }

    throw new ApiException(response.status);
  }


  async getEmployeesByCity(mobiscanId: string): Promise<EmployeesByCityDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/employees-by-city`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapEmployeesByCityDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getEmployeesBySchedule(mobiscanId: string): Promise<EmployeesByScheduleDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/employees-by-schedule`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapEmployeesByScheduleDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getPolicy(mobiscanId: string): Promise<ReportPolicyDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/policy`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapReportPolicyDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getInfrastructure(mobiscanId: string): Promise<ReportInfrastructureDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/infrastructure`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapReportInfrastructureDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getMeasuresAndInspirations(mobiscanId: string): Promise<MeasuresAnsInspirationsDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/treffik`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapMeasuresAndInspirationsDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getIsolines(mobiscanId: string): Promise<IsolinesCollectionDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/isolines`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapIsolinesCollectionDTOFromJson(json);
    }

    throw new ApiException(response.status);
  }

  async getPotentialExportBlob(mobiscanId: string): Promise<Blob> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/export`;
    const options = { method: 'GET' };
    const response = await httpClient.shareTokenOrAuthorizedFetch(url, options);

    if (response.status === 200) {
      return await response.blob();
    }

    throw new ApiException(response.status);
  }

  async getShareToken(mobiscanId: string): Promise<ShareTokenDTO> {
    const url = AppConfig.API_URL + `/mobiscan/${mobiscanId}/share`;
    const options = { method: 'GET' };
    const response = await httpClient.authorizedFetch(url, options);

    if (response.status === 200) {
      const json = await response.json();
      return mapShareTokenFromJson(json);
    }

    throw new ApiException(response.status);
  }


}