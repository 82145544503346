export enum IntroPosition {
  top,
  right,
  bottom,
  left,
}

interface PageIntroItemProps {
  position: IntroPosition,
  children?: React.ReactNode,
}

export function PageIntroItem(props: PageIntroItemProps): JSX.Element {

  return <>
    { props.children }
  </>;
}
