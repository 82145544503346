import { Layout } from "features/common/ui/layout/Layout";
import { getEnterpriseRegistrationInfo, resetState } from "features/register/domain/registrationOperations";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, } from "redux/store";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import { BackButton, BackButtonType } from "features/common/ui/BackButton";
import { TextField } from "features/common/ui/forms/TextField";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router";
import * as validation from "features/register/domain/validation";
import { EnterpriseInfo } from "features/magda/domain/models";
import { FetchStatus } from "features/common/hooks/UseFetch";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import * as routes from "routes";

export default function RegisterPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <BackButton label={ t('register_page_header_back') } targetUrl={ routes.LOGIN } displayType={ BackButtonType.box } />
        <h1 className="page-title">{ t('register_page_header_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <div className="content-wrapper">
      <div className="inner-wrapper">
        {
          <CbeNumberForm />
        }
      </div>
    </div>
  </Layout>;
}

function CbeNumberForm() {
  const { t } = useTranslation();
  const [cbeNumber, setCbeNumber] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    executeFetch();
  };

  const fetchEnterpriseInfo = async () => {
    // Client side validate
    const validationResult = validation.validateCbeForm(cbeNumber, t);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const enterpriseInfoAction = await dispatch(getEnterpriseRegistrationInfo(cbeNumber));
    const result = unwrapResult(enterpriseInfoAction);

    if (result.success) {
      navigate(routes.REGISTER_STEP_TWO);
      return { result: result.enterpriseInfo };
    }

    return { validationResult: result.validationResult };
  }

  const { executeFetch, fetchStatus, validationResult } = useValidatedFetch<EnterpriseInfo>(fetchEnterpriseInfo, false);


  return <form onSubmit={ onSubmit }>
    <TextField identifier="cbeNumber" value={ cbeNumber } onChange={ setCbeNumber } required={ true } hideAsterisk={ true } label={ t('register_form_cbe_number') } disabled={ fetchStatus === FetchStatus.pending } />

    <div role="alert"> {/* "alert" content will be read out */ }

      {
        validationResult
        && !validationResult.isValid
        && <ValidationErrorBox mainMessage={ t('register_validation_title') } messages={ validationResult.messages } />
      }

      {
        fetchStatus === FetchStatus.unknownError
        && <ErrorBox errorMessage={ t('register_magda_unknown_error') } />
      }

    </div>

    {
      fetchStatus === FetchStatus.pending
        ? <LoadingIndicator />
        : <div className="form-actions">
          <div className="form-action form-action--large">
            <button type="submit" className="form-button">{ t('register_form_next') }</button>
          </div>
        </div>
    }
  </form>
}
