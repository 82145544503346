import { Coordinates } from "features/map/domain/models";
import { renderToString } from "react-dom/server";
import { Marker } from "react-leaflet";
import { divIcon } from "leaflet";
import styles from "./TextMarker.module.scss";

interface TextMarkerProps {
  coordinates: Coordinates,
  text: string;
}

export const TextMarker = (props: TextMarkerProps) => {
  const iconHtml = renderToString(<TextMarkerText text={ props.text } />);

  // We'll have to "hack" a bit around to make the iconSize work, as it can't be sized dynamically 
  // If you leave it as undefined, the icon anchor is all wrong, and to define the anchor you would need to base it on the size
  // which we don't have, as the size would be dynamic...
  return <Marker
    position={ [props.coordinates.latitude, props.coordinates.longitude] }
    icon={ divIcon({ html: iconHtml, iconSize: [50, 20] }) }
  />
}

interface TextMarkerTextProps {
  text: string;
}

export const TextMarkerText = (props: TextMarkerTextProps) => {
  return <div className={ styles.TextMarker }>
    { props.text }
  </div>
}
