import { provinceApi, provinceStorage } from "features/common/data/repositories";
import { DropdownOption } from "features/common/ui/forms/Dropdown";
import { mapPendingRegistrationFromDTO, mapPendingRegistrationListFromDTOs, mapRegisteredEnterpriseListFromDTOs, mapProvinceUsersFromDTOs, mapCreateProvinceUserResponse, mapDeleteProvinceUserResponse, mapProvinceMobiscanListFromDTOs } from "features/province/domain/mappers";
import { PendingRegistration, RegisteredEnterprise, ProvinceUser, CreateProvinceUserResult, DeleteProvinceUserResult, ProvinceMobiscan } from "features/province/domain/models";


export async function getPendingRegistrations(): Promise<PendingRegistration[]> {
  const dtos = await provinceApi.fetchPendingRegistrations();

  return mapPendingRegistrationListFromDTOs(dtos);
}

export async function getPendingRegistration(cbeNumber: string): Promise<PendingRegistration | null> {
  const dtos = await provinceApi.fetchPendingRegistrations();
  const matchingRegistrationDto = dtos.find((dto) => dto.enterprise.cbeNumber === cbeNumber);

  if (matchingRegistrationDto) {
    return mapPendingRegistrationFromDTO(matchingRegistrationDto);
  } else {
    return null;
  }
}

export async function approveRegistration(registration: PendingRegistration): Promise<void> {
  await provinceApi.approveRegistration(registration.enterprise.cbeNumber);
}

export async function rejectRegistration(registration: PendingRegistration): Promise<void> {
  await provinceApi.rejectRegistration(registration.enterprise.cbeNumber);
}

export async function getRegisteredCompanies(): Promise<RegisteredEnterprise[]> {
  const dtos = await provinceApi.fetchRegisteredEnterprises();

  return mapRegisteredEnterpriseListFromDTOs(dtos);
}

export function filterCompanies(enterpriseList: RegisteredEnterprise[], searchText: string): RegisteredEnterprise[] {
  return enterpriseList.filter((enterprise) => {
    const searchTextForName = searchText.trim().toLowerCase(); //TODO: maybe cleanup/reform
    const cleanedUpEnterpriseName = enterprise.name.trim().toLowerCase();
    const matchesEnterpriseName = cleanedUpEnterpriseName.includes(searchTextForName);

    const searchTextForCbe = searchText.replace(/\D/g, "");
    const matchesCbe = searchTextForCbe.length > 0 ? enterprise.cbeNumber.includes(searchTextForCbe) : false;

    return matchesEnterpriseName || matchesCbe;
  });
}

export async function loginAsEnterprise(cbeNumber: string) {
  await provinceStorage.persistProvinceEnterpriseCbeNumber(cbeNumber);
}

export async function logoutFromEnterprise() {
  await provinceStorage.deleteProvinceEnterpriseCbeNumber();
}

export async function getProvinceMobiscans(): Promise<ProvinceMobiscan[]> {
  const dtos = await provinceApi.fetchProvinceMobiscans();

  return mapProvinceMobiscanListFromDTOs(dtos);
}

export function filterProvinceMobiscans(provinceMobiscans: ProvinceMobiscan[], searchText: string, status: DropdownOption): ProvinceMobiscan[] {
  return provinceMobiscans.filter((provinceMobiscan) => {
    const searchTextForName = searchText.trim().toLowerCase(); //TODO: maybe cleanup/reform
    const cleanedUpEnterpriseName = provinceMobiscan.enterprise.name.trim().toLowerCase();
    const matchesEnterpriseName = cleanedUpEnterpriseName.includes(searchTextForName);

    const searchTextForCbe = searchText.replace(/\D/g, "");
    const matchesCbe = searchTextForCbe.length > 0 ? provinceMobiscan.enterprise.cbeNumber.includes(searchTextForCbe) : false;

    const matchesStatus = status.value === 'ALL' ? true : provinceMobiscan.status === status.value;

    return (matchesEnterpriseName || matchesCbe) && matchesStatus;
  });
}

export async function getProvinceUsers(): Promise<ProvinceUser[]> {
  const dtos = await provinceApi.fetchProvinceUsers();

  return mapProvinceUsersFromDTOs(dtos);
}

export async function createProvinceUser(provinceUser: ProvinceUser): Promise<CreateProvinceUserResult> {
  const result = await provinceApi.createProvinceUser(provinceUser);
  return mapCreateProvinceUserResponse(result);
}

export async function deleteProvinceUser(id: number): Promise<DeleteProvinceUserResult> {
  const result = await provinceApi.deleteProvinceUser(id);
  return mapDeleteProvinceUserResponse(result);
}