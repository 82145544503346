import { basicRadiusesLayerKey, Coordinates, MapLayerCollectionKey, nmbsStationLayerKey } from "features/map/domain/models";
import { IsolineCollection } from "features/mobiscan/report/domain/models";
import { IsolineLayer } from "features/mobiscan/report/ui/tabs/maps/layers/IsolineLayer";
import { NmbsStationsLayer } from "features/mobiscan/report/ui/tabs/maps/layers/NmbsStationsLayer";
import { RadiusLayer } from "features/mobiscan/report/ui/tabs/maps/layers/RadiusLayer";

interface LayerCollectionRendererProps {
  layersToRender: MapLayerCollectionKey[],
  isolineCollections: IsolineCollection[],
  establishmentCoordinates: Coordinates,
}

export function LayerCollectionRenderer(props: LayerCollectionRendererProps) {
  return <>
    {
      props.layersToRender.map((layerKey) => {
        if (layerKey === nmbsStationLayerKey) {
          return <NmbsStationsLayer key='layer-nmbs' />;
        }

        if (layerKey === basicRadiusesLayerKey) {
          return <RadiusLayer key='layer-radius' center={ props.establishmentCoordinates } />;
        }

        const isolineCollection = props.isolineCollections.find(collection => collection.name === layerKey);
        if (isolineCollection) {
          return <IsolineLayer key={ `layer-${isolineCollection.name}` } isolineCollection={ isolineCollection } center={ props.establishmentCoordinates } />
        }

        throw Error('Unknown layer: ' + layerKey);
      })
    }
  </>;
}